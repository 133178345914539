import { useConfirm } from '@/common/hooks/useConfirm';
import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useStaffStatusStore } from '@/modules/staff/domain/stores/updateStaff/staffStatus';
import { RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const ActiveStatus = () => {
  const [ConfirmDialog, confirm] = useConfirm(
    'Are you sure?',
    'You are about to change the status of the staff member. Do you want to continue?'
  );

  const staffMember = useSelector((state: RootState) => state.staff.staffMember.staffMember);
  const { changeStaffStatus, status: changeStatusRequest } = useStaffStatusStore();
  const [status, setStatus] = useState<boolean>(staffMember?.status === 'active');

  useEffect(() => {
    setStatus(staffMember?.status === 'active');
  }, [staffMember?.status]);

  const handleStatusChange = async () => {
    const ok = await confirm();

    if (ok) {
      try {
        const newStatus = !status;
        await changeStaffStatus({
          staff_id: staffMember?.id,
          status: newStatus ? 'active' : 'inactive'
        });

        setStatus(newStatus);

        toast({
          description: `Staff member ${newStatus ? 'activated' : 'inactivated'} successfully`,
          variant: 'default'
        });
      } catch (error) {
        toast({
          description: 'Error changing the status of the staff member',
          variant: 'destructive'
        });
      }
    }
  };

  return (
    <>
      <ConfirmDialog />

      <div className="flex items-center gap-4">
        <Switch checked={status} onCheckedChange={handleStatusChange} disabled={changeStatusRequest === 'loading'} />
        <Label className="text-xs">Active Status</Label>
      </div>
    </>
  );
};
