import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const TrackerElopementService = {
    getTrackerElopement: async (formData: any) => {
        try {
            const response = await axios.get('trackerElopement/getTrackerElopement', {
                params: formData,
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching Elopement: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching Elopement: ' + error.message);
        }
    },
    saveTrackerElopement: async (formData: any) => {
        try {
            const response = await axios.post(`trackerElopement/saveTrackerElopement`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error creating tracker Elopement: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error creating tracker Elopement: ' + error.message);
        }
    }
}

export default TrackerElopementService;