import { useRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '@/store/store';
import * as authActions from '@/auth/presentation/slices/authSlice';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const CHECK_INTERVAL = 30000; // Check every 30 seconds
const DEBUG = false;

export const useSessionExpiration = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const lastActivityRef = useRef(Date.now());
  const checkIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [showExpirationDialog, setShowExpirationDialog] = useState(false);

  const resetTimer = useCallback(() => {
    lastActivityRef.current = Date.now();
    localStorage.setItem('lastActivity', lastActivityRef.current.toString());
    if (DEBUG) console.log('Activity detected, timer reset:', new Date().toISOString());
  }, []);

  const handleLogout = useCallback(async () => {
    setShowExpirationDialog(false);
    localStorage.removeItem('access_token');
    localStorage.removeItem('location_selected');
    localStorage.removeItem('lastActivity');
    localStorage.removeItem('sessionExpired');
    navigate('/login', { replace: true });
    await dispatch(authActions.logout());
  }, [dispatch, navigate]);

  const triggerExpirationAlert = useCallback(() => {
    if (!showExpirationDialog) {
      setShowExpirationDialog(true);
      localStorage.removeItem('access_token');
      localStorage.removeItem('location_selected');
      localStorage.setItem('sessionExpired', 'true');
    }
  }, [showExpirationDialog]);

  const checkInactivity = useCallback(() => {
    const now = Date.now();
    const lastActivity = parseInt(localStorage.getItem('lastActivity') || '0', 10);
    const timeSinceLastActivity = now - lastActivity;

    if (DEBUG) console.log('Time since last activity:', timeSinceLastActivity, 'ms');

    if (timeSinceLastActivity > INACTIVITY_TIMEOUT) {
      if (DEBUG) console.log('Inactivity timeout reached, showing dialog');
      triggerExpirationAlert();
    }
  }, [triggerExpirationAlert]);

  useEffect(() => {
    if (!isAuthenticated || import.meta.env?.VITE_SESSION_EXPIRATION != 'true') {
        return
    };

    const handleActivity = () => {
      resetTimer();
    };

    // Mouse events
    const allEvents = ['mousedown', 'mousemove', 'touchstart', 'scroll', 'keydown', 'click'];
    allEvents.forEach(event => window.addEventListener(event, handleActivity, true));

    // Init interval checker
    checkIntervalRef.current = setInterval(checkInactivity, CHECK_INTERVAL);

    // Initialize last activity timestamp
    if (!localStorage.getItem('lastActivity')) {
      resetTimer();
    }

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'sessionExpired' && event.newValue === 'true') {
        setShowExpirationDialog(true);
      }
    };
    window.addEventListener('storage', handleStorageChange);

    if (DEBUG) console.log('Session expiration monitoring started');

    return () => {
      allEvents.forEach(event => window.removeEventListener(event, handleActivity, true));
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
      }
      window.removeEventListener('storage', handleStorageChange);
      if (DEBUG) console.log('Session expiration monitoring stopped');
    };
  }, [isAuthenticated, resetTimer, checkInactivity]);

  const SessionExpirationDialog = () => (
    <Dialog open={showExpirationDialog} onOpenChange={() => {handleLogout();}}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('auth.sessionExpiredInactivity')}</DialogTitle>
        </DialogHeader>
        <p>{t('auth.sessionExpiredInactivityMessage')}</p>
        <DialogFooter>
          <Button className="bg-primary" onClick={handleLogout}>{t('auth.login')}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  return { SessionExpirationDialog };
};
