import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { fetchDeviceById, updateDevice, updateDeviceStatus } from '../slices/SecuritySlice';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { DeviceI, LocationI, UpdateDeviceDataI } from '../../domain/device.domain';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import SelectStatus from '../components/restrictByDevice/SelectStatus';
import { ArrowLeft, Loader2, Trash } from 'lucide-react';
import { toast } from '@/common/presentation/components/ui/use-toast';
import DeviceForm from '../components/restrictByDevice/DeviceForm';
import { useConfirm } from '@/common/hooks/useConfirm';
import { useTranslation } from 'react-i18next';
import { UserHelper } from '@/utils/helpers/user.helper';

interface OptionI {
  label: string;
  value: string;
  imageUrl?: string;
}

interface DeviceInfoFormI {
  name: string;
  description: string;
  status: string;
}

const DeviceEditPage = () => {
  const [selectedUsers, setSelectedUsers] = useState<OptionI[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<OptionI[]>([]);
  const staffList = useSelector((state: RootState) => state.staff.allStaff.staff) ?? [];
  const locationsState = useSelector((state: RootState) => state.locations.allLocations) ?? [];
  const [locations, setLocations] = useState<OptionI[]>([]);
  const [users, setUsers] = useState<OptionI[]>([]);
  const [fullStaffList, setFullStaffList] = useState<any[]>([]);
  const [deviceInfo, setDeviceInfo] = useState<DeviceI | null>(null);
  const { deviceId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { formatDate } = useDateHelpers();
  const [updating, setUpdating] = useState<boolean>(false);
  const navigate = useNavigate();
  const [firstLocationSelection, setFirstLocationSelection] = useState<boolean>(false);
  const { t } = useTranslation();
  const [ConfirmDialog, confirm] = useConfirm(t('security.areYouSure'), t('security.YouAreDeleteTheDevice'));
  const [deviceInfoForm, setDeviceInfoForm] = useState<DeviceInfoFormI>({
    name: '',
    description: '',
    status: 'inactive'
  });

  useEffect(() => {
    getDeviceInfo();
    getStaff();
  }, []);

  useEffect(() => {
    if (!deviceInfo) return;
    loadDefaultData(deviceInfo);
  }, [deviceInfo]);

  const loadDefaultData = (deviceInfo: DeviceI) => {
    const locationsDefault = deviceInfo.locations
      .map((location) => {
        if (location.pivot.status === 'active') {
          return {
            label: location.name,
            value: location.id,
            imageUrl: location.image_path
          };
        }
      })
      .filter((location): location is { label: string; value: string; imageUrl: string } => location !== undefined);
    setSelectedLocations(locationsDefault);

    const usersDefault = deviceInfo.users
      .map((user) => {
        if (user.pivot.status === 'active') {
          return {
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
            imageUrl: user.profile_url
          };
        }
      })
      .filter((user): user is { label: string; value: string; imageUrl: string } => user !== undefined);

      if (deviceInfo.all_users) {        
        setSelectedUsers([
          {
            label: t('security.allUserSelectedLocations'),
            value: 'all-users',
            imageUrl: undefined
          }
        ]);
      } else {
        setSelectedUsers(usersDefault);
      } 
  };

  const getStaff = useCallback(async () => {
    try {
      const response = await dispatch(getStaffByLocation(undefined));
      const { payload } = response;
      setFullStaffList(payload);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (fullStaffList.length == 0) return;

    const updatedUsers = getStaffFromLocations();
    setUsers(updatedUsers);
    // If is the first page load and the user has not selected a location
    if (!firstLocationSelection) {
      return;
    }

    // If all users option is selected we keep it
    if (selectedUsers.some((user: OptionI) => user.value === 'all-users')) {
      return;
    }

    removeStaffNoBelongsLocationSelected(updatedUsers);
  }, [selectedLocations, fullStaffList]);

  const getStaffFromLocations = (): OptionI[] => {
    return fullStaffList
      .filter((staff) =>
        staff.locations.some((location: LocationI) =>
          selectedLocations.some((selected) => selected.value === location.id)
        )
      )
      .map((staff) => ({
        label: `${staff.first_name} ${staff.last_name}`,
        value: staff.id,
        imageUrl: staff.profile_url
      }));
  };

  const removeStaffNoBelongsLocationSelected = (updatedUsers: OptionI[]) => {
    // Keep only the users that were already selected and belongs to selected locations
    const filteredSelectedUsers = selectedUsers.filter((user) =>
      updatedUsers.some((updatedUser) => updatedUser.value === user.value)
    );

    // Get all the users (selected/no selected) that belongs to selected locations
    const usersFromSelectedLocations = fullStaffList
      .filter((staff) =>
        // Staff should belongs at least in 1 selected locations
        staff.locations.some((staffLocation: any) =>
          selectedLocations.some((location) => location.value === staffLocation.id)
        )
      )
      .map((staff) => ({
        label: `${staff.first_name} ${staff.last_name}`,
        value: staff.id,
        imageUrl: staff.profile_url
      }));

    const combinedUsers = [
      ...filteredSelectedUsers,
      ...usersFromSelectedLocations.filter(
        (staff) => !filteredSelectedUsers.some((selectedUser) => selectedUser.value === staff.value)
      )
    ];

    setSelectedUsers(combinedUsers);
  };

  useEffect(() => {
    if (locationsState.status === 'idle') {
      setLocations(formatLocationOptions(locationsState.locations));
    }
  }, [locationsState]);

  const formatLocationOptions = (locations: any[]): OptionI[] => {
    return locations.map((location) => ({
      label: location.name,
      value: location.id,
      imageUrl: location.image_path
    }));
  };

  const getDeviceInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(fetchDeviceById(deviceId as string));
      const device = response.payload.device;
      setDeviceInfo(device);
      setDeviceInfoForm({
        name: device.name,
        description: device.description,
        status: device.status
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, deviceId]);

  const handleUsersChange = (selectedUsers: any) => {
    setSelectedUsers(selectedUsers);
  };

  const handleLocationsChange = (selectedLocations: any) => {
    setFirstLocationSelection(true);
    setSelectedLocations(selectedLocations);
  };

  const inputHandler = (name: string, value: string) => {
    setDeviceInfoForm({
      ...deviceInfoForm,
      [name]: value
    });
  };

  const handleStatusChange = async (newStatus: string) => {
    setDeviceInfoForm({
      ...deviceInfoForm,
      status: newStatus
    });
  };

  const updateDeviceInformation = async () => {
    if (deviceInfoForm.name.length < 3 || deviceInfoForm.name.length > 50) {
      toast({
        title: t('security.invalidDeviceName'),
        description: t('security.deviceNameLength'),
        variant: 'destructive'
      });
      return;
    }

    if (
      !deviceInfoForm.description ||
      deviceInfoForm.description.length < 10 ||
      deviceInfoForm.description.length > 200
    ) {
      toast({
        title: t('security.invalidDescription'),
        description: t('security.descriptionLength'),
        variant: 'destructive'
      });
      return;
    }

    try {
      if (!deviceInfo) return;
      setUpdating(true);
      const formData: UpdateDeviceDataI = {
        id: deviceInfo.id,
        name: deviceInfoForm.name,
        description: deviceInfoForm.description,
        users: selectedUsers.map((user) => user.value),
        locations: selectedLocations.map((location) => location.value),
        status: deviceInfoForm.status
      };
      const response = await dispatch(updateDevice(formData));
      if (response.payload.response) {
        toast({
          title: t('security.deviceUpdatedSuccess'),
          className: 'bg-green-500 text-white'
        });
      } else {
        toast({
          title: t('security.errorUpdatingDevice'),
          variant: 'destructive'
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: t('security.errorUpdatingDevice'),
        description: t('security.unexpectedError'),
        variant: 'destructive'
      });
    }
    setUpdating(false);
  };

  const handleDelete = async (deviceId: string) => {
    const ok = await confirm();

    if (ok) {
      try {
        setUpdating(true);
        await dispatch(
          updateDeviceStatus({
            deviceId: deviceId,
            newStatus: 'removed'
          })
        );

        toast({
          title: t('security.deviceDeletedSuccess'),
          className: 'bg-green-500 text-white'
        });
        setUpdating(false);
        handleGoBack();
      } catch (error) {
        setUpdating(false);
        console.log(error);
      }
    }
  };

  const handleGoBack = () => {
    navigate('/security');
  };

  return (
    <>
      <ConfirmDialog />
      <div className="flex flex-col pt-2">
        <div className="flex items-center space-x-4">
          <Button variant="ghost" onClick={handleGoBack}>
            <ArrowLeft className="mr-2 h-4 w-4" />
            {t('security.back')}
          </Button>
        </div>
        <div className="pt-6">
          <CustomCard
            title={t('security.manageDevice')}
            description={t('security.updateDeviceInfo')}
            button={
              <>
                {deviceInfo && (
                  <div className="flex flex-row gap-2">
                    <SelectStatus
                      handleStatusChange={handleStatusChange}
                      item={{ id: deviceInfo.id, status: deviceInfoForm.status }}
                      isLoading={isLoading || updating}
                      currentValue={deviceInfoForm.status}
                    />
                    <Button
                      variant="outline"
                      size="icon"
                      className="text-red-500"
                      onClick={() => {
                        handleDelete(deviceInfo.id);
                      }}
                    >
                      <Trash className="h-4 w-4" />
                    </Button>
                  </div>
                )}
              </>
            }
          >
            <div className="mt-6 px-2 md:px-4 lg:px-0 flex flex-col gap-6">
              {isLoading ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {[...Array(6)].map((_, index) => (
                    <Skeleton key={index} className="h-10 w-full rounded-md" />
                  ))}
                </div>
              ) : (
                <>
                  <DeviceForm
                    deviceInfoForm={deviceInfoForm}
                    inputHandler={inputHandler}
                    updating={updating}
                    selectedLocations={selectedLocations}
                    selectedUsers={selectedUsers}
                    handleLocationsChange={handleLocationsChange}
                    handleUsersChange={handleUsersChange}
                    locations={locations}
                    users={users}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div>
                      <Label>{t('security.ipAddress')}</Label>
                      <p className="text-gray-700 dark:text-gray-400 mt-1">{deviceInfo?.registered_ip}</p>
                    </div>
                    <div>
                      <Label>{t('security.fingerPrint')}</Label>
                      <p className="text-gray-700 dark:text-gray-400 mt-1 truncate">{deviceInfo?.device_id}</p>{' '}
                    </div>
                    <div>
                      <Label>{t('security.browser')}</Label>
                      <p className="text-gray-700 dark:text-gray-400 mt-1 capitalize">{deviceInfo?.browser}</p>
                    </div>
                    <div>
                      <Label>{t('security.deviceType')}</Label>
                      <p className="text-gray-700 dark:text-gray-400 mt-1 capitalize">
                        {t(`security.${deviceInfo?.type ? deviceInfo.type.toLowerCase() : ''}`)}
                      </p>
                    </div>
                    <div>
                      <Label>{t('security.operatingSystem')}</Label>
                      <p className="text-gray-700 dark:text-gray-400 mt-1 capitalize">{deviceInfo?.operative_system}</p>
                    </div>
                    <div>
                      <Label>{t('security.createdBy')}</Label>
                      <p className="text-gray-700 dark:text-gray-400 mt-1 capitalize">
                        {UserHelper.getStaffName(deviceInfo?.created_by ?? '', staffList)}
                      </p>
                    </div>
                    <div>
                      <Label>{t('security.dateCreated')}</Label>
                      <p className="text-gray-700 dark:text-gray-400 mt-1">
                        {deviceInfo?.created_at ? formatDate(deviceInfo.created_at) : ''}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <Button disabled={updating} onClick={updateDeviceInformation} className="btn btn-primary">
                      {updating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                      {t('security.saveChanges')}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </CustomCard>
        </div>
      </div>
    </>
  );
};

export default DeviceEditPage;
