import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useRouteParams = () => {
  // Obtenemos los parámetros de búsqueda actuales y la función para actualizarlos
  const [searchParams, setSearchParams] = useSearchParams();

  // Convertimos searchParams a un objeto plano para un acceso más sencillo
  const params = useMemo(() => {
    const paramsObj: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      paramsObj[key] = value;
    }
    return paramsObj;
  }, [searchParams]);

  // Función para establecer o actualizar un parámetro de búsqueda
  const setParam = (key: string, value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  // Función para eliminar un parámetro de búsqueda
  const removeParam = (key: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };

  // Función para resetear todos los parámetros de búsqueda
  const resetParams = () => {
    setSearchParams({});
  };

  return { params, setParam, removeParam, resetParams };
};

export default useRouteParams;
