import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { deleteQuickNote } from '../../slices/QuickNotesSlice';

const QuickNotes = {

  // updateQuickNote: async (formData: any) => {
  //   try {
  //     const response = await axios.post(`quickNotes/update`, formData);
  //     if (response.data) {
  //       return response.data;
  //     }
  //   } catch (error: any) {
  //     throw new Error(`Error update quick note: ${error.message}`);
  //   }
  // },
  saveOrUpdateQuickNote: async (formData: any)=>{
    try {
      const response = await axios.post(`quickNotes/saveOrUpdateQuickNote`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error get note categories: ${error.message}`);
    }
  },
  getNoteCategories: async () => {
    try {
      const { data } = await axios.get(`quickNotes/getNoteCategories`);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get note categories: ${error.message}`);
    }
  },
  getQuickNotes: async (formData: any) => {
    try {
      const { data } = await axios.post(`quickNotes/getQuickNotes`, formData);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get note categories: ${error.message}`);
    }
  },
  deleteQuickNote: async (id: string) => {
    try {
      const { data } = await axios.delete(`quickNotes/deleteQuickNote/${id}`);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get note categories: ${error.message}`);
    }
  }

}

export default QuickNotes;