import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    isLoading: boolean;
    currentValue: string;
    item: {
        id: string;
        status: string;
    };
    handleStatusChange: (newStatus: string, itemId: string) => void;
};

const SelectStatus: React.FC<IProps> = ({isLoading, currentValue, item, handleStatusChange}) => {
  const { t } = useTranslation();
  
  const statuses = [
    {
      label: t('security.active'),
      key: 'active',
      classColor: 'bg-green-500'
    },
    {
      label: t('security.pending'),
      key: 'pending',
      classColor: 'bg-amber-500'
    },
    {
      label: t('security.inactive'),
      key: 'inactive',
      classColor: 'bg-gray-500'
    }
  ];

  const getTranslatedStatus = (statusKey: string) => {
    const status = statuses.find(s => s.key === statusKey);
    return status ? status.label : statusKey;
  };

  const getStatusColor = (statusKey: string) => {
    const status = statuses.find(s => s.key === statusKey);
    return status ? status.classColor : 'bg-gray-500';
  };
  
  return (
    <Select
      disabled={isLoading}
      value={currentValue}
      onValueChange={(newStatus) => handleStatusChange(newStatus, item.id)}
    >
      <SelectTrigger className="w-[130px]">
        <SelectValue>
          <span className="flex items-center">
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin mr-2" />
            ) : (
              <span
                className={cn(
                  'h-2 w-2 rounded-full mr-2',
                  getStatusColor(item.status)
                )}
              />
            )}
            {getTranslatedStatus(item.status)}
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {statuses.map((status, index) => (
          <SelectItem key={index} value={status.key}>
            <span className="flex items-center">
              <span className={cn('h-2 w-2 rounded-full mr-2', status.classColor)}></span>
              {status.label}
            </span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default SelectStatus;