import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NoteCategory from "../infrastructure/services/NoteCategory";

interface IPagination {
  total: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
  hasMorePages: boolean;
}

interface INoteCategory {
  id: string;
  category: string;
  type: string;
  status: boolean;
}

interface ICategoryNoteData {
  items: INoteCategory[];
  pagination: IPagination;
}

export interface INoteCategoryState {
  categories: ICategoryNoteData;
  isLoading: boolean;
  error: string | null;
}

const initialState: INoteCategoryState = {
  categories: {
    items: [],
    pagination: {
      total: 0,
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      hasMorePages: false
    }
  },
  isLoading: false,
  error: null
};

export const getNoteCategories = createAsyncThunk(
  'noteCategories/getNoteCategories',
  async (formData: any) => {
    const response = await NoteCategory.getNoteCategories(formData);
    return response;
  }
);

export const saveOrUpdateNoteCategory = createAsyncThunk(
  'noteCategories/saveOrUpdateNoteCategory',
  async (formData: any) => {
    const response = await NoteCategory.saveOrUpdateNoteCategory(formData);
    return response;
  }
);

export const deleteNoteCategory = createAsyncThunk(
  'noteCategories/deleteNoteCategory',
  async (id: string) => {
    const response = await NoteCategory.deleteNoteCategory(id);
    return response;
  }
);

const NoteCategorySlice = createSlice({
  name: 'noteCategories',
  initialState,
  reducers: {
    noteCategoriesLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNoteCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.isLoading = false;
      })
      .addCase(saveOrUpdateNoteCategory.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteNoteCategory.fulfilled, (state) => {
        state.isLoading = false;
      });
  }
});

export const { noteCategoriesLoading } = NoteCategorySlice.actions;
export default NoteCategorySlice.reducer;