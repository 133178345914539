import useRouteParams from '@/common/hooks/RouteParamsHook';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { useInactiveStaffStore } from '@/modules/staff/domain/stores/dashboard/InnactiveStaff';
import { useInactiveStaffDialog } from '@/modules/staff/infrastructure/hooks/dashboard/useInactiveStaffDialog';
import { useEffect, useMemo } from 'react';
import { columns } from '@/modules/staff/presentation/components/dashboard/tables/InnactiveStaffColumns';

export const InactiveStaffDialog = () => {
  const { isOpen, onClose } = useInactiveStaffDialog();
  const { getInactiveStaff, inactiveStaff } = useInactiveStaffStore();
  const { params } = useRouteParams();

  const staff = useMemo(() => {
    return inactiveStaff.map((staff) => {
      return {
        ...staff.user,
        location: staff.location
      };
    });
  }, [inactiveStaff]);

  useEffect(() => {
    const location = params.l === 'all-locations' ? undefined : params.l;
    getInactiveStaff(location);
  }, []);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[80vw]  max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl">Inactive Staff</DialogTitle>
          </DialogHeader>

          <SingleTable data={staff} columns={columns} disable={false} filterKey={'first_name'} />
        </DialogContent>
      </Dialog>
    </>
  );
};
