import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { useUpdateCustomFieldDialog } from '@/modules/staff/infrastructure/hooks/updateStaff/updateCustomField';
import { Eye, EyeOff, Pencil, Trash } from 'lucide-react';
import { UpdateCustomFieldDialog } from './dialogs/UpdateCustomFieldDialog';
import { useCustomFieldsStore } from '@/modules/staff/domain/stores/updateStaff/customFields';

type Props = {
  field: any;
};

export const CustomFieldItem = ({ field }: Props) => {
  const { onOpen: openAddCustomFieldDialog } = useUpdateCustomFieldDialog();
  const { updateCustomFields, getCustomFields, deleteCustomField } = useCustomFieldsStore();

  const handleStatusChange = async (status: string) => {
    const payload = {
      key: field.key,
      value: {
        ...field,
        status
      }
    };

    try {
      await updateCustomFields({ data: payload });
      await getCustomFields();
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCustomField({ key: field.key });
      await getCustomFields();
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <>
      <Card className="border-t-4 border-t-primary/80">
        <CardContent className="flex flex-row items-center justify-between gap-4 p-4">
          {field.label}
          <div className="flex flex-row gap-2">
            <Button className="text-xs" variant={'outline'} size="sm" onClick={() => openAddCustomFieldDialog(field)}>
              <Pencil className="size-4" />
            </Button>

            <Button
              className="text-xs"
              variant={'outline'}
              size="sm"
              onClick={() => handleStatusChange(field.status === 'active' ? 'inactive' : 'active')}
            >
              {field.status === 'active' ? <Eye className="size-4" /> : <EyeOff className="size-4" />}
            </Button>

            <Button
              className="flex gap-2 border-red-500 hover:bg-red-500/10"
              variant={'outline'}
              size={'sm'}
              onClick={handleDelete}
            >
              <Trash className="h-4 w-4 flex-1 text-red-500" />
            </Button>
          </div>
        </CardContent>
      </Card>

      <UpdateCustomFieldDialog />
    </>
  );
};
