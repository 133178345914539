import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Button } from '@/components/ui/button';
import { DeviceI } from '@/modules/security/domain/device.domain';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeviceInfo from '../DeviceInfo';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import LocationsBadge from './LocationsBadge';

interface IProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  device: DeviceI;
}

interface UserI {
  id: string;
  first_name: string;
  last_name: string;
  profile_url: string;
}

const ViewDeviceDialog: React.FC<IProps> = ({ isOpen, setIsOpen, device }) => {
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();
  const staffList = useSelector((state: RootState) => state.staff.allStaff.staff) ?? [];

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      title={t('security.device')}
      description=""
      className="md:max-w-[625px] lg:max-w-[700px]"
      newDialog={true}
      overFlow={true}
    >
      <div className="space-y-4">
        <div className="bg-white dark:bg-zinc-900 rounded-lg px-6 py-2 shadow-sm">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">{device.name}</h3>
          {device.description && <p className="text-sm text-gray-600 dark:text-gray-300">{device.description}</p>}
        </div>

        <div className="bg-white dark:bg-zinc-900 rounded-lg p-6 shadow-sm">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <DeviceInfo label={t('security.ipAddress')} value={device.registered_ip} />
            <DeviceInfo label={t('security.fingerPrint')} value={device.device_id} />
            <DeviceInfo label={t('security.browser')} value={device.browser} />
            <DeviceInfo label={t('security.deviceType')} value={t(`security.${device.type?.toLowerCase() ?? ''}`)} />
            <DeviceInfo label={t('security.operatingSystem')} value={device.operative_system} />
            <DeviceInfo label={t('security.userAgent')} value={device.registered_agent} />

            {device.locations && (
              <DeviceInfo label={t('security.facilities')} value={<LocationsBadge device={device} />} />
            )}

            {device.users && (
              <DeviceInfo
                label={t('security.users')}
                value={device.all_users ? t('security.allUserSelectedLocations') : <UsersBadge users={device.users} />}
              />
            )}
          </div>

          <div className="mt-6 pt-6 border-t dark:border-zinc-800 grid grid-cols-1 md:grid-cols-2 gap-6">
            <DeviceInfo
              label={t('security.createdBy')}
              value={
                device.created_by ? (
                  <div className="flex items-center gap-2">
                    <span>{UserHelper.getStaffName(device.created_by, staffList)}</span>
                  </div>
                ) : (
                  ''
                )
              }
            />
            <DeviceInfo
              label={t('security.dateCreated')}
              value={
                device.created_at ? (
                  <div className="flex items-center gap-2">
                    <span>{formatDate(device.created_at)}</span>
                  </div>
                ) : (
                  ''
                )
              }
            />
          </div>
        </div>

        <div className="flex justify-end">
          <Button className="bg-primary hover:bg-primary/90 transition-colors" onClick={() => setIsOpen(false)}>
            {t('security.close')}
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};

const UsersBadge = ({ users }: { users: UserI[] }) =>
  users.map((user: UserI) => (
    <Badge className="items-center align-middle mx-1 my-1" key={user.id}>
      <Avatar className="mr-2 h-6 w-6">
        <AvatarImage src={user.profile_url} className="h-full w-full object-cover" />
        <AvatarFallback className="text-sm font-normal text-black dark:text-white">
          {`${user.first_name} ${user.last_name}`
            .split(' ')
            .map((chunk: string) => chunk[0])
            .join('')}
        </AvatarFallback>
      </Avatar>
      {`${user.first_name} ${user.last_name}`}
    </Badge>
  ));

export default ViewDeviceDialog;
