import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';

interface Props {
  status: string;
  label: string;
  width?: string;
  height?: string;
}

export function LoadingComponent({ status, label, width = 'w-full', height = 'h-[50vh]' }: Props) {
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (status === 'loading') {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500); // Cambia cada 500 ms

      return () => clearInterval(interval); // Limpia el intervalo al desmontar
    }
  }, [status]);

  return (
    <>
      {status === 'loading' && (
        <div className={cn(`${width} ${height} flex flex-col justify-center items-center`)}>
          <Loader2 className="mx-auto animate-spin size-6 text-primary" />
          <span>
            {label}
            {dots}
          </span>
        </div>
      )}
    </>
  );
}
