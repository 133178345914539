import CustomFieldsService from '@/modules/staff/infrastructure/services/CustomFields';
import { create } from 'zustand';

type CustomFields = {
  custom_fields: any;
  status: 'idle' | 'loading' | 'failed';
  setStatus: 'idle' | 'loading' | 'failed';
  error: string | null;

  getCustomFields: () => Promise<any>;
  setCustomFields: ({ data }: { data: any }) => Promise<any>;
  updateCustomFields: ({ data }: { data: any }) => Promise<any>;
  deleteCustomField: ({ key }: { key: string }) => Promise<any>;
};

export const useCustomFieldsStore = create<CustomFields>((set) => ({
  custom_fields: null,
  status: 'idle',
  setStatus: 'idle',
  error: null,

  getCustomFields: async () => {
    set({ status: 'loading' });
    try {
      const response = await CustomFieldsService.getCustomFields();
      set({ status: 'idle', custom_fields: response.data.custom_fields });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  setCustomFields: async ({ data }) => {
    set({ setStatus: 'loading' });
    try {
      const response = await CustomFieldsService.setCustomFields(data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.errors || error.message;
      set({ setStatus: 'failed', error: errorMessage });
      throw new Error(JSON.stringify(errorMessage));
    }
  },

  updateCustomFields: async ({ data }) => {
    set({ setStatus: 'loading' });
    try {
      const response = await CustomFieldsService.updateCustomFields(data);
      set({ setStatus: 'idle' });
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.errors || error.message;
      set({ setStatus: 'failed', error: errorMessage });
      throw new Error(JSON.stringify(errorMessage));
    }
  },

  deleteCustomField: async ({ key }) => {
    set({ setStatus: 'loading' });
    try {
      const response = await CustomFieldsService.deleteCustomField(key);
      set({ setStatus: 'idle' });
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.errors || error.message;
      set({ setStatus: 'failed', error: errorMessage });
      throw new Error(JSON.stringify(errorMessage));
    }
  }
}));
