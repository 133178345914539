import StaffStatusService from '@/modules/staff/infrastructure/services/StaffStatus';
import { create } from 'zustand';

type InactiveStaffDialogState = {
  inactiveStaff: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  getInactiveStaff: (locationId: string | undefined) => Promise<any>;
};

export const useInactiveStaffStore = create<InactiveStaffDialogState>((set) => ({
  inactiveStaff: [],
  status: 'idle',
  error: null,

  getInactiveStaff: async (locationId) => {
    set({ status: 'loading' });
    try {
      const response = await StaffStatusService.getInactiveStaff(locationId);
      set({ status: 'idle', inactiveStaff: response.data.inactive_staff });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
