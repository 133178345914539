import { Dialog, DialogContent, DialogHeader } from '@/common/presentation/components/ui/dialog';
import { useCustomFieldsStore } from '@/modules/staff/domain/stores/updateStaff/customFields';
import { useUpdateCustomFieldDialog } from '@/modules/staff/infrastructure/hooks/updateStaff/updateCustomField';
import { CustomFieldForm } from '../forms/CustomFieldForm';
import { useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/common/presentation/components/ui/alert';

export const UpdateCustomFieldDialog = () => {
  const { data, isOpen, onClose } = useUpdateCustomFieldDialog();
  const { status, updateCustomFields, getCustomFields } = useCustomFieldsStore();
  const [error, setError] = useState<string[] | null>(null);

  const onSubmit = async (values: any) => {
    const reqData = {
      key: values.label.trim().replace(/'/g, '').replace(/\s+/g, '_').replace(/[-_]+/g, '_').toLowerCase(),
      ...values
    };

    const payload = {
      key: data.key,
      value: reqData
    };

    try {
      await updateCustomFields({ data: payload });
      await getCustomFields();

      onClose();
      setError(null);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        const errorMessages = Object.values(error.response.data.errors);
        setError(errorMessages as string[]);
      } else {
        const errorMessages = [...Object.values(JSON.parse(error.message))];
        setError(errorMessages as string[]);
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <h2 className="text-center text-lg font-semibold">Update Custom Field</h2>
        </DialogHeader>

        {error && (
          <Alert className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <AlertTitle>Error adding custom field</AlertTitle>
            <AlertDescription>
              {error.map((errorMessage, index) => (
                <div key={index}>{errorMessage}</div>
              ))}
            </AlertDescription>
          </Alert>
        )}

        <CustomFieldForm defaultValues={data} onSubmit={onSubmit} status={status} buttonLabel="Update Field" />
      </DialogContent>
    </Dialog>
  );
};
