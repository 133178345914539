import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { CreateNote } from './notes/createNote';
import { CreateStaffNote } from './staffNotes/createStaffNote';
import { CrudState, NoteCategoryI } from '@/modules/notes/domain/note.domain';
import { DraftList } from '@/modules/notes/presentation/components/notes/draftList';
import { fetchRefreshNotes, fetchRefreshStaffNotes, removeNoteFilter, setNoteFilters, cleanNotes, fetchrefreshDrafts, fetchGetNotes, fetchGetStaffNotes } from '../slices/NoteSlice';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { Input } from '@/common/presentation/components/registry/new-york/ui/input';
import { Navigation } from './common/navigation';
import { NoteDisplay } from '@/modules/notes/presentation/components/common/noteDisplay';
import { NoteFilters } from './common/noteFilters';
import { NoteList } from '@/modules/notes/presentation/components/notes/noteList';
import { NoteStaffList } from './staffNotes/noteStaffList';
import { RootState } from '@/store/store';
import { Search } from 'lucide-react';
import { ShareNote } from './common/shareNote';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/registry/new-york/ui/tabs';
import { TooltipProvider } from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { UpdateNote } from './notes/UpdateNote';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { websocket } from '@/utils/helpers/websocket.helper';
import NoteHelperService from '../../infrastructure/services/NoteHelperService';

interface NoteProps {
  sizes: number[] | undefined;
  tabValue: string;
  setTabValue: (value: string) => void
}

export interface CategoryAccumulator {
  [key: string]: string;
}

export function Note({ sizes = [440, 655], tabValue, setTabValue }: NoteProps) {
  // Redux
  const dispatch = useDispatch();
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const notes = useSelector((state: RootState) => state.notes.notes.payload);
  const selectedNotes = useSelector((state: RootState) => state.notes.selectedNotes);
  const drafts = useSelector((state: RootState) => state.notes.drafts.data);
  const staffNotes = useSelector((state: RootState) => state.notes.staffNotes.payload);
  const metaNote = useSelector((state: RootState) => state.notes.notes?.meta);
  const noteFilters = useSelector((state: RootState) => state.notes.noteFilters);
  const categories = useSelector((state: RootState) => state.notes.categories);

  // Initial
  const { t } = useTranslation();
  const { clientInfo } = useConfigurationsProvider();

  // Hooks
  const [crudState, setCrud] = useState<CrudState>(NoteHelperService.defineState({}));
  const [size, setSize] = useState<any>(sizes);
  const [loadingNotes, setLoadingNotes] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const refA = useRef<ImperativePanelHandle>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (isMobile) {
      refA.current?.resize(size[0]);
    }
  }, [size]);

  useEffect(() => {
    websocket.subscribe(`notes-${clientInfo.id}`, (data: any) => {
      if (data.message[0] == 'staff') {
        dispatch<any>(fetchRefreshStaffNotes());
      } else {
        dispatch<any>(fetchRefreshNotes());
      }
    });
  }, []);

  useEffect(() => {
    const getNotes = async () => {
      if (!crudState.showingDrafts) {
        let findNextPage = noteFilters.find(filter => filter[0] == 'nextPage' && filter[1] > 1);
        setLoadingNotes((findNextPage ? false : true));
        let findTabStaff = noteFilters.find(filter => filter[0] == 'target_type' && filter[1] == 'staff')
        if (findTabStaff) {
          await dispatch<any>(fetchGetStaffNotes());
        } else {
          await dispatch<any>(fetchGetNotes());
        }
        setLoadingNotes(false);
      }
    }
    getNotes();
  }, [noteFilters]);

  const categoriesNames = categories.reduce((acc: CategoryAccumulator, item: NoteCategoryI) => {
    acc[item.id] = item.category;
    return acc;
  }, {} as CategoryAccumulator);

  // Behavior
  const setCrudState = (state: CrudState) => {
    // Callback
    crudState.action(state);
    // Change state
    setCrud(state);

    if (isMobile) {
      const newSize = NoteHelperService.resizePanel(state);
      setSize(newSize);
    }
  };

  const handleDrafts = () => {
    dispatch<any>(fetchrefreshDrafts());
    setCrudState(NoteHelperService.defineState({ showingDrafts: true }));
    removeReadUnredFilter(true, true);
  };

  const handleReadAndUnreadNotes = (type: string) => {
    setCrudState(NoteHelperService.defineState({ showingDrafts: false }));
    dispatch<any>(cleanNotes());
    if (type == 'read') {
      removeReadUnredFilter(false, true);
    } else {
      removeReadUnredFilter(true, false);
    }
    dispatch(setNoteFilters([type, 'true']));
    dispatch(setNoteFilters(['nextPage', 1]));
  };

  const handleAllNotes = () => {
    setCrudState(NoteHelperService.defineState({ showingDrafts: false }));
    dispatch<any>(cleanNotes());
    removeReadUnredFilter(true, true);
    dispatch(setNoteFilters(['nextPage', 1]));
  };

  const removeReadUnredFilter = (read: boolean, unread: boolean) => {
    if (read) {
      dispatch(removeNoteFilter('read'));
    }
    if (unread) {
      dispatch(removeNoteFilter('unread'));
    }
  }

  const handleSubmitSearchNotes = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery == '') {
      dispatch(removeNoteFilter('search'));
    } else {
      dispatch(setNoteFilters(['search', searchQuery ?? '']));
    }
    dispatch(setNoteFilters(['nextPage', '1']));
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Navigation crudState={crudState} setCrudState={setCrudState} />
      <div className='grid grid-cols-12 gap-4'>
        <Card className="col-span-12 md:col-span-5 border-t-4 border-t-primary/80">
          <CardContent>
            <Tabs className='mt-3' value={tabValue} onValueChange={(e) => setTabValue(e)} defaultValue={crudState.showingDrafts ? 'drafts' : 'all'}>
              {!crudState.showingStaffNotes && (
                <TabsList className='w-full gap-0 rounded-none border-border p-0 text-muted-foreground bg-white min-h-12'>
                  <TabsTrigger
                    value="all"
                    onClick={() => handleAllNotes()}
                    className="w-[25%] rounded-none px-1 -mb-[3px] shadow-none border-b-2 text-base data-[state=active]:border-primary data-[state=active]:text-primary flex flex-wrap h-full"
                    title={`${t('notes.allNotes')} (${metaNote.quantityAllNotes || 0})`}
                  >
                    <p className='text-sm'>{t('notes.allNotes')}</p>
                    <p className='text-xs ml-1'>({metaNote.quantityAllNotes || 0})</p>
                  </TabsTrigger>
                  <TabsTrigger
                    value="unread"
                    onClick={() => handleReadAndUnreadNotes('unread')}
                    className="w-[25%] rounded-none px-1 -mb-[3px] shadow-none border-b-2 text-base data-[state=active]:border-primary data-[state=active]:text-primary flex flex-wrap h-full"
                    title={`${t('notes.unread')} (${metaNote.quantityUnread || 0})`}
                  >
                    <span className='text-sm'>{t('notes.unread')}</span>
                    <span className='text-xs ml-1'>({metaNote.quantityUnread || 0})</span>
                  </TabsTrigger>
                  <TabsTrigger
                    value="read"
                    onClick={() => handleReadAndUnreadNotes('read')}
                    className="w-[25%] rounded-none px-1 -mb-[3px] shadow-none border-b-2 text-base data-[state=active]:border-primary data-[state=active]:text-primary flex flex-wrap h-full"
                    title={`${t('notes.tabReadTtitle')} (${metaNote.quantityRead || 0})`}
                  >
                    <span className='text-sm'>{t('notes.read')}</span>
                    <span className='text-xs ml-1'>({metaNote.quantityRead || 0})</span>
                  </TabsTrigger>
                  <TabsTrigger
                    value="drafts"
                    onClick={() => handleDrafts()}
                    className="w-[25%] rounded-none px-1 -mb-[3px] shadow-none border-b-2 text-base data-[state=active]:border-primary data-[state=active]:text-primary flex flex-wrap h-full"
                    title={t('notes.draft')}
                  >
                    <span className='text-sm'>{t('notes.draft')}</span>
                    <span className='text-xs ml-1'>(0)</span>
                  </TabsTrigger>
                </TabsList>
              )}
              <div className="bg-background/95 px-0 py-4 backdrop-blur supports-[backdrop-filter]:bg-background/60 flex items-center justify-between">
                <form className='w-full mr-3' onSubmit={handleSubmitSearchNotes}>
                  <div className="relative h-9 shadow">
                    <Input placeholder={t('notes.search')} className="pl-8 focus-visible:ring-primary" onChange={(e) => setSearchQuery(e.target.value)} onBlur={handleSubmitSearchNotes} />
                    <Button variant="ghost" size="icon" type="submit">
                      <Search className="absolute left-2 top-2.5 h-4 w-4" />
                    </Button>
                  </div>
                </form>
                {selectedNotes.length <= 0 ? (
                  <NoteFilters crudState={crudState} setTabValue={setTabValue}></NoteFilters>
                ) : (
                  <ShareNote></ShareNote>
                )}
              </div>

              {/* All Notes Tab */}
              <TabsContent value="all" className="m-0">
                {crudState.showingStaffNotes ? (
                  <NoteStaffList items={staffNotes} setCrudState={setCrudState} loadingNotes={loadingNotes} />
                ) : (
                  <NoteList
                    key="all-notes"
                    setCrudState={setCrudState}
                    items={notes}
                    setSize={isMobile ? setSize : undefined}
                    categoriesNames={categoriesNames}
                    loadingNotes={loadingNotes}
                  />
                )}
              </TabsContent>

              {/* Unread Notes Tab */}
              <TabsContent value="unread" className="m-0">
                {crudState.showingStaffNotes ? (
                  <NoteStaffList items={staffNotes} setCrudState={setCrudState} loadingNotes={loadingNotes} />
                ) : (
                  <NoteList
                    key="unread-notes"
                    setCrudState={setCrudState}
                    items={notes}
                    setSize={isMobile ? setSize : undefined}
                    categoriesNames={categoriesNames}
                    loadingNotes={loadingNotes}
                  />
                )}
              </TabsContent>

              {/* Read Notes Tab */}
              <TabsContent value="read" className="m-0">
                {crudState.showingStaffNotes ? (
                  <NoteStaffList items={staffNotes} setCrudState={setCrudState} loadingNotes={loadingNotes} />
                ) : (
                  <NoteList
                    key="read-notes"
                    setCrudState={setCrudState}
                    items={notes}
                    setSize={isMobile ? setSize : undefined}
                    categoriesNames={categoriesNames}
                    loadingNotes={loadingNotes}
                  />
                )}
              </TabsContent>

              {/* Drafts Tab */}
              <TabsContent value="drafts" className="m-0">
                <DraftList items={drafts} setCrudState={setCrudState} crudState={crudState} loadingNotes={loadingNotes} />
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>

        {!crudState.isCreating &&
          !crudState.isUpdating &&
          !crudState.isFinishingDraft &&
          !crudState.isUpdatingStaffNote &&
          !crudState.isCreatingStaffNote ? (
          notes && (
            <NoteDisplay
              note={
                !crudState.showingStaffNotes
                  ? notes?.find((item) => item.id === noteSelected) || null
                  : staffNotes?.find((item) => item.id === noteSelected) || null
              }
              setCrudState={setCrudState}
              crudState={crudState}
              categoriesNames={categoriesNames}
            />
          )
        ) : !crudState.isUpdatingStaffNote && !crudState.isCreatingStaffNote ? (
          crudState.isCreating || crudState.isFinishingDraft ? (
            <CreateNote crudState={crudState} setCrudState={setCrudState} />
          ) : (
            <UpdateNote
              crudState={crudState}
              setCrudState={setCrudState}
              note={notes?.find((item) => item.id === crudState.itemId) || null}
            />
          )
        ) : (
          <CreateStaffNote
            crudState={crudState}
            setCrudState={setCrudState}
            note={staffNotes?.find((item) => item.id === crudState.itemId) || null}
          ></CreateStaffNote>
        )}
      </div>
    </TooltipProvider>
  );
}
