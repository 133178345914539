import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@/components/ui/button";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { t } from "i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from "@/common/presentation/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { getNoteCategories, saveOrUpdateQuickNote, quickNotesLoading } from '@/modules/settings/presentation/components/menus/notes/slices/QuickNotesSlice';
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { FormMessage } from "@/common/presentation/components/ui/form";
import CustomSelect from "@/common/presentation/components/CustomSelect/CustomSelect";
import { Switch } from "@/common/presentation/components/ui/switch";
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Alert, AlertDescription } from "@/common/presentation/components/ui/alert";
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';

import { CheckCircle2 } from "lucide-react";

interface IDialogCreateOrUpdate {
    onChangeOpen: (open: boolean) => void;
    title: string;
    open: boolean;
    isUpdate?: boolean;
    onChangeIsUpdate?: (state: boolean) => void
    dataUpdate?: {
        id?: string;
        nameCustomNote: string;
        description: string;
        category_id: string;
        category: string;
        status: boolean;
    };
    onChangeGetData?: (currentPage: number) => void
}

const FormSchema = z.object({
    name: z.string({
        required_error: "Name is required",
    }).min(1, {
        message: "Name is required"
    }),
    description: z.string({
        required_error: "Description is required",
    }).min(1, {
        message: "description is required"
    }),
    category: z.string({
        required_error: "Category is required",
        invalid_type_error: "Name must be a category name",
    }).min(1, {
        message: "Category is required"
    }),
    status: z.boolean()
});

type FormValues = z.infer<typeof FormSchema>;

interface SelectOption {
    value: string;
    label: string;
}

export const DialogCreateOrUpdate: React.FC<IDialogCreateOrUpdate> = ({
    onChangeOpen,
    open,
    title,
    isUpdate = false,
    onChangeIsUpdate,
    dataUpdate,
    onChangeGetData
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [showSuccess, setShowSuccess] = useState(false);
    const { noteCategories, isLoading } = useSelector((state: RootState) => state.settings.quickNotes);


    const [selectedOption, setSelectedOption] = useState<SelectOption>({
        value: "",
        label: t('settings.select')
    });

    const form = useForm<FormValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            name: "",
            description: "",
            category: "",
            status: true
        }
    });

    const options: SelectOption[] = useMemo(() => [
        { value: "", label: t('settings.select') },
        ...(noteCategories?.map((nCategory: any) => ({
            value: nCategory.id,
            label: nCategory.category
        })) || [])
    ], [noteCategories, t]);


    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        try {
            const payload = {
                ...data,
                idUpdate: isUpdate ? dataUpdate?.id : undefined
            };

            dispatch(quickNotesLoading());
            const response = await dispatch(saveOrUpdateQuickNote(payload));

            if (response.payload.result === 'ok') {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                    onChangeOpen(false);
                    onChangeIsUpdate?.(false);
                }, 3000);
                if (onChangeGetData) {
                    onChangeGetData(1);
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    useEffect(() => {
        if (open) {
            if (isUpdate && dataUpdate) {// when is update
                const categoryOption = options.find(opt => opt.value === dataUpdate.category_id) || {
                    value: dataUpdate.category_id,
                    label: dataUpdate.category
                };
                form.reset({
                    name: dataUpdate.nameCustomNote,
                    description: dataUpdate.description,
                    category: dataUpdate.category_id,
                    status: dataUpdate.status
                });
                setSelectedOption(categoryOption);
            }

            if (!isUpdate) {
                form.reset({
                    name: "",
                    description: "",
                    category: "",
                    status: true
                });
                setSelectedOption({ value: "", label: t('settings.select') });
            }

        } else {
            onChangeIsUpdate?.(false);
        }

    }, [open, isUpdate, dataUpdate, options]);


    useEffect(() => {
        dispatch(getNoteCategories());
    }, [dispatch])



    return <>

        <CustomDialog
            height="auto"
            title={title}
            width="30rem"
            newDialog={true}
            isOpen={open}
            onClose={() => onChangeOpen(false)}
            className="z-[999999] max-w-[92%] sm:max-w-[450px] "
            overFlow={true}
        >
            <div className="h-[73vh]">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="h-[100%] space-y-4 flex flex-col justify-between h-[100%]">
                        {!isLoading && (
                            <ScrollArea className="flex-grow pr-4">
                                <div>
                                    <FormField
                                        name="name"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className="pb-4">
                                                <FormLabel>{t('Name')}</FormLabel>
                                                <FormControl>
                                                    <Input {...field} placeholder="Enter name" />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        name="description"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className="pb-4">
                                                <FormLabel>{t('Description')}</FormLabel>
                                                <FormControl>
                                                    <Textarea {...field} placeholder="Enter description" />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <div className="flex flex-col-reverse md:flex-row justify-between items-start">
                                        <FormField
                                            control={form.control}
                                            name="category"
                                            render={({ field }) => (
                                                <FormItem className="pb-4 w-full md:w-[50%]">
                                                    <FormLabel>{t('calendar.categories')}</FormLabel>
                                                    <CustomSelect
                                                        value={selectedOption}
                                                        onChange={(val: SelectOption) => {
                                                            if (val) {
                                                                setSelectedOption(val);
                                                                field.onChange(val.value);
                                                            }
                                                        }}
                                                        options={options}
                                                        isSearchable={true}
                                                    />
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>

                                    <FormField
                                        control={form.control}
                                        name="status"
                                        render={({ field }) => (
                                            <FormItem className="pb-4">
                                                <div className="space-y-0.5">
                                                    <FormLabel>{t('settings.status')}</FormLabel>
                                                </div>
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={field.onChange}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </ScrollArea>
                        )}

                        {isLoading && (
                            <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
                                <Spinner size={'lg'} />
                            </div>
                        )}
                        <div className="absolute bottom-0 left-0 right-0 p-4">
                            {showSuccess ? (
                                <Alert className="bg-green-50 border-green-200 mb-4">
                                    <CheckCircle2 className="h-4 w-4 text-green-600" />
                                    <AlertDescription className="text-green-600">
                                    {isUpdate ? t('settings.updatedSuccessfully') : t('settings.createdSuccessfully')}
                                    </AlertDescription>
                                </Alert>
                            ) : (
                                <div className="flex justify-end">
                                    <Button type="submit">
                                        {t('settings.save')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </form>
                </Form>
            </div>
        </CustomDialog>



    </>
};