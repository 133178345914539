import React from 'react';
import DirectDeleteDialog from './DirectDeleteDialog';
import DirectUpdateDialog from './DirectUpdateDialog';

interface RenderActionsColumnProps {
  row: any;
  handleButtonOpenDialog: (fields: any[], title: string, row: any[], description: string) => void;
  dataDirectReport: (page: number, reset: boolean) => void
}

const RenderActionsColumn: React.FC<RenderActionsColumnProps> = ({ row, dataDirectReport }) => {
  return (
    <div className='flex'>
      <DirectUpdateDialog row={row} dataDirectReport={dataDirectReport} />
      <DirectDeleteDialog row={row} dataDirectReport={dataDirectReport} />
    </div>
  );
};

export default RenderActionsColumn;