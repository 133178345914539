import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { format, setISODay } from "date-fns";
import { da, es, enUS } from "date-fns/locale";
import { cn } from "@/lib/utils";
import { z } from "zod";

import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"
import { Button } from "@/common/presentation/components/ui/button.tsx";
import { Input } from "@/common/presentation/components/ui/input.tsx";
import { Checkbox } from "@/common/presentation/components/ui/checkbox.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/common/presentation/components/ui/dialog.tsx";
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Form } from "@/common/presentation/components/ui/form.tsx";
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';
import { RadioGroup, RadioGroupItem } from "@/common/presentation/components/ui/radio-group.tsx";
import { CommandGroup } from "cmdk";

import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { getStaffSelector } from "../../../../staff/presentation/slices/usersSlice.ts";

import {
  Command,
  CommandEmpty,
  CommandList,
  CommandInput,
  CommandItem,
} from "@/common/presentation/components/ui/command.tsx";

import { DateRanges } from "./DateRanges.tsx";

//Styles
import styles from "../../pages/styles/index.module.css";

//Interfaces
import { DialogTaskEvent } from "../../../domain/DialogTaskEvent.ts";
import { Textarea } from "@/common/presentation/components/ui/textarea.tsx";

// Hooks redux
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Interfaces for hooks redux
import { AppDispatch, RootState } from '@/store/store';

//slices
import {
  saveTaskEvents,
  getCategories,
  getDataCalendar,
  getSpecificTaskEvent,
  updateTaskEvent,
  setUpdated,
  getUsersToNotify
} from "../../slices/CalendarSlice.ts";
import { ICategory } from "../../../domain/calendar.domain.ts";
import { DialogCustomRecurrence } from "../DialogTaskEvent/DialogCustomRecurrence.tsx";
import { toast } from "@/common/presentation/components/ui/use-toast.ts";

import { useTranslation } from 'react-i18next';

// import { sliceEventStore } from "@fullcalendar/core/internal";

const FormSchema = z.object({
  startDate: z.date(),
  startTime: z.string(),
  endDate: z.date().nullable(),
  endTime: z.string(),
  title: z
    .string({
      required_error: "Title is required",
      invalid_type_error: "Name must be a string",
    }).min(1, {
      message: "Title is required"
    }),
  allDay: z.boolean().default(false).optional(),
  type: z
    .enum(['task', 'event'], {
      required_error: "Type is required",
    }),
  options: z
    .string({
      required_error: "Options is required",
    }),
  detail: z.string(),
  repeatEveryCount: z.
    coerce.number({
      required_error: "is required",
      invalid_type_error: 'errror type',
    }).min(1, {
      message: "Min value must be 1"
    }).optional(),
  repeatEveryType: z.string(),
  repeatMonthlyOn: z.object({
    repeatFrequency: z.string().optional(),
    week: z.coerce.number().optional(), // Number week in the month
    weekday: z.string().optional(),
    day: z.string().optional() // Number day fixed of month
  }).optional(),
  daysOfWeek: z.array(z.string()), //days_of_the_week
  ends: z.string(),
  onDate: z.date(),
  residentId: z.string(), //belongs to
  userId: z.string(),   // belongs to
  relatedTo: z.string(),
  locationId: z.string(),
  category: z.string({
    required_error: "Category is required",
    invalid_type_error: "Name must be a category name",
  }).min(1, {
    message: "Category is required"
  }),
  usersToNotify: z.array(z.object({
    value: z.string(),
    label: z.string()
  })).optional(),
  createdBy: z.string(),
  afterOccurrences: z.number(),

  idUpdate: z.string().optional()
});

FormSchema.required({
  options: true,
  title: true,
  startDate: true,
  category: true
});


const getInitialTime = (time: any = '') => {
  const now = time || new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

type FormValues = z.infer<typeof FormSchema>;

export const TaskEventDialog: React.FC<DialogTaskEvent> = (
  { open,
    onClose,
    residentId = '',
    userId = 'all-users',
    locationId = '',
    selectedDate = format(new Date(), "MM/dd/yyyy"),
    startEndDateUpdate,
    onSelectedDate,
    fetchParamsData,
    isUpdate,
    idUpdate,
    relatedTo = 'residents',
    onCloseInfoCard,
    fromCalendar = true
  }) => {
  
  const { t, i18n  } = useTranslation();

  const [isAllDay, setAllDay] = useState<boolean>(false);
  const [isOpenPopoverOptions, setOpenPopoverOptions] = useState<boolean>(false);

  const [isOpenPopoverCategories, setOpenPopoverCategories] = useState<boolean>(false);
  const [isOpenDialogCustom, setDialogCustom] = useState<boolean>(false);

  const [repeatEveryTypesState, setRepeatEveryTypesState] = useState<string>('');

  const [selectedTime, setSelectedTime] = useState<string>(getInitialTime());
  const [repeatMonthlyOn, setRepeatMonthlyOn]: any = useState<Array<any> | object>([]);

  const [type, setType]: any = useState<string>('event');

  const [descriptionRecurrence, setDescriptionRecurrence] = useState<string>('');

  //Redux
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const dispatch = useDispatch<AppDispatch>();
  const categories: ICategory[] = useTypedSelector((state) => state.calendar.categories);


  let usersToNotify: any = useSelector((state: RootState) => state.calendar.usersToNotify);
  if (usersToNotify.length > 0) {
      usersToNotify = [{ value: 'all-users', label: t('calendar.allUsers') }, ...usersToNotify];
  }

  const [isLoading, setLoading] = useState(true);

  //When is udpate
  const dataEvent: any = (isUpdate && idUpdate)
    ? useSelector((state: RootState) => state.calendar.taskEventInfo)
    : {};
  
  const options: Record<string, string> = {
    "does_not_repeat": t('calendar.doesNotRepeat'),
    "daily": t('calendar.daily'),
    "custom": t('calendar.custom')
  }

  const defaultValues: any = {
    title: '',
    startDate: new Date(`${selectedDate}`),
    endDate: new Date(`${selectedDate}`),
    startTime: format(new Date(), "hh:mm a"),
    endTime: format(new Date(), "hh:mm a"),
    allDay: false,
    type: type,
    residentId: '',
    usersToNotify: [{ value: 'all-users', label: t('calendar.allUsers') }],
    userId: '',
    locationId: '',
    createdBy: '',
    relatedTo: relatedTo,
    detail: '',
    options: 'does_not_repeat',
    repeatEveryCount: 1,
    repeatEveryType: '',
    repeatMonthlyOn: {},
    daysOfWeek: ['monday'],
    ends: 'never',
    onDate: new Date(),
    afterOccurrences: 1
  };
  

  // When is update, get event
  useEffect(() => {

    if (isUpdate && idUpdate) {
      const eventId: object = { eventId: idUpdate }

      dispatch(getSpecificTaskEvent(eventId))
        .then((response: any) => {
          if (response.payload.id) {
            setLoading(false);
          }
        });
    }
  }, [open, isLoading]);



  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const handleIsAllDay = (): void => {
    setAllDay(!isAllDay);
    form.setValue("startDate", new Date(`${selectedDate}`));
    form.setValue("endDate", new Date(`${selectedDate}`));
  };

  const handleCloseDialogCustom = (): void => {
    summaryRecurrence();

    setDialogCustom(false);
  }


  const handleTimeStartDateSelected = (time: string) => {
    form.setValue('startTime', `${time}`);
    setSelectedTime(`${time}`);
    // Actualizar la hora seleccionada del TimePicker
  };

  const handleTimeEndDateSelected = (time: string) => {
    // Actualizar la hora seleccionada del TimePicker
    form.setValue('endTime', `${time}`);
    setSelectedTime(`${time}`);

  };

  const cleanForm = () => {
    form.control._reset();
    form.setValue('startDate', new Date(`${selectedDate}`));
    form.setValue('endDate', new Date(`${selectedDate}`));
  }

  /* 
    Actualiza el estado de la fecha actual al seleccionar otra desde el form 
    asi puede concordar el repeat every type 
  */
  useEffect(() => {
    if (open === true && fromCalendar && onSelectedDate) {
      onSelectedDate(form.getValues('startDate'));
    }
  }, [form.getValues('startDate')]);



  //Reset dates when change type "task" or "event"
  useEffect(() => {
    if (!isUpdate) {
      form.setValue('endTime', format(new Date(), "hh:mm a"));

      if (form.getValues('type') === "event") {
        form.setValue('endDate', new Date(`${selectedDate}`));

      } else if (form.getValues('type') === "task") {
        form.setValue('endDate', null);
      }
    }
  }, [form.getValues('type')]);



  useEffect(() => {

    dispatch(getCategories());

  }, []);



  //Update state loading when is update and clear form
  useEffect(() => {
    if (isUpdate && open === false) {
      setLoading(true);
    }

    //Limpia el form cada vez que abrimos el dialog
    if (open === true && isUpdate === false) {
      setType('event');
      cleanForm();
    }

  }, [open]);


  // When is update
  useEffect(() => {
    if (
      isUpdate && idUpdate != undefined && idUpdate?.length > 0 &&
      Object.keys(dataEvent).length > 0 &&
      dataEvent?.id.length > 0
    ) {

      const start = !fromCalendar && startEndDateUpdate
        ? startEndDateUpdate.start // end date from library full calendar 
        : selectedDate;

      const end = !fromCalendar && startEndDateUpdate
        ? startEndDateUpdate.start // start date from library full calendar
        : dataEvent.end_date_time;

      const monthlyOn = dataEvent?.tasks_events_custom?.monthly_on ? JSON.parse(dataEvent?.tasks_events_custom.monthly_on) : {};
      const afterOccurrences = dataEvent?.tasks_events_custom?.after_ocurrences ? dataEvent?.tasks_events_custom.after_ocurrences : 1;

      form.setValue('title', dataEvent.title);
      form.setValue('residentId', dataEvent?.resident?.residentId ?? '');
      form.setValue('startDate', new Date(`${start}`));
      form.setValue('endDate', new Date(`${end}`));
      form.setValue('startTime', format(new Date(`${dataEvent.start_date_time}`), "hh:mm a"));
      form.setValue('endTime', format(new Date(`${dataEvent.end_date_time}`), "hh:mm a"));
      form.setValue('allDay', dataEvent.all_day);
      form.setValue('type', dataEvent.type);
      form.setValue('category', dataEvent.tasks_events_categories_id);
      form.setValue('relatedTo', dataEvent?.related_to ?? '');
      form.setValue('options', dataEvent.options);
      form.setValue('detail', dataEvent.detail ?? '');
      form.setValue('locationId', dataEvent?.resident_location?.location_id ?? '');
      form.setValue('createdBy', dataEvent.created_by);
      form.setValue('repeatEveryCount', dataEvent?.tasks_events_custom?.repeat_every_count ?? 1);
      form.setValue('repeatEveryType', dataEvent?.tasks_events_custom?.repeat_every_type ?? '');
      form.setValue('repeatMonthlyOn', monthlyOn ?? {}); // Corregir montly por monthly
      form.setValue('daysOfWeek', dataEvent?.tasks_events_custom?.days_of_week === '[]' ? [] : dataEvent?.tasks_events_custom?.days_of_week ?? []);
      form.setValue('ends', dataEvent?.tasks_events_custom?.ends ?? 'never');
      form.setValue('onDate', dataEvent.options === 'custom' ? new Date(`${dataEvent?.tasks_events_custom?.ends_on_date}`) : new Date());

      if (afterOccurrences > 1) {
        form.setValue('afterOccurrences', afterOccurrences ?? 1);
      }

      setType(dataEvent.type);
      setAllDay(dataEvent.all_day ?? false);
      setRepeatMonthlyOn(dataEvent?.tasks_events_custom?.monthly_on ?? '');
      setRepeatEveryTypesState(dataEvent?.tasks_events_custom?.repeat_every_type ?? '');

    }

  }, [idUpdate, selectedDate]);


  useEffect(() => {
    dispatch(getUsersToNotify({ locationId }));
  }, [])


  const changeUsersToNotify = (valuesSelected: any) => {

    const findAllUsers = valuesSelected.find((u: any) => u.value == 'all-users') ?? false;

    if (findAllUsers) {
      if (valuesSelected.length > 1) {
        const users = valuesSelected.filter((val: any) => val != findAllUsers);
        return users;
      }
      return [findAllUsers];
    }

    return valuesSelected;

  }


  async function saveInformation(data: z.infer<typeof FormSchema>) {

    try {

      if (!residentId) {
        toast({
          description: t('calendar.seletedResident'),
          className: 'bg-red-500 text-white'
        });
        return;
      }

      const newData: z.infer<typeof FormSchema> = { ...data, residentId, relatedTo, userId, locationId };
      const response = await dispatch(saveTaskEvents(newData));
        toast({
          description: t('calendar.dataSavedCorrectly'),
          className: 'bg-green-500 text-white'
        });
      if (response.payload.result === 'ok' && fetchParamsData) {
        toast({
          description: t('calendar.dataSavedCorrectly'),
          className: 'bg-green-500 text-white'
        });
        cleanForm();
        dispatch(getDataCalendar({formData: fetchParamsData})); //Get data calendar
      }

    } catch (error) {
      console.error('Error saving information:', error);
    }

  }


  const handleSaveOrUpdateInformation = async (data: z.infer<typeof FormSchema>) => {

    if (isUpdate === false) {
      saveInformation(data);
    } else {
      const newData: z.infer<typeof FormSchema> = { ...data, idUpdate };
      const { payload }: any = await dispatch(updateTaskEvent(newData));

      if (payload.data.result === 'ok') {
        toast({
          description: t('calendar.updatedSuccessfully'),
          className: 'bg-green-500 text-white'
        });
        dispatch(setUpdated(true));

      } else {
        toast({
          description: t('calendar.tryAgain'),
          className: 'bg-red-500 text-white'
        });

      }

    }

    onClose();
    onCloseInfoCard?.();
    if (fetchParamsData) {
      dispatch(getDataCalendar({formData: fetchParamsData}));
    }
  }


  const summaryRecurrence = () => {
    const repeatEveryType = form.getValues('repeatEveryType');

    if (!repeatEveryType || form.getValues('options') !== 'custom') return;
    
    let resume = `${t('calendar.repeatEvery')} ${form.getValues('repeatEveryCount')}`;
        resume += ` ${t('calendar.summaryRecurrence.repeatEveryType.' + repeatEveryType)}`;

    switch (repeatEveryType) {
      case 'week':
        resume += ` ${t('calendar.summaryRecurrence.in')} `
        resume += ` ${form.getValues('daysOfWeek').map((d: string) => t('calendar.' + d).toLowerCase()).join(', ')} `
        resume += ` ${t('calendar.ends')?.toLowerCase()}`;
        break;

      case 'month':
        if (repeatMonthlyOn.length === 0) break;
        resume = getSummaryMonltyOn(resume);
        resume += ` ${t('calendar.summaryRecurrence.ends')}`;
        break;
    }

    if (repeatEveryType === 'year' || repeatEveryType === 'day') {
      resume += `, ${t('calendar.summaryRecurrence.ends')}`;
    }

    resume = getsummaryEnds(resume);
    setDescriptionRecurrence(resume);

  }

  const getSummaryMonltyOn = (resume: string): string => {

    if (repeatMonthlyOn.length === 0) return resume;

    let label = repeatMonthlyOn.filter((obj: any) =>
      JSON.stringify(obj.value) == JSON.stringify(form.getValues('repeatMonthlyOn'))
    )[0].label;

    label = label.split(' ');
    label = label.slice(1).join(' ').toLowerCase();

    resume += ` ${label},`;
    return resume;
  }

  const getsummaryEnds = (resume: string): string => {

    switch (form.getValues('ends')) {
      case 'never':
        resume += ` ${t('calendar.never')?.toLowerCase()}`;
        break;
      case 'on':
        resume += ` ${t('calendar.on')?.toLowerCase()}`;
        resume += ` ${format(new Date(form.getValues('onDate')), t('dateFormats.dateFormat'))}`;
        break;

      case 'after':
        resume += ` ${t('calendar.summaryRecurrence.after')}`;
        resume += ` ${form.getValues('afterOccurrences')} ${t('calendar.summaryRecurrence.occurrences').toLocaleLowerCase()}`;
        break;
    }

    return resume;
  }


  const translateSelectedOptions = (value: any ): Array<{value:'', label:''}> => { 
    if(!value) [];

     return (value.map((user: any)=>{
        if(user.value === 'all-users'){
          return { value: 'all-users', label: t('calendar.allUsers') }
        }
        return user;
      }));
  }


  // useEffect(() => {
  //   console.log(form.formState.errors);
  // }, [form.formState]);

  return (

    <Dialog onOpenChange={onClose} open={open} modal>
      <DialogContent className={`m-0 w-[95%] max-w-[425px] rounded-md overflow-x-hidden z-[99999] md:m-auto overflow-y-scroll max-h-[95vh]`} >
        <DialogHeader>
          <DialogTitle>{isUpdate ? t('calendar.updateTaskEvent') : t('calendar.newTaskEvent')}</DialogTitle>
        </DialogHeader>

        {isUpdate && isLoading ? (<div>{t('calendar.loading')}...</div>)
          : (
            <Form {...form}>
              <form key={isUpdate ? 'update-form' : 'create-form'} onSubmit={form.handleSubmit(handleSaveOrUpdateInformation)} className="space-y-8">

                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="pb-1 ">
                      <FormLabel>{t('calendar.title')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('calendar.title')}
                          {...field}
                          autoComplete="false"
                          className=""
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="type"
                  render={({ field }) => (
                    <FormItem className="mb-4 hidden">
                      <FormControl>
                        <RadioGroup
                          className="flex flex-row gap-6"
                          onValueChange={(type: string) => {
                            field.onChange(type);
                            setType(type);
                          }}
                          defaultValue={field.value}
                          required
                        >
                          <FormItem className="flex items-center space-x-1 space-y-0 ">
                            <FormControl>
                              <RadioGroupItem value="task" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              {t('calendar.task')}
                            </FormLabel>
                          </FormItem>

                          <FormItem className="flex items-center space-x-1 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="event" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              {t('calendar.event')}
                            </FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <DateRanges
                  key={`date-ranges`}
                  form={form}
                  isAllDay={isAllDay}
                  onChangeTimeStartDateSelected={handleTimeStartDateSelected}
                  onChangeTimeEndDateSelected={handleTimeEndDateSelected}
                  selectedTime={selectedTime}
                  type={type}
                  selectedDate={selectedDate}
                  repeatEveryTypesState={repeatEveryTypesState}
                  onChangeRepeatMonthlyOn={setRepeatMonthlyOn}
                  isUpdate={isUpdate}
                  idUpdate={idUpdate}
                />

                <div className="flex flex-col-reverse md:flex-row md:flex-row justify-between items-start ">

                  <FormField
                    control={form.control}
                    name="category"
                    render={({ field }) => (
                      <FormItem className="pb-[8px] w-full md:w-[60px]">
                        <FormLabel>
                          {t('calendar.categories')}
                        </FormLabel>
                        <Popover open={isOpenPopoverCategories} onOpenChange={setOpenPopoverCategories}>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={cn(
                                  "w-[100%] md:w-[260px] justify-between",
                                  "text-muted-foreground"
                                )}
                              >
                                {
                                  field.value
                                    ? (categories.find((c: ICategory) => c.id === field.value)?.name)
                                    : t('calendar.selectOption')
                                }

                                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-[200px] p-0 z-[9999999]">

                            <Command>
                              <CommandInput placeholder={t('calendar.searchOption')} />
                              <CommandEmpty>{t('calendar.noOptionFound')}.</CommandEmpty>

                              <CommandList>
                                {categories?.map((category: ICategory) => (

                                  <CommandItem
                                    key={category.id}
                                    value={category.id}
                                    onSelect={(currentValue: string) => {
                                      form.setValue("category", currentValue);
                                      setOpenPopoverCategories(false)
                                    }}
                                  >

                                    {
                                    (i18n.exists('calendar.listCategories.' + category.name.toLocaleLowerCase().replace(/\s+/g, ''))) 
                                      ? t('calendar.listCategories.' + category.name.toLocaleLowerCase().replace(/\s+/g, '')) 
                                      : category.name
                                      }
                                    <CheckIcon
                                      className={cn(
                                        "ml-auto h-4 w-4",
                                        field.value == category.id ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                  </CommandItem>
                                ))}

                              </CommandList>
                            </Command>
                          </PopoverContent>
                        </Popover>

                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="allDay"
                    render={({ field }) => (
                      <FormItem className="flex items-center self-end md:self-start mb-3">
                        <FormLabel>{t('calendar.allDay')}</FormLabel>
                        <FormControl>
                          <Checkbox
                            className={`pt-0 ${styles.dialogTaskEventCheckbox}`}
                            checked={field.value}
                            onCheckedChange={(currentValue) => {
                              field.onChange(currentValue);
                              handleIsAllDay();
                            }}

                          />

                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />

                </div>

                <FormField
                  control={form.control}
                  name="options"
                  render={({ field }) => (

                    <FormItem className="flex flex-col ">
                      <FormLabel>{t('calendar.moreOptions')}</FormLabel>

                      <Popover open={isOpenPopoverOptions} onOpenChange={setOpenPopoverOptions}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "w-full md:w-[260px] justify-between",
                                !field.value && "text-muted-foreground"
                              )}
                            >

                              {field.value ? options[field.value] : t('calendar.selectOption')}

                              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-[200px] p-0 z-[9999999]">

                          <Command>
                            <CommandInput placeholder={t('calendar.searchOption')} />
                            <CommandList>
                              <CommandEmpty>{t('calendar.noOptionFound')}.</CommandEmpty>
                              <CommandGroup>
                                {Object.keys(options).map((key: string) => (
                                  <CommandItem
                                    key={key}
                                    value={key}
                                    onSelect={(currentValue: any) => {
                                      form.setValue("options", currentValue)
                                      setOpenPopoverOptions(false)
                                      if (currentValue == 'custom') {
                                        setDialogCustom(true);
                                      }
                                    }}
                                  >
                                    {options[key]}
                                    <CheckIcon
                                      className={cn(
                                        "ml-auto h-4 w-4",
                                        field.value === key ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>

                      {
                        form.getValues('options') === 'custom'
                        && descriptionRecurrence && (
                          <>
                            <p className="text-primary text-sm">{descriptionRecurrence}</p>
                          </>
                        )
                      }
                      <FormMessage />
                    </FormItem>

                  )}
                />


                <FormField 
                  control={form.control}
                  name="usersToNotify"
                  render={({ field }) => (
                    <FormItem className="w-full lg:max-w-96">
                      <FormLabel>{t('calendar.usersToNotify')}</FormLabel>
                      <FormControl>
                        <MultiSelect
                          placeHolder={t('calendar.selectedUsers')}
                          options={usersToNotify}
                          className="lg:max-w-96 text-sm font-medium"
                          customMessage={'No hay usuarios disponibles'}
                          selectedOptions={translateSelectedOptions(field.value ?? [])}
                          name={field.name}
                          onChange={(selectedOptions) => {
                            const values = changeUsersToNotify(selectedOptions);
                            field.onChange(values);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <DialogCustomRecurrence
                  key={'dialog-custom-recurrence'}
                  open={isOpenDialogCustom}
                  onOpenChange={handleCloseDialogCustom}
                  form={form}
                  repeatMonthlyOn={repeatMonthlyOn}
                  valueRepeatEveryType={repeatEveryTypesState}
                  onChangeRepeatEveryTypes={setRepeatEveryTypesState}
                  isUpdate={isUpdate}
                />

                <FormField
                  control={form.control}
                  name="detail"
                  render={({ field }) => (
                    <FormItem className="flex flex-col mt-6">
                      <FormLabel>{t('calendar.detail')}</FormLabel>
                      <Textarea
                        placeholder={t('calendar.typeYourDescription')}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <FormMessage />
                    </FormItem>

                  )}
                />


                <div className="w-full flex justify-end">
                  <Button type="submit" className="mr-2">
                    {isUpdate ? t('calendar.update') : t('calendar.save')}
                  </Button>
                  <Button type="button" onClick={onClose}>
                    {t('calendar.close')}
                  </Button>
                </div>

              </form>
            </Form>

          )}


      </DialogContent>
    </Dialog>


  );


};

export default FormValues;