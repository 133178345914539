import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { cleanFilters, removeNoteFilter, setNoteFilters } from '../../slices/NoteSlice';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { Label } from '@/common/presentation/components/ui/label';
import { ListFilter, FilterX } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useRouteParams from '@/common/hooks/RouteParamsHook';

interface NoteFiltersProps {
  crudState: CrudState;
  setTabValue: (value: string) => void
}

export function NoteFilters({ crudState, setTabValue }: NoteFiltersProps) {
  // Initial 
  const { t } = useTranslation();
  const { params } = useRouteParams();

  // Redux
  const dispatch = useDispatch();
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const categoriesFilter = useSelector((state: RootState) => state.notes.categories);
  const noteFilters = useSelector((state: RootState) => state.notes.noteFilters);
  const optionsTimeFilter = ["last15days", "last3Months", "last6Months", "lastYear", "allTime"];


  let categories = [];
  if (crudState.showingStaffNotes) {
    categories = categoriesFilter.filter(category => category.type == 'staff');
  } else {
    categories = categoriesFilter.filter(category => category.type == 'resident');
  }

  const selectedFilter = (type: string) => {
    let findStaff = noteFilters.find(filter => filter[0] == type);
    if (findStaff) {
      return findStaff[1]
    }
    return type == 'betweenTime' ? 'allTime' : 'all';
  }

  // Behavoir
  const updateNoteFilters = (key: string, value: string) => {
    dispatch(setNoteFilters(['nextPage', 1]));
    if (value == '') {
      dispatch(removeNoteFilter(key));
    } else {
      dispatch(setNoteFilters([key, value]));
    }
  };

  const handleResidentFilter = async (residentId: string) => {
    updateNoteFilters('resident', residentId != 'all' ? residentId : '');
  };

  const handleStaffFilter = async (staffId: string) => {
    updateNoteFilters('staff', staffId != 'all' ? staffId : '');
  };

  const handleCategoryFilter = async (categoryId: string) => {
    updateNoteFilters('category', categoryId != 'all' ? categoryId : '');
  };

  const handleTimeFilter = async (time: string) => {
    updateNoteFilters('betweenTime', time != 'allTime' ? time : '');
  };

  const goCleanFilters = async () => {
    dispatch(cleanFilters());
    dispatch(setNoteFilters(['nextPage', 1]));
    dispatch(setNoteFilters(['location', params.l]));
    if (crudState.showingStaffNotes) {
      dispatch(setNoteFilters(['target_type', 'staff']));
    }
    setTabValue('all');
  };

  return (
    <Popover>
      <PopoverTrigger>
        <ListFilter className='text-primary'></ListFilter>
      </PopoverTrigger>
      <PopoverContent>
        <div className="grid gap-4">
          <div className="grid gap-2">
            {!crudState.showingStaffNotes && (
              <div className="grid grid-cols-3 items-center gap-3">
                <Label htmlFor="resident">{t('notes.resident')}</Label>
                <Select value={selectedFilter('resident')} onValueChange={handleResidentFilter}>
                  <SelectTrigger className="col-span-2 h-8">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent id="resident">
                    <ScrollArea className='max-h-[200px] overflow-auto'>
                      <SelectItem value="all">{t('notes.all')}</SelectItem>
                      {residents?.map((resident) => (
                        <SelectItem key={resident.id} value={resident.id}>
                          {resident.first_name + ' ' + resident.last_name}
                        </SelectItem>
                      ))}
                    </ScrollArea>
                  </SelectContent>
                </Select>
              </div>
            )}
            <div className="grid grid-cols-3 items-center gap-3">
              <Label htmlFor="staff">{t('notes.titleStaffFilter')}</Label>
              <Select value={selectedFilter('staff')} onValueChange={handleStaffFilter}>
                <SelectTrigger className="col-span-2 h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent id="staff">
                  <ScrollArea className='max-h-[200px] overflow-auto'>
                    <SelectItem value="all">{t('notes.all')}</SelectItem>
                    {staff?.map((staff: any) => (
                      <SelectItem key={staff.id} value={staff.id}>
                        {staff.first_name + ' ' + staff.last_name}
                      </SelectItem>
                    ))}
                  </ScrollArea>
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-3 items-center gap-3">
              <Label htmlFor="category">{t('notes.category')}</Label>
              <Select value={selectedFilter('category')} onValueChange={handleCategoryFilter}>
                <SelectTrigger className="col-span-2 h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent id="category">
                  <ScrollArea className='max-h-[200px] overflow-auto'>
                    <SelectItem value="all">{t('notes.all')}</SelectItem>
                    {categories?.map((category: any) => (
                      <SelectItem key={category.id} value={category.id}>
                        {category.category}
                      </SelectItem>
                    ))}
                  </ScrollArea>
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-3 items-center gap-3">
              <Label htmlFor="betweenTime">{t('notes.timeRange')}</Label>
              <Select value={selectedFilter('betweenTime')} onValueChange={handleTimeFilter}>
                <SelectTrigger className="col-span-2 h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent id="betweenTime">
                  {optionsTimeFilter?.map((optionTime: any) => (
                    <SelectItem key={optionTime} value={optionTime}>
                      {t(`notes.optionsTimeSelect.${optionTime}`)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Button variant="default" type="button" onClick={goCleanFilters}>
              <FilterX className="mr-2 h-4 w-4" />
              {t('notes.clear')}
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
