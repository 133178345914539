import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { CustomFieldsResponse } from '@/modules/staff/domain/models/updateStaff/customFields';

const CustomFieldsService = {
  getCustomFields: async () => {
    const response = await axios.get<CustomFieldsResponse>(`/staff/configurations/custom-fields`);
    return response;
  },

  setCustomFields: async (data: any) => {
    const response = await axios.post(`/staff/configurations/custom-fields`, { ...data });
    return response;
  },

  updateCustomFields: async (data: any) => {
    const response = await axios.put(`/staff/configurations/custom-fields`, { ...data });
    return response;
  },

  deleteCustomField: async (key: string) => {
    const response = await axios.delete(`/staff/configurations/custom-fields/${key}`);
    return response;
  },

  getStaffCustomFields: async (staff_id: string) => {
    const response = await axios.get(`/staff/${staff_id}/custom-fields`);
    return response;
  },

  saveStaffCustomFields: async (staff_id: string, data: any) => {
    const response = await axios.put(`/staff/${staff_id}/custom-fields`, { ...data });
    return response;
  }
};

export default CustomFieldsService;
