import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MedicationConfiguration from "../infrastructure/services/MedicationConfiguration";

export interface IEmarXsState {
  emarXs: [],
  isLoading: boolean;
  error: string | null;
}

const initialState: IEmarXsState = {
    emarXs:[],
    isLoading: false,
    error: ''
};


// export const getAllowUsersEditMedication = createAsyncThunk('allowStaffEditMedication/getEnabledMedications',
//  async () => {
//     const response = await MedicationConfiguration.getAllowUser();
//     return response;
//   }
// );

export const updateEmarXs = createAsyncThunk('medicationConfigurations/updateEmarXs',
  async (formData: any ) => {
     const response = await MedicationConfiguration.updateEmarXs(formData);
     return response;
   }
 );

const EmarXslice = createSlice({
  name: 'emarXs',
  initialState,
  reducers: {
    emarXsLoading: (state)=>{
     state.isLoading = true;
     state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.
    addCase(updateEmarXs.fulfilled, (state, action) => {
      state.emarXs = action.payload;
      state.isLoading = false;
    });
  }
});

export const { emarXsLoading } = EmarXslice.actions;
export default EmarXslice.reducer;