import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ShareNoteService from '../../infrastructure/services/ShareNoteService';
import { InitialStateI } from '../../domain/reports.domain';

const initialState: InitialStateI = {
    sharenote: { result: "", payload: [], meta: [] }
};

type typeGetReportShareNote = {
    filters: [string, string][];
    nextPage: string;
}

export const getReportShareNote = createAsyncThunk("reportShareNote/get", async (body: typeGetReportShareNote) => {
    let filters = [...body.filters];
    filters.push(['nextPage', body.nextPage]);
    const response = await ShareNoteService.getNotes(filters);
    return response;
});

const ReportSlice = createSlice({
    name: 'reportShareNote',
    initialState,
    reducers: {
        cleanShareNote(state) {
            state.sharenote = initialState.sharenote;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReportShareNote.fulfilled, (state, action) => {
            if (action.payload.meta.page > 1) {
                state.sharenote.payload = state.sharenote.payload.concat(action.payload.payload)
                state.sharenote.meta = action.payload.meta
            } else {
                state.sharenote = action.payload;
            }
        });
    }
});

export const { cleanShareNote } = ReportSlice.actions;
export default ReportSlice.reducer;
