import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { LoadingComponent } from '@/common/presentation/components/Loading/LoadingComponent';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { EmptyIcon } from '@/modules/residents/presentation/components/EmptyIcon';
import { useCustomFieldsStore } from '@/modules/staff/domain/stores/updateStaff/customFields';
import { useStaffCustomFieldsStore } from '@/modules/staff/domain/stores/updateStaff/staffCustomFields';
import { useManageCustomFieldsDialog } from '@/modules/staff/infrastructure/hooks/updateStaff/manageCustomFields';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { AddCustomFieldDialog } from '../dialogs/AddCustomFieldDialog';
import { ManageCustomFieldsDialog } from '../dialogs/ManageCustomFieldsDialog';

interface Props {
  className?: string;
}

const rolesToValidate = ['Super Administrator', 'Executive'];

export const CustomFieldsForm = ({ className }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { custom_fields, getCustomFields, status: customFieldsStatus } = useCustomFieldsStore();
  const { staff_custom_fields, getStaffCustomFields, staffCustomFieldsStatus, status } = useStaffCustomFieldsStore();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { onOpen: openManageCustomFieldDialog } = useManageCustomFieldsDialog();
  const { user } = useSelector((state: RootState) => state.auth);

  const { params } = useRouteParams();

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    getStaffCustomFields(params.s);
  }, [getStaffCustomFields, params.s]);

  const activeFields = useMemo(() => {
    if (!custom_fields) return [];

    return Object.entries(custom_fields)
      .map(([key, field]: any) => ({ key, ...field }))
      .filter((field) => field.status === 'active')
      .sort((a, b) => a.order - b.order);
  }, [custom_fields]);

  const fieldTypes = (type: string, isOptional: boolean) => {
    switch (type) {
      case 'text':
      case 'textarea':
        return isOptional ? z.string().default('').optional() : z.string().default('');
      case 'number':
        return isOptional ? z.number().default(0).optional() : z.number().default(0);
      case 'date':
        return isOptional ? z.date().default(new Date()).optional() : z.date().default(new Date());
      case 'boolean':
        return isOptional ? z.boolean().default(false).optional() : z.boolean().default(false);
      default:
        return z.any();
    }
  };

  const formSchema = useMemo(() => {
    const fields = activeFields.reduce((acc: any, field: any) => {
      acc[field.key] = fieldTypes(field.type, !field.required);
      return acc;
    }, {});

    return z.object(fields);
  }, [activeFields]);

  const defaultValues = useMemo(() => {
    return activeFields.reduce((acc: any, field: any) => {
      const staffValue = staff_custom_fields ? staff_custom_fields[field.key] : undefined;
      const defaultValue = staffValue !== undefined ? staffValue : field.default_value;
      acc[field.key] = defaultValue;
      return acc;
    }, {});
  }, [activeFields, staff_custom_fields]);

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const onSubmit = async (data: any) => {
    const payload = {
      staff_id: params.s,
      data: {
        custom_fields: {
          ...data
        }
      }
    };
    const response = await dispatch(staffMemberFormActions.updateCustomFieldsForm(payload));

    if (response.payload) {
      toast({
        description: 'Company staff member updated successfully'
      });
      form.reset(data);
    } else {
      toast({
        description: 'Failed to update company staff member',
        variant: 'destructive'
      });
    }
  };

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  return (
    <>
      <div className={cn(className)}>
        <CustomAccordion
          title="Custom Fields"
          isOpen={(isOpen) => setIsAccordionOpen(isOpen)}
          components={
            <>
              {staffCustomFieldsStatus === 'idle' &&
                isAdmin &&
                customFieldsStatus === 'idle' &&
                activeFields.length > 0 &&
                isAccordionOpen && (
                  <Button
                    className="text-xs"
                    variant={'default'}
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openManageCustomFieldDialog();
                    }}
                  >
                    Manage Custom Fields
                  </Button>
                )}
            </>
          }
        >
          <>
            {(staffCustomFieldsStatus === 'loading' || customFieldsStatus === 'loading') && (
              <LoadingComponent status={customFieldsStatus} label="Loading Custom Fields" height="h-[25vh]" />
            )}

            {staffCustomFieldsStatus === 'idle' && customFieldsStatus === 'idle' && activeFields.length === 0 && (
              <div className="w-full flex flex-col items-center py-12 gap-4">
                <EmptyIcon className="h-[10vh] w-auto" />
                <p>No hay campos personalizados activos para los miembros del personal.</p>
                {isAdmin && (
                  <Button className="text-xs" variant={'default'} size="sm" onClick={openManageCustomFieldDialog}>
                    Manage Custom Fields
                  </Button>
                )}
              </div>
            )}

            {staffCustomFieldsStatus === 'idle' && customFieldsStatus === 'idle' && activeFields.length > 0 && (
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <div className="flex flex-col gap-4 px-2 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {activeFields.map((field: any) => (
                        <FormField
                          key={field.key}
                          control={form.control}
                          name={field.key}
                          render={({ field: formField }) => (
                            <FormItem>
                              <FormLabel>{field.label}</FormLabel>
                              <FormControl>
                                <>
                                  {field.type === 'number' && (
                                    <Input
                                      {...formField}
                                      type="number"
                                      inputMode="numeric"
                                      placeholder={field.placeholder}
                                    />
                                  )}
                                  {(field.type === 'text' || field.type === 'email') && (
                                    <Input {...formField} placeholder={field.placeholder} />
                                  )}
                                  {field.type === 'textarea' && (
                                    <Textarea
                                      className="min-h-[40px] h-[40px]"
                                      {...formField}
                                      placeholder={field.placeholder}
                                    />
                                  )}
                                </>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                    </div>
                  </div>

                  {form.formState.isDirty && (
                    <div className="mt-6 flex justify-end space-x-2">
                      <Button type="submit" disabled={status === 'loading'}>
                        {status === 'loading' && <Loader2 className="size-4 mr-2 animate-spin" />}
                        Update
                      </Button>
                    </div>
                  )}
                </form>
              </Form>
            )}
          </>
        </CustomAccordion>
      </div>

      <AddCustomFieldDialog />
      <ManageCustomFieldsDialog />
    </>
  );
};
