import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { ITrackerCleaningTask } from "./TrackerCleaningMockup";
import { TrackerCleaningTaskCreateDialog } from "./TrackerCleaningTaskCreateDialog";
import { useEffect, useState } from "react";
import { TrackerCleaningTaskDeleteDialog } from "./TrackerCleaningTaskDeleteDialog";
import { TrackerCleaningTaskEditDialog } from "./TrackerCleaningTaskEditDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { getTasks } from "../../slices/TrackerCleaningSlice";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { Search } from "lucide-react";
import { useTranslation } from "react-i18next";
import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import useCleaningColumns from "./CleaningColumns";
import renderActionsColumn from "./renderActionsColumn";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";

export function TrackerCleaningTask() {
    const dispatch = useDispatch();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [columns] = useState(useCleaningColumns());
    let [dataSource, setDataSource] = useState<ITrackerCleaningTask[]>([]);
    const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setDataSource(trackerCleaning.activeTasks);
    }, [trackerCleaning]);
    
    const fetchData = async () => {
        dispatch(getTasks(
            {
                location_id: locationSelectedId
            }
        ));
    }

    return (
        <div className="grid grid-cols-1 items-end gap-2 w-100">
            <TableCustomVirtuoso
                data={dataSource}
                columns={columns}
                renderCellContent={(index, column, data) => data[index][column.key]}
                additionalButtons={<TrackerCleaningTaskCreateDialog />}
            />
        </div>
    );
}
