import { Label } from '@/common/presentation/components/ui/label';
import { Button } from '@/common/presentation/components/ui/button';
import { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { Input } from '@/common/presentation/components/ui/input';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { createSummary, getAreas, getTasks } from '../../slices/TrackerCleaningSlice';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { getDayHygiene, getHygiene, saveHygiene } from '../../slices/TrackerHygieneSlice';

const TrackerHygieneDetailed = (props: { dataHygieneReport: (page: number, resetData: boolean) => void, resetFunc: () => void }) => {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { toast } = useToast();
    const trackerHygiene = useSelector((state: RootState) => state.trackerHygiene);
    const { t } = useTranslation();
    const [types, setTypes] = useState([]);
    const [value, setValue] = useState<string[]>([]);
    const { locationSelectedId } = useLocation();
    const { formatDate } = useDateHelpers();
    const [values, setValues] = useState({
        date: new Date(),
        types: "",
        detail: "",
        value: ""
    });

    useEffect(() => {
        if(trackerHygiene.dayHygienes.data) {
            setTypes(
                trackerHygiene.dayHygienes.data.types.column_field
                    .filter(
                        (value) => value.status === "active"
                    )
            );
        }

        setValue(
            [
                "Done",
                "Refused"
            ]
        );
    }, [trackerHygiene.dayHygienes]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleDateChange = (value: any) => {
       setValues({
            ...values,
            date: value
        });
    };

    const handleTypesChange = (value: any) => {
        setValues({
             ...values,
             types: value
         });
     };

     const handleValueChange = (value: any) => {
        setValues({
             ...values,
             value
         });
     };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(params.r) {
            if(values.types && values.value) {
                dispatch(saveHygiene(
                    [
                        {
                            type: values.types,
                            value: values.value,
                            resident_id: params.r,
                            detail: values.detail,
                            location_id: locationSelectedId
                        }
                    ]
                ));
        
                fetchData();
                cleanInputs();
        
                toast(
                    {
                        description: t("cleaning.form.detailed.toast.success")
                    }
                ); 
            } else {
                toast(
                    {
                        description: t('trackers.trackerhygiene.required')
                    }
                ); 
            }
        } else {
            toast(
                {
                    description: t('trackers.trackerhygiene.residentRequired')
                }
            ); 
        }
    }

    const fetchData = () => {
        props.resetFunc();
        props.dataHygieneReport(1, true);

        dispatch(
            getDayHygiene(
                {
                    location_id: locationSelectedId,
                    day: format(new Date(), "d")
                }
            )
        );
    }

    const cleanInputs = () => {
        setValues({
            date: new Date(),
            types: "",
            detail: "",
            value: ""
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 mt-5">
                <div className="grid grid-cols-1 items-center gap-1">
                        <Label htmlFor={"date"} className="text-left">
                            { t("cleaning.form.detailed.form.date") }
                        </Label>

                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                variant={"outline"}
                                className={cn(
                                    "w-[280px] justify-start text-left font-normal w-100",
                                    !values.date && "text-muted-foreground"
                                )}
                                >
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    {values.date ? formatDate(values.date.toISOString()) : <span>mm/dd/yyyy - hh:mm aa</span>}
                                </Button>
                            </PopoverTrigger>

                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                value={values.date}
                                timePicker={true}
                                onChange={handleDateChange}
                                />
                            </PopoverContent>
                        </Popover>
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"area"} className="text-right justify-self-start">
                        {t("trackers.trackerhygiene.type")}
                    </Label>

                    <Select onValueChange={handleTypesChange} value={values.types}>
                        <SelectTrigger>
                            <SelectValue placeholder={t('trackers.trackerhygiene.selectAType')} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    types && types.length ? (
                                        types.map(value => <SelectItem value={value.id}>{value.name}</SelectItem>)
                                    ) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"area"} className="text-right justify-self-start">
                        {t("trackers.trackerhygiene.state")}
                    </Label>

                    <Select onValueChange={handleValueChange} value={values.value}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select a Value" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    value && value.length ? (
                                        value.map(value => <SelectItem value={value}>{value}</SelectItem>)
                                    ) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>


                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"detail"} className="text-right justify-self-start">
                        { t("cleaning.form.detailed.form.detail") }
                    </Label>

                    <Input
                        id={"detail"}
                        name={"detail"}
                        type="text"
                        value={values.detail}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="w-100 mt-4 flex flex-row justify-end">
                <Button type="submit" className="mr-2">
                    { t("cleaning.form.detailed.form.submit") }
                </Button>
            </div>
        </form>
    );
}

export default TrackerHygieneDetailed;
