import { Button } from '@/common/presentation/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  path: string;
}

export const FormsNavigation = ({ path = '/' }: Props) => {
  const naigate = useNavigate();
  const { t } = useTranslation();

  const onBack = () => {
    naigate(path);
  };

  return (
    <>
      <div className="w-full flex gap-4">
        <Button variant={'ghost'} onClick={onBack}>
          <ArrowLeft className="size-4 mr-2" />
          {t('emar.navigation.back')}
        </Button>
      </div>
    </>
  );
};
