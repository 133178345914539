import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const MenuService = {
  getLocationMenus: async(locationId: string)=>{
    const response = await axios.get(`/menus/getLocationMenus/${locationId}`);
    if(response?.data){
      return response.data;
    }
    
  }
};

export default MenuService;
