import DeviceEditPage from '@/modules/security/presentation/pages/DeviceEditPage';
import SecurityPage from '@/modules/security/presentation/pages/SecurityPage';
import { Route, Routes } from 'react-router-dom';

const SecurityRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SecurityPage />}></Route>
      <Route path="/edit/:deviceId" element={<DeviceEditPage />} />
    </Routes>
  );
};

export default SecurityRouter;
