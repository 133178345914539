const DeviceInfo = ({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactNode;
}) => (
  <div className={`flex flex-col w-64`}>
    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 min-w-[140px]">
      {label}
    </span>
    <span className="text-sm text-gray-800 dark:text-white flex-1 break-words truncate">
      {value}
    </span>
  </div>

);

export default DeviceInfo;
