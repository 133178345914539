import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import {
    IUpdateSleepData,
    IUpdateNapSleep,
    ITrackerSleepFormData
} from '../interfaces/SleepInterface';


const TrackerSleepService = {
    getTrackerSleep: async (locationId: string, residentId: string, perPage: number, page: number) => {
        try {
            const response = await axios.get('trackerSleep/getTrackerSleep', {
                params: { locationId, residentId, perPage, page },
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching Sleep: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching Sleep: ' + error.message);
        }
    },
    getDayTrackerSleep: async (location_id: string, resident_id: string) => {
        try {
            const response = await axios.get('trackerSleep/getDayTrackerSleep', {
                params: { location_id, resident_id },
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching Sleep: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching Sleep: ' + error.message);
        }
    },
    getTrackerNapSleep: async (location_id: string, resident_id: string, perPage: number, page: number) => {
        try {
            const response = await axios.get('trackerSleep/getTrackerNapSleep', {
                params: { location_id, resident_id, perPage, page },
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching Snap: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching Snap: ' + error.message);
        }
    },
    saveTrackerSleep: async (formData: ITrackerSleepFormData) => {
        try {
            const response = await axios.post(`trackerSleep/saveTrackerSleep`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error creating tracker Sleep: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error creating tracker Sleep: ' + error.message);
        }
    },

    updateTrackerSleep: async (formData: IUpdateSleepData) => {
        try {
            const response = await axios.post(`trackerSleep/updateTrackerSleep`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error updating tracker Sleep: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error updating tracker Sleep: ' + error.message);
        }
    },

    updateTrackerNapSleep: async (formData: IUpdateNapSleep) => {
        try {
            const response = await axios.post(`trackerSleep/updateTrackerNapSleep`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error updating tracker Nap Sleep: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error updating tracker Nap Sleep: ' + error.message);
        }
    },
    deleteTrackerNapSleep: async (id: string) => {
        try {
            const response = await axios.post(`trackerSleep/deleteTrackerNapSleep`, id);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error deleting tracker Nap Sleep: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error deleting tracker Nap Sleep: ' + error.message);
        }
    }

}

export default TrackerSleepService;