import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { Separator } from '@/common/presentation/components/ui/separator';
import { cn } from '@/lib/utils';
import { format, parse } from 'date-fns';
import { FileClock } from 'lucide-react';
import { RefObject, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useEmarPrnHistory } from '../../infrastructure/hooks/UseEmarPrnHistory';
import { usePrnDialog } from '../../infrastructure/hooks/UsePrnDialog';
import { useTranslation } from 'react-i18next';

interface MedicationCardProps {
  className?: string;
  data: any;
  children?: React.ReactNode;
  setRef?: (ref: RefObject<HTMLDivElement>) => void;
  selectedDate: Date;
  // selectedTime: string;
  // locationId?: string;
  // residentId?: string;
}

export const EmarPrnCard = ({ className, data, setRef }: MedicationCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { onOpen } = usePrnDialog();
  const { onOpen: onOpenEmarPrnHistory } = useEmarPrnHistory();
  const { locationId } = useParams();

  useEffect(() => {
    if (cardRef.current) {
      setRef?.(cardRef);
    }
  }, [cardRef]);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onOpenEmarPrnHistory(data);
  };

  return (
    <Card
      className={cn('flex flex-col hover:cursor-pointer', className)}
      ref={cardRef}
      onClick={() => onOpen(data, locationId!)}
    >
      <CardContent className="space-y-2">
        <CardHeader className="px-0">
          <div className="flex justify-between gap-2">
            <Badge variant="outline" className="text-md font-bold border-blue-500 text-blue-500">
              As Needed
            </Badge>

            {data?.emar_medication_prns?.length > 0 && (
              <Button variant="ghost" size={'icon'} className="size-8" onClick={handleOpen}>
                <FileClock className="size-6" />
              </Button>
            )}
          </div>
          <CardTitle className="text-xl font-bold">
            {data?.medication?.name?.toUpperCase()}{' '}
            {data?.medication?.strength ? `${data?.strength} ${data?.form}` : data?.form}
          </CardTitle>
        </CardHeader>
        <Separator />
        <div className="text-md">
          <p>
            RX #: <span className="font-bold">{data?.chart[0]?.rx || ''}</span>
          </p>
          <p>
            Start Date:{' '}
            <span className="font-bold">
              {data?.chart[0].cycle_start_date
                ? format(parse(data?.chart[0]?.cycle_start_date, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
                : ''}
            </span>
          </p>
          <p>
            Physician: <span className="font-bold">{data?.prescribing_physician?.name || ''}</span>
          </p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Instructions</h3>
          <p className="text-md">{data?.instruction || ''}</p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-lg font-bold">Special Instructions</h3>
          <p className="text-md">{data?.special_instructions || ''}</p>
        </div>
      </CardContent>
      <CardFooter className="mt-auto w-full flex flex-col gap-2">
        {data?.responsible_of_administration?.length > 0 && (
          <>
            <Separator />
            <p className="w-full p-2 text-md font-bold text-center rounded-md bg-red-200 text-red-700">
              {data.responsible_of_administration.map((item: { name: string }) => item.name).join(', ')}
            </p>
          </>
        )}
      </CardFooter>
    </Card>
  );
};
