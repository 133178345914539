import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StaffService from '../../infrastructure/services/StaffService';
import CustomFieldsService from '../../infrastructure/services/CustomFields';

interface StaffMemberFormState {
  status: 'idle' | 'loading' | 'failed';
  isValid: boolean;
  error: string | null;
}

const initialState: StaffMemberFormState = {
  status: 'idle',
  isValid: false,
  error: null
};

export const updateStaffPersonalInformationForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffPersonalInformation(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);

export const updateCustomFieldsForm = createAsyncThunk(
  'staffForm/updateCustomFieldsForm',
  async ({ staff_id, data }: { staff_id: string; data: any }) => {
    try {
      const response = await CustomFieldsService.saveStaffCustomFields(staff_id, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);

export const updateStaffMemberTerminationForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffMemberTerminationForm(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating Termination: ' + error);
    }
  }
);
export const updateStaffcompanyForm = createAsyncThunk('staffForm/updatePersonalInformationForm', async (data: any) => {
  try {
    const response = await StaffService.updateStaffcompanyForm(data);
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
  }
});
export const updateStaffTrainingForm = createAsyncThunk(
  'staffForm/updatePersonalInformationForm',
  async (data: any) => {
    try {
      const response = await StaffService.updateStaffTrainingForm(data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);
export const updateOtherForm = createAsyncThunk(
  'staffForm/updateOtherForm',
  async ({ data, staff_id }: { data: any; staff_id: string }) => {
    try {
      const response = await StaffService.updateOtherForm(data, staff_id);
      return response.data;
    } catch (error: any) {
      const message = error.response.data.message;
      throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
    }
  }
);
export const updateStaffloginForm = createAsyncThunk('staffForm/updatePersonalInformationForm', async (data: any) => {
  try {
    const response = await StaffService.updateStaffloginForm(data);
    return response.data;
  } catch (error: any) {
    const message = JSON.stringify(error.response.data);
    throw new Error(message !== undefined ? message : 'Error updating user: ' + error);
  }
});

const staffMemberFormSlice = createSlice({
  name: 'staffMemberForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateStaffPersonalInformationForm.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateStaffPersonalInformationForm.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(updateStaffPersonalInformationForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  }
});

export default staffMemberFormSlice.reducer;
