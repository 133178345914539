import { AlertCard } from '../alert-card';
import { Button } from '@/common/presentation/components/ui/button';
import { CircleAlert, Loader2, NotebookPen } from 'lucide-react';
import { NoteI } from '@/modules/notes/domain/note.domain';
import { RootState } from '@/store/store';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoteService from '@/modules/notes/infrastructure/services/NoteService';
import NotesModal from './notes-modal';
import React, { useEffect, useState } from 'react';
import SkeletonNotes from './skeleton';

interface ResidentI {
  id: string;
  first_name: string;
  last_name: string;
}

const NoteLayout = ({
  children
}: {
  children: React.ReactNode;
}) => (
  <div className="p-2">
    <div className="transition-all hover:scale-[1.01] rounded-lg border p-4">
      <div className="grid">{children}</div>
    </div>
  </div>
);

interface NoteIProps {
  isLoading: boolean;
  note?: NoteI;
  residents: ResidentI[];
  handlerNoteSelection?: (note: NoteI | undefined) => void | null;
}

const Note: React.FC<NoteIProps> = ({ isLoading, note, residents, handlerNoteSelection }) => {
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();
  const [isReading, setIsReading] = useState<boolean>(false);
  const [readingNoteId, setReadingNoteId] = useState<string>('');
  const authUserId = useSelector((state: RootState) => state.auth.user?.id);
  const residentNameById = (residentId: string | null): string | null => {
    if (residentId === null) return t('dashboard.everyone');
    return UserHelper.getResidentName(residentId, residents);
  };

  useEffect(() => {
    validateRead();
  }, [])

  const validateRead = () => {
    let userRead = (note?.readers || []).find((item: any) => item.user_id == authUserId);
    setReadingNoteId(userRead && userRead.status == 'read' ? (note?.id || '') : '')
  }

  const readNote = async (noteId: string | null) => {
    if (!noteId) {
      return;
    }
    try {
      setReadingNoteId(noteId);
      setIsReading(true);
      const { result } = await NoteService.unreadNote({
        note_id: noteId,
        status: 'read'
      });

      if (result !== 'ok') {
        throw Error('Error reading note');
      }
      setIsReading(false);
    } catch (error) {
      setReadingNoteId('');
      setIsReading(false);
      console.log(error);
    }
  };

  return (
    <NoteLayout>
      <div className="flex sm:flex-row justify-between items-start sm:items-center gap-2">
        <div className='cursor-pointer' onClick={() => {
          if (handlerNoteSelection && note) {
            handlerNoteSelection(note);
          }
        }}>
          <div className="col-span-10 flex flex-col text-primary font-semibold w-full">
            {isLoading ? (
              <>
                <Skeleton className="h-4 w-24 mb-2" />
                <Skeleton className="h-4 w-36 mb-2" />
              </>
            ) : (
              <>
                <span className="w-full flex flex-row gap-2 items-center">
                  {residentNameById(note?.resident_id ?? null)} {note?.urgent == true && <CircleAlert size={18} />}
                </span>
                <span className="text-sm w-full">{formatDate(note?.created_at ?? '')}</span>
              </>
            )}
          </div>
          <div className="col-span-10 flex flex-col">
            {isLoading ? <Skeleton className="h-4 w-full" /> : <span>{note?.content}</span>}
          </div>
        </div>
        {readingNoteId !== note?.id && (
          <div className="self-end sm:self-auto">
            <Button
              disabled={isReading}
              onClick={(e) => {
                e.stopPropagation();
                readNote(note?.id ?? null);
              }}
            >
              {isReading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  {t('dashboard.reading')}
                </>
              ) : (
                <>{t('dashboard.read')}</>
              )}
            </Button>
          </div>
        )}
      </div>
    </NoteLayout>
  );
};

interface INoteCardProps {
};

export const NotesCard: React.FC<INoteCardProps> = () => {
  const notes = useSelector((state: RootState) => state.dashboard.notes) ?? null;
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents) ?? [];
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [noteSelected, setNoteSelected] = useState<NoteI | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (notes.result === 'idle' || (notes.payload.length > 0 && residents.length > 0)) {
      setIsLoading(false);
    } else if (notes.result == 'loading') {
      setIsLoading(true);
    }
  }, [notes, residents]);

  const handlerNoteSelection = (note: NoteI | undefined) => {
    if (note) {
      setNoteSelected(note);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <AlertCard title={t('dashboard.notes')} alerts={notes.payload ?? []} icon={NotebookPen} custom>
        {isLoading ? (
          <SkeletonNotes />
        ) : notes.payload.length == 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          notes.payload.map((note: NoteI) => (
            <Note
              key={note.id}
              isLoading={false}
              note={note}
              residents={residents}
              handlerNoteSelection={handlerNoteSelection}
            />
          ))
        )}
      </AlertCard>
      <NotesModal isOpen={isModalOpen} note={noteSelected} setIsOpen={setIsModalOpen} />
    </>
  );
};
