import { Input } from '@/components/ui/input';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import CustomSelect from "@/common/presentation/components/CustomSelect/CustomSelect";

interface Filters {
  category: string;
  relatedTo: string;
  status: string;
}

interface FiltersTableProps {
  filters: Filters;
  onFilterChange: (newFilters: Filters) => void;
  onClear: () => void;
}

export const FiltersTable: React.FC<FiltersTableProps> = ({ 
  filters,
  onFilterChange, 
  onClear 
}) => {
  const { t } = useTranslation();

  const handleInputChange = (key: keyof Filters, value: string) => {
    onFilterChange({
      ...filters,
      [key]: value
    });
  };

  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full">
      <div className="relative w-full sm:w-1/3">
        <Input
          type="text"
          placeholder={t('settings.notes.nameCategory')}
          className="w-full h-10 rounded-lg border-gray-300 px-4 py-2 text-sm focus:border-primary focus:ring-primary focus:outline-none"
          value={filters.category}
          onChange={(e) => handleInputChange("category", e.target.value)}
        />
        {filters.category && (
          <Button
            onClick={onClear}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 bg-transparent hover:bg-transparent border-none shadow-none text-gray-500"
            variant="outline"
          >
            <X className="w-4 h-4" />
          </Button>
        )}
      </div>

      <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
        <div className="w-full sm:w-[150px]">
          <CustomSelect
            value={{ 
              label: filters.relatedTo ? t(`common.${filters.relatedTo}`) : t('settings.notes.relatedTo'), 
              value: filters.relatedTo 
            }}
            onChange={(val: any) => handleInputChange("relatedTo", val.value)}
            options={[
              { value: "", label: t('common.all') },
              { value: "resident", label: t('common.resident') },
              { value: "staff", label: t('common.staff') },
            ]}
            isSearchable={true}
          />
        </div>

        <div className="w-full sm:w-[150px]">
          <CustomSelect
            value={{ 
              label: filters.status ? t(`common.${filters.status}`) : t('common.status'), 
              value: filters.status 
            }}
            onChange={(val: any) => handleInputChange("status", val.value)}
            options={[
              { value: "", label: t('common.all') },
              { value: "active", label: t('common.active') },
              { value: "inactive", label: t('common.inactive') },
            ]}
            isSearchable={true}
          />
        </div>
      </div>
    </div>
  );
};

export default FiltersTable;