import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const TrackerFallService = {
    getTrackerFall: async (formData: any) => {
        try {
            const response = await axios.get('trackerFall/getTrackerFall', {
                params: formData,
            });
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error fetching Fall: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error fetching Fall: ' + error.message);
        }
    },
    saveTrackerFall: async (formData: any) => {
        try {
            const response = await axios.post(`trackerFall/saveTrackerFall`, formData);
            if (response && response.data) {
                return response.data;
            } else {
                throw new Error('Error creating tracker Fall: No data returned from server');
            }
        } catch (error: any) {
            throw new Error('Error creating tracker Fall: ' + error.message);
        }
    }
}

export default TrackerFallService;