import { t } from "i18next";
import RenderSummaryActionsColumn from "./CleaningSummaryActionsColumns";
import { format } from "date-fns";

const useCleaningSummaryColumns = () => {
  const CleaningColumns = [
    {
        key: 'date',
        id: 'date',
        labelTranslationKey: 'cleaning.report.summary.table.date',
        render: (element: any) => format(new Date(element.data.date), "dd/MM/yyyy") + "\n" + format(new Date(element.data.date), "hh:mm a"),
        value: 'date',
        visible: true,
        sortable: true,
        width: '16%'
    },
    {
        key: 'task',
        id: 'task',
        labelTranslationKey: 'cleaning.report.summary.table.task',
        render: (element: any) => element.data.task.name,
        value: 'task',
        visible: true,
        sortable: true,
        width: '16%'
    },
    {
        key: 'detail',
        id: 'detail',
        labelTranslationKey: 'cleaning.report.summary.table.detail',
        render: (element: any) => element.data.detail,
        value: 'detail',
        visible: true,
        sortable: true,
        width: '16%'
    },
    {
        key: 'area',
        id: 'area',
        labelTranslationKey: 'cleaning.report.summary.table.area',
        render: (element: any) => element.data.area.name,
        value: 'area',
        visible: true,
        sortable: true,
        width: '16%'
    },
    {
        key: 'staff',
        id: 'staff',
        labelTranslationKey: 'cleaning.report.summary.table.staff',
        render: (element: any) => element.data.staff.first_name + ' ' + element.data.staff.last_name,
        value: 'staff',
        visible: true,
        sortable: true,
        width: '16%'
    },
    {
        key: 'actions',
        labelTranslationKey: 'contacts.actions',
        render: (rowData: any) => RenderSummaryActionsColumn(rowData),
        visible: true,
        static: true,
        width: '10%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(CleaningColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = CleaningColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useCleaningSummaryColumns;
