import { useEffect, useState } from "react";
import { ITrackerCleaningSummary } from "./TrackerCleaningMockup";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { format } from "date-fns";
import { TrackerCleaningSummaryEditDialog } from "./TrackerCleaningSummaryEditDialog";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { deleteSummary, getSummaries, updateSummary } from "../../slices/TrackerCleaningSlice";
import useRouteParams from "@/common/hooks/RouteParamsHook";
import { Pencil, Search, Trash } from "lucide-react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import useCleaningSummaryColumns from "./CleaningSummaryColumns";

export function TrackerCleaningSummary() {
    const dispatch = useDispatch();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const configurations = useSelector((state: RootState) => state.configurations);
    const { t } = useTranslation();
    let [dataSource, setDataSource] = useState<ITrackerCleaningSummary[]>([]);
    const { locationSelectedId } = useLocation();
    const [accentColor, setAccentColor] = useState("");
    const [columns] = useState(useCleaningSummaryColumns());

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        setDataSource(trackerCleaning.summaries);
    }, [trackerCleaning]);

    const fetchData = async () => {
        await dispatch(getSummaries(
            {
                location_id: locationSelectedId
            }
        ));
    }
    
    return (
            <div>
            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("cleaning.report.summary.title")}
                    </CardTitle>
                </CardHeader>
                    
                <CardContent>
                    <div className="grid grid-cols-1 grid-row-2 w-100">
                        <TableCustomVirtuoso
                            data={dataSource}
                            columns={columns}
                            renderCellContent={(index, column, data) => data[index][column.key]}
                            additionalButtons={<></>}
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
