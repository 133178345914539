import React, { useEffect } from 'react';
import { X, Info, CheckCircle, AlertCircle } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Toast } from '@/components/ui/toast';
import { useToast } from '../ui/use-toast';

interface CustomToastProps {
  title: string;
  description: string;
  type: 'success' | 'error' | 'info';
  onClose: () => void;
  duration?: number;
}

export const CustomToast: React.FC<CustomToastProps> = ({ title, description, type, onClose, duration = 2000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const typeConfig = {
    success: {
      classes: 'bg-green-100 text-green-800 border border-green-500 rounded-lg',
      icon: <CheckCircle className="w-5 h-5 text-green-500" />
    },
    error: {
      classes: 'bg-red-100 border border-red-500 rounded-lg',
      icon: <AlertCircle className="w-5 h-5 text-red-500" />
    },
    info: {
      classes: 'bg-blue-100 text-blue-800 border border-blue-500 rounded-lg',
      icon: <Info className="w-5 h-5 text-blue-500" />
    }
  };

  const { classes, icon } = typeConfig[type] || typeConfig.info;

  return (
    <Toast className={cn('fixed top-4 right-4 max-w-sm z-50 p-4', classes)}>
      <div className="flex items-start gap-3">
        <div className="shrink-0 flex items-center">{icon}</div>
        <div className="flex-grow">
          <h4 className="font-semibold mb-1">{title}</h4>
          <p className="text-sm">{description}</p>
        </div>
        <button
          onClick={onClose}
          className="shrink-0 rounded-md opacity-70 ring-offset-background transition-opacity hover:opacity-100"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
    </Toast>
  );
};

export const useCustomToast = () => {
  const { toast } = useToast();

  const showToast = (type: CustomToastProps['type'], title: string, description: string, duration = 2000) => {
    toast({
      title,
      description,
      duration,
      className: cn(
        'fixed top-4 right-4 max-w-sm z-50 p-4',
        type === 'success' && 'text-green-800 border border-green-500 rounded-lg',
        type === 'error' && 'text-red-800 border border-red-500 rounded-lg',
        type === 'info' && 'text-blue-800 border border-blue-500 rounded-lg'
      ),
      children: (
        <div className="flex items-start gap-3">
          {type === 'success' && <CheckCircle className="w-5 h-5 text-green-500" />}
          {type === 'error' && <AlertCircle className="w-5 h-5 text-red-500" />}
          {type === 'info' && <Info className="w-5 h-5 text-blue-500" />}
          <div className="flex-grow">
            <h4 className="font-semibold mb-1">{title}</h4>
            <p className="text-sm">{description}</p>
          </div>
          <button className="shrink-0 rounded-md opacity-70 ring-offset-background transition-opacity hover:opacity-100">
            <X className="w-4 h-4" />
          </button>
        </div>
      )
    });
  };

  return { showToast };
};
