import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { useLoginInformationStore } from '@/modules/staff/domain/stores/updateStaff/loginInformation';
import { GetPicklist } from '@/modules/staff/infrastructure/services/StaffService';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Eye, EyeOff, Loader } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z
  .object({
    user_level: z.string().optional(),
    username: z.string().optional(),
    password: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (value === undefined || value === '') return true;
          if (value.length < 8 || value.length > 30) return false;
          return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,30}$/.test(value);
        },
        {
          message:
            'Password must be at least 8 characters, no more than 30 characters, and contain at least one uppercase letter, one lowercase letter, and one number.'
        }
      ),
    confirm_password: z.string()
  })
  .refine(
    (data) => {
      if (data.password === undefined || data.password === '') return true;
      return data.password === data.confirm_password;
    },
    {
      message: "Passwords don't match",
      path: ['confirm_password']
    }
  );

export type FormValues = z.infer<typeof formSchema>;

const rolesToValidate = ['Super Administrator', 'Executive'];

export const LoginInformationForm = ({ className }: Props) => {
  const [roleOptions, setRoleOptions] = useState([]);
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMemberForm);
  const { user } = useSelector((state: RootState) => state.auth);
  const { updateStaffloginForm, error: reqError } = useLoginInformationStore();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      user_level: '',
      username: '',
      password: '',
      confirm_password: ''
    },
    mode: 'onChange'
  });

  const onSubmitForm = async (data: FormValues) => {
    try {
      await updateStaffloginForm(data, staffMember?.id);

      toast({
        description: 'Login member staff updated successfully'
      });

      form.reset({
        user_level: data?.user_level || '',
        username: data?.username || '',
        password: '',
        confirm_password: ''
      });
    } catch (error) {
      const { errors } = reqError;

      if (errors.username) {
        toast({
          description: errors.username?.[0],
          variant: 'destructive'
        });

        return;
      }

      toast({
        description: 'Error updating login member staff',
        variant: 'destructive'
      });
    }
  };

  const getPicklist = async () => {
    try {
      const { data }: any = await GetPicklist();
      const { rol } = data;
      const rolOptions = rol.map((rol: any) => {
        return {
          label: rol.name,
          value: rol.id
        };
      });
      setRoleOptions(rolOptions);
    } catch (error) {
      toast({
        description: 'Error loading user level',
        variant: 'destructive'
      });
    }
  };

  useEffect(() => {
    getPicklist();
  }, []);

  useEffect(() => {
    if (staffMember && roleOptions.length > 0) {
      form.reset({
        user_level: staffMember?.role_id || '',
        username: staffMember?.username || '',
        password: '',
        confirm_password: ''
      });
    }
  }, [staffMember, roleOptions]);

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  const isPersonalInformation = useMemo(() => {
    return user?.id === staffMember?.id;
  }, [user, staffMember]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Login Infomation">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="flex flex-col gap-4 px-2 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div>
                      <FormField
                        control={form.control}
                        name="user_level"
                        render={({ field }) => {
                          return (
                            <FormItem>
                              <FormLabel>User Level</FormLabel>
                              <Select
                                onValueChange={(value) => {
                                  field.onChange(value);
                                }}
                                defaultValue={field.value}
                                value={field.value}
                                disabled={staffFormStatus === 'loading' || !isAdmin}
                              >
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select a resident house" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  {roleOptions.map((rol: any, index: number) => (
                                    <SelectItem value={rol.value} key={index}>
                                      {rol.label}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <FormField
                        control={form.control}
                        name="username"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Username <span className="text-primary">*</span>
                            </FormLabel>
                            <FormControl>
                              <Input
                                placeholder=""
                                {...field}
                                autoCorrect="off"
                                autoComplete="off"
                                disabled={staffFormStatus === 'loading' || !(isAdmin || isPersonalInformation)}
                                className="w-full rounded-lg bg-background"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Password</FormLabel>
                            <FormControl>
                              <div className="relative flex items-center">
                                <Input
                                  {...field}
                                  value={field.value}
                                  type={passwordVisible ? 'text' : 'password'}
                                  disabled={staffFormStatus === 'loading' || !(isAdmin || isPersonalInformation)}
                                  autoCorrect="off"
                                  autoComplete="off"
                                  className="w-full rounded-lg bg-background"
                                />
                                <Eye
                                  className={cn(
                                    'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                    passwordVisible && 'hidden'
                                  )}
                                  onClick={() => setPasswordVisible(true)}
                                />
                                <EyeOff
                                  className={cn(
                                    'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                    !passwordVisible && 'hidden'
                                  )}
                                  onClick={() => setPasswordVisible(false)}
                                />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <FormField
                        control={form.control}
                        name="confirm_password"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Confirm password</FormLabel>
                            <FormControl>
                              <div className="relative flex items-center">
                                <Input
                                  {...field}
                                  value={field.value}
                                  type={passwordVisible ? 'text' : 'password'}
                                  disabled={staffFormStatus === 'loading' || !(isAdmin || isPersonalInformation)}
                                  autoCorrect="off"
                                  autoComplete="off"
                                  className="w-full rounded-lg bg-background"
                                />
                                <Eye
                                  className={cn(
                                    'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                    passwordVisible && 'hidden'
                                  )}
                                  onClick={() => setPasswordVisible(true)}
                                />
                                <EyeOff
                                  className={cn(
                                    'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                    !passwordVisible && 'hidden'
                                  )}
                                  onClick={() => setPasswordVisible(false)}
                                />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
                {form.formState.isDirty && (
                  <div className="mt-6 flex justify-end space-x-2">
                    <Button type="submit" disabled={staffFormStatus === 'loading'}>
                      {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </div>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
