import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/common/presentation/components/ui/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Separator } from '@/common/presentation/components/ui/separator';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { Building, CalendarIcon, CheckIcon, Eye, EyeOff, Loader2 } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { GetPicklist } from '@/modules/staff/infrastructure/services/StaffService';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

interface Props {
  defaultValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  disabled?: boolean;
}

const formSchema = z
  .object({
    first_name: z
      .string({
        required_error: 'First name is required.'
      })
      .min(3, {
        message: 'First name must be at least 3 characters.'
      })
      .max(30, {
        message: 'First name must not be longer than 30 characters.'
      }),
    middle_name: z.string().optional(),
    last_name: z
      .string({
        required_error: 'Last name is required.'
      })
      .min(3, {
        message: 'Last name must be at least 3 characters.'
      })
      .max(30, {
        message: 'Last name must not be longer than 30 characters.'
      }),
    dob: z.date({
      required_error: 'Date of birth is required.'
    }),
    sex: z.string().min(1, {
      message: 'Sex is required.'
    }),
    email: z.union([z.string().email(), z.literal('')]).refine((value) => value !== '', {
      message: 'Email is required.'
    }),
    user_level: z.string(),
    username: z.string(),
    password: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (!value) return true;
          if (value.length < 8 || value.length > 30) return false;
          return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,30}$/.test(value);
        },
        {
          message:
            'Password must be at least 8 characters, no more than 30 characters, and contain at least one uppercase letter, one lowercase letter, and one number.'
        }
      ),
    confirmpassword: z.string(),
    locations: z.array(z.string()).refine((value) => value.some((item) => item), {
      message: 'You have to select at least one location'
    }),
    hire_date: z.date({
      required_error: 'Hire date is required.'
    }),
    position_title: z.string().optional()
  })
  .refine(
    (data) => {
      if (!data.password) return true;
      return data.password === data.confirmpassword;
    },
    {
      message: "Passwords don't match",
      path: ['confirmpassword']
    }
  );

type FormValues = z.infer<typeof formSchema>;

export const NewStaffForm = ({ defaultValues, onSubmit, disabled }: Props) => {
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const { status: residentFormStatus } = useSelector((state: RootState) => state.residents.residentForm);
  const { user } = useSelector((state: RootState) => state.auth);

  const [rolOptions, setRolOptions] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const locationOptions =
    locations.map((location: any) => ({
      value: location.id,
      label: location.name
    })) || [];

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false
  });

  const canEdit = useMemo(() => {
    return user?.roles.some((role: any) => role.name === 'Super Administrator');
  }, [user]);

  const getPicklist = async () => {
    try {
      const { data }: any = await GetPicklist();
      const { rol } = data;
      const rolOptions = rol.map((rol: any) => {
        return {
          label: rol.name,
          value: rol.id
        };
      });
      setRolOptions(rolOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPicklist();
  }, []);

  const handleSubmit = async (values: FormValues) => {
    values.username = values.username.toLowerCase();
    onSubmit(values);
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4 px-1 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-2">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      First Name
                      <span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={disabled}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="middle_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Middle Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={disabled}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Last Name <span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        disabled={disabled}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-1 gap-2 items-end ">
                <FormField
                  control={form.control}
                  name="dob"
                  render={({ field }) => (
                    <FormItem className="col-span-9">
                      <FormLabel>
                        Date of Birth <span className="text-primary"> *</span>
                      </FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-full pl-3 text-left font-normal',
                                !field.value && 'text-muted-foreground'
                              )}
                              disabled={disabled}
                            >
                              {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date of birth</span>}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <div className="rounded-md border">
                            <CustomCalendar
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              selectedDate={field.value || undefined}
                              MonthAndYearPicker
                              maxSelectorYear={new Date().getFullYear()}
                            />
                          </div>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="sex"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Sex <span className="text-primary"> *</span>
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                      defaultValue={field.value}
                      disabled={disabled}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a sex" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="male">Male</SelectItem>
                        <SelectItem value="female">Female</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Email <span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder=""
                        className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                        {...field}
                        type="email"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Separator className=" bg-primary" />
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 p-2">
              <div>
                <FormField
                  control={form.control}
                  name="user_level"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        User Level <span className="text-primary"> *</span>
                      </FormLabel>
                      <Select
                        onValueChange={(value) => {
                          field.onChange(value);
                          console.log('Selected value:', value); // Imprime el valor seleccionado
                        }}
                        value={field.value}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="select a role for the user" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {rolOptions.map((rol: any, index: number) => (
                            <SelectItem value={rol.value} key={index}>
                              {rol.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Username <span className="text-primary"> *</span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder=""
                          className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Password <span className="text-primary"> *</span>
                      </FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input
                            {...field}
                            type={passwordVisible ? 'text' : 'password'}
                            disabled={residentFormStatus === 'loading'}
                            placeholder="********"
                            autoCorrect="off"
                            autoComplete="off"
                            className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                          />
                          <Eye
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(true)}
                          />
                          <EyeOff
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              !passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(false)}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="col-span-2 md:col-span-1">
                <FormField
                  control={form.control}
                  name="confirmpassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Confirm password <span className="text-primary"> *</span>
                      </FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input
                            {...field}
                            type={passwordVisible ? 'text' : 'password'}
                            disabled={residentFormStatus === 'loading'}
                            placeholder="********"
                            autoCorrect="off"
                            autoComplete="off"
                            className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                          />
                          <Eye
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(true)}
                          />
                          <EyeOff
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              !passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(false)}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <Separator className=" bg-primary" />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-2">
              <div>
                <FormField
                  control={form.control}
                  name="locations"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Location <span className="text-primary"> *</span>
                      </FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-full flex items-center justify-start font-normal',
                                !field.value && 'text-muted-foreground'
                              )}
                              disabled={residentFormStatus === 'loading'}
                            >
                              <Building className="mr-2 size-4 text-muted-foreground" />
                              <span>Locations</span>

                              {field.value?.length > 0 && (
                                <>
                                  <Separator orientation="vertical" className="mx-2 h-4" />
                                  <Badge variant="outline" className="rounded-sm px-1 font-normal lg:hidden">
                                    {field.value.length}
                                  </Badge>
                                  <div className="hidden space-x-1 lg:flex">
                                    {field.value.length > 1 ? (
                                      <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                                        {field.value.length} selected
                                      </Badge>
                                    ) : (
                                      field.value.map((location) => (
                                        <Badge
                                          variant="secondary"
                                          className="rounded-sm px-1 font-normal"
                                          key={location}
                                        >
                                          {locationOptions.find((t) => t.value === location)?.label}
                                        </Badge>
                                      ))
                                    )}
                                  </div>
                                </>
                              )}
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className={cn('p-1', !canEdit && 'pointer-events-none')}>
                          <Command className="w-full">
                            <CommandInput placeholder="Search time" />
                            <CommandList>
                              <CommandEmpty>No results found.</CommandEmpty>
                              <CommandGroup>
                                {locationOptions.map((location: any, index: number) => {
                                  const isSelected = field.value?.includes(location.value);
                                  return (
                                    <CommandItem
                                      value={location.value}
                                      key={index}
                                      onSelect={() =>
                                        field.onChange(
                                          isSelected
                                            ? field.value.filter((t) => t !== location.value)
                                            : [...(field.value || []), location.value]
                                        )
                                      }
                                    >
                                      <div
                                        className={cn(
                                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                          isSelected
                                            ? 'bg-primary text-primary-foreground'
                                            : 'opacity-50 [&_svg]:invisible'
                                        )}
                                      >
                                        <CheckIcon className={cn('h-4 w-4')} />
                                      </div>
                                      {location.label}
                                    </CommandItem>
                                  );
                                })}
                              </CommandGroup>

                              {field.value?.length > 0 && (
                                <>
                                  <CommandSeparator className="mb-1" />

                                  <CommandItem
                                    onSelect={() => {
                                      field.onChange([]);
                                    }}
                                    className="justify-center text-center"
                                  >
                                    Clear times
                                  </CommandItem>
                                </>
                              )}
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="hire_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Hire Date <span className="text-primary"> *</span>
                      </FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outline'}
                              className={cn(
                                'w-full pl-3 text-left font-normal',
                                !field.value && 'text-muted-foreground'
                              )}
                              disabled={disabled}
                            >
                              {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a hire date</span>}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <div className="rounded-md border">
                            <CustomCalendar
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              selectedDate={field.value || undefined}
                              MonthAndYearPicker
                            />
                          </div>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div>
                <FormField
                  control={form.control}
                  name="position_title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Position Title</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          className="focus-visible:ring-primary focus-visible:ring-offset-primary"
                          disabled={residentFormStatus === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-2">
            <Button type="submit" disabled={disabled || !form.formState.isValid}>
              {disabled && <Loader2 className="size-4 animate-spin mr-2" />}
              Save Staff
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
