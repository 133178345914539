import { EmarDetailPage } from '@/modules/emar/presentation/pages/EmarDetailPage';
import { Route, Routes } from 'react-router-dom';

const EmarRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<EmarDetailPage />}></Route>
      <Route path="/:locationId" element={<EmarDetailPage />}></Route>
    </Routes>
  );
};

export default EmarRouter;
