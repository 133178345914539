import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Separator } from '@/common/presentation/components/ui/separator';

interface CustomAccordionProps {
  className?: string;
  title?: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  openOption?: boolean;
  titleComponent?: React.ReactNode;
  headerPadding?: boolean;
  components?: React.ReactNode;
  separator?: boolean;
  isOpen?: (isOpen: boolean) => void;
}

export const CustomAccordion = ({
  className,
  title,
  children,
  openOption = true,
  defaultOpen = false,
  titleComponent,
  headerPadding = true,
  components,
  separator = false,
  isOpen
}: CustomAccordionProps) => {
  const [accordionOpen, setAccordionOpen] = useState(defaultOpen);

  useEffect(() => {
    if (isOpen) {
      isOpen(accordionOpen);
    }
  }, [accordionOpen, isOpen]);

  return (
    <div
      className={cn(
        'border p-5 rounded-lg bg-[#FFFFFF] dark:bg-[#09090B] shadow-sm border-t-4 border-t-primary/80 col-span-4',
        className
      )}
    >
      <div
        className={cn('flex justify-between items-center w-full my-4 h-0', openOption && 'cursor-pointer')}
        onClick={() => {
          if (openOption) setAccordionOpen(!accordionOpen);
        }}
      >
        <div className="flex flex-1">
          {title && !titleComponent && <span className="text-xl text-primary font-semibold">{title}</span>}
          {titleComponent && !title && titleComponent}
        </div>
        <div className="px-2">{components}</div>
        {openOption && (
          <div className="w-auto">
            <ChevronDown
              className={cn(
                'h-[1.2rem] w-[1.2rem] transition-all block',
                accordionOpen && 'rotate-90 scale-0 dark:rotate-0 dark:scale-100 hidden'
              )}
            />
            <ChevronUp
              className={cn(
                'h-[1.2rem] w-[1.2rem] transition-all block',
                !accordionOpen && 'rotate-90 scale-0 dark:rotate-0 dark:scale-100 hidden'
              )}
            />
          </div>
        )}
      </div>

      <div
        className={cn(
          'grid overflow-hidden transition-all duration-300 ease-in-out  text-sm',
          accordionOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0',
          headerPadding && accordionOpen && 'pt-4'
        )}
      >
        {separator && <Separator className="bg-primary/80" />}
        {accordionOpen && <div className="overflow-hidden">{children}</div>}
      </div>
    </div>
  );
};
