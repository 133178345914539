import { useDispatch } from 'react-redux';
import ResidentAlertContainer from './components/medicationAlertModal/components/ResidentAlertContainer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MedicationDispensingAlertI, MedicationDispensingDataI } from '../../domain/dashboard.domain';
import { AppDispatch } from '@/store/store';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { DatePickerWithRange } from '@/common/presentation/components/DatePickerWithPresets/DatePickerWithRange';
import { DateRange } from 'react-day-picker';
import { Button } from '@/components/ui/button';
import { fetchNotGivenMedicationAlerts } from '../slices/DashboardSlice';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import ResidentAlertContainerSkeleton from './components/medicationAlertModal/components/ResidentAlertContainerSkeleton';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { useTranslation } from 'react-i18next';

interface MedicationAlertI {
  resident_id: string;
  medications: MedicationDispensingDataI[];
}

const MedicationAlertsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [medicationAlerts, setMedicationAlerts] = useState<MedicationAlertI[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { locationSelectedId } = useLocation();
  const currentDate = useMemo(() => new Date(), []);
  const startDate = useMemo(() => new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), []);
  const [dateRangeSelected, setDateRangeSelected] = useState<DateRange>({ from: startDate, to: currentDate });
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    getAlerts(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [locationSelectedId]);

  const getAlerts = useCallback(
    async (signal?: AbortSignal) => {
      try {
        if (!dateRangeSelected.from && !dateRangeSelected.to) {
          return;
        }

        const dateRange = {
          from: dateRangeSelected?.from?.toISOString().split('T')[0] ?? '',
          to: dateRangeSelected?.to?.toISOString().split('T')[0] ?? ''
        };

        const response = await dispatch(
          fetchNotGivenMedicationAlerts({
            locationId: locationSelectedId,
            signal: signal,
            date_range: dateRange
          })
        );
        if (!response.payload) {
          throw new Error(JSON.stringify(response));
        }
        const { medication_no_given_alerts }: { medication_no_given_alerts: MedicationDispensingAlertI } =
          response.payload;
        formatData(medication_no_given_alerts);
      } catch (error) {}
    },
    [dateRangeSelected, locationSelectedId]
  );

  const onFilter = useCallback(async () => {
    setIsLoading(true);
    await getAlerts();
    setIsLoading(false);
  }, [locationSelectedId, dateRangeSelected]);

  const formatData = (alerts: MedicationDispensingAlertI) => {
    const alertsData = alerts ? Object.values(alerts) : [];
    setMedicationAlerts(alertsData);
    setIsLoading(false);
  };

  const updateDateRange = (dateRange: DateRange | undefined) => {
    if (!dateRange) {
      setDateRangeSelected({
        from: undefined,
        to: undefined
      });
    } else {
      setDateRangeSelected(dateRange);
    }
  };

  const cleanFilter = useCallback(() => {
    setDateRangeSelected({
      from: startDate,
      to: currentDate
    });
  }, []);

  return (
    <>
      <div>
        <LocationSelector quickView={true} />
        <div className="font-semibold text-2xl text-primary py-8">{t('dashboard.medicationAlerts')}</div>
        <CustomCard title="" description="">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col md:flex-row gap-2 flex-col-2">
              <DatePickerWithRange
                onUpdate={updateDateRange}
                selected={dateRangeSelected}
                maxDaysSelectable={90}
                untilCurrentDate={true}
              />
              <Button className="dark:text-white" onClick={onFilter} disabled={isLoading}>
                {t('dashboard.filter')}
              </Button>
              <Button className="dark:text-white" onClick={cleanFilter} disabled={isLoading}>
                {t('dashboard.cleanFilter')}
              </Button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
              {isLoading ? (
                <>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <ResidentAlertContainerSkeleton key={index} />
                  ))}
                </>
              ) : (
                medicationAlerts.map((alertInfo, index) => (
                  <ResidentAlertContainer key={index} alertInfo={alertInfo} index={index} maxItemsProp={4} />
                ))
              )}
            </div>
          </div>
        </CustomCard>
      </div>
    </>
  );
};

export default MedicationAlertsPage;
