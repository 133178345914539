import React from 'react';
import { Row } from "@tanstack/react-table";
import { TrackerCleaningTaskEditDialog } from './TrackerCleaningTaskEditDialog';
import { TrackerCleaningTaskDeleteDialog } from './TrackerCleaningTaskDeleteDialog';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEditClick: (id: number, name: string, status: string) => void;
  handleDeleteClick: (id: string) => void;
}

const RenderActionsColumn: React.FC<RenderActionsColumnProps> = (rowData) => {

  return (
    <div className='flex'>
        <TrackerCleaningTaskEditDialog id={rowData.id} name={rowData.name} status={rowData.status} />
        <TrackerCleaningTaskDeleteDialog id={rowData.id} />
    </div>
  );
};

export default RenderActionsColumn;
