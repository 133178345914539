import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import QuickTrackerInputsProps from "../interfaces/QuickTrackerInputsPropsInterface";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { CustomCalendar } from "../../CustomCalendar/CustomCalendar";
import { useEffect, useState } from "react";

const QuickTrackerInputDate = ({
    item,
    resident,
    type,
    exist,
    existOnList,
    onselectvalue
}: QuickTrackerInputsProps) => {
    const [date, setDate] = useState<Date | null>();

    useEffect(() => {
        setDate(item && !existOnList ? new Date(item.action) : null);
    }, [item, existOnList]);

    return (
        <div className="flex justify-center">
            <Popover 
                key={item} 
            >
                <PopoverTrigger asChild>
                    <Button
                        variant={"outline"}
                        className={cn(
                            "w-[280px] justify-start text-left font-normal w-100",
                            !item && "text-muted-foreground"
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? format(date, "MM/dd/yyyy - hh:mm aa") : <span>mm/dd/yyyy - hh:mm aa</span>}
                    </Button>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0">
                    <CustomCalendar
                        selectedDate={date}
                        timePicker={true}
                        onChange={
                            (value) => {
                                setDate(value);

                                onselectvalue(
                                    value.toISOString(), 
                                    resident, 
                                    type, 
                                    exist,
                                    exist ? item.id : undefined,
                                    exist ? item.id_data : undefined
                                )
                            }
                        }
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default QuickTrackerInputDate;
