import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useInactivePharmacyDialog } from '@/modules/pharmacy/infrastructure/hooks/dashboard/useInactivePharmacyDialog';
import { useNewPharmacy } from '@/modules/pharmacy/infrastructure/hooks/dashboard/useNewPharmacy';
import { AppDispatch, RootState } from '@/store/store';
import { CircleFadingPlus, Pencil } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { NewStaffForm } from '@/modules/staff/presentation/components/dashboard/forms/NewStaffForm';
import { createNewPharmacy, fetchAllPharmacies, createNewPharmacyModule } from '../slices/PharmacySlice';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { PharmacyI } from '../../domain/pharmacy.domian';
import { NewPharmacyForm } from '../components/forms/NewPharmacyForm';
import Repeater from '../components/Repeater';
import { useNavigate } from 'react-router-dom';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';

const rolesToValidate = ['Super Administrator', 'Executive'];

export const PharmacyDashboardPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { onOpen, onClose, isOpen } = useNewPharmacy();
  const [sort, setSort] = useState<{ column: string; direction: string }>({
    column: '',
    direction: ''
  });
  const navigate = useNavigate();
  const pharmaciesState = useSelector((state: RootState) => state.pharmacy);
  const [pharmaciesData, setPharmaciesData] = useState<any[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();

  useEffect(() => {
    getPharmacies();
  }, []);

  useEffect(() => {
    formatPharmacyData(pharmaciesState.pharmacies);
  }, [pharmaciesState]);

  const formatPharmacyData = (PharmacyList: PharmacyI[]) => {
    const formatedData = PharmacyList.map((pharmacy) => ({
      id: pharmacy.id,
      npi: pharmacy.npi,
      ncpdpid: pharmacy.ncpdpid,
      name: pharmacy.name,
      phone: pharmacy.phone,
      address: pharmacy.address,
      city: pharmacy.city,
      state: pharmacy.state,
      zip: pharmacy.zip,
      country: pharmacy.country,
      email: pharmacy.email,
      is_verified: pharmacy.is_verified,
      status: pharmacy.status,
      created_at: pharmacy.created_at,
      updated_at: pharmacy.updated_at
    }));
    setPharmaciesData(formatedData);
  };

  const getPharmacies = async () => {
    try {
      await dispatch(fetchAllPharmacies());
    } catch (error) {
      console.log(error);
    }
  };

  const tableSorting = (columnKey: string, direction: string) => {
    setSort({ column: columnKey, direction });
    const sortedData = [...pharmaciesData].sort((a: any, b: any) => {
      if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setPharmaciesData(sortedData);
  };

  const onSubmit = async (values: any) => {
    const response: any = await dispatch(createNewPharmacyModule(values));

    if (response.meta.requestStatus === 'fulfilled') {
      onClose();
      toast({
        title: 'Pharmacy created'
      });
      dispatch(fetchAllPharmacies());
    }

    if (response.meta.requestStatus === 'rejected') {
      const errorMessage = response?.error?.message || 'An error occurred';
      toast({
        description: errorMessage,
        variant: 'destructive'
      });
    }
  };

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  const columnsPharmacyTable = [
    {
      key: 'name',
      id: 'name',
      labelTranslationKey: 'name',
      value: 'name',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'npi',
      id: 'npi',
      labelTranslationKey: 'npi',
      value: 'npi',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'ncpdpid',
      id: 'ncpdpid',
      labelTranslationKey: 'ncpdpid',
      value: 'ncpdpid',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'phone',
      id: 'phone',
      labelTranslationKey: 'phone',
      value: 'phone',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'address',
      id: 'address',
      labelTranslationKey: 'address',
      value: 'address',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'city',
      id: 'city',
      labelTranslationKey: 'city',
      value: 'city',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'state',
      id: 'state',
      labelTranslationKey: 'state',
      value: 'state',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'country',
      id: 'country',
      labelTranslationKey: 'country',
      value: 'country',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'email',
      id: 'email',
      labelTranslationKey: 'email',
      value: 'email',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'zip',
      id: 'zip',
      labelTranslationKey: 'zip',
      value: 'zip',
      visible: true,
      static: true,
      sortable: true
    },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: 'actions',
      static: true,
      render: (row: any) => renderActionsColumn(row)
    }
  ];

  const renderActionsColumn = (row: any) => (
    <div className="flex gap-2 justify-center">
      <Button
        variant="outline"
        size="icon"
        onClick={() => {
          navigate(`update/${row.id}`);
        }}
      >
        <Pencil className="h-4 w-4" />
      </Button>
    </div>
  );

  const aditionalActions = useMemo(
    () => (
      <div className="flex ml-auto w-auto gap-2">
        {isAdmin && (
          <>
            <Button
              onClick={() => {
                navigate(`/pharmacies/update`);
              }}
              className="flex ml-auto w-auto gap-2"
              variant="default"
            >
              <span className="hidden md:block">New Pharmacy</span>
            </Button>
          </>
        )}
      </div>
    ),
    [onOpen, isAdmin]
  );

  return (
    <>
      <div className="flex flex-col gap-4 my-4 mt-20">
        <CustomCard title="Pharmacy" description="This module is designed for comprehensive pharmacy management, for creating and edit pharmacy profiles, assign new locations, and manage associated clients efficiently.">
          <TableCustomVirtuoso
            showSearchInput={true}
            columns={columnsPharmacyTable}
            data={pharmaciesData}
            isLoading={pharmaciesState.status === 'loading'}
            renderCellContent={(index: number, column: any) =>
              column.render ? column.render(pharmaciesData[index]) : pharmaciesData[index][column.key]
            }
            additionalButtons={aditionalActions}
            onSort={tableSorting}
            loadMore={() => {}}
            hasMore={false}
          />
        </CustomCard>
      </div>
    </>
  );
};
