import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DeviceInfoI, IStateSecurity, UpdateDeviceDataI } from '../../domain/device.domain';
import SecurityService from '../../infraestructure/services/SecurityService';

const initialState: IStateSecurity = {
  devices: {
    status: 'loading',
    list: []
  },
  current_device: {
    status: 'loading',
    device: null
  }
};

export const postRequestDeviceRegistration = createAsyncThunk(
  'device/requestRegistration',
  async (params: { deviceInfo: DeviceInfoI; locationId: string }) => {
    try {
      const response = SecurityService.requestDeviceRegistration(params.deviceInfo, params.locationId);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDevices = createAsyncThunk(
  'device/list',
  async (params: { locationId: string; status?: string | null; type?: string | null; name?: string | null }) => {
    try {
      const response = SecurityService.getDevices(params.locationId, params?.status, params?.type, params?.name);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDevice = createAsyncThunk('device/get', async (params: { fingerPrint: string }) => {
  try {
    const response = SecurityService.getDevice(params.fingerPrint);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchDeviceById = createAsyncThunk('device/getById', async (deviceId: string) => {
  try {
    const response = SecurityService.getDeviceById(deviceId);
    return response;
  } catch (error) {
    throw error;
  }
});

export const updateDeviceStatus = createAsyncThunk(
  'device/updateStatus',
  async (params: { deviceId: string; newStatus: string }) => {
    try {
      const response = SecurityService.updateDeviceStatus(params.deviceId, params.newStatus);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateDevice = createAsyncThunk('device/update', async (params: UpdateDeviceDataI) => {
  try {
    const response = SecurityService.updateDevice(params);
    return response;
  } catch (error) {
    throw error;
  }
});

export const updateLocationRestrictByDevice = createAsyncThunk(
  'device/updateLocationRestrictByDevice',
  async (params: { location_id: string; status: boolean }) => {
    try {
      const response = SecurityService.updateLocationRestrictByDevice(params.location_id, params.status);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const registerDevice = createAsyncThunk(
  'device/updateLocationRestrictByDevice',
  async (params: { deviceInfo: DeviceInfoI; locationsId: string[], name: string }) => {
    try {
      const response = SecurityService.registerDevice(params.deviceInfo, params.locationsId, params.name);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const SecuritySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      state.devices.status = 'idle';
      state.devices.list = action.payload.list;
    });
    builder.addCase(fetchDevices.pending, (state) => {
      state.devices.status = 'loading';
      state.devices.list = [];
    });
    builder.addCase(fetchDevice.fulfilled, (state, action) => {
      state.current_device.status = 'idle';
      state.current_device.device = Object.values(action.payload.device).length > 0 ? action.payload.device : null;
    });
    builder.addCase(fetchDevice.pending, (state) => {
      state.current_device.status = 'loading';
      state.current_device.device = null;
    });
  }
});

export const {} = SecuritySlice.actions;
export default SecuritySlice.reducer;
