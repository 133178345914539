import React, { useEffect, useState } from 'react';
import { format, parse, isValid } from 'date-fns';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Input } from '@/components/ui/input';
import { CalendarIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';

interface DateInputWithCalendarProps {
  value: string;
  onChange: (date: string) => void;
  defaultValue?: string;
}

const DateInputWithCalendar = ({ value, onChange, defaultValue }: DateInputWithCalendarProps) => {
  const { t } = useTranslation();
  const dateFormat = t('dateFormats.dateFormat');
  const [inputValue, setInputValue] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (defaultValue) {
      const parsedDefault = parse(defaultValue, 'yyyy-MM-dd', new Date());
      if (isValid(parsedDefault)) {
        setSelectedDate(parsedDefault);
        setInputValue(format(parsedDefault, dateFormat));
        onChange(defaultValue);
      }
    } else if (value) {
      const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
      if (isValid(parsedDate)) {
        setSelectedDate(parsedDate);
        setInputValue(format(parsedDate, dateFormat));
      }
    } else {
      setInputValue('');
      setSelectedDate(null);
    }
  }, [defaultValue, value, dateFormat]);

  useEffect(() => {
    if (value) {
      const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
      if (isValid(parsedDate)) {
        setSelectedDate(parsedDate);
        setInputValue(format(parsedDate, dateFormat));
      }
    }
  }, [value, dateFormat]);

  const formatInputToDisplay = (input: string): string => {
    const numbers = input.replace(/\D/g, '');

    const isMMDDYYYY = dateFormat.startsWith('MM');

    if (numbers.length === 0) return '';

    if (isMMDDYYYY) {
      if (numbers.length <= 2) {
        const month = parseInt(numbers);
        if (month > 12) return '12';
        return numbers;
      }
      if (numbers.length <= 4) {
        const month = parseInt(numbers.slice(0, 2));
        const day = parseInt(numbers.slice(2));
        return `${month > 12 ? '12' : numbers.slice(0, 2)}/${numbers.slice(2)}`;
      }
      if (numbers.length <= 8) {
        const month = parseInt(numbers.slice(0, 2));
        const day = parseInt(numbers.slice(2, 4));
        return `${month > 12 ? '12' : numbers.slice(0, 2)}/${day > 31 ? '31' : numbers.slice(2, 4)}/${numbers.slice(
          4
        )}`;
      }
    } else {
      if (numbers.length <= 2) {
        const day = parseInt(numbers);
        if (day > 31) return '31';
        return numbers;
      }
      if (numbers.length <= 4) {
        const day = parseInt(numbers.slice(0, 2));
        const month = parseInt(numbers.slice(2));
        return `${day > 31 ? '31' : numbers.slice(0, 2)}/${month > 12 ? '12' : numbers.slice(2)}`;
      }
      if (numbers.length <= 8) {
        const day = parseInt(numbers.slice(0, 2));
        const month = parseInt(numbers.slice(2, 4));
        return `${day > 31 ? '31' : numbers.slice(0, 2)}/${month > 12 ? '12' : numbers.slice(2, 4)}/${numbers.slice(
          4
        )}`;
      }
    }

    return numbers.slice(0, 8);
  };

  const isValidDate = (dateString: string): boolean => {
    const parts = dateString.split(/[-/]/);
    if (parts.length !== 3) return false;

    const isMMDDYYYY = dateFormat.startsWith('MM');
    const year = parseInt(parts[2]);
    const month = parseInt(isMMDDYYYY ? parts[0] : parts[1]) - 1; // Adjust month for Date object
    const day = parseInt(isMMDDYYYY ? parts[1] : parts[0]);

    if (year < 1000 || year > 9999) return false;

    const date = new Date(year, month, day);
    return date.getMonth() === month && date.getDate() === day && date.getFullYear() === year;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = formatInputToDisplay(rawValue);
    setInputValue(formattedValue);

    if (formattedValue.length >= 8 && formattedValue.includes('/')) {
      if (isValidDate(formattedValue)) {
        const parsedDate = parse(formattedValue, dateFormat, new Date());
        onChange(format(parsedDate, 'yyyy-MM-dd'));
        setSelectedDate(parsedDate);
      }
    } else if (formattedValue === '') {
      onChange('');
      setSelectedDate(null);
    }
  };

  const handleDayClick = (day: Date) => {
    const formattedDate = format(day, dateFormat);
    setInputValue(formattedDate);
    onChange(format(day, 'yyyy-MM-dd'));
    setSelectedDate(day);
    setIsPopoverOpen(false);
  };

  return (
    <div className="relative">
      <Input
        type="text"
        value={inputValue || ''}
        onChange={handleInputChange}
        placeholder={t('medications.medicationForm.selectDate')}
        className="pr-10"
        maxLength={10}
        style={{ width: '150px' }}
      />
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <button
            type="button"
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <CalendarIcon className="h-4 w-4" />
          </button>
        </PopoverTrigger>
        <PopoverContent>
          <div className="p-2">
            <CustomCalendar
              selectedDate={selectedDate}
              onChange={handleDayClick}
              MonthAndYearPicker={true}
              timePicker={false}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default DateInputWithCalendar;
