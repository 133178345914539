import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';
import React, { createContext, useContext } from 'react';

interface RoleHelperContextType {
  roles: any[];
  hasRole: (roleToCheck: string) => boolean;
  hasAnyRole: (rolesToCheck: string[]) => boolean;
  hasAllRoles: (rolesToCheck: string[]) => boolean;
}

export const RoleHelperContext = createContext<RoleHelperContextType | undefined>(undefined);

export const RoleHelperProvider: React.FC<{children: JSX.Element }> = ({ children }) => {
  const { roles } : { roles: any } = useContext(AuthContext);

  const hasRole = (roleToCheck: string): boolean => {
    return roles.some((role: any) => role.name === roleToCheck);
  };

  const hasAnyRole = (rolesToCheck: string[]): boolean => {
    return rolesToCheck.some((role) => hasRole(role));
  };

  const hasAllRoles = (rolesToCheck: string[]): boolean => {
    return rolesToCheck.every((role) => hasRole(role));
  };

  return (
    <RoleHelperContext.Provider value={{ roles, hasRole, hasAnyRole, hasAllRoles }}>
      {children}
    </RoleHelperContext.Provider>
  );
};

export const useRoleHelper = () => useContext(RoleHelperContext)!;