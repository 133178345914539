import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMedicationsByResidentId, updateMassiveResidentMedications } from '../../../slices/medicationsSlice';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { RootState } from '@/store/store';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { Label } from '@/common/presentation/components/ui/label';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import DateInputWithCalendar from '../../MedicationsDetails/DateInputWithCalendar';
import useColumnsCentralizedMedications from './columnsCentralizedMedications';
import useRouteParams from '@/common/hooks/RouteParamsHook';

const FormCentralizedMedicationRecord = () => {
  const createEmptyRow = () => {
    return columns.reduce(
      (acc, column, index) => {
        if (index >= 2) {
          acc[column.key] = '';
        }
        return acc;
      },
      { id: `sample-${Date.now()}` }
    );
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultResidentId = searchParams.get('r');
  const [residentId, setResidentId] = useState(defaultResidentId || '');
  const { medicationsByResidentId } = useSelector((state: RootState) => state.medications);
  const [pageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const { params } = useRouteParams();
  const [filters, setFilters] = useState<{ filter: string }>({ filter: '' });
  const columns = useColumnsCentralizedMedications();
  const [data, setData] = useState([]);
  const [changedData, setChangedData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const [sampleRow, setSampleRow] = useState(createEmptyRow());
  const [modifiedRows, setModifiedRows] = useState({});
  const [hasSampleData, setHasSampleData] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getAllMedicationsByResidentId({
        residentId: params.r || 'all-residents',
        filter: filters.filter,
        page: currentPage,
        limit: pageSize
      })
    )
      .unwrap()
      .then((action) => {
        if (action.data) {
          const transformedData = action.data.map((medication) => {
            const transformedChart = medication.chart.map((chartItem) => ({
              rx: chartItem.rx,
              status: chartItem.status,
              refills: chartItem.refills,
              fillDate: chartItem.fillDate,
              qtyReceived: chartItem.qtyReceived,
              cycleEndDate: chartItem.cycle_end_date,
              expirationDate: chartItem.expiration_date,
              cycleStartDate: chartItem.cycle_start_date
            }));
            return { ...medication, chart: transformedChart };
          });
          setData(transformedData);
        }
      })
      .catch((error) => {
        console.error('Error al obtener medicaciones:', error);
      });
  }, [residentId, filters.filter, currentPage, dispatch, params.r]);

  useEffect(() => {
    setEditedData({});
  }, [residentId]);

  const handleSampleRowChange = (columnKey, value) => {
    const columnIndex = columns.findIndex((col) => col.key === columnKey);
    if (columnIndex >= 2) {
      setSampleRow((prevSampleRow) => {
        const newSampleRow = {
          ...prevSampleRow,
          [columnKey]: value
        };

        // Check if any sample field has data
        const hasAnyData = Object.values(newSampleRow).some((val) => val !== '');
        setHasSampleData(hasAnyData);

        return newSampleRow;
      });
    }
  };

  const applySampleToRow = (index) => {
    if (!hasSampleData) return;

    const updatedRow = {
      ...data[index],
      ...Object.keys(sampleRow).reduce((acc, key) => {
        if (key !== 'id' && key !== 'medication_name' && key !== 'type_medication_name' && key !== 'rx') {
          acc[key] = sampleRow[key];
        }
        return acc;
      }, {})
    };

    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = updatedRow;
      return newData;
    });

    setChangedData((prevChangedData) => ({
      ...prevChangedData,
      [index]: updatedRow
    }));

    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [index]: {
        id: data[index].id,
        ...updatedRow
      }
    }));

    toast({ description: 'Sample data applied to row.' });
  };

  const handleCheckboxChange = (index, isChecked) => {
    if (isChecked && hasSampleData) {
      applySampleToRow(index);
    }
    handleCellChange(index, 'selected', isChecked, '', data[index].id);
  };

  const handleCellChange = useCallback(
    (index, columnKey, value, medicationName, medicationId) => {
      let formattedValue = value;

      if (columnKey === 'calendar' && isValid(new Date(value))) {
        formattedValue = new Date(value).toISOString();
      }

      setData((prevData) => {
        const updatedData = prevData.map((row, idx) => (idx === index ? { ...row, [columnKey]: formattedValue } : row));
        return updatedData;
      });

      setEditedData((prevEditedData) => ({
        ...prevEditedData,
        [index]: {
          id: medicationId,
          [columnKey]: formattedValue,
          medicationName,
          medicationId
        }
      }));

      setModifiedRows((prevModifiedRows) => ({
        ...prevModifiedRows,
        [medicationId]: true
      }));
    },
    [data]
  );

  const submitChart = async () => {
    const rowsToSave = data.filter((row) => modifiedRows[row.id]);

    const response = await dispatch(updateMassiveResidentMedications(rowsToSave));
    if (!response.error) {
      toast({ description: 'Actualización realizada con éxito!' });
      dispatch(
        getAllMedicationsByResidentId({
          residentId: params.r || 'all-residents',
          filter: filters.filter,
          page: currentPage,
          limit: pageSize
        })
      );
    }
  };

  const renderCellContent = (index, column) => (
    <td key={column.key}>
      {column.editable ? (
        column.type === 'calendar' ? (
          <DateInputWithCalendar
            value={data[index][column.key] || ''}
            onChange={(date) => handleCellChange(index, column.key, date, '', data[index].id)}
          />
        ) : column.type === 'checkbox' ? (
          <Checkbox
            checked={data[index][column.key]}
            onCheckedChange={(isChecked) => handleCheckboxChange(index, isChecked)}
          />
        ) : (
          <Input
            type={column.type === 'number' ? 'number' : 'text'}
            value={data[index][column.key]}
            onChange={(e) => handleCellChange(index, column.key, e.target.value, '', data[index].id)}
            style={{ width: '100px' }}
          />
        )
      ) : (
        data[index][column.key]
      )}
    </td>
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  return (
    <div className="space-y-6">
      <div className="border border-gray-300 p-4 bg-gray-100 rounded-md">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-semibold">Sample</h2>
          {hasSampleData && <div className="text-sm text-gray-600">Check the box in any row to apply sample data</div>}
        </div>
        <div className="flex gap-6">
          {columns.slice(4).map((column) => (
            <div key={column.key} className="flex flex-col">
              <Label htmlFor={`sample-${column.key}`} className="text-sm font-medium mb-1">
                {t(column.labelTranslationKey)}
              </Label>
              {column.type === 'calendar' ? (
                <DateInputWithCalendar
                  value={sampleRow[column.key] || ''}
                  onChange={(date) => handleSampleRowChange(column.key, date)}
                />
              ) : (
                <Input
                  id={`sample-${column.key}`}
                  type={column.type === 'number' ? 'number' : 'text'}
                  value={sampleRow[column.key] || ''}
                  onChange={(e) => handleSampleRowChange(column.key, e.target.value)}
                  className="w-[140px]"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <TableCustomVirtoso
        columns={columns}
        data={data}
        total={medicationsByResidentId?.meta?.total}
        pageSize={pageSize}
        page={currentPage}
        selectedRows={selectedRows}
        onSelectRows={setSelectedRows}
        renderCellContent={renderCellContent}
        showViewOptions={false}
      />
      <div className="flex justify-end gap-2">
        <Button onClick={submitChart} type="submit" className="bg-primary text-white">
          {'Save in Cycle & LIC622'}
        </Button>
      </div>
    </div>
  );
};

export default FormCentralizedMedicationRecord;
