import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";

interface IModuleCard {
    title: string;
    id: string;
    color?: string;
    icon: React.ReactNode;
    onClick: (id: string) => void;
}

const ModuleCard: React.FC<IModuleCard> = ({ id, color, title, icon, onClick }) => {
    
    const handleCardClick = () => {
        onClick(id);
    };

    if (!color) return null;

    return (
        <Card 
            className={'w-full w-md-[239px] shadow-genericCard'}
            onClick={handleCardClick}
        >
            <CardContent className={'flex p-2 px-4 justify-left gap-4 flex-[1_0_0] w-full w-md-[239px] h-[72px] items-center flex-shrink-0 '}>
                 {icon && (
                    <div className="">
                        <div
                            style={{ backgroundColor: color }} 
                            className={`bg-[${color}] text-white rounded-[32px] w-10 h-10 flex justify-center items-center rounded-[19px]`}
                        >
                            {icon}
                        </div>
                    </div>
                )}
                <p className="text-sm font-semibold leading-[16px] text-[14px] text-grayBase">{title}</p>
            </CardContent>
        </Card>
    );
};

export default ModuleCard;