import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ExpandableTextProps {
    html: string;
    maxLines: string;
}

export function ExpandableText({ html, maxLines }: ExpandableTextProps) {

    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isTruncated, setIsTruncated] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (html != '<p></p>' && contentRef.current && contentRef.current.scrollHeight > contentRef.current.clientHeight) {
            setIsTruncated(true);
        } else {
            setIsTruncated(false);
        }
    }, [html]);

    return (
        <div className="text-xs">
            <div
                ref={contentRef}
                className={`text-ellipsis ${isExpanded ? '' : maxLines}`}
                dangerouslySetInnerHTML={{ __html: html }}
            />
            {isTruncated && (
                <span onClick={() => setIsExpanded(!isExpanded)} className="text-primary cursor-pointer">
                    {isExpanded ? t('notes.readLess') : t('notes.readMore')}...
                </span>
            )}
        </div>
    );
}
