import * as React from 'react';
import { Check } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Button } from '@/common/presentation/components/ui/button';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Separator } from '@/common/presentation/components/ui/separator';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/common/presentation/components/ui/command';
import { useTranslation } from 'react-i18next';

interface Option {
  name: string;
  value: string;
  icon?: React.ComponentType<{ className?: string }>;
}

interface FilterTableProps {
  statusOptions: Option[];
  devicesTypes: Option[];
  filters: { deviceType: string; status: string };
  setFilters: (filters: { status: string; deviceType: string }) => void;
  fetchFilteredData: (filters: { status: string; deviceType: string }) => void;
}

const FilterTable: React.FC<FilterTableProps> = ({
  statusOptions,
  devicesTypes,
  filters = { status: '', deviceType: '' },
  setFilters,
  fetchFilteredData
}) => {
  const { t } = useTranslation();

  const selectedStatusValues = new Set((filters.status ?? '').split(',').filter(Boolean));
  const selectedDevicesTypeValues = new Set((filters.deviceType ?? '').split(',').filter(Boolean));

  const handleSelectAll = (type: 'deviceType' | 'status') => {
    if (type === 'status') {
      const newFilters = { ...filters, status: 'all' };
      setFilters(newFilters);
      fetchFilteredData(newFilters);
    } else {        
      const newFilters = { ...filters, deviceType: 'all' };
      setFilters(newFilters);
      fetchFilteredData(newFilters);
    }
  };

  const handleOptionSelect = (type: 'deviceType' | 'status', option: Option) => {
    const selectedSet = type === 'deviceType' ? selectedDevicesTypeValues : selectedStatusValues;
    const optionsArray = type === 'deviceType' ? devicesTypes : statusOptions;
    const newValueSet = new Set(selectedSet);

    if (newValueSet.has(option.value)) {
      newValueSet.delete(option.value);
    } else {
      newValueSet.add(option.value);
    }

    const allSelected = optionsArray.every((opt) => newValueSet.has(opt.value));
    const filterValues = allSelected ? 'all' : Array.from(newValueSet).join(',');

    const newFilters = { ...filters, [type]: filterValues };
    setFilters(newFilters);
    fetchFilteredData(newFilters);
  };

  const handleClearFilters = (type: 'deviceType' | 'status') => {
    const newFilters = { ...filters, [type]: '' };
    setFilters(newFilters);
    fetchFilteredData(newFilters);
  };

  return (
    <div className="flex items-center space-x-2">
      {/* Status Filter */}
      <div className="flex items-center space-x-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" size="sm" className="h-8 border-dashed">
              {t('security.status')}
              {selectedStatusValues.size > 0 && (
                <>
                  <Separator orientation="vertical" className="mx-2 h-4" />
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                    {selectedStatusValues.size}
                  </Badge>
                  <div className="hidden space-x-1 lg:flex">
                    {statusOptions
                      .filter((option) => selectedStatusValues.has(option.value))
                      .map((option) => (
                        <Badge key={option.value} variant="secondary" className="rounded-sm px-1 font-normal">
                          {t(`security.${option.name.toLowerCase()}`)}
                        </Badge>
                      ))}
                  </div>
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0" align="start">
            <Command>
              <CommandList>
                <CommandEmpty>{t('filterTable.noStatusOptions')}</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    onSelect={() => handleSelectAll('status')}
                    className={filters.status === 'all' ? 'bg-primary text-primary-foreground' : ''}
                  >
                    {t('medications.filterTable.selectAll')}
                  </CommandItem>
                  {statusOptions.map((option) => {
                    const isSelected = selectedStatusValues.has(option.value);
                    return (
                      <CommandItem key={option.value} onSelect={() => handleOptionSelect('status', option)}>
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <Check className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                        <span>{t(`security.${option.name.toLowerCase()}`) || option.name}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
                {selectedStatusValues.size > 0 && (
                  <>
                    <CommandSeparator />
                    <CommandGroup>
                      <CommandItem
                        onSelect={() => handleClearFilters('status')}
                        className="justify-center text-center"
                      >
                        {t('medications.filterTable.clearStatusFilters')}
                      </CommandItem>
                    </CommandGroup>
                  </>
                )}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      {/* Device Type Filter */}
      <div className="flex items-center space-x-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" size="sm" className="h-8 border-dashed">
              {t('security.deviceType')}
              {selectedDevicesTypeValues.size > 0 && (
                <>
                  <Separator orientation="vertical" className="mx-2 h-4" />
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                    {selectedDevicesTypeValues.size}
                  </Badge>
                  <div className="hidden space-x-1 lg:flex">
                    {devicesTypes
                      .filter((option) => selectedDevicesTypeValues.has(option.value))
                      .map((option) => (
                        <Badge key={option.value} variant="secondary" className="rounded-sm px-1 font-normal">
                          {t(`security.${option.name.toLowerCase()}`)}
                        </Badge>
                      ))}
                  </div>
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0" align="start">
            <Command>
              <CommandList>
                <CommandEmpty>{t('filterTable.noDeviceOptions')}</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    onSelect={() => handleSelectAll('deviceType')}
                    className={filters.deviceType === 'all' ? 'bg-primary text-primary-foreground' : ''}
                  >
                    {t('medications.filterTable.selectAll')}
                  </CommandItem>
                  {devicesTypes.map((option) => {
                    const isSelected = selectedDevicesTypeValues.has(option.value);
                    return (
                      <CommandItem key={option.value} onSelect={() => handleOptionSelect('deviceType', option)}>
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <Check className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                        <span>{t(`security.${option.name.toLowerCase()}`) || option.name}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
                {selectedDevicesTypeValues.size > 0 && (
                  <>
                    <CommandSeparator />
                    <CommandGroup>
                      <CommandItem
                        onSelect={() => handleClearFilters('deviceType')}
                        className="justify-center text-center"
                      >
                        {t('security.clearDeviceTypeFilters')}
                      </CommandItem>
                    </CommandGroup>
                  </>
                )}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default FilterTable;