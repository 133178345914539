import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppraisalNeedsAndServiceCard } from './AppraisalNeedsAndServiceCard';
import { FormsHistory } from './FormsHistory';
import { FormsNavigation } from './Navigation';

export const AppraisalNeedsAndServicesPlanPage = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident || {});
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();

  useEffect(() => {
    if (!resident) {
      dispatch(residentActions.getResidentById(params.r));
    }
  }, [resident]);

  return (
    <div className="mt-16 flex flex-col gap-4">
      <FormsNavigation path="/residents/forms" />
      <AppraisalNeedsAndServiceCard />
      <FormsHistory />
    </div>
  );
};
