import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as staffActions from '@/modules/staff/presentation/slices/staffSlice';
import { useInactiveStaffStore } from '@/modules/staff/domain/stores/dashboard/InnactiveStaff';
import { useStaffStatusStore } from '@/modules/staff/domain/stores/updateStaff/staffStatus';
import { AppDispatch } from '@/store/store';
import { useDispatch } from 'react-redux';
import { useConfirm } from '@/common/hooks/useConfirm';

interface Props {
  id: string;
}

export const TableActions = ({ id }: Props) => {
  const { changeStaffStatus, status: residentStatusReq } = useStaffStatusStore();
  const { getInactiveStaff } = useInactiveStaffStore();
  const { params } = useRouteParams();
  const dispatch = useDispatch<AppDispatch>();
  const [ConfirmDialog, confirm] = useConfirm(
    'Are you sure?',
    'You are about to change the status of the staff member. Do you want to continue?'
  );

  const location = params.l === 'all-locations' ? undefined : params.l;

  const handleActivate = async () => {
    const ok = await confirm();

    if (ok) {
      try {
        await changeStaffStatus({ staff_id: id, status: 'active' });
        getInactiveStaff(location);
        dispatch(staffActions.getStaffByLocation(location));

        toast({
          title: `Resident status has been updated to active`,
          variant: 'default'
        });
      } catch (error: any) {
        console.error(error.message);
        toast({
          title: 'Error updating resident status',
          variant: 'destructive'
        });
      }
    }
  };

  return (
    <>
      <ConfirmDialog />

      <div className="flex gap-4">
        <Button
          variant="outline"
          className="hover:border-green-600 hover:text-green-600 text-green-500 border-green-500"
          onClick={handleActivate}
          disabled={residentStatusReq === 'loading'}
        >
          Activate Staff
        </Button>
      </div>
    </>
  );
};
