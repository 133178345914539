import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Pencil, Ellipsis, CircleFadingPlus, Trash } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { getTrackerDirectTypes } from '../slices/TrackerDirectSlice';

function TrackerDirectType({ dataDirectReport }: { dataDirectReport: (page: number, reset: boolean) => void }) {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const dispatch = useDispatch();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState(null);
    const [dataid, setDataid] = useState(null);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const trackerDirect = useSelector((state: RootState) => state.trackerDirect);
    const [selectedDirect, setSelectedDirect] = useState<string>(params.e || 'form');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const { t } = useTranslation();
    const [accentColor, setAccentColor] = useState("");
    const { locationSelectedId } = useLocation();
    const configurations = useSelector((state: RootState) => state.configurations);
    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);
    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedDirect(e);
    };
    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    const columnsreport = [
        { label: t('trackers.customtype.Name'), value: 'name', type: 'date' },
    ];

    useEffect(() => {
        if (trackerDirect.directTypes && Array.isArray(trackerDirect.directTypes) && trackerDirect.directTypes.length > 0) {
            setTypes(
                trackerDirect.directTypes.filter((value) => value.status === "active")
            );
        } else {
            setTypes([]);
        }
    }, [trackerDirect.directTypes]);

    const setpTypes = () => {
        dispatch(getTrackerDirectTypes());
    };

    const Fields = {
        'Edit': [
            { fieldName: 'name', label: t('trackers.customtype.Name'), type: 'text' },
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(fields);
        setDialogTitle(title);
        setAction(title);
        setDataid(row.id);

        if(title === "Delete") {
            setDialogTitle(t("cleaning.report.summary.delete.title"));
            setDialogDescription(t("trackers.trackerdirect.DeleteDescription"));
        } else if(title === "Edit") {
            setDialogTitle(t("cleaning.report.summary.edit.title"));
            setDialogDescription("");
        } else {
            setDialogTitle(t("common.create"));
            setDialogDescription("");
        }
    };

    const handleSubmit = async () => {
        setShowEditDialog(false);

        if (action == 'Edit' || action == 'Delete') {
            await TrackerService.updateTrackerDirectType(
                {
                    id_data: dataid,
                    data: {
                        name: formData.name,
                        status: action == 'Edit' ? 'active' : 'inactive'
                    }
                }
            );
        }  else {
            await TrackerService.saveTrackerDirectType(
                {
                    data: {
                        name: formData.name,
                        status: "active",
                        location_id: locationSelectedId
                    }
                }
            );
        }
        
        setpTypes();
        if(dataDirectReport) dataDirectReport(1, true);
    };

    useEffect(() => {
        setpTypes();
    }, [locationSelectedId]);

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
        setFormData({});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const handleChangechecked = (checked, column) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [column.fieldName]: checked
        }));
    };


    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {
        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'switch':
                return (<Switch
                    checked={formData[column.fieldName] === 'inactive' ? false : !!formData[column.fieldName]}
                    onCheckedChange={(checked) => handleChangechecked(checked, column)}
                />);
            default:
                return null;
        }
    };
    
    return (
        <div>
            <div>
                {
                    locationSelectedId && (
                        <div>
                            <Card className="border-t-4 border-t-primary/50">
                                <CardHeader>
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                            {t('trackers.trackerdirect.typeCustom')}
                                    </CardTitle>
                                    
                                    <div className="flex w-full items-center justify-between">
                                        <div>

                                        </div>

                                        <div>
                                            <Button 
                                                variant="outline" 
                                                size="sm" 
                                                className='bg-primary/80 hover:bg-primary/80 hover:text-white text-white align-self-end'
                                                onClick={() => handleButtonOpenDialog(Fields['Edit'], 'New', [])}
                                            >
                                                <CircleFadingPlus className="h-4 w-4 mr-1" />
                                                <span>{t('trackers.customtype.New')}</span>
                                            </Button>
                                        </div>
                                    </div>
                                </CardHeader>

                                <CardContent>
                                    <div className="border border-solid border-gray-300 border-opacity-50 rounded-md" style={{ height: "30rem", overflowY: "auto" }}>
                                        <Table className="shadow-lg">
                                            <TableHeader style={{ position: "sticky", top: 0 }}>
                                                <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                    {
                                                        columnsreport.map(
                                                            (column) => (
                                                                <TableHead className="text-white w-100" key={column.value} style={{ position: "sticky", top: 0 }}>
                                                                    { column.label }
                                                                </TableHead>
                                                            )
                                                        )
                                                    }
                                                    <TableHead className="text-white w-5" style={{ position: "sticky", top: 0 }}>
                                                        {t('trackers.Actions')}
                                                    </TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            
                                            <TableBody className="bg-white dark:bg-background">
                                                {types && types
                                                    .map((row, index) => (
                                                        <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                            {columnsreport.map((column, columnIndex) => (
                                                                <TableCell key={columnIndex} className="py-2 px-4 w-100">
                                                                    {capitalizeWords(String(row[column.value as keyof typeof row]))}
                                                                </TableCell>
                                                            ))}
                                                            <TableCell className="py-2 px-4 flex text-nowrap">
                                                                <Button onClick={() => handleButtonOpenDialog(Fields['Edit'], 'Edit', row)} variant="outline" size="icon" className='mr-2'>
                                                                    <Pencil className="h-4 w-4" />
                                                                </Button>

                                                                <Button onClick={() => handleButtonOpenDialog([], 'Delete', row)} variant="outline" size="icon" className='mr-2'>
                                                                    <Trash className="h-4 w-4" />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>

                                            <CustomDialog
                                                isOpen={showEditDialog}
                                                onClose={() => handleCloseEditDialog()}
                                                title={dialogTitle}
                                                onSubmit={handleSubmit}
                                                width={"30rem"}
                                                newDialog={true}
                                                description={dialogDescription}
                                            >
                                                <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                    <div className="grid gap-4">
                                                        {fields && fields.map((field, index) => (
                                                            <div key={field.fieldName} className="flex flex-col">
                                                                <Label htmlFor={field.fieldName} className='mb-1'>{field.label}</Label>

                                                                {renderInputForColumn(field, index)}
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className="flex flex-row justify-end mt-4">
                                                        <Button type="submit" className="bg-primary">
                                                            { t("trackers.trackerdirect.accept") }
                                                        </Button>
                                                    </div>
                                                </form>
                                            </CustomDialog>
                                        </Table>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default TrackerDirectType;
