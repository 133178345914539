import { TableHead, TableRow } from "@/components/ui/table";
import { ImageCardTables } from "../../Selectors/ImageCardTables";
import { t } from "i18next";
import QuickTrackerTableHeaderProps from "../interfaces/QuickTrackerTableHeaderPropsInterface";

const QuickTrackerTableHeader = ({ residents }: QuickTrackerTableHeaderProps) => {
    return (
        <TableRow className="py-2 px-4 text-center bg-primary text-white rounded-t-md hover:bg-primary">
            <TableHead
                className="text-white bg-primary"
                style={{ width: "15rem", minWidth: "15rem", position: "sticky", left: 0, zIndex: "90" }}
            >
                {t('trackers.type')}
            </TableHead>

            {
                residents.length ? (
                    residents.map(
                        (value, index) => (
                            <TableHead
                                key={index}
                                className={`text-white`}
                                style={{ position: "sticky", top: "0", width: "auto", minWidth: "10rem", zIndex: "80" }}
                            >
                                <ImageCardTables
                                    item={{
                                        id: value.id,
                                        firstName: value.first_name,
                                        lastName: value.last_name,
                                        image: value.image_url
                                    }}
                                    aspectRatio={'rounded'}
                                />
                            </TableHead>
                        )
                    )
                ) : null
            }
        </TableRow>
    );
};

export default QuickTrackerTableHeader;
