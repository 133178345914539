import React from 'react';
import { cn } from '@/lib/utils';
import { DayPicker } from 'react-day-picker';
import { t } from 'i18next';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  onMonthYearSelect?: (date: { month: number; year: number }) => void;
};

export function YearAndMonthSelect({ onMonthYearSelect }: CalendarProps) {
  const [selectedMonth, setSelectedMonth] = React.useState<number>(new Date().getMonth());
  const [selectedYear, setSelectedYear] = React.useState<number>(new Date().getFullYear());

  const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - 5 + i);
  const months: string[] = t('months', { returnObjects: true });

  const handleMonthSelect = (monthIndex: number) => {
    setSelectedMonth(monthIndex);
    notifyParent(monthIndex, selectedYear);
  };

  const handleYearSelect = (year: number) => {
    setSelectedYear(year);
    notifyParent(selectedMonth, year);
  };

  const notifyParent = (month: number, year: number) => {
    if (onMonthYearSelect) {
      onMonthYearSelect({ month: month + 1, year });
    }
  };

  return (
    <div className="space-y-6">
      <fieldset className="border p-4 rounded-md">
        <legend className="text-lg font-medium mb-2">Seleccionar Año</legend>
        <select
          value={selectedYear}
          onChange={(e) => handleYearSelect(Number(e.target.value))}
          className="border rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="Seleccionar Año"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </fieldset>

      <fieldset className="border p-4 rounded-md">
        <legend className="text-lg font-medium mb-2">Seleccionar Mes</legend>
        <div className="grid grid-cols-3 gap-4">
          {months.map((month, index) => (
            <button
              key={index}
              type="button"
              className={cn(
                'border rounded-md p-2 text-center cursor-pointer',
                selectedMonth === index ? 'bg-blue-500 text-white' : ''
              )}
              onClick={() => handleMonthSelect(index)}
              aria-pressed={selectedMonth === index}
              aria-label={`Seleccionar ${month}`}
            >
              {month}
            </button>
          ))}
        </div>
      </fieldset>
    </div>
  );
}