import { Button } from '@/common/presentation/components/ui/button';
import { Form, FormField, FormItem, FormMessage } from '@/common/presentation/components/ui/form';
import { TableCell, TableRow } from '@/common/presentation/components/ui/table';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { useHistoryDocuments } from '@/modules/residents/infrastructure/hooks/use-history-documents';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { AppDispatch } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { File as FileIconLucide, Loader2, Save, Trash } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';

interface Props {
  resident_id: string;
  document: any;
}

const formSchema = z.object({
  comments: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

export const HistoryTableItem = ({ document, resident_id }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { onClose } = useHistoryDocuments();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm<FormValues>({
    defaultValues: {
      comments: document.comments
    },
    resolver: zodResolver(formSchema)
  });

  const onSubmit = async (formValues: any) => {
    setIsLoading(true);
    const data = {
      comments: formValues.comments
    };

    const response = await dispatch(residentActions.updateResidentDocument({ documentId: document.id, data }));

    if (response.meta.requestStatus === 'fulfilled') {
      form.reset();
      dispatch(residentActions.getResidentById(resident_id));
      onClose();
    }
    setIsLoading(false);
  };

  const deleteDocument = async () => {
    setIsLoading(true);
    const response = await dispatch(residentActions.deleteResidentDocument(document.id));

    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(residentActions.getResidentById(resident_id));
      onClose();
    }
    setIsLoading(false);
  };

  const handleViewDocument = () => {
    window.open(document?.url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Form {...form}>
      <TableRow>
        <TableCell>
          <FormField
            control={form.control}
            name="comments"
            render={({ field }) => (
              <FormItem>
                <Textarea {...field} className="h-10 min-h-10" />
                <FormMessage />
              </FormItem>
            )}
          />
        </TableCell>

        <TableCell>{format(new Date(document.created_at), 'MM/dd/yyyy hh:mm a')}</TableCell>

        <TableCell>
          <div className="flex gap-2 w-full items-center">
            <Button size={'icon'} variant={'outline'} onClick={handleViewDocument} disabled={document?.url === null}>
              <FileIconLucide className="size-4" />
            </Button>

            {document?.parent_document_id !== null && (
              <Button
                size={'icon'}
                variant={'outline'}
                onClick={() => {
                  deleteDocument();
                }}
                disabled={isLoading}
                className="border-red-500 hover:bg-red-600 text-red-500 hover:text-white"
              >
                {
                  isLoading ? (
                    <Loader2 className={`size-4 animate-spin`} />
                  ) : (
                    <Trash className="size-4" />
                  )
                }
              </Button>
            )}

            {form.formState.isDirty && (
              <Button
                variant="default"
                size="icon"
                className="bg-green-500 hover:bg-green-600 text-white"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                {
                  isLoading ? (
                    <Loader2 className={`size-4 animate-spin`} />
                  ) : (
                    <Save className="size-4" />
                  )
                }
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>
    </Form>
  );
};
