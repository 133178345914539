import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import { AppDispatch, RootState } from "@/store/store";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";
import { Pencil, Trash } from "lucide-react";
import { DialogCreateOrUpdate } from "./DialogCreateOrUpdate";
import { 
  getBedroomQrCategories,
  bedroomQrCategoriesLoading, 
  deleteBedroomQrCategory
}  from '@/modules/settings/presentation/components/menus/BedroomQr/slices/BedroomQrCategorySlice'

import DeleteDialog from "@/common/presentation/components/DeleteDialog/DeleteDialog";
import { FiltersTable } from "./FiltersTable";


export const BedroomQrCategory = () => {

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [hasMoreRows, setHasMoreRows] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [sorting, setSorting] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);

  
  const dispatch = useDispatch<AppDispatch>();
  const { categories, isLoading } = useSelector((state: RootState) => state.settings.bedroomQrCategory);

  const [dataUpdate, setDataUpdate] = useState({
    id: '',
    name: '',
    status: 'inactive',
    availability: 'single_client'
  });

  const renderActionsColumn = (rowData: any) => (

    <div className="flex gap-2 justify-end z-[9999999999]">
      <Button variant="outline" size="icon" onClick={() => {
        setIsUpdate(true);
        setOpenDialog(true);
        setDataUpdate(rowData);
      }}>
        <Pencil className="h-4 w-4" />
      </Button>
      {rowData?.availability == 'single_client' &&
        <Button variant="outline" size="icon" onClick={() => {
          setIdDelete(rowData.id);
          setOpenDeleteAlert(true);
        }}>
          <Trash className="h-4 w-4" />
      </Button>}
    </div>
  );

  const columns = [
    { key: "name", labelTranslationKey: "common.name", sortable: true },
    { key: "status", labelTranslationKey: "common.status" },
    {
      key: 'actions',
      id: 'actions',
      labelTranslationKey: '',
      render: renderActionsColumn,
      width: 'w-64',
      static: true
    }
  ];

  const handleSort = (columnKey: any, direction: any) => {
    setSorting({ key: columnKey, direction });

    const sortedData = [...categories?.items ?? []].sort((a: any, b: any) => {
      if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
  };


  const loadMore = useCallback(async () => {
    if (!hasMoreRows) return;
    try {
      const nextPage = currentPage + 1;
      const params = {
        currentPage: nextPage,
        perPage: rowsPerPage
      };

      dispatch(bedroomQrCategoriesLoading());

      const response = await dispatch(getBedroomQrCategories(params));
      if (response?.payload?.items) {
        const parseData = Object.values(response.payload.items);

        setData(prevData => [...prevData, ...parseData]);
        setCurrentPage(nextPage);
      }

    } catch (error) {
      throw new Error(`Error get data: ${error}`);
    }

  }, [hasMoreRows, currentPage]);


  const callbackRenderContent = useCallback((index: any, column: any, data: any) => {
    
    const row = data[index];

      switch (column.key) {
        case 'name':
          return <>{row?.name }</>
        case 'status':
          return row?.status ==  'active' ? t('common.active') : t('common.inactive');
      }

  }, [categories]);

  const handleDelete = () => {
    if (idDelete) {
      dispatch(bedroomQrCategoriesLoading());
      dispatch(deleteBedroomQrCategory(idDelete))
      getData();
    }
  }

  const handleFilterChange = async (value: string) => {
    setFilterValue(value);
    
    if (value.length >= 1) {
      dispatch(bedroomQrCategoriesLoading());
      const response = await dispatch(getBedroomQrCategories({ 
        currentPage: 1, 
        perPage: rowsPerPage,
        search: value
      }));
      
      if (response?.payload?.items) {
        setData(response.payload.items);
        setHasMoreRows(response.payload.pagination.hasMorePages);
      }
    } else if (value === '') {
      getData(1);
    }
  };

  const handleClearFilter = () => {
    setFilterValue('');
    getData(1);
  };

  const getData = async (cPage = currentPage) => {
    
    dispatch(bedroomQrCategoriesLoading());
    const response = await dispatch(getBedroomQrCategories({ cPage, perPage: rowsPerPage }));
    
    const data = response?.payload;
    if (data?.items) {
      setData(data.items);
      setHasMoreRows(data.pagination.hasMorePages)
    }
  };

  useEffect(() => {
    getData();
  }, [dispatch]);


  return <>

    <TableCustomVirtuoso
      additionalButtons={
        <Button
          variant="outline"
          size="sm"
          className='bg-primary hover:bg-primary hover:text-white text-white align-self-end'
          onClick={() => setOpenDialog(true)}
        ><span>{t('common.new')}</span></Button>
      }
      data={data}
      columns={columns}
      renderCellContent={callbackRenderContent}
      onSort={handleSort}
      loadMore={loadMore}
      hasMore={hasMoreRows}
      isLoading={isLoading}
      filters={
        <FiltersTable
          onFilterChange={handleFilterChange}
          filterValue={filterValue}
          onClear={handleClearFilter}
        />
      }
      showSearchInput={false}
    />

    <DialogCreateOrUpdate
      onChangeOpen={setOpenDialog}
      open={isOpenDialog}
      isUpdate={isUpdate}
      onChangeIsUpdate={setIsUpdate}
      dataUpdate={dataUpdate}
      onChangeGetData={getData}
    />

    <DeleteDialog
      isOpen={isOpenDeleteAlert}
      onOpenChange={setOpenDeleteAlert}
      handleDelete={handleDelete}
    />

  </>;


}

