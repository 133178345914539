import { SingleImageDropzone } from '@/common/presentation/components/ImageDropZone/single-image-dropzone';
import { Dialog, DialogContent, DialogFooter, DialogHeader } from '@/common/presentation/components/ui/dialog';
import * as staffMemberActions from '@/modules/staff/presentation/slices/staffMemberSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as userActions from '@/auth/presentation/slices/authSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useProfileImage } from '@/modules/residents/infrastructure/hooks/use-profile-image';
import { useProfileImageStore } from '@/modules/staff/domain/stores/updateStaff/profileImage';

export const ProfileImageDialog = () => {
  const { isOpen, onClose } = useProfileImage();
  const [file, setFile] = useState<File>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const { setProfileImage } = useProfileImageStore();
  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch<AppDispatch>();

  const onChange = async (file?: File) => {
    if (file) {
      setIsSubmitting(true);
      setFile(file);

      try {
        const response = await setProfileImage(staffMember?.id, file);

        setFile(undefined);
        setIsSubmitting(false);

        dispatch(staffMemberActions.setStaffMember({ ...staffMember, profile_url: response.url }));
        if (staffMember?.id === user?.id) {
          dispatch(userActions.setUser({ ...user, profile_url: response.url }));
        }

        onClose();
        toast({
          description: 'Profile picture uploaded successfully'
        });
      } catch (error: any) {
        toast({
          description: error.message,
          variant: 'destructive'
        });
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <h2 className="text-center text-lg font-semibold">Staff Profile Picture</h2>
        </DialogHeader>
        <SingleImageDropzone className="w-full outline-none" disabled={isSubmitting} value={file} onChange={onChange} />
        <DialogFooter>
          <p className="text-xs">Maximum file size is 5MB. Supported file types are jpg, jpeg, png, tiff, webp.</p>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
