import React from 'react';
import { Row } from "@tanstack/react-table";
import { TrackerCleaningAreaEditDialog } from './TrackerCleaningAreaEditDialog';
import { TrackerCleaningAreaDeleteDialog } from './TrackerCleaningAreaDeleteDialog';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEditClick: (id: number, name: string, status: string) => void;
  handleDeleteClick: (id: string) => void;
}

const RenderAreaActionsColumn: React.FC<RenderActionsColumnProps> = (rowData) => {

  return (
    <div className='flex'>
        <TrackerCleaningAreaEditDialog id={rowData.id} name={rowData.name} status={rowData.status} />
        <TrackerCleaningAreaDeleteDialog id={rowData.id} />
    </div>
  );
};

export default RenderAreaActionsColumn;
