import useRouteParams from "@/common/hooks/RouteParamsHook";
import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import { Tabs, TabsList, TabsTrigger } from "@/common/presentation/components/ui/tabs";
import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TrackerSeizureForm } from "../components/seizure/TrackerSeizureForm";
import { TrackerSeizureReport } from "../components/seizure/TrackerSeizureReport";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { CircleFadingPlus } from "lucide-react";

export function SeizurePage() {
    const { params, setParam } = useRouteParams();
    const [selectedTab, setTab] = useState("form");
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [location, setLocation] = useState<string | null>(null);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
          setParam('e', 'form');
        }

        if (!locationSelectedId || locationSelectedId.length === 0) {
          setLocation(locationSelectedId ?? 'all-locations');
          setParam('l', locationSelectedId ?? 'all-locations');
        }

      }, [params]);

    const onSelectedTab = (event: string) => {
        setTab(event);
    }

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />

            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{color: accentColor}}>{t("seizure.title")}</div>
                            <div className="text-1xl">
                                {t("seizure.description")}
                            </div>
                        </div>

                        <Tabs defaultValue={selectedTab} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    { t("seizure.form.label") }
                                </TabsTrigger>

                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    { t("seizure.report.label") }
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                )
            }

            {
                locationSelectedId && selectedTab === "form" && <TrackerSeizureForm />
            }

            {
                locationSelectedId && selectedTab === "report" && <TrackerSeizureReport />
            }
        </div>
    );
}
