import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { NotesEditor } from '@/common/presentation/components/Notes/NotesEditor';
import { Label } from '@/common/presentation/components/ui/label';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import NumberInput from '../NumberInput';
import { CalendarIcon } from 'lucide-react';
import { createNewPharmacy, fetchPharmacies } from '@/modules/pharmacy/presentation/slices/PharmacySlice';
import { createPhysician, fetchPhysicianTypes, fetchPhysicians } from '@/common/presentation/slices/physicianSlice';
import { fetchRoutes, fetchResponsible } from '../../slices/medicationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import CustomCombobox from './combobox';
import { cn } from '@/lib/utils';

const MedicationsOptional: React.FC<{
  residentId: string;
  locationId: string;
  control: any;
  handleSubmit: any;
  setValue: (name: string, value: any) => void;
}> = ({ residentId, locationId, control, handleSubmit, setValue }) => {
  const {
    formState: { errors }
  } = useForm();

  const { routes, responsibleAdministrations } = useSelector((state: RootState) => state.medications);
  const { physicians, physicianTypes } = useSelector((state: RootState) => state.physicians);
  const { pharmacies } = useSelector((state: RootState) => state.pharmacy);
  const dispatch = useDispatch<AppDispatch>();
  const [dialogOpenPharmacy, setDialogOpenPharmacy] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResponsibles, setSelectedResponsibles] = useState<number[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchRoutes());
    dispatch(fetchResponsible());
    dispatch(fetchPhysicians());
    dispatch(fetchPhysicianTypes());

    if (locationId) {
      dispatch(fetchPharmacies(locationId));
    }
  }, [dispatch, locationId]);

  const handlePharmacyFormSubmit = async (data) => {
    data.location_id = locationId;
    try {
      const response = await dispatch(createNewPharmacy(data)).unwrap();
      toast({
        description: 'Pharmacy saved successfully!',
        variant: 'success'
      });
      setValue('pharmacy_id', response.id);
      dispatch(fetchPharmacies(locationId));
    } catch (error) {
      toast({
        description: error.message,
        variant: 'destructive'
      });
    }
    setDialogOpenPharmacy(false);
  };

  const handlePhysicianFormSubmit = async (data) => {
    data.resident_id = residentId;
    try {
      const response = await dispatch(createPhysician(data)).unwrap();
      setDialogOpen(false);
      if (!response.error) {
        dispatch(fetchPhysicians());
        toast({
          description: 'Physician saved successfully!',
          variant: 'success'
        });
        setValue('physician_id', response.id);
        dispatch(fetchPhysicians());
      } else {
        toast({
          description: response.error.message,
          variant: 'destructive'
        });
      }
    } catch (error) {
      toast({
        description: error.message,
        variant: 'destructive'
      });
    }
    setValue('physician_id', '');
  };

  const handleSelect = (selectedPhysician) => {
    setValue('physician_typeName', selectedPhysician.physician_type?.name || '');
    setValue('prescriber_npi', selectedPhysician.npi || '');
    setValue('physician_id', selectedPhysician.id || '');
  };

  return (
    <div className="p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6">
        <div>
          <Label htmlFor="physician_id" className="text-sm mb-2">
            {t('medications.medicationDetail.prescribingPhysician')}
          </Label>
          <Controller
            name="physician_id"
            control={control}
            render={({ field }) => (
              <CustomCombobox
                options={physicians}
                onSelect={handleSelect}
                onCreate={handlePhysicianFormSubmit}
                placeholder={t('medications.medicationDetail.selectPhysician')}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                entityType="doctor"
                fields={[
                  { name: 'name', placeholder: t('medications.medicationDetail.doctorName'), required: true },
                  {
                    name: 'physician_type_id',
                    placeholder: t('medications.medicationDetail.doctorType'),
                    required: false,
                    options: physicianTypes
                  },
                  { name: 'npi', placeholder: t('medications.medicationDetail.npi'), required: false }
                ]}
              />
            )}
          />
        </div>
        <div>
          <Label htmlFor="prescriber_npi" className="text-sm mb-2">
            {t('medications.medicationDetail.prescriberNPI')}
          </Label>
          <Controller
            name="prescriber_npi"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="NPI"
                readOnly
                className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              />
            )}
          />
        </div>
        <div>
          <Label htmlFor="physician_typeName" className="text-sm mb-2">
            {t('medications.medicationDetail.typePhysician')}
          </Label>
          <Controller
            name="physician_typeName"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t('medications.medicationDetail.physicianType')}
                readOnly
                className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              />
            )}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-6 mb-6">
        <div>
          <Label htmlFor="pharmacy_id" className="text-sm mb-2">
            {t('medications.medicationDetail.pharmacyName')}
          </Label>
          <Controller
            name="pharmacy_id"
            control={control}
            render={({ field }) => (
              <CustomCombobox
                options={pharmacies}
                onCreate={handlePharmacyFormSubmit}
                placeholder={t('medications.medicationDetail.selectPharmacy')}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  setValue('pharmacy_id', value);
                }}
                entityType="pharmacy"
                fields={[
                  { name: 'name', placeholder: t('medications.medicationDetail.pharmacyName'), required: true },
                  { name: 'address', placeholder: t('medications.medicationDetail.pharmacyAdmin'), required: false },
                  { name: 'phone', placeholder: t('medications.medicationDetail.pharmacyPhone'), required: false }
                ]}
                onSelect={(selectedPharmacy) => {
                  if (selectedPharmacy) {
                    field.onChange(selectedPharmacy.id);
                  }
                }}
              />
            )}
          />
        </div>
        <div>
          <Label htmlFor="date_prescribed" className="text-sm mb-2">
            {t('medications.medicationDetail.datePrescribed')}
          </Label>
          <Controller
            name="date_prescribed"
            control={control}
            render={({ field }) => (
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'w-full justify-start text-left text-sm py-1',
                      !field.value && 'text-muted-foreground'
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {field.value
                        ? format(parse(field.value, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
                        : t('medications.medicationForm.selectDate')}
                    </span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <CustomCalendar
                    selectedDate={field.value ? parse(field.value, 'yyyy-MM-dd', new Date()) : null}
                    onChange={(date) => {
                      const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;
                      setValue('date_prescribed', formattedDate);
                    }}
                    MonthAndYearPicker
                  />
                </PopoverContent>
              </Popover>
            )}
          />
        </div>

        <div>
          <Label htmlFor="frequency" className="text-sm mb-2">
            Frequency
          </Label>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <Input
                name="frequency"
                value={field.value}
                onChange={field.onChange}
                className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              />
            )}
          />
        </div>
        <div>
          <Label htmlFor="routes_medication_id" className="text-sm mb-2">
            Route of Medication
          </Label>
          <Controller
            name="routes_medication_id"
            control={control}
            render={({ field }) => (
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger className="w-full focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                  <SelectValue placeholder="Select Route" />
                </SelectTrigger>
                <SelectContent>
                  {routes.length > 0 ? (
                    routes.map((route) => (
                      <SelectItem key={route.id} value={route.id}>
                        {route.name}
                      </SelectItem>
                    ))
                  ) : (
                    <p>No routes available</p>
                  )}
                </SelectContent>
              </Select>
            )}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-6 mb-6">
        <div className="col-span-12 sm:col-span-6">
          <Label htmlFor="responsible_administrations_id" className="text-sm">
            Responsible Administration
          </Label>
          <Controller
            name="responsible_administrations_id"
            control={control}
            render={({ field }) => {
              const initialSelected = Array.isArray(field.value) ? field.value : JSON.parse(field.value || '[]');

              const handleBadgeClick = (id: string) => {
                const isSelected = initialSelected.includes(id);

                const newSelected = isSelected
                  ? initialSelected.filter((itemId) => itemId !== id)
                  : [...initialSelected, id];

                field.onChange(newSelected);
              };

              return (
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                  {responsibleAdministrations.length > 0 ? (
                    responsibleAdministrations.map((responsible) => {
                      const isSelected = initialSelected.includes(responsible.id);

                      return (
                        <div
                          key={responsible.id}
                          onClick={() => handleBadgeClick(responsible.id)}
                          className={cn(
                            'cursor-pointer text-xs py-1 px-2 transition-colors',
                            'w-full h-8 flex items-center justify-center',
                            'truncate rounded-full',
                            isSelected ? 'bg-primary text-white' : 'bg-gray-200 text-primary',
                            'hover:bg-primary hover:text-white'
                          )}
                          title={responsible.name}
                        >
                          <span className="truncate max-w-full">{responsible.name}</span>
                        </div>
                      );
                    })
                  ) : (
                    <div>No responsible administrations available</div>
                  )}
                </div>
              );
            }}
          />
        </div>
        <div className="col-span-6">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="flex flex-col items-start">
              <Label htmlFor="current_quantity" className="text-sm">
                Current Quantity
              </Label>
              <NumberInput name="current_quantity" control={control} />
            </div>
            <div className="flex flex-col items-start">
              <Label htmlFor="minimum_quantity" className="text-sm">
                Minimum Quantity
              </Label>
              <NumberInput name="minimum_quantity" control={control} />
            </div>
            <div className="flex flex-col items-start">
              <Label htmlFor="units_per_dose" className="text-sm">
                Units per Dose
              </Label>
              <NumberInput name="units_per_dose" control={control} />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
        <div className="mb-4">
          <Label htmlFor="purpose" className="text-sm mb-2">
            Purpose
          </Label>
          <Controller
            name="purpose"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              />
            )}
          />
        </div>

        <div className="mb-4">
          <Label htmlFor="not_exceed" className="text-sm mb-2">
            Not to Exceed
          </Label>
          <Controller
            name="not_exceed"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                className="text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
              />
            )}
          />
        </div>
      </div>

      <div className="mb-4">
        <Label htmlFor="content_notes" className="text-sm mb-4">
          Content Notes
        </Label>
        <Controller
          name="content_notes"
          control={control}
          render={({ field }) => (
            <NotesEditor onChange={field.onChange} value={field.value} className="min-h-auto h-[200px] pb-10" />
          )}
        />
      </div>
    </div>
  );
};

export default MedicationsOptional;
