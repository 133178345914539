import useRouteParams from "@/common/hooks/RouteParamsHook";
import { LocationSelector } from "@/common/presentation/components/Selectors/LocationSelector";
import { ResidentSelector } from "@/common/presentation/components/Selectors/ResidentSelector";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { RootState } from "@/store/store";
import { CircleFadingPlus, Ellipsis, Pencil, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { format } from "date-fns";
import { trackOutingsGeneralTableData, trackOutingsHospitalizationTableData, trackOutingsMedicalTableData } from "./trackerOutingsMockup";
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { Button } from '@/common/presentation/components/ui/button';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { SingleImageDropzone } from '@/common/presentation/components/ImageDropZone/single-image-dropzone';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import { Input } from '@/common/presentation/components/ui/input';
import { DeleteOutingsTrackerReportDialog } from "../components/DeleteOutingsTrackerReportDialog";
import { GeneralOutingsEditDialog } from "../components/GeneralOutingsEditDialog";
import { MedicalOutingsEditDialog } from "../components/MedicalOutingsEditDialog";
import { HospitalizationOutingsEditDialog } from "../components/HospitalizationOutingsEditDialog";
import { DatePickerDemo, GeneralOutingsTable } from "../components/GeneralOutingsTable";
import * as trackersActions from '../slices/TrackerSlice';
import { GeneralOutingsCreateForm } from "../components/GeneralOutingsCreateForm";
import { HospitalizationOutingsCreateForm } from "../components/HospitalizationOutingsCreateForm";
import { MedicalOutingsTable } from "../components/MedicalOutingsTable";
import { HospitalizationOutingsTable } from "../components/HospitalizationOutingsTable";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { MedicalOutingsCreateForm } from "../components/MedicalOutingsCreateForm";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import * as RPNInput from 'react-phone-number-input';

function TrackerOutingsPage() {
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const dispatch = useDispatch();
    const { params, setParam } = useRouteParams();
    const [selectedDirect, setSelectedDirect] = useState<string>(params.e || 'form');
    const [selectedReport, setSelectedReport] = useState<string>(params.e || 'form');
    const [selectedCloud, setSelectedCloud] = useState<string>(params.e || 'form');
    const [location, setLocation] = useState<string | null>(null);
    const [data, setData] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [showEditDialog, setShowEditDialog] = useState<any>(null);
    const [formValue, setFormValue] = useState<any>(null);
    const [fields, setDialogField] = useState<any>(null);
    const [dialogTitle, setDialogTitle] = useState<any>(null);
    const [dialogDescription, setDialogDescription] = useState<any>(null);
    const [action, setAction] = useState<any>(null);
    const [formData, setFormData] = useState<{ [key: string]: string }>({});
    const [formLayout, setFormLayout] = useState<any>(null);
    const [tableColumns, setTableColumns] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const { locationSelectedId } = useLocation();
    const configurations = useSelector((state: RootState) => state.configurations);
    const [accentColor, setAccentColor] = useState("");

    const Fields = {
        'EditGeneral': [
            { fieldName: "activity", label: 'Outing / Activity', type: "text" },
            { fieldName: "duration", label: 'Duration', type: "text" },
            { fieldName: "staff", label: 'Staff(S) Accompanying and Tittle', type: "text" },
            { fieldName: "note", label: 'Notes', type: "textarea" }
        ],
        'EditMedical' : [
            { fieldName: "note", label: 'Notes', type: "text" },
            { fieldName: "specialty", label: 'Specialty', type: "text" },
            { fieldName: "doctorName", label: 'Doctor Name', type: "text" },
        ],
        'EditHospitalization': [
            { fieldName: "date", label: 'Date', type: "text" },
            { fieldName: "hospitalName", label: 'Hospital Name', type: "text" },
            { fieldName: "hospitalSocialFullName", label: 'Hospital social worker/discharge planner full name', type: "text" },
            { fieldName: "hospitalSocialTelephone", label: 'Hospital social worker/discharge planner telephone', type: "text" },
            { fieldName: "hospitalizationReason", label: 'Reason for Hospitalization', type: "text" },
            { fieldName: "dateBack", label: 'Date back to the facility', type: "text" },
        ]
    };

    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    const onSelectReport = (e: string) => {
        setSelectedReport(e);

        switch(e) {
            case "general":
                setFormLayout("EditGeneral");

                setTableColumns(
                    [
                        { label: 'Date/Time', value: 'date', type: 'date' },
                        { label: 'Resident (link)', value: 'resident' },
                        { label: 'Outing / Activity', value: 'activity' },
                        { label: 'Duration', value: 'duration' },
                        { label: 'Staff', value: 'staff' },
                        { label: 'Notes', value: 'note' }
                    ]
                );
                
                setTableData(trackOutingsGeneralTableData);
            break;

            case "medical":
                setFormLayout("EditMedical");

                setTableColumns(
                    [
                        { label: 'Date', value: 'date', type: 'date' },
                        { label: 'Resident (link)', value: 'resident' },
                        { label: 'Speciality', value: 'specialty' },
                        { label: 'Doctor Name', value: 'doctorName' },
                        { label: 'Note', value: 'note' }
                    ]
                );
                
                setTableData(trackOutingsMedicalTableData);
            break;

            case "hospitalizations":
                setFormLayout("EditHospitalization");

                setTableColumns(
                    [
                        { label: 'Date', value: 'date', type: 'date' },
                        { label: 'Resident (link)', value: 'resident' },
                        { label: 'Hospital name', value: 'hospitalName' },
                        { label: 'Reason', value: 'hospitalizationReason' },
                        { label: 'Date Back', value: 'dateBack' },
                    ]
                );
                
                setTableData(trackOutingsHospitalizationTableData);
            break;
        }
    };

    const onSelectCloud = (e: string) => {
        setSelectedCloud(e);
    };

    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedDirect(e);
    };

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

  return (
    <div>
        <LocationSelector quickView={true} />

        {
            locationSelectedId && (
                <div className="my-4 flex items-center justify-between">
                    <div className="flex flex-col gap-2">
                        <div className="font-semibold text-2xl">Outings Tracker</div>
                        <div className="font-semibold text-1xl">Track all your outings in one place.</div>
                    </div>

                    <Tabs defaultValue={selectedDirect} onValueChange={(e) => { onSelectVitals(e); }} className="w-auto">
                        <TabsList>
                            <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                <CircleFadingPlus className="h-4 w-4 mr-1" />
                                New
                            </TabsTrigger>
                            <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                Report
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                </div>

            )
        }

        {
            selectedDirect === 'form' && (
                <div>
                    {
                        locationSelectedId && (
                            <div className="flex flex-col gap-4">
                                <div>
                                    <ResidentSelector
                                        onSelect={onResidentSelect}
                                        locationId={location}
                                        residentId={params.r}
                                        showAllResidents={false}
                                    />
                                </div>
                            </div>
                        )
                    }

                    {
                        locationSelectedId && <div>
                          <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                            <CardHeader>
                                <div className="flex justify-between">
                                    {
                                        selectedCloud === "general" &&
                                        <div>
                                            <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>General Outings</CardTitle>
                                        </div>
                                    }

                                    {
                                        selectedCloud === "medical" &&
                                        <div>
                                            <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>Medical Appointments</CardTitle>
                                        </div>
                                    }

                                    {
                                        selectedCloud === "hospitalizations" &&
                                        <div>
                                            <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>Hospitalizations</CardTitle>
                                        </div>
                                    }

                                    <Tabs defaultValue={selectedCloud} onValueChange={(e) => { onSelectCloud(e); }} className="w-auto mb-2">
                                        <TabsList>
                                            <TabsTrigger value="general" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white" autoFocus>
                                                General Outings
                                            </TabsTrigger>

                                            <TabsTrigger value="medical" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                Medical Appointments
                                            </TabsTrigger>

                                            <TabsTrigger value="hospitalizations" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                Hospitalizations
                                            </TabsTrigger>
                                        </TabsList>
                                    </Tabs>
                                </div>
                            </CardHeader>
  
                              <CardContent>
                                  {
                                    selectedCloud === "general" && <GeneralOutingsCreateForm />
                                  }

                                  {
                                    selectedCloud === "medical" && <MedicalOutingsCreateForm />
                                  }

                                  {
                                    selectedCloud === "hospitalizations" && <HospitalizationOutingsCreateForm />
                                  }
                              </CardContent>
                          </Card>
                      </div>
                    }
                </div>
            )
        }

        {
            selectedDirect === 'report' && (
                <Card className="border-t-4 border-t-primary/80">
                    <CardHeader>
                        <div className="flex justify-between">
                            { selectedReport === "general" && (<CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>General Outings</CardTitle>)}
                            { selectedReport === "medical" && (<CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>Medical Appointments</CardTitle>)}
                            { selectedReport === "hospitalizations" && (<CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>Hospitalizations</CardTitle>)}

                            <Tabs defaultValue={selectedReport} onValueChange={(e) => { onSelectReport(e); }} className="w-auto">
                                <TabsList>
                                    <TabsTrigger value="general" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white" autoFocus>
                                        General Outings
                                    </TabsTrigger>

                                    <TabsTrigger value="medical" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                        Medical Appointments
                                    </TabsTrigger>

                                    <TabsTrigger value="hospitalizations" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                        Hospitalizations
                                    </TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>
                    </CardHeader>

                    <CardContent>
                        { selectedReport === "general" && <GeneralOutingsTable />}
                        { selectedReport === "medical" && <MedicalOutingsTable />}
                        { selectedReport === "hospitalizations" && <HospitalizationOutingsTable />}
                    </CardContent>
                </Card>
            )
        }
    </div>
  );
}

export default TrackerOutingsPage;
