import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/common/presentation/components/ui/accordion';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { Switch } from '@/common/presentation/components/ui/switch';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const fieldTypes = [
  { value: 'text', label: 'Text' },
  { value: 'textarea', label: 'Textarea' }
  //   { value: 'number', label: 'Number' },
  //   { value: 'date', label: 'Date' },
  //   { value: 'boolean', label: 'Boolean' }
];

const formSchema = z.object({
  label: z
    .string()
    .min(3, { message: 'Label must be at least 3 characters long' })
    .max(30, { message: 'Label must not be longer than 30 characters' })
    .regex(/^[a-zA-Z0-9'_\- ]*$/, {
      message: "Only alphanumeric characters, apostrophes ('), hyphens (-), underscores (_), and spaces are allowed."
    }),
  type: z.enum(fieldTypes.map((field) => field.value) as [string, ...string[]]),
  required: z.boolean(),
  default_value: z.string().optional(),
  placeholder: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

interface Props {
  defaultValues: Partial<FormValues>;
  onSubmit: (data: FormValues) => void;
  status: 'idle' | 'loading' | 'failed';
  buttonLabel?: string;
}

export const CustomFieldForm = ({ defaultValues, onSubmit, status, buttonLabel = 'Add Field' }: Props) => {
  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
    mode: 'onChange'
  });

  const handleSubmit = (data: FormValues) => {
    onSubmit({ ...data });
    form.reset(data);
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4 px-2 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm">
            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="label"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Field Name <span className="text-primary"> *</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} type="text" disabled={status === 'loading'} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Field Type <span className="text-primary"> *</span>
                    </FormLabel>
                    <Select
                      onValueChange={(value) => field.onChange(value as any)}
                      value={field.value}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger disabled={status === 'loading'}>
                          <SelectValue placeholder="Select a type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {fieldTypes.map((type: any, index: number) => (
                          <SelectItem key={index} value={type.value}>
                            {type.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="required"
                render={({ field }) => {
                  return (
                    <FormItem className="flex flex-col">
                      <FormLabel className="text-sm">Required</FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value as boolean}
                          onCheckedChange={field.onChange}
                          disabled={status === 'loading'}
                          aria-readonly
                        />
                      </FormControl>
                    </FormItem>
                  );
                }}
              />

              <Accordion type="single" collapsible>
                <AccordionItem value="options">
                  <AccordionTrigger className="text-primary text-sm">Advanced Options</AccordionTrigger>
                  <AccordionContent className="px-1">
                    <FormField
                      control={form.control}
                      name="default_value"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Default Value</FormLabel>
                          <FormControl>
                            <Input {...field} disabled={status === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="placeholder"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Placeholder</FormLabel>
                          <FormControl>
                            <Input {...field} disabled={status === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <Button type="submit" disabled={status === 'loading' || !form.formState.isValid}>
              {status === 'loading' && <Loader2 className="size-4 animate-spin mr-2" />}
              {buttonLabel}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
