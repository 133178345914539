import { Label } from '@radix-ui/react-label';
import { Lightbulb } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    labelMessage: string;
};

const DoYouKnow: React.FC<IProps> = ({ labelMessage }) => {
  const { t } = useTranslation();
  return (
    <div className="border rounded border-primary p-2 bg-primary/5">
      <div className="flex flex-row gap-4 items-center">
        <Lightbulb className="text-primary align-middle" size={24} />
        <div className="flex flex-col gap-1">
          <Label className="justify-start font-bold text-primary text-base">{t('doYouKnow')}</Label>
          <Label className="text-sm font-semibold">{t(labelMessage)}</Label>
        </div>
      </div>
    </div>
  );
};

export default DoYouKnow;
