import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { CrudState, FormData as FormDataModel, NoteCategoryI, NoteI } from '@/modules/notes/domain/note.domain';
import { Loader, Save } from 'lucide-react';
import { QuillJS } from '../common/quilljs';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { SelectNoteCategory } from '../common/SelectNoteCategory';
import { StaffSelector } from '@/modules/notes/presentation/components/staffNotes/StaffSelector';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import NoteService from '../../../infrastructure/services/NoteService';
import React, { useEffect, useState } from 'react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { fetchRefreshStaffNotes, setNoteFilters } from '../../slices/NoteSlice';


interface CreateNoteProps {
  crudState: CrudState;
  setCrudState: (crudState: CrudState) => void;
  note: NoteI | null;
}

export function CreateStaffNote({ crudState, setCrudState, note }: CreateNoteProps) {
  
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { params } = useRouteParams();
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const clientId = useSelector((state: RootState) => state.configurations.configurations.client_id);

  const defaultFormData: FormDataModel = {
    content: '',
    html_content: '',
    status: 'active',
    user_id: [],
    category_id: '',
    resident_id: null,
    staff_id: crudState.isUpdatingStaffNote ? note?.staffs.map(item => item.idStaff) ?? [] : [],
    location_id: crudState.isUpdatingStaffNote ? note?.scope_location_ids ?? [] : [],
    reference: 'notePage',
    images: null,
    client_id: clientId,
    draft_id: null,
    urgent: false,
    department_id: []
  };

  // Hooks
  const [formData, setFormData] = useState<FormDataModel>(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (crudState.isUpdatingStaffNote && note) {
      if (note.category_id) {
        setFormData({ ...formData, ['category_id']: note.category_id });
      }
    }
  }, [crudState]);

  // Behaviors
  const handleQuillJs = (values: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...values
    }));
  };

  const getContent = () => {
    if (crudState.isUpdatingStaffNote && note) {
      return note.html_content;
    }

    return '';
  };

  const handleSelectCategory = (category: NoteCategoryI) => {
    setFormData({
      ...formData,
      ['category_id']: category.id
    });
  };

  const onStaffSelect = (staffId: any) => {
    const selectedStaff = staff.find((element) => element.id === staffId);

    if (!selectedStaff) return;

    const updatedLocations = [...formData.location_id];

    selectedStaff.locations.forEach((location: any) => {
      const index = updatedLocations.indexOf(location.id);
      if (index !== -1) {
        updatedLocations.splice(index, 1);
      } else {
        updatedLocations.push(location.id);
      }
    });

    if (formData.staff_id) {
      const updatedStaff = [...formData.staff_id];

      const index = updatedStaff.indexOf(staffId);
      if (index !== -1) {
        updatedStaff.splice(index, 1);
      } else {
        updatedStaff.push(staffId);
      }

      setFormData({
        ...formData,
        ['location_id']: updatedLocations,
        ['staff_id']: updatedStaff
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    let images = formData.images;
    let idNote = null;

    if (!crudState.isUpdatingStaffNote) {

      if (!formData.staff_id?.length) {
        toast({
          description: t('notes.staffNotFound'),
        });
        setIsLoading(false);
        return
      }

      if (!formData.category_id) {
        toast({
          description: t('notes.categoryNotFound'),
        });
        setIsLoading(false);
        return
      }

      if (!formData.content || formData.content.trim().length < 2) {
        toast({
          description: t('notes.contentNoteLength'),
        });
        setIsLoading(false);
        return;
      }


      formData.images = [];
      let response = await NoteService.createNote(formData);
      idNote = response.payload.id;

      dispatch(setNoteFilters(['nextPage', 1]));
    } else {
      if (!crudState.itemId) {
        console.log('Error updating note');
        setIsLoading(false);
        return;
      }

      let data = {
        ...formData,
        id: crudState.itemId
      };

      data.images = [];
      await NoteService.updateNote(data);
      idNote = crudState.itemId;

      dispatch<any>(fetchRefreshStaffNotes());
    }

    if (images?.length && idNote) {
      let form = new FormData();
      form.append('id', idNote);
      form.append('type', 'note');
      images.forEach(img => form.append('images[]', img))
      await NoteService.uploadImges(form);
    }

    setIsLoading(false);

    setCrudState(NoteHelperService.defineState({ showingStaffNotes: true }));
  };

  return (
    <Card className="col-span-12 md:col-span-7 border-t-4 border-t-primary/80">
      <CardContent>
        <StaffSelector className="mt-3" locationId={params?.l} onSelect={onStaffSelect} staffId={note?.staffs.map(item => item.idStaff)} setCrudState={setCrudState} />
        <ScrollArea className='h-[calc(100vh-450px)]'>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="flex justify-between">
              <SelectNoteCategory decodeHTMLEntities={NoteHelperService.decodeHTMLEntities}
                handleSelectCategory={handleSelectCategory}
                value={crudState.isUpdatingStaffNote && note?.category_id ? note.category_id : ''}
                target="staff"
              />
            </div>
            <div className="w-full h-full pt-4">
              <QuillJS onChange={handleQuillJs} value={getContent()} />
            </div>
            <div className="pt-4 flex justify-end">
              <Button type="submit" size="sm" className="ml-auto">
                {isLoading ? (
                  <Loader className="mr-2 h-4 w-4 animate-spin" />
                ) : crudState.isUpdatingStaffNote ? (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    {t('notes.update')}
                  </>
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    {t('notes.send')}
                  </>
                )}
              </Button>
            </div>
          </form>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
