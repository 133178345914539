import { useTranslation } from 'react-i18next';

const useColumnsAudit = () => {
  const { t } = useTranslation();

  const columnsAudit = [
    {
      key: 'changed_at',
      id: 'changed_at',
      labelTranslationKey: 'medications.audit.columns.changedAt',
      value: 'changed_at',
      visible: true,
      sortable: true,
      width: '25%'
    },
    {
      key: 'field',
      id: 'field',
      labelTranslationKey: 'medications.audit.columns.field',
      value: 'field',
      visible: true,
      sortable: true,
      width: '25%'
    },
    {
      key: 'user_name',
      id: 'user_name',
      labelTranslationKey: 'medications.audit.columns.user',
      value: 'user_name',
      visible: true,
      sortable: true,
      width: '25%'
    },
    {
      key: 'old_value',
      id: 'old_value',
      labelTranslationKey: 'medications.audit.columns.oldValue',
      value: 'old_value',
      visible: true,
      sortable: true,
      width: '25%'
    },
    {
      key: 'new_value',
      id: 'new_value',
      labelTranslationKey: 'medications.audit.columns.newValue',
      value: 'new_value',
      visible: true,
      sortable: true,
      width: '25%'
    }
  ];

  const translateColumnLabel = (key: string) =>
    t(columnsAudit.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = columnsAudit.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
};

export default useColumnsAudit;
