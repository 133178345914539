import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { useProfileImage } from '@/modules/residents/infrastructure/hooks/use-profile-image';
import { AppDispatch, RootState } from '@/store/store';
import { ImageIcon, Loader, Trash } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';

import * as userActions from '@/auth/presentation/slices/authSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { ResidentAvatar } from '@/modules/residents/presentation/components/ResidentAvatar';
import { useProfileImageStore } from '@/modules/staff/domain/stores/updateStaff/profileImage';
import * as staffMemberActions from '@/modules/staff/presentation/slices/staffMemberSlice';
import { ProfileImageDialog } from './dialogs/ProfileImageDialog';

export const ProfileImage = () => {
  const { onOpen } = useProfileImage();
  const { deleteProfileImage } = useProfileImageStore();
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const { status } = useSelector((state: RootState) => state.residents.residentForm);
  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch<AppDispatch>();

  const removeImage = async () => {
    try {
      await deleteProfileImage(staffMember?.id);

      toast({
        description: 'Profile picture deleted successfully'
      });

      dispatch(staffMemberActions.setStaffMember({ ...staffMember, profile_url: null }));
      if (staffMember?.id === user?.id) {
        dispatch(userActions.setUser({ ...user, profile_url: null }));
      }
    } catch (error: any) {
      toast({
        description: error.message,
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center p-4">
        <div className="overflow-hidden rounded-md relative group">
          <Avatar
            className={cn(
              'rounded-md min-h-auto max-h-[400px] h-full w-auto transition-all group-hover:scale-105 group-hover:cursor-pointer aspect-[3/4]'
            )}
          >
            <AvatarImage src={staffMember?.profile_url || undefined} alt="User Image" className="object-cover" />
            <AvatarFallback className="bg-[#FFFFFF] dark:bg-[#09090B] text-sm rounded-md">
              <div className="h-full w-full flex flex-col items-center justify-center gap-4 px-16">
                <ResidentAvatar className="h-auto w-full" />
                <p>Upload a profile picture</p>
              </div>
            </AvatarFallback>
          </Avatar>
          <div className="absolute bottom-5 opacity-0 group-hover:opacity-100 flex w-full justify-evenly">
            <Button
              className="text-xs text-muted-foreground flex flex-row gap-2"
              variant={'outline'}
              size={'sm'}
              onClick={onOpen}
              disabled={status === 'loading'}
            >
              <ImageIcon />
              Change Image
            </Button>
            {staffMember?.profile_url && (
              <Button
                className="text-xs text-muted-foreground flex flex-row gap-2"
                variant={'outline'}
                size={'sm'}
                onClick={removeImage}
                disabled={status === 'loading'}
              >
                {status === 'loading' ? <Loader className="animate-spin" /> : <Trash />}
                Delete Image
              </Button>
            )}
          </div>
        </div>
      </div>

      <ProfileImageDialog />
    </>
  );
};
