import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
 import { Label } from '@/common/presentation/components/ui/label';
 import { Input } from '@/common/presentation/components/ui/input';
import { Trash } from 'lucide-react';
import { useDispatch } from 'react-redux';
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { t } from 'i18next';
import { useState } from 'react';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';


export function DeleteHospitalizationsOutingsReportDialog(props: any) {
    const { params } = useRouteParams();
    const dispatch = useDispatch();
    const [deleteData, setDeleteData] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const { locationSelectedId } = useLocation();
    
    const openDeleteDialog = () => {
        setOpenDelete(true);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = () => {
        dispatch(trackerActions.destroyTrackerOutingsHospitalization(
            {
                id: props.id,
                id_data: props.id_data
            }
        ));


        fetchData();

        return true;
    }

    const fetchData = () => {
        dispatch(trackerActions.getTrackerOutingsHospitalization(
            {
                "location_id": locationSelectedId
            }
            )
        );
    }

    return (
        <div>
            <Button onClick={() => openDeleteDialog()} variant="outline" size="icon" className='mr-2'>
                <Trash className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.delete.title")} 
                description={t("cleaning.report.summary.delete.description")} 
                width="30rem"
                newDialog={true}
                isOpen={openDelete}
                onSubmit={() => handleDelete()}
                onClose={closeDeleteDialog}
            ></CustomDialog>
        </div>
    );
};
