import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@/common/presentation/components/ui/table';
import { useHistoryDocuments } from '@/modules/residents/infrastructure/hooks/use-history-documents';
import { HistoryTableItem } from '../HistoryTableItem';

interface Column {
  key: string;
  label: string;
  type?: 'text' | 'checkbox' | 'action';
  width?: string;
}

const columns: Column[] = [
  {
    key: 'comments',
    label: 'Comments',
    type: 'text',
    width: '30%'
  },
  {
    key: 'created_at',
    label: 'Upload Date',
    type: 'text',
    width: '30%'
  },
  {
    key: 'actions',
    label: 'Actions',
    type: 'action',
    width: '20%'
  }
];

export const HistoryDocumentsDialog = () => {
  const { isOpen, onClose, documents } = useHistoryDocuments();

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[90vw]  max-h-[90vh] overflow-y-auto rounded">
          <DialogHeader>
            <DialogTitle className="text-xl">Documents History</DialogTitle>
          </DialogHeader>

          <Table>
            <TableHeader>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.key} style={{ width: column.width }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {documents.map((document) => {
                return <HistoryTableItem document={document} key={document.id} resident_id={document.resident_id} />;
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};
