import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { getContacts, getContactsByResident, getListPhonesById, deleteContact } from '../slices/contactsSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { ArrowLeft, Trash, CircleFadingPlus, Mail, Phone, Pencil, Eye, CircleUserRound, Building2, StretchHorizontal, UtilityPole, SquareChevronRight, Waypoints, SquareStack, BookA } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { Button } from '@/common/presentation/components/ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { cn } from '@/lib/utils';
import { NavigationBar } from '@/common/presentation/components/navigations/NavigationBar';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import useContactsColumns from '../components/PersonalContacts/ContactsColumns';
import renderActionsColumn from '../components/PersonalContacts/renderActionsColumn';
import { useTranslation } from 'react-i18next';
import FilterForm from '../components/PersonalContacts/ContactsFilterForm';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSchema, IContactsResumeData } from '../components/PersonalContacts/ContactsSchema';

const DialogContactInfo = ({ contact, isOpen, onClose, phones }) => {
    const { t } = useTranslation();
    
    return (
    <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
            <DialogHeader>
                <DialogTitle className="text-primary text-2xl font-bold">{t('contacts.contact_information')}</DialogTitle>
            </DialogHeader>
            <hr className="border-primary w-full my-2" />
            <div>
                <p className="flex justify-center"><CircleUserRound size={50} className="w-56" /></p>
                <p className="text-center text-xl"><span>{contact.full_name}</span></p>
                <p className="pt-2 pb-2">
                    <DialogDescription>
                        {t('contacts.contact_details')}.
                    </DialogDescription>
                </p>
                <p className="pt-2 pb-2">
                    <DialogDescription>
                        {t('contacts.phones')}:
                    </DialogDescription>
                </p>
                <ul className="list-disc pl-5">
                    {phones.map((phone, index) => (
                        <li key={index}>{phone.number} {t('contacts.ext')}: {phone.ext}</li>
                    ))}
                </ul>
                <p className="pt-2 pb-2">
                    <DialogDescription>
                        {t('contacts.personal_information')}:
                    </DialogDescription>
                </p>
                <p className="flex mt-3"><Building2 className="mr-2" /><strong className="mr-1">{t('contacts.company')}:</strong> {contact.company}</p>
                <p className="flex mt-3"><Mail className="mr-2" /><strong className="mr-1">{t('contacts.email')}:</strong> {contact.email}</p>
                <p className="flex mt-3"><StretchHorizontal className="mr-2" /><strong className="mr-1">{t('contacts.address')}:</strong> {contact.address}</p>
                <p className="flex mt-3"><UtilityPole className="mr-2" /><strong className="mr-1">{t('contacts.city')}:</strong> {contact.city}</p>
                <p className="flex mt-3"><Waypoints className="mr-2" /><strong className="mr-1">{t('contacts.state')}:</strong> {contact.state}</p>
                <p className="flex mt-3"><SquareChevronRight className="mr-2" /><strong className="mr-1">{t('contacts.zip_code')}:</strong> {contact.zipcode}</p>
                <p className="flex mt-3"><SquareStack className="mr-2" /><strong className="mr-1">{t('contacts.country')}:</strong> {contact.country}</p>
            </div>
            <DialogFooter>
                <DialogClose asChild>
                    <Button>{t('contacts.close')}</Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    </Dialog>
    );
};

const ContactsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const contacts = useSelector((state: RootState) => state.contacts.contacts) || [];
    const phones = useSelector((state: RootState) => state.contacts.phones) || {};
    const [data, setData] = useState<any[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const { params, setParam } = useRouteParams();
    const [filter, setFilter] = useState<string>('');

    const [selectedContact, setSelectedContact] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const residentId = searchParams.get('r');
    const locationId = searchParams.get('l');
    const ContactsColumns = useContactsColumns();
    const [sort, setSort] = useState<{ column: string; direction: string }>({ column: '', direction: '' });
    const [filteredContacts, setFilteredContacts] = useState([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);

    
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema)
    });
    
    const lastFilters: IContactsResumeData = {
        resident_id: params.r,
        location_id: params.l,
        per_page: rowsPerPage,
        page: currentPage,
        title: data.title || ''
    };

    // Extraer el residentId y locationId para usarlo en la navegación
    //const contact = contacts.find((contact) => contact.resident?.id === residentId);
    //const locationId = contact?.resident_location?.location_id;

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                
                if (response.payload) {
                    const selectedLocations =
                        params.l === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === params.l);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }
    
        if (!params.l && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }
    
        setSelectedLocations(params.l === 'all-locations' ? locations : locations.filter((l) => l.id === params.l));
        setLocation(params.l);
    
        if (!params.r && params.l) {
            setParam('r', 'all-residents');
        }
    }, [params, locations]);

    const onResidentSelect = (residentId: string) => {
        setParam('r', residentId);
        //console.log(residentId);
    };

    useEffect(() => {
        // Reiniciar la paginación cuando cambia el residente
        setCurrentPage(1);
        setData([]); // Limpiar los contactos
        setHasMoreRows(true);
        setIsLoading(false); // Asegurar que el loading esté false al empezar

        if (!params.r || params.r === 'all-residents') {
            // Llamar a la función para obtener todos los contactos con paginación
            dispatch(getContacts({ page: 1, per_page: rowsPerPage }));  // Siempre empieza desde la primera página
            fetchData(1, rowsPerPage);  // Reinicia desde la primera página
        } else {
            // Llamar a la función para obtener los contactos filtrados por residente con paginación
            dispatch(getContactsByResident({ residentId: params.r, page: 1, per_page: rowsPerPage }));  // Reinicia desde la primera página
            fetchData(1, rowsPerPage);  // Reinicia desde la primera página
        }
    }, [dispatch, params.r, rowsPerPage]);
    
    useEffect(() => {
        // Fetch phone numbers for each contact
        contacts.forEach((contact) => {
            dispatch(getListPhonesById(contact.id));
        });
    }, [contacts, dispatch]);

    // useEffect para manejar contactos
    useEffect(() => {
        if (contacts.length) {
            const formattedData = contacts.map((contact) => ({
                id: contact.id || 'N/A',
                category: contact.category?.name || 'N/A',
                linkto: contact.resident ? `${contact.resident.first_name} ${contact.resident.last_name}` : 'All Residents',
                resident_id: contact.linkto || 'N/A',
                company: contact.company || 'N/A',
                name: contact.name || 'N/A',
                last_name: contact.last_name || 'N/A',
                relationship: contact.relationship || 'N/A',
                email: contact.email || 'N/A',
                full_name: `${contact.name} ${contact.last_name}`,
                address: contact.address || 'N/A',
                phone: phones[contact.id] && phones[contact.id].length > 0 ? phones[contact.id][0].number : 'N/A',
                city: contact.city || 'N/A',
                state: contact.state || 'N/A',
                zipcode: contact.zipcode || 'N/A',
                country: contact.country || 'N/A',
                same_as: contact.same_as || 'N/A'
            }));

            // Solo actualizar si `data` está vacío para evitar duplicados
            if (data.length === 0) {
                setData(formattedData);
            }
        } else {
            setData([]); // Resetear si no hay contactos
        }
    }, [contacts, phones]);

    // Función para obtener contactos
    const fetchData = async (page: number, perPage: number, filters?: IContactsResumeData) => {
        setIsLoading(true);
        let filterData = filters || lastFilters;
    
        // Pasa todos los filtros, no solo title
        const response = await dispatch(getContacts({
            page,
            per_page: perPage,
            filters: filterData // Pasa todo el objeto filterData
        }));
    
        if (!response.payload || response.payload.result !== 'ok') {
            setIsLoading(false);
            return;
        }
    
        const { data: newData, total } = response.payload.payload;
        const newContacts = newData.map(contact => ({
            id: contact.id || 'N/A',
            category: contact.category?.name || 'N/A',
            linkto: contact.resident ? `${contact.resident.first_name} ${contact.resident.last_name}` : 'All Residents',
            resident_id: contact.linkto || 'N/A',
            company: contact.company || 'N/A',
            name: contact.name || 'N/A',
            last_name: contact.last_name || 'N/A',
            relationship: contact.relationship || 'N/A',
            email: contact.email || 'N/A',
            full_name: `${contact.name} ${contact.last_name}`,
            address: contact.address || 'N/A',
            phone: phones[contact.id]?.[0]?.number || 'N/A',
            city: contact.city || 'N/A',
            state: contact.state || 'N/A',
            zipcode: contact.zipcode || 'N/A',
            country: contact.country || 'N/A',
            same_as: contact.same_as || 'N/A',
        }));
    
        setData(prevData => page === 1 ? newContacts : [...prevData, ...newContacts]);
        setHasMoreRows(total > (page * perPage));
        setIsLoading(false);
    };    

    const loadMore = useCallback(() => {
        if (!hasMoreRows || isLoading) return; // prevenir multiples llamadas a la API
    
        const nextPage = currentPage + 1; // calcula la siguiente pagina
        setCurrentPage(nextPage); // actualiza la pagina actual
        fetchData(nextPage, rowsPerPage); // llama a fetchData con la siguiente pagina
    }, [hasMoreRows, isLoading, currentPage]);

    useEffect(() => {
        fetchData(currentPage, rowsPerPage); // carga los datos iniciales
    }, [currentPage, rowsPerPage]);

    const handleEyeClick = (contact) => {
        setSelectedContact(contact);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedContact(null);
    };

    const handleEditClick = (id, linkto) => {
        // Redirecciona a la página de edición de contactos
        navigate(`/contacts/edit/${id}?r=${linkto}`);
    };

    const handleNewContactClick = () => {
        if (residentId && locationId) {
            navigate(`/contacts/new/?l=${locationId}&r=${residentId}`);
        }
    };

    const handleNewAllContactClick = () => {
        navigate(`/contacts/new/?r=all-residents`);
    };

    const handleBackClick = () => {
        navigate('/contacts');
    };

    const handleDeleteClick = async (contactId: string) => {
        try {
            // Elimina el contacto del backend
            await dispatch(deleteContact(contactId));
    
            // Filtra el contacto eliminado de los datos locales antes de refrescar
            setData((prevData) => prevData.filter(contact => contact.id !== contactId));
    
            // Vuelve a cargar los datos con los filtros actuales
            await fetchData(1, rowsPerPage, lastFilters);
        } catch (error) {
            console.error('Error deleting contact:', error);
        }
    };
    
    const columnsWithActions = ContactsColumns.map((column) => {
        if (column.key === 'actions') {
          return {
            ...column,
            render: (rowData: any) => renderActionsColumn({ rowData, handleEyeClick, handleEditClick, handleDeleteClick })
          };
        }
        return column;
    });
    
    const [columns, setColumns] = useState(columnsWithActions);
    const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });

    const handleSort = (columnKey, direction) => {
        setSorting({ key: columnKey, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
            if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setData(sortedData);
    };
    //console.log("Data before rendering table:", data); // Verificar data antes de renderizar

    useEffect(() => {
        if (residentId) {
          filterContactsByResident(residentId);
        } else {
          setFilteredContacts(contacts); // Si no hay residente, muestra todos los contactos
        }
    }, [residentId, contacts]);
    
    // Función para filtrar los contactos según el ID del residente
    const filterContactsByResident = (residentId) => {
    const filtered = contacts.filter(contact => contact.resident_id === residentId);
    setFilteredContacts(filtered);
    };

    const filterContact = async (data: z.infer<typeof FormSchema>) => {
        try {
            const { title } = data;
            const filterData: IContactsResumeData = {
                ...lastFilters,
                title // Usar el campo title aquí en lugar de contact_id
            };
    
            setCurrentPage(1);  // Reiniciar la paginación
            await fetchData(1, rowsPerPage, filterData);  // Enviar filtros actualizados
        } catch (error) {
            console.error('Error filtering', error);
        }
    };      

    const cleanFilter = async () => {
        // Resetear el formulario (asegúrate de limpiar todos los campos que puedan estar activos)
        form.reset({
            title: '',        // Limpia el campo de título (si es parte de los filtros)
        });
    
        // Reiniciar los filtros al estado por defecto
        const resetFilters: IContactsResumeData = {
            resident_id: '',   // No aplicar filtro de residente
            location_id: '',   // No aplicar filtro de ubicación
            page: 1,           // Reiniciar la paginación a la primera página
            per_page: rowsPerPage,  // Mantener el número de resultados por página
            title: '',         // Limpia el filtro por título si existe
        };
    
        setCurrentPage(1); // Reiniciar la paginación
        await fetchData(1, rowsPerPage, resetFilters);  // Llamar a fetchData con filtros vacíos
    };

    return (
        <div className=" ">
            <div className="flex flex-col gap-4 my-4">
                <div className="flex items-center justify-between">
                    <div className="flex gap-2">
                        <div className="font-semibold text-2xl">
                            {selectedLocations.length > 0 ? (
                                <>{t('contacts.contacts')} {selectedLocations.length > 1 ? 'All Locations' : `- ${selectedLocations[0]?.name}`}</>
                            ) : (
                                'Contacts'
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {!params.l && (
                <>
                    <LocationSelector selections={false} />
                    <TableCustomVirtuoso
                    filters={
                    <FilterForm
                        form={form}
                        onFilter={filterContact}
                        onCleanFilter={cleanFilter}
                    />
                    }
                    data={data}
                    columns={columns}
                    renderCellContent={(index, column, data) => data[index][column.key]}
                    onSort={handleSort}
                    loadMore={loadMore} // Aquí se llama la función de scroll infinito
                    hasMore={hasMoreRows} // Indica si hay más filas por cargar
                    isLoading={isLoading}
                    showSearchInput={false}
                    additionalButtons={
                        <>
                            <div className="flex flex-row items-center gap-1">
                                <Button onClick={handleNewAllContactClick} className="flex flex-row items-center gap-2 hover:bg-[#B6B6B6]" size="sm">
                                    <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                                    {t('contacts.new')}
                                </Button>
                            </div>
                        </>
                      }
                    />
                    {selectedContact && (
                        <DialogContactInfo
                            contact={selectedContact}
                            isOpen={isDialogOpen}
                            onClose={handleCloseDialog}
                            phones={phones[selectedContact.id] || []}
                        />
                    )}
                </>
            )}
            {params.l && location && (
                <>
                    <NavigationBar>
                        <Button variant="ghost" onClick={handleBackClick}>
                        <>
                            <ArrowLeft className="mr-2 h-4 w-4" />
                            {t('contacts.back')}
                        </>
                        </Button>
                    </NavigationBar>
                    <div className="flex flex-col gap-4 my-4">
                        <ResidentSelector onSelect={onResidentSelect} locationId={location} residentId={params.r} />
                    </div>
                    <div className="Table">
                        <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                            <TableCustomVirtuoso
                            filters={
                                <FilterForm
                                    form={form}
                                    onFilter={filterContact}
                                    onCleanFilter={cleanFilter}
                                />
                            }
                            data={data}
                            columns={columns}
                            renderCellContent={(index, column, data) => data[index][column.key]}
                            onSort={handleSort}
                            loadMore={loadMore} // llama la función de scroll infinito
                            hasMore={hasMoreRows} // filas por cargar
                            isLoading={isLoading}
                            showSearchInput={false}
                            additionalButtons={
                                <>
                                    <div className="flex flex-row items-center gap-1">
                                        <Button onClick={handleNewContactClick} className="flex flex-row items-center gap-2 hover:bg-[#B6B6B6]" size="sm">
                                            <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                                            {t('contacts.new')}
                                        </Button>
                                    </div>
                                </>
                              }
                            />
                            {selectedContact && (
                                <DialogContactInfo
                                    contact={selectedContact}
                                    isOpen={isDialogOpen}
                                    onClose={handleCloseDialog}
                                    phones={phones[selectedContact.id] || []}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ContactsPage;