import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/components/ui/input';
import { Loader } from 'lucide-react';
import { useDocumentsStore } from '@/modules/residents/domain/stores/documents/documentsStore';
import { useState } from 'react';

const formSchema = z.object({
  label: z
    .string()
    .min(3, {
      message: 'The name of the category must be at least 3 characters.'
    })
    .regex(/^[a-zA-Z0-9'_\- ]*$/, {
      message: "Only alphanumeric characters, apostrophes ('), hyphens (-), underscores (_), and spaces are allowed."
    })
    .refine(
      (label) => {
        const { customCategories, defaultCategories } = useDocumentsStore.getState();

        const formattedLabel = label.trim().replace(/'/g, '').replace(/\s+/g, '_').replace(/[-_]+/g, '_').toLowerCase();

        const isDuplicate =
          customCategories.some((category) => category.value === formattedLabel) ||
          defaultCategories.some((category) => category.value === formattedLabel);

        return !isDuplicate;
      },
      {
        message: 'This category already exists.'
      }
    )
});

type FormValues = z.infer<typeof formSchema>;

interface CategoryFormProps {
  status: 'loading' | 'idle' | 'success' | 'error';
  defaultValues: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  handleLabel?: string;
}

export const CategoryForm = ({ defaultValues, onSubmit, status, handleLabel }: CategoryFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange'
  });

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    await onSubmit({ ...data });
    setIsLoading(false);
    form.reset();
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="label"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} disabled={status === 'loading'} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="col-span-full w-full"
            disabled={!form.formState.isValid || status === 'loading' || isLoading}
          >
            {(status === 'loading' || isLoading) && <Loader className="mr-2 h-4 w-4 animate-spin" />}
            {handleLabel || 'Create Category'}
          </Button>
        </form>
      </Form>
    </>
  );
};
