import { cn } from '@/lib/utils';

export const Logo = ({ path, clientName }: { path: string; clientName: string }) => {
  return (
    <div className={cn('gap-2 flex items-center flex-col')}>
      <img src={path} alt="Caring Data Client Logo" width={100} height={100} />
      <p className="text-sm font-semibold">{clientName}</p>
    </div>
  );
};
