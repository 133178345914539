import { Skeleton } from "@/common/presentation/components/registry/default/ui/skeleton";
import { ScrollArea } from "@/components/ui/scroll-area";


const ResidentAlertContainerSkeleton = () => {
  return (
    <div className="border rounded-lg p-4 shadow-sm w-full mb-4 dark:bg-black">
      <div className="flex flex-row items-center gap-3 mb-3">
        <Skeleton className="h-7 w-7 rounded-full bg-gray-300 dark:bg-zinc-900" />
        <Skeleton className="h-4 w-40 bg-gray-300 dark:bg-zinc-900" />
      </div>
      <ScrollArea className="rounded-md border" style={{ height: "350px" }}>
        {[...Array(5)].map((_, index) => (
          <div key={index} className="bg-gray-100 dark:bg-zinc-900 p-2 m-2 rounded">
            <Skeleton className="h-4 w-24 mb-2 bg-gray-300 dark:bg-zinc-950" />
            <div className="flex flex-wrap items-center gap-2 mb-2">
              <Skeleton className="h-4 w-32 bg-gray-300 dark:bg-zinc-950" />
              <div className="flex-grow" />
              <Skeleton className="h-6 w-12 bg-gray-300 dark:bg-zinc-950" />
              <Skeleton className="h-6 w-12 bg-gray-300 dark:bg-zinc-950" />
            </div>
          </div>
        ))}
      </ScrollArea>
    </div>
  );
};

export default ResidentAlertContainerSkeleton;