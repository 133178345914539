import { create } from 'zustand';
import ResidentFormService from '@/modules/residents/infrastructure/services/DocumentsService';
import { DocumentCategory } from '@/modules/residents/infrastructure/types/documents';

type RequestStatus = 'idle' | 'loading' | 'creating' | 'failed';

type DocumentsState = {
  documents: any[];
  customCategories: DocumentCategory[];
  defaultCategories: DocumentCategory[];
  status: RequestStatus;
  error: string | null;

  createCategory: (data: any) => Promise<any>;
  getCategories: () => Promise<any>;
  getResource: (data: any) => Promise<any>;
};

export const useDocumentsStore = create<DocumentsState>((set) => ({
  documents: [],
  customCategories: [],
  defaultCategories: [],
  status: 'idle',
  error: null,

  createCategory: async (data: any) => {
    return handleRequest(set, 'creating', async () => {
      const response = await ResidentFormService.createCategory(data);
      set({ customCategories: response.data?.categories });
      return response.data;
    });
  },

  getCategories: async () => {
    return handleRequest(set, 'loading', async () => {
      const response = await ResidentFormService.getCategories();
      set({ customCategories: response.data?.categories });
      return response.data;
    });
  },

  getResource: async (data: any) => {
    return handleRequest(set, 'loading', async () => {
      const response = await ResidentFormService.getResource(data);
      set({ defaultCategories: response.data?.document_resource?.other_information });
      return response?.data;
    });
  }
}));

const handleRequest = async (set: any, status: RequestStatus, request: () => Promise<any>) => {
  set({ status, error: null });
  try {
    const response = await request();
    set({ status: 'idle' });
    return response;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;
    set({ status: 'failed', error: errorMessage });
    throw new Error(errorMessage);
  }
};
