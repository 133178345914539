import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerConfiguration from "../infrastructure/services/TrackerConfiguration";

export interface ITrackerConfigurationsState {
  enabledTrackers: [],
  isLoading: boolean;
  error: string | null;
}

const initialState: ITrackerConfigurationsState = {
    enabledTrackers:[],
    isLoading: false,
    error: ''
};


export const getEnabledTrackers = createAsyncThunk('trackerConfiguration/getEnabledTrackers',
 async (locationId: string = '') => {
    const response = await TrackerConfiguration.getEnabledTrackers(locationId);
    return response;
  }
);

export const update = createAsyncThunk('trackerConfiguration/update',
  async (formData: any ) => {
     const response = await TrackerConfiguration.update(formData);
     return response;
   }
 );

const TrackerConfigurationSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    trackerConfigurationLoading: (state)=>{
     state.isLoading = true;
     state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.
    addCase(getEnabledTrackers.fulfilled, (state, action) => {
      state.enabledTrackers = action.payload;
      state.isLoading = false;
    });
  }
});

export const { trackerConfigurationLoading } = TrackerConfigurationSlice.actions;
export default TrackerConfigurationSlice.reducer;