import React, { useState, useMemo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { useSettings } from '../../infrastructure/hooks/use-settings';


import { Skeleton } from '@/common/presentation/components/ui/skeleton';


import { Home, ArrowLeft } from 'lucide-react';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '@/common/presentation/components/ui/breadcrumb';

import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';


import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';

import { IRoute } from '../../infrastructure/utils/interfaceModules'
import { modules } from '../../infrastructure/utils/Modules';
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { Input } from '@/common/presentation/components/ui/input';
import ModuleCard from '@/common/presentation/components/ModuleCard/ModuleCard';

const iconColors: string[] = [
  "#06b6b4",
  "#1e3aba",
  "#f97316",
  "#db2777",
  "#0284c7",
  "#86198f",
  "#10b981",
  "#e11d48",
  "#FBBF24",
  "#6366f1",
  "#d97706"
];

export const SettingsDialog: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, onClose } = useSettings();
  const [search, setSearch] = useState('');
  const [route, setRoute] = useState<string[]>([]);
  const { locationSelectedId } = useLocation();
  const { isLoading, configurations } = useSelector((state: RootState) => state.configurations);
  const primaryColor = useMemo(() => (isLoading) ? '#3b8bc3' : configurations.accent_color ?? '#3b8bc3', [isLoading]);

  const currentModule = useMemo(() => {
    return route.reduce<IRoute>((accumulator, currentValue) => {
      setSearch('')
      if (accumulator === undefined) {
        return modules.find(module => module.key === currentValue);
      }
      if ('subModules' in accumulator) {
        return accumulator.subModules?.find((subModule: any) => subModule.key === currentValue)
      }
      return accumulator;

    }, undefined)

  }, [route]);


  const handleNavigation = (key: string) => {
    setRoute(prev => [...prev, key]);
  };

  const handleBack = () => {
    setRoute(prev => prev.slice(0, -1));
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value.toLocaleLowerCase() ?? '');
  }

  const filteredModules = useMemo(() => {
    if (search?.length > 0 && modules?.length > 0) {
      return modules.filter(m => m?.label.toLocaleLowerCase().includes(search));
    }
    return modules;

  }, [modules, search]);

  const filteredCurrentModule = useMemo(() => {

    if (search?.length > 0 && typeof currentModule == 'object') {
      const matchSubModules = currentModule?.subModules?.filter((sm: any) => sm.label.toLowerCase().includes(search));
      return {
        ...currentModule,
        subModules: matchSubModules ?? {}
      };
    }

    return currentModule;

  }, [currentModule, search]);


  const renderContent = () => {

    if (currentModule == undefined) { //Default module settings
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 
          xl:grid-cols-4 md:gap-x-4 gap-y-3 gap-x-2 md:gap-y-5 md:gap-x-4 md:gap-y-5
          "
          key={'parentModule'}
        >
          {filteredModules.map((module: any, index: number) => {
            const color = iconColors[index % iconColors.length];
            return (
              <ModuleCard
                key={module.key}
                id={module.key}
                color={color}
                title={module.label}
                icon={module.icon}
                onClick={() => handleNavigation(module.key)}
              />);


          })}
        </div>
      );
    }

    if ('subModules' in currentModule && currentModule.subModules) { // Sub module of module
      return (
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-3 gap-x-2 md:gap-x-4 md:gap-y-5"
          key={'childSubModule'}
        >
          {filteredCurrentModule.subModules.map((subModule: any, index: number) => {
            const color = iconColors[index % iconColors.length];
            return (
              <ModuleCard
                key={subModule.key}
                color={color}
                id={subModule.key}
                title={subModule.label}
                icon={subModule.icon}
                onClick={() => handleNavigation(subModule.key)}
              />);
          })}
        </div>
      );
    }

    return <React.Fragment key={'component'}>{
      currentModule.isRequiredLocation === true &&
        locationSelectedId === 'all-locations'
        ? (
          <>
            <p className='text-red-600'>{t('settings.selectedLocation') + '!'}</p>
            <div><LocationSelector quickView={true} /></div>
          </>
        ) : (
          currentModule.component // Component of module or subModule
        )}</React.Fragment>


  };

  const keyContent = React.isValidElement(renderContent())
    ? renderContent().key
    : null;

  const getResults = useMemo(() => {
    let result = '';
    if (keyContent == 'parentModule') {
      result = `${modules.length} ${t('common.of')} ${(filteredModules.length ?? 0)} `;
    }
    if (keyContent == 'childSubModule') {
      result = `${currentModule.subModules.length} ${t('common.of')} ${(filteredCurrentModule.subModules.length ?? 0)} `;
    }

    return result + t('common.results');

  }, [filteredCurrentModule, filteredModules, modules]);


  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose} key={'dialogSettingsConfigurations'}>
        
        <DialogContent
          className={`max-w-[1118px] w-[92%] h-[90vh] max-h-[624px] flex flex-col pr-4 pb-2 pl-4 
                      sm:pt-5 sm:pr-8 sm:pb-4 sm:pl-8 gap-0 rounded-lg border-t-4 border-primary`}
          >
          <DialogHeader>
            <DialogTitle className='text-primary text-lg md:text-2xl'>{t('settings.settings')}</DialogTitle>
            <div className='flex justify-between pt-2 pb-4'>
              <Breadcrumb >
                <BreadcrumbList>
                  <BreadcrumbItem>
                    <BreadcrumbLink onClick={() => setRoute([])} className='flex gap-1'>
                      <Home className="h-4 w-4" />
                      <span className='text-xs'>{t('settings.settings')}</span>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  {route.map((key, index) => (
                    <React.Fragment key={key}>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <BreadcrumbPage onClick={() => setRoute(route.slice(0, index + 1))} className='text-xs'>
                          {modules.find(m => m.key === key)?.label ||
                            modules.flatMap(m => m.subModules || []).find(sm => sm.key === key)?.label}
                        </BreadcrumbPage>
                      </BreadcrumbItem>
                    </React.Fragment>
                  ))}
                </BreadcrumbList>
              </Breadcrumb>
              {route.length > 0 && (
                <Button variant="ghost" className='h-[10px] text-[12px]' onClick={handleBack}>
                  <ArrowLeft size={11} className="mr-2 h-4 w-4 text-[12px]" />
                  {t('settings.back')}
                </Button>
              )}
            </div>


            <div className='pb-6 m-0 flex flex-col' style={{ margin: '0px' }}>
              <hr className="border-primary w-full" />
              {['parentModule'].includes(keyContent ?? '') &&
                <div className='
                    flex flex-col md:flex-row gap-y-2 md:gap-0 pt-6 text-left md:justify-between 
                    md:items-center text-[#52525B] text-sm font-normal font-medium leading-5'
                >
                  <h2>{t('settings.searchTitle')}</h2>
                  <Input
                    type='text'
                    className='md:w-[350px] h-[36px] p-y-2 px-4'
                    placeholder={t('common.search')}
                    onChange={handleSearch}
                  />
                </div>
              }
            </div>

          </DialogHeader>

          {['parentModule', 'childSubModule'].includes(keyContent ?? '') ?
            (<>
              <ScrollArea className="w-ful bg-neutralLight p-6 h-[100%] max-h-[388px] rounded-lg">
                {isLoading && (
                  <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
                    <Spinner size={'lg'} />
                  </div>
                )}

                {!isLoading && renderContent()}
              </ScrollArea>
              <div className='w-full text-end text-[13px] font-semibold leading-[16px] text-[#A1A1AA] pt-4'>
                {getResults}
              </div>
            </>
            ) : (
              <>
                {!isLoading && renderContent()}
              </>
            )}
        </DialogContent>
      </Dialog>


    </>
  );
};