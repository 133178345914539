import { NewstaffDialog } from '@/modules/staff/presentation/components/dashboard/dialogs/NewStaffDialog';
import { InactiveStaffDialog } from '@/modules/staff/presentation/components/dashboard/dialogs/InactiveStaffDialog';

export const Dialogs = () => {
  return (
    <>
      <NewstaffDialog />
      <InactiveStaffDialog />
    </>
  );
};
