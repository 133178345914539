import React, { useState, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { useController } from 'react-hook-form';

const NumberInput = ({ name, control }) => {
  const {
    field: { value, onChange }
  } = useController({
    name,
    control,
    defaultValue: 0
  });

  const handleChange = (e) => {
    const newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
    onChange(newValue);
  };

  const handleIncrement = () => {
    const newValue = (value || 0) + 1;
    onChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = (value || 0) - 1;
    if (newValue >= 0) {
      onChange(newValue);
    }
  };

  return (
    <div className="flex items-center border border-input rounded-md w-32 h-8 overflow-hidden focus-within:ring-ring-primary focus-within:ring-ring focus-within:ring-offset-2">
      <button
        onClick={handleDecrement}
        className="px-2 py-1 text-primary hover:bg-primary/10 transition-colors focus:outline-none"
        disabled={value <= 0}
      >
        -
      </button>
      <Input
        type="number"
        value={value || ''}
        onChange={handleChange}
        className="w-full h-full px-1 text-center border-none focus:outline-none focus:ring-0 text-sm focus-visible:ring-0"
      />
      <button
        onClick={handleIncrement}
        className="px-2 py-1 text-primary hover:bg-primary/10 transition-colors focus:outline-none"
      >
        +
      </button>
    </div>
  );
};

export default NumberInput;
