import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LoadingComponent } from '@/common/presentation/components/Loading/LoadingComponent';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { Button } from '@/common/presentation/components/ui/button';
import { EmptyIcon } from '@/modules/residents/presentation/components/EmptyIcon';
import { useInactiveStaffDialog } from '@/modules/staff/infrastructure/hooks/dashboard/useInactiveStaffDialog';
import { useNewStaff } from '@/modules/staff/infrastructure/hooks/dashboard/useNewStaff';
import { Dialogs as StaffDashboardDialogs } from '@/modules/staff/presentation/components/dashboard/dialogs/Dialogs';
import { getColumns } from '@/modules/staff/presentation/components/dashboard/tables/StaffTableColumns';
import * as staffSliceActions from '@/modules/staff/presentation/slices/staffSlice';
import { AppDispatch, RootState } from '@/store/store';
import { CircleFadingPlus, UserCog, UserRoundX } from 'lucide-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const rolesToValidate = ['Super Administrator', 'Executive'];

export const StaffPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { onOpen } = useNewStaff();
  const navigate = useNavigate();
  const { params, setParam } = useRouteParams();
  const { staff, status } = useSelector((state: RootState) => state.staff.allStaff);
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const { onOpen: onOpenInactiveStaffDialog } = useInactiveStaffDialog();

  const filteredStaff = useMemo(() => {
    return staff.filter((s) => s.id !== user?.id);
  }, [staff]);
  console.log(staff)
  useEffect(() => {
    if (!params.l) {
      setParam('l', 'all-locations');
    }
  }, [params.l, setParam]);

  useEffect(() => {
    if (params.l) {
      const location = params.l === 'all-locations' ? undefined : params.l;
      dispatch(staffSliceActions.getStaffByLocation(location));
    }
  }, [params.l, dispatch]);

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  const selectedRow = useCallback(
    (rowIndex: number) => {
      const selectedStaff = staff[rowIndex];
      navigate(`/staff/profile?s=${selectedStaff.id}`);
    },
    [staff, navigate]
  );

  const columns = useMemo(() => getColumns(isAdmin), [isAdmin]);

  const aditionalActions = useMemo(
    () => (
      <div className="flex ml-auto w-auto gap-2">
        <Button
          onClick={() => {
            navigate(`/staff/profile?s=${user?.id}`);
          }}
          className="flex ml-auto w-auto gap-2"
          variant="outline"
        >
          <UserCog className="h-4 w-4" />
          <span className="hidden md:block">{t('staff.dashboard.my_profile')}</span>
        </Button>
        {isAdmin && (
          <>
            <Button onClick={onOpenInactiveStaffDialog} className="flex ml-auto w-auto gap-2" variant="outline">
              <UserRoundX className="h-4 w-4" />
              <span className="hidden md:block">{t('staff.dashboard.inactive_staff')}</span>
            </Button>
            <Button onClick={onOpen} className="flex ml-auto w-auto gap-2" variant="default">
              <CircleFadingPlus className="h-4 w-4" />
              <span className="hidden md:block">{t('staff.dashboard.new_staff')}</span>
            </Button>
          </>
        )}
      </div>
    ),
    [onOpen, isAdmin]
  );

  return (
    <>
      <LocationSelector locationId={params.l} />

      {status === 'loading' && <LoadingComponent status={status} label="Loading Staff" />}

      {status === 'failed' && (
        <div className="min-h-[50vh] w-full h-full flex flex-col items-center justify-center py-12 gap-4">
          <EmptyIcon className="h-auto w-[8vw]" />
          <p>Failed to load staff. Please try again later.</p>
        </div>
      )}

      {status === 'idle' && (
        <div className="flex flex-col gap-4 my-4">
          <SingleTable
            data={filteredStaff}
            columns={columns}
            onRowClick={(index) => {
              if (isAdmin) {
                selectedRow(index);
              }
            }}
            filterKey="first_name"
            aditionalActions={aditionalActions}
          />
        </div>
      )}

      <StaffDashboardDialogs />
    </>
  );
};
