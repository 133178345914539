import useRouteParams from '@/common/hooks/RouteParamsHook';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { CardsToShow } from '@/modules/staff/domain/models/profile/formCards';
import { useFormsCardsStore } from '@/modules/staff/domain/stores/profile/formCards';
import { ProfileImage } from '@/modules/staff/presentation/components/updateStaff/ProfileImage';
import { CompanyForm } from '@/modules/staff/presentation/components/updateStaff/forms/CompanyForm';
import { CustomFieldsForm } from '@/modules/staff/presentation/components/updateStaff/forms/CustomFieldsForm';
import { LoginInformationForm } from '@/modules/staff/presentation/components/updateStaff/forms/LoginInformation';
import { PersonalInformationForm } from '@/modules/staff/presentation/components/updateStaff/forms/PersonalInformationForm';
import * as staffMemberActions from '@/modules/staff/presentation/slices/staffMemberSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OtherForm } from '../components/updateStaff/forms/OtherForm';
import { StaffActions } from '../components/updateStaff/forms/StaffActions';
import { TerminationForm } from '../components/updateStaff/forms/TerminationForm';

const rolesToValidate = ['Super Administrator', 'Executive'];

export const UpdateStaffMemberPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const navigate = useNavigate();
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const { user } = useSelector((state: RootState) => state.auth);

  const { formCardsToShow, getFormCardsToShow } = useFormsCardsStore();

  useEffect(() => {
    const fetchStaffMember = async () => {
      const response = await dispatch(staffMemberActions.getStaffMemberById(params.s));

      if (response?.meta.requestStatus === 'rejected') {
        toast({
          description: 'Error al cargar el miembro del personal',
          variant: 'destructive'
        });
        navigate('/');
      }
    };

    fetchStaffMember();
  }, [dispatch, navigate, params.s]);

  useEffect(() => {
    getFormCardsToShow();
  }, [getFormCardsToShow]);

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  useEffect(() => {
    if (!user || !params.s) return;

    if (!isAdmin && params.s !== user.id) {
      toast({
        description: 'You are not allowed to view this page',
        variant: 'destructive'
      });
      navigate('/');
    }
  }, [user, params.s, isAdmin, navigate]);

  const validateCard = useCallback(
    (card: keyof CardsToShow): boolean => {
      if (!formCardsToShow?.[card]) return false;
      const cardInfo = formCardsToShow[card];

      if (cardInfo.status === 'inactive') return false;

      if (cardInfo.staff_can_view === false && user?.roles.some((role: any) => role.name === 'Staff')) {
        return false;
      }

      return true;
    },
    [formCardsToShow, user]
  );

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full py-4 flex flex-col gap-6">
          <BackNavigationBar
            onlyBack={true}
            titleComponent={
              <h1 className="text-2xl font-semibold">
                Update Staff for{' '}
                {staffMember ? (
                  <span className="font-bold">
                    {staffMember.first_name} {staffMember.middle_name} {staffMember.last_name}
                  </span>
                ) : (
                  'Loading...'
                )}
              </h1>
            }
          />

          <div className="grid grid-cols-4 rounded-lg bg-secondary p-4 w-full gap-4">
            <div className="col-span-4 xl:col-span-1 flex flex-col items-center justify-center">
              <ProfileImage />
              {isAdmin && <StaffActions />}
            </div>

            <div className="col-span-4 xl:col-span-3 ">
              <PersonalInformationForm />
            </div>

            {/* {validateCard('training') && (
              <div className="col-span-4">
                <TrainingForm />
              </div>
            )} */}

            {validateCard('other') && (
              <div className="col-span-4">
                <OtherForm />
              </div>
            )}

            {validateCard('custom_fields') && (
              <div className="col-span-4">
                <CustomFieldsForm />
              </div>
            )}

            {validateCard('company') && (
              <div className="col-span-4">
                <CompanyForm />
              </div>
            )}

            {validateCard('termination') && isAdmin && (
              <div className="col-span-4">
                <TerminationForm />
              </div>
            )}

            {validateCard('login_information') && (
              <div className="col-span-4">
                <LoginInformationForm />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
