import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Form,
  MedicationType,
  Medicine,
  MedicineRoute,
  ResponsibleRoute
} from '@/modules/medications/domain/Medications';
import MedicationService from '@/modules/medications/infrastructure/services/MedicationService';

interface FormCopy {
  residentId: string;
  medicationId: string;
}

interface FetchMedicationsParams {
  locationId?: string | null;
}

interface FetchMedicationsByClientIdParams {
  locationId: string | undefined;
  residentId: string | undefined;
  filter: string | undefined;
  page?: number;
  limit?: number;
}

interface MedicationState {
  medications: Medicine[];
  centralizedmedications: [];
  medicationsResident: Medicine[];
  types: MedicationType[];
  routes: MedicineRoute[];
  responsibleAdministrations: ResponsibleRoute[];
  forms: Form[];
  residentMedicationTime: [];
  myMedications: Medicine[];
  medicationsByClientId: any[];
  medicationsByResidentId: Medicine[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;
  currentPage: number;
  totalPages: number;
  filterValue: string;
  totalItems: number;
  medicationstatus: 'idle' | 'loading' | 'failed';
  destroyedMedications: [];
  disposalStatus: 'idle';
  disposalError: null;
  medicationAudits: any[];
  auditStatus: 'idle' | 'loading' | 'failed';
  auditError: string | null;
  medicationResidentsUpdateMassive: any[];
  medicationsAllMedicationsByDestroyed: any[];
}

const initialState: MedicationState = {
  medications: [],
  centralizedmedications: [],
  types: [],
  routes: [],
  forms: [],
  myMedications: [],
  medicationsByClientId: [],
  medicationsByResidentId: [],
  residentMedicationTime: [],
  responsibleAdministrations: [],
  medicationResidentsUpdateMassive: [],
  medicationsResident: [],
  status: 'idle',
  error: null,
  currentPage: 1,
  totalPages: 1,
  totalItems: 0,
  medicationstatus: 'idle',
  filterValue: '',
  destroyedMedications: [],
  disposalStatus: 'idle',
  disposalError: null,
  medicationAudits: [],
  auditStatus: 'idle',
  auditError: null,
  medicationsAllMedicationsByDestroyed: []
};

// Thunks
export const fetchMedications = createAsyncThunk(
  'medications/fetchMedications',
  async ({ locationId, filter, medicationId }: { locationId: string; filter: string; medicationId: string }) => {
    const response = await MedicationService.getAllMedications(locationId, filter, medicationId);
    return response.data;
  }
);

export const fetchMedicationsByLocation = createAsyncThunk(
  'medications/fetchMedications',
  async ({ medicationId, filter, residentId }) => {
    try {
      const response = await MedicationService.getAllMedicationsByLocation(medicationId, filter, residentId);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching medications: ' + error.message);
    }
  }
);

export const fetchTypes = createAsyncThunk('medications/fetchTypes', async ({ locationId }: FetchMedicationsParams) => {
  const response = await MedicationService.getAllTypes(locationId);
  return response.data;
});

export const fetchRoutes = createAsyncThunk<MedicineRoute[]>('medications/fetchRoutes', async () => {
  const response = await MedicationService.getAllRoutes();
  return response.data;
});

export const fetchResponsible = createAsyncThunk<ResponsibleRoute[]>('medications/fetchResponsibleRoute', async () => {
  const response = await MedicationService.getAllResponsible();
  return response.data;
});

export const fetchForms = createAsyncThunk<ResponsibleRoute[]>('medications/fetchForms', async () => {
  const response = await MedicationService.getAllForms();
  return response.data;
});

export const createResidentMedication = createAsyncThunk<Medicine, FormData>(
  'medications/createResidentMedication',
  async (formData) => {
    const response = await MedicationService.createResidentMedications(formData);
    return response.data;
  }
);

export const getAllMedicationsByClientId = createAsyncThunk(
  'medications/list/resident-medication',
  async (params: FetchMedicationsByClientIdParams) => {
    const response = await MedicationService.getAllMedicationsByClientId(
      params.locationId,
      params.residentId,
      params.filter,
      params.type,
      params.status,
      params.page,
      params.limit
    );
    return {
      data: response.data,
      currentPage: params.page || 1,
      totalPages: response.data.total_pages,
      totalItems: response.data.total_items
    };
  }
);

export const getAllMedicationsByResidentId = createAsyncThunk(
  'residents/getAllResidentsByClientId',
  async ({ residentId, page = 1, limit = 50 }) => {
    const response = await MedicationService.getAllMedicationsByResidentId(residentId, page, limit);
    return response;
  }
);

export const getResidentMedicationTime = createAsyncThunk('medications/residentTime', async (residentId?: string) => {
  const response = await MedicationService.getResidentMedicationTime(residentId);
  return response.data.medication_times;
});

export const fetchResidentMedicationById = createAsyncThunk(
  'medications/fetchResidentMedicationById',
  async (id: string) => {
    const response = await MedicationService.getResiddentMedicationById(id);
    return response;
  }
);

export const GenerateMedicationPDF = createAsyncThunk(
  'medications/GenerateMedicationPDF',
  async ({ residentId, type, data }: { residentId: string; type: string; data: any }) => {
    const response = await MedicationService.getMedicationsPdf(residentId, type, data);
    return response.data;
  }
);

export const GenerateTimesPDF = createAsyncThunk(
  'medications/GenerateTimesPDF',
  async ({ locationId }: { locationId: string }) => {
    const response = await MedicationService.getTimesPdf(locationId);
    return response.data;
  }
);

export const editResidentMedicationById = createAsyncThunk(
  'medications/editResidentMedication',
  async ({ id, data }: { id: string; data: FormData }) => {
    try {
      const response = await MedicationService.editResidentMedicationById(id, data);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('An unknown error occurred');
    }
  }
);

export const disposeMedication = createAsyncThunk(
  'medications/disposeMedication',
  async (disposalData: any, thunkAPI) => {
    try {
      const response = await MedicationService.disposeMedication(disposalData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error disposing medication');
    }
  }
);

export const getDestroyedMedications = createAsyncThunk(
  'medications/getDestroyedMedications',
  async (residentId: string, thunkAPI) => {
    try {
      const response = await MedicationService.getDestroyedMedications(residentId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching destroyed medications');
    }
  }
);

export const copyMedication = createAsyncThunk('medications/medication-copy', async (formCopy: FormCopy, thunkAPI) => {
  try {
    const response = await MedicationService.copyMedication(formCopy);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Error copying medication');
  }
});

export const fetchMedicationAudits = createAsyncThunk(
  'medications/fetchMedicationAudits',
  async (residentMedicationId: string, thunkAPI) => {
    try {
      const response = await MedicationService.getMedicationAudits(residentMedicationId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching medication audits');
    }
  }
);

export const fetchCentralizedMedication = createAsyncThunk(
  'medications/fetchCentralizedMedication',
  async ({ residentId, page = 1, limit = 50 }: { residentId: string; page?: number; limit?: number }) => {
    try {
      const response = await MedicationService.getCentralizedMedication(residentId, page, limit);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching centralized medication: ' + error.message);
    }
  }
);

export const updateMassiveResidentMedications = createAsyncThunk(
  'medications/updateMassiveResidentMedications',
  async (medicationsData, { rejectWithValue }) => {
    try {
      const response = await MedicationService.updateMassiveResidentMedications(medicationsData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchGetAllMedicationsByDestroyed = createAsyncThunk(
  'medications/getAllMedicationsByDestroyed',
  async ({ residentId, filter, page, limit }, { rejectWithValue }) => {
    try {
      const data = await MedicationService.getAllMedicationsByResidentDestruction(residentId, filter, page, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const medicationsSlice = createSlice({
  name: 'medications',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    resetMedications: (state) => {
      state.medicationsByClientId = [];
      state.totalPages = 0;
      state.totalItems = 0;
    },
    resetPage: (state) => {
      state.currentPage = 1;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMedicationsByClientId.pending, (state) => {
        state.medicationstatus = 'loading';
      })
      .addCase(getAllMedicationsByClientId.fulfilled, (state, action) => {
        const { data, currentPage } = action.payload;

        if (currentPage === 1) {
          // Si es la primera página, reemplaza los datos existentes
          state.medicationsByClientId = data;
        } else {
          // Si es paginación (scroll), agrega los nuevos datos a los existentes
          state.medicationsByClientId = [...state.medicationsByClientId, ...data];
        }

        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.medicationstatus = 'idle';
      })
      .addCase(getAllMedicationsByClientId.rejected, (state, action) => {
        state.medicationstatus = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(fetchGetAllMedicationsByDestroyed.fulfilled, (state, action) => {
        state.medicationstatus = 'idle';
        state.medicationsAllMedicationsByDestroyed = action.payload.data;
      })
      .addCase(fetchCentralizedMedication.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCentralizedMedication.fulfilled, (state, action) => {
        state.centralizedmedications = action.payload.data;
        state.status = 'idle';
      })
      .addCase(fetchCentralizedMedication.rejected, (state) => {
        state.status = 'failed';
      })
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        (state, action) => {
          state.status = 'idle';
          const { type, payload } = action;
          switch (type) {
            case fetchMedications.fulfilled.type:
              state.medications = payload;
              break;
            case fetchMedicationsByLocation.fulfilled.type:
              state.medications = payload;
              break;
            case fetchTypes.fulfilled.type:
              state.types = payload;
              break;
            case fetchRoutes.fulfilled.type:
              state.routes = payload;
              break;
            case fetchResponsible.fulfilled.type:
              state.responsibleAdministrations = payload;
              break;
            case fetchForms.fulfilled.type:
              state.forms = payload;
              break;
            case createResidentMedication.fulfilled.type:
              state.medications.push(payload);
              break;
            case getResidentMedicationTime.fulfilled.type:
              state.residentMedicationTime = payload;
              break;
            case getAllMedicationsByResidentId.fulfilled.type:
              state.medicationsByResidentId = payload;
              break;
            case fetchResidentMedicationById.fulfilled.type:
              state.medicationsResident = payload;
              break;
            case editResidentMedicationById.fulfilled.type:
              state.medicationsResident = Array.isArray(state.medicationsResident)
                ? state.medicationsResident.map((med) => (med.id === payload.id ? payload : med))
                : [payload];
              break;
            case disposeMedication.fulfilled.type:
              state.destroyedMedications.push(payload);
              break;
            case getDestroyedMedications.fulfilled.type:
              state.destroyedMedications = payload;
              break;
            case fetchMedicationAudits.fulfilled.type:
              state.medicationAudits = payload;
              break;
            case copyMedication.fulfilled.type:
              state.medicationsResident.push(payload);
              break;
            default:
              break;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        }
      );
  }
});

export const { setPage, resetMedications, resetPage } = medicationsSlice.actions;

export default medicationsSlice.reducer;
