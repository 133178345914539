import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerFallService from '../../infrastructure/services/TrackerFallService';


export const getTrackerFall = createAsyncThunk("trackerFall/getTrackerFall", async (body: any) => {
    const response = await TrackerFallService.getTrackerFall(body);

    return response;
});

export const saveTrackerFall = createAsyncThunk("trackerFall/SaveTrackerFall", async (body: any) => {
    const response = await TrackerFallService.saveTrackerFall(body);

    return response;
});

interface TrackerFallState {
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    fallReport: any[];
    saveFallResponse?: { 
        message: string;
        data: any[];          
    };
}

const initialState: TrackerFallState = {
    status: 'idle',
    error: null,
    fallReport: []
};


const trackerFall = createSlice({
    name: 'trackerFall',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getTrackerFall.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getTrackerFall.fulfilled, (state, action) => {
            state.status = 'idle';
            state.fallReport = action.payload;
        });

        builder.addCase(getTrackerFall.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });
        builder.addCase(saveTrackerFall.fulfilled, (state, action) => {
            state.status = 'idle';
            state.saveFallResponse = action.payload;
        });

        builder.addCase(saveTrackerFall.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });
    }
});

export default trackerFall.reducer;
