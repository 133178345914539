import { Label } from '@/common/presentation/components/ui/label';
import { InformationItem } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/InformationItem';
import { useMemo } from 'react';

export const FacilityInformation = ({ resident }: any) => {
  const { address, name, licensing } = useMemo(() => resident?.locations?.[0], [resident]);

  const phone = useMemo(() => {
    const rootPhone = resident?.locations?.[0]?.phone.replace(/[^\d]/g, '');

    if (rootPhone.length === 10) {
      return `(${rootPhone.slice(0, 3)}) ${rootPhone.slice(3, 6)}-${rootPhone.slice(6)}`;
    }

    return resident?.locations?.[0]?.phone;
  }, [resident]);

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-lg font-bold">Facility Information</Label>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <InformationItem label="Name of Facility" value={name || 'Not Specified'} />
        <InformationItem label="Adress" value={address || 'Not Specified'} />
        <InformationItem label="Check Type Of Needs And Services Plan" value={'Not Specified'} />
        <InformationItem
          label="Person(s) Or Agency(ies) Referring Client/Resident For Placement"
          value={'Not Specified'}
        />
        <InformationItem label="Facility License Number" value={licensing || 'Not Specified'} />
        <InformationItem label="Telephone Number" value={phone || 'Not Specified'} />
      </div>
    </div>
  );
};
