import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { cn } from '@/lib/utils';
import { ContactsNoteI } from '@/modules/notes/domain/note.domain';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, DialogDescription } from '@/common/presentation/components/ui/dialog';
import { getContactsNote, resetSelectedNote } from '@/modules/notes/presentation/slices/NoteSlice';
import { Loader, Share2, X } from 'lucide-react';
import { MultiValue } from 'react-select';
import { RootState } from '@/store/store';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import NoteService from '../../../infrastructure/services/NoteService';


interface Option {
  value: string;
  label: string;
}

interface OptionEmail {
  email: string;
  id: string | null;
}

interface FormDataShareNotes {
  note_id: string[];
  email: OptionEmail[];
  comment: string;
}

const customClassNames = {
  control: () =>
    cn(
      '!bg-background border-1 !border-gray-300 dark:!border-gray-700 focus:!border-red-400 !rounded focus:!outline-none'
    ),
  menu: () => cn('!bg-background !border-2 !border-gray focus:!border-red-400 !z-[9999]'),
  option: ({ isSelected }: any) =>
    cn(
      'dark:text-white dark:hover:!bg-gray-400 hover:!bg-primary/10 before:!bg-primary/10',
      isSelected ? '!bg-primary' : ''
    ),
  singleValue: () => cn('text-dark dark:text-white'),
  multiValue: () => cn('!bg-primary !text-white'),
  multiValueLabel: () => cn('!text-white')
};

export function ShareNote() {
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const selectedNotes = useSelector((state: RootState) => state.notes.selectedNotes);
  const contacts = useSelector((state: RootState) => state.notes.contacts) || [];

  // Hooks
  const [openDialogAddUser, setOpenDialogAddUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formDataShareNotes, setFormDataShareNotes] = useState<FormDataShareNotes>({
    note_id: selectedNotes,
    email: [],
    comment: '',
  });

  const [noteToOptions, setNoteToOptions] = useState<Option[]>([]);

  // Filter contacts to only include those with valid emails
  useEffect(() => {
    if (openDialogAddUser) {
      dispatch<any>(getContactsNote());
    }
  }, [openDialogAddUser]);

  // Filter contacts to only include those with valid emails
  useEffect(() => {
    const filteredContacts = contacts.map(({ email, name, id }: ContactsNoteI) => ({ value: id, label: `${name} (${email})` }));

    setNoteToOptions(filteredContacts);
  }, [contacts]);

  // Behavior
  const handleSubmitShareNotes = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    let data = {
      ...formDataShareNotes,
      note_id: selectedNotes
    }

    NoteService.shareNote(data);

    setTimeout(() => {
      toast({
        title: t('notes.yourNoteHasBeenShared'),
        description: ``
      });
      setIsLoading(false);
      setOpenDialogAddUser(false);
      dispatch<any>(resetSelectedNote());
    }, 1000);
  };

  const handleSelectShareNote = (selectedOptions: MultiValue<Option>) => {
    const recipients = selectedOptions.map(option => {
      let contact = contacts.find(({ id }: ContactsNoteI) => id == option.value)
      let data: OptionEmail = {
        id: contact ? contact.id : null,
        email: contact ? contact.email : option.label
      }
      return data;
    });


    setFormDataShareNotes({
      ...formDataShareNotes,
      email: recipients
    });
  };

  return (
    <Dialog
      open={openDialogAddUser}
      onOpenChange={() => setOpenDialogAddUser(!openDialogAddUser)}>
      <DialogTrigger className='flex items-center'>
        <Button variant="ghost" size="icon">
          <Share2 className="h-4 w-4 text-primary" />
        </Button>
        <Button variant="ghost" size="icon" onClick={(e) => {
          e.stopPropagation();
          setOpenDialogAddUser(false);
          dispatch(resetSelectedNote());
        }}>
          <X className="h-4 w-4 text-primary" />
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full max-w-lg">
        <DialogHeader>
          <DialogTitle>{t('notes.shareNotes')}</DialogTitle>
        </DialogHeader>
        <DialogDescription className='font-bold text-xs'>
          {t('notes.descriptionShare')}
        </DialogDescription>
        <DialogDescription>
          {t('notes.youAreSharing').replace("#", selectedNotes.length.toString())}
        </DialogDescription>
        <form onSubmit={handleSubmitShareNotes}>
          <div className="grid gap-4 pb-4">
            <CreatableSelect
              isMulti
              name="shareWith"
              classNames={customClassNames}
              options={noteToOptions}
              classNamePrefix="select"
              placeholder={t('notes.shareWith')}
              onChange={handleSelectShareNote}
              className="w-full"
            />
          </div>

          <Textarea className='mb-3 focus-visible:ring-primary' placeholder={t('notes.comment')} onChange={(e) => {
            e.preventDefault();
            setFormDataShareNotes({
              ...formDataShareNotes,
              comment: e.target.value
            });
          }}></Textarea>

          <DialogFooter>
            <Button type="submit">
              {isLoading ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : t('notes.share')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
