import { create } from 'zustand';

type UpdateCustomField = {
  data: any;
  isOpen: boolean;
  onOpen: (data: any) => void;
  onClose: () => void;
};

export const useUpdateCustomFieldDialog = create<UpdateCustomField>((set) => ({
  data: null,
  isOpen: false,
  onOpen: (data) => set({ data, isOpen: true }),
  onClose: () => set({ data: null, isOpen: false })
}));
