import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Separator } from '@/common/presentation/components/ui/separator';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { RootState } from '@/store/store';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DateSelector } from './DateSelector';
import { EmarPrnSection } from './EmarPrnSection';
import { EmarRoutineSection } from './EmarRoutineSection';
import OverviewTable from './tables/OverviewTable';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useTranslation } from 'react-i18next';

interface EmarMedicationsCardProps {
  locationId?: string;
}

export const EmarMedicationsCard = ({ locationId }: EmarMedicationsCardProps) => {
  const { params, setParam } = useRouteParams();
  const { t } = useTranslation();
  const { locationId: paramLocationId } = useParams<{ locationId: string }>();

  const effectiveLocationId = locationId || paramLocationId || 'all-locations';
  const [date, setDate] = useState<Date>(new Date());
  const { getMedicationsTimesByResident, medications } = useMedicationStore();
  const { resident } = useSelector((state: RootState) => state.residents.resident, shallowEqual);

  const routineMedications = useMemo(() => {
    return medications.filter((med) => med?.medication_type?.name === 'Routine');
  }, [medications]);

  const prnMedications = useMemo(() => {
    return medications.filter((med) => med?.medication_type?.name === 'PRN');
  }, [medications]);

  const formattedDate = useMemo(() => format(date, 'yyyy-MM-dd'), [date]);
  const residentId = params.r ?? 'all-residents';

  const fetchMedications = useCallback(() => {
    getMedicationsTimesByResident(effectiveLocationId as string, residentId, formattedDate);
  }, [getMedicationsTimesByResident, effectiveLocationId, residentId, formattedDate]);

  useEffect(() => {
    fetchMedications();
  }, [fetchMedications]);

  const accordionTitle = useMemo(() => {
    return `Medications for ${residentId === 'all-residents' ? 'All Residents' : resident?.first_name || ''}`;
  }, [residentId, resident]);

  const handleSubTabChange = useCallback(
    (tab: string) => {
      setParam('s', tab);
    },
    [setParam]
  );

  const renderContent = () => {
    if (params.e === 'summary') {
      return (
        <>
          <Tabs value={params.s} onValueChange={handleSubTabChange}>
            <TabsList>
              <TabsTrigger
                value="managerRoutine"
                className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
              >
                {t('Manager Routine')}
              </TabsTrigger>
              <TabsTrigger
                value="managerPRN"
                className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
              >
                {t('Manager PRN')}
              </TabsTrigger>
              <TabsTrigger
                value="exception"
                className="data-[state=active]:bg-primary data-[state=active]:text-white text-gray-700"
              >
                {t('Exception')}
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <OverviewTable />
        </>
      );
    }

    return (
      <>
        <DateSelector onSelectDate={setDate} defaultDate={date} />
        <p>Filter by date and time to view the medications that need to be administered.</p>
        <Separator />
        {params.e === 'routine' && (
          <EmarRoutineSection medications={routineMedications} residentId={residentId} date={date} emar={params.e} />
        )}
        {params.e === 'prn' && (
          <EmarPrnSection medications={prnMedications} residentId={residentId} date={date} emar={params.e} />
        )}
      </>
    );
  };

  return (
    <>
      <CustomAccordion title={accordionTitle} defaultOpen openOption={false}>
        <div className="flex flex-col gap-4">{renderContent()}</div>
      </CustomAccordion>
    </>
  );
};