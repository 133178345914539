import { Label } from '@/common/presentation/components/ui/label';
import { Button } from '@/common/presentation/components/ui/button';
import { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { Input } from '@/common/presentation/components/ui/input';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { RadioGroup, RadioGroupItem } from '@/common/presentation/components/ui/radio-group';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch } from 'react-redux';
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { ToastAction } from '@/common/presentation/components/ui/toast';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

export function HospitalizationOutingsCreateForm() {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const { params, setParam } = useRouteParams();
    const { locationSelectedId } = useLocation();
    const [values, setValues] = useState({
        date: null,
        hospitalName: "",
        HospitalDischargeFullName: "",
        HospitalDischargeTelephone: "",
        hospitalizationReason: "",
        dateBack: null
    });

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleDateChange = (value: any) => {
       setValues({
            ...values,
            date: value
        });
    };

    const handleDateBackChange = (value: any) => {
        setValues({
             ...values,
             dateBack: value
         });
    };

    const cleanInputs = () => {
        setValues({
            date: null,
            hospitalName: "",
            HospitalDischargeFullName: "",
            HospitalDischargeTelephone: "",
            hospitalizationReason: "",
            dateBack: null
        });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(params.r) {
            if(values.date && values.hospitalName && values.HospitalDischargeFullName && values.HospitalDischargeTelephone && values.hospitalizationReason && values.dateBack) {
                const response = await dispatch(trackerActions.saveTrackerOutingsHospitalization(
                    [
                        {
                            "date": values.date.toISOString(),
                            "hospitalName": values.hospitalName,
                            "hospitalSocialWorkerFullName": values.HospitalDischargeFullName,
                            "hospitalSocialWorkerTelephone": values.HospitalDischargeTelephone,
                            "hospitalizationReason": values.hospitalizationReason,
                            "dateBack": values.dateBack.toISOString(),
                            "resident_id": params.r,
                            "location_id": locationSelectedId
                        }
                    ]
                ));
        
                if(response.payload) {
                    toast(
                        {
                            description: "Hospitalization created correctly"
                        }
                    );

                    cleanInputs();
                }
            } else {
                toast(
                    {
                        description: "You must fill out all the requested fields"
                    }
                );
            }
        } else {
            toast(
                {
                    description: "You must select a resident"
                }
            );
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="general-outings-form grid grid-cols-2 gap-4 mt-5">
                <div className="grid grid-cols-1 gap-1">
                    <Label htmlFor={"date"} className="text-right justify-self-start">{"Date/Time"}</Label>

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                            variant={"outline"}
                            className={cn(
                                "w-[280px] justify-start text-left font-normal w-100",
                                !values.date && "text-muted-foreground"
                            )}
                            >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {values.date ? format(values.date, "MM/dd/yyyy - hh:mm aa") : <span>mm/dd/yyyy - hh:mm aa</span>}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                timePicker={true}
                                onChange={handleDateChange}
                            />
                        </PopoverContent>
                    </Popover>
                </div>

                <div className="grid grid-cols-1 self-start gap-1">
                    <Label htmlFor={"hospitalName"} className="text-right justify-self-start">{"Hospital Name"}</Label>

                    <Input
                        id={"hospitalName"}
                        name={"hospitalName"}
                        type="text"
                        value={values.hospitalName}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>
                
                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"HospitalDischargeFullName"} className="text-right justify-self-start">{"Hospital Social worker/discharge planner full name"}</Label>

                    <Input
                        id={"HospitalDischargeFullName"}
                        name={"HospitalDischargeFullName"}
                        type="text"
                        value={values.HospitalDischargeFullName}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"HospitalDischargeTelephone"} className="text-right justify-self-start">{"Hospital Social worker/discharge planner Telephone"}</Label>

                    <Input
                        id={"HospitalDischargeTelephone"}
                        name={"HospitalDischargeTelephone"}
                        type="text"
                        value={values.HospitalDischargeTelephone}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"hospitalizationReason"} className="text-right justify-self-start">{"Reason for Hospitalization"}</Label>

                    <Input
                        id={"hospitalizationReason"}
                        name={"hospitalizationReason"}
                        type="text"
                        value={values.hospitalizationReason}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"dateBack"} className="text-right justify-self-start">{"Date"}</Label>

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                            variant={"outline"}
                            className={cn(
                                "w-[280px] justify-start text-left font-normal w-100",
                                !values.dateBack && "text-muted-foreground"
                            )}
                            >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {values.dateBack ? format(values.dateBack, "MM/dd/yyyy - hh:mm aa") : <span>mm/dd/yyyy - hh:mm aa</span>}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                timePicker={true}
                                onChange={handleDateBackChange}
                            />
                        </PopoverContent>
                    </Popover>
                </div>
            </div>

            <div className="w-100 mt-3 flex flex-row justify-end">
                <Button type="submit" className="mr-2">Save</Button>
            </div>
        </form>
    );
} 
