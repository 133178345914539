import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { FormValues } from '@/modules/staff/presentation/components/updateStaff/forms/LoginInformation';

const LoginInformationService = {
  updateStaffloginForm: async (data: FormValues, staff_id: string) => {
    const response = await axios.put(`/staff/${staff_id}/login`, { ...data });
    return response;
  }
};

export default LoginInformationService;
