import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { useTranslation } from 'react-i18next';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { format } from "date-fns";
import { Button } from '@/common/presentation/components/ui/button';
import { Pencil, Trash, Calendar as CalendarIcon } from 'lucide-react';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Input } from '@/common/presentation/components/ui/input';
import { updateTrackerNapSleep, deleteTrackerNapSleep } from "../../slices/TrackerSleepSlice";
import { TrackerI } from '../../../infrastructure/interfaces/SleepInterface';

interface TrackerSleepNapSummaryProps {
    dataNapReport: TrackerI[];
    isNapLoading: boolean;
    loadMore: () => void;
    hasMore: boolean;
    dataSleepNapReport: (page: number, rowsPerPage: number, resetData: boolean) => void;
    resident: string;
    rowsPerPage: number;
}


export function TrackerSleepNapSummary({ dataNapReport, isNapLoading, dataSleepNapReport, resident, loadMore,
    hasMore, rowsPerPage }: TrackerSleepNapSummaryProps) {

    const { t } = useTranslation();
    const { formatDate } = useDateHelpers();
    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };
    const [showEditNap, setShowEditNap] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<TrackerI | null>(null);
    const parsedStartUp = selectedRow?.data.start_up ? new Date(selectedRow.data.start_up) : new Date(); // Fecha por defecto si no hay fecha
    const formattedStartUp = format(parsedStartUp, "dd/MM/yyyy hh:mm a");
    const parsedEndUp = selectedRow?.data.end_up ? new Date(selectedRow.data.end_up) : new Date(); // Fecha por defecto si no hay fecha
    const formattedEndUp = format(parsedEndUp, "dd/MM/yyyy hh:mm a");
    const dispatch = useDispatch<AppDispatch>();
    const [isSubmitForm, setIsSubmitForm] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');

    const renderActions = (row: TrackerI) => (
        <div className="flex space-x-2">

            <span className="cursor-pointer" onClick={() => handleOpenModal(row, 'edit')}>
                <Pencil className="mr-2 h-4 w-4" />
            </span>

            <span className="cursor-pointer" onClick={() => handleOpenModal(row, 'delete')}>
                <Trash className="mr-2 h-4 w-4" />
            </span>

        </div>
    );
    const columnsreport = [
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name', sortable: true },
        { key: 'start_up', labelTranslationKey: t('trackers.trackerSleep.startUp'), value: 'start_up', sortable: true },
        { key: 'end_up', labelTranslationKey: t('trackers.trackerSleep.endUp'), value: 'end_up', sortable: true },
        { key: 'detail', labelTranslationKey: t('trackers.Detail'), value: 'detail', sortable: true },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: (row: TrackerI) => renderActions(row)

        },
    ];

    const handleOpenModal = (rowData: TrackerI, actionType: 'edit' | 'delete') => {
        setSelectedRow(rowData)
        setShowEditNap(true)
        setAction(actionType)
    }

    const handleDelete = async () => {
        try {
            await dispatch(deleteTrackerNapSleep(selectedRow!.id))
            handleCloseModal()
            dataSleepNapReport(1, rowsPerPage, true)
        } catch (error) {
            console.log(error)
        }
    }


    const handleCloseModal = () => {
        setShowEditNap(false)
    }

    const handleStartUpChange = (date: Date) => {
        setSelectedRow((prevState: TrackerI | null) => {
            if (prevState) {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        start_up: date.toISOString()
                    }
                };
            }
            return prevState;
        });
    };

    const handleEndUpChange = (date: Date) => {
        setSelectedRow((prevState: TrackerI | null) => {
            if (prevState) {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        end_up: date.toISOString()
                    }
                };
            }
            return prevState;
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSelectedRow((prevState: TrackerI | null) => {
            if (prevState) {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        [name]: value,
                    }
                };
            }
            return prevState;
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!selectedRow || !selectedRow.data.detail) {
            alert("El campo Detail es obligatorio");
            return;
        }

        try {
            setIsSubmitForm(true);
            const { id } = selectedRow;
            const { start_up, end_up, detail } = selectedRow.data;

            const updateData = {
                id,
                start_up,
                end_up,
                detail
            };
            await dispatch(updateTrackerNapSleep(updateData));
            handleCloseModal();
            setIsSubmitForm(false);
            dataSleepNapReport(1, rowsPerPage, true);
        } catch (error) {
            console.error('Error actualizando el tracker sleep:', error);
            setIsSubmitForm(false);
        }
    };

    return (

        <div>
            <TableCustomVirtuoso
                data={dataNapReport}
                columns={columnsreport}
                renderCellContent={(index, column, data) => {
                    const row = data[index];
                    switch (column.key) {
                        case 'resident_name':
                            return `${capitalizeWords(row.data.resident.first_name)} ${capitalizeWords(row.data.resident.last_name)}`;

                        case 'start_up':

                            return (
                                <>
                                    {formatDate(row.data.start_up, false)}
                                    <br />
                                    {format(new Date(row.data.start_up), "hh:mm a")}
                                </>
                            );

                        case 'end_up':

                            return (
                                <>
                                    {formatDate(row.data.end_up, false)}
                                    <br />
                                    {format(new Date(row.data.end_up), "hh:mm a")}
                                </>
                            );


                        case 'detail':
                            return row.data.detail ? capitalizeWords(row.data.detail) : '';

                        default:
                            return row[column.key];
                    }
                }}
                additionalButtons={<></>}
                isLoading={isNapLoading}
                key={resident}
                loadMore={loadMore}
                hasMore={hasMore}

            />


            <CustomDialog
                newDialog={true}
                isOpen={showEditNap}
                title={action === 'edit' ? t('notes.edit') : t('trackers.Delete')}
                onClose={handleCloseModal}
            >
                <div>
                    {action === 'edit' ? (
                        <form onSubmit={handleSubmit}>
                            <div className="grid gap-4">
                                <Label htmlFor="tracker_sleep_date" className="control-label mb-3">
                                    {t('trackers.trackerSleep.startUp')}
                                </Label>
                                <div className="input-group">
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                {formattedStartUp}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-auto p-0">
                                            <CustomCalendar
                                                onChange={handleStartUpChange}
                                                selectedDate={parsedStartUp}
                                                MonthAndYearPicker={true}
                                                timePicker={true}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                            <div className="grid gap-4">
                                <Label htmlFor="tracker_sleep_date" className="control-label mb-3 mt-4">
                                    {t('trackers.trackerSleep.endUp')}
                                </Label>
                                <div className="input-group">
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                {formattedEndUp}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-auto p-0">
                                            <CustomCalendar
                                                onChange={handleEndUpChange}
                                                selectedDate={parsedEndUp}
                                                MonthAndYearPicker={true}
                                                timePicker={true}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                            <div className="grid gap-4">
                                <Label htmlFor="detail" className="control-label mb-3 mt-4">
                                    {t('trackers.Detail')}
                                </Label>
                                <div className="input-group">
                                    <Input
                                        className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                                        id="detail"
                                        name="detail"
                                        type="text"
                                        value={selectedRow?.data.detail}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="w-full flex justify-end mt-4">
                                <Button type="submit"
                                    className="bg-primary text-white px-4 py-2 rounded"
                                    disabled={isSubmitForm}>
                                    {t('trackers.Save')}
                                </Button>
                            </div>
                        </form>
                    ) : (
                        <div>
                            <div className='flex justify-center'>
                                <h2>{t('trackers.sureDelete')}</h2>
                            </div>

                            <div className="w-full flex justify-end mt-5">
                                <Button onClick={handleDelete} className="bg-red-600 text-white px-4 py-2 rounded">
                                    {t('trackers.Delete')}
                                </Button>
                                <Button onClick={handleCloseModal} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
                                    {t('calendar.cancel')}
                                </Button>
                            </div>
                        </div>

                    )}
                </div>
            </CustomDialog>
        </div >

    )

}