import LoginInformationService from '@/modules/staff/infrastructure/services/LoginInformation';
import { FormValues } from '@/modules/staff/presentation/components/updateStaff/forms/LoginInformation';
import { create } from 'zustand';

type LoginInformation = {
  passwordVisible: boolean;
  status: 'idle' | 'loading' | 'failed';
  error: any | null;

  setPasswordVisible: (value: boolean) => void;
  updateStaffloginForm: (data: FormValues, staff_id: string) => Promise<any>;
};

export const useLoginInformationStore = create<LoginInformation>((set) => ({
  passwordVisible: false,
  status: 'idle',
  error: null,

  setPasswordVisible: (value) => set({ passwordVisible: value }),

  updateStaffloginForm: async (data, staff_id) => {
    set({ status: 'loading' });
    try {
      const response = await LoginInformationService.updateStaffloginForm(data, staff_id);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error?.response?.data || error.message });
      throw new Error(error?.response?.data?.message || error.message);
    }
  }
}));
