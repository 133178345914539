import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
 import { Label } from '@/common/presentation/components/ui/label';
 import { Input } from '@/common/presentation/components/ui/input';
import { CalendarIcon, Pencil } from 'lucide-react';
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import { useDispatch } from 'react-redux';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { t } from 'i18next';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

export function HospitalizationOutingsEditDialog(props: any) {
    const dispatch = useDispatch();
    const { params, setParam } = useRouteParams();
    const { locationSelectedId } = useLocation();
    const [values, setValues] = useState({
        hospitalName: "",
        hospitalSocialWorkerFullName: "",
        hospitalSocialWorkerTelephone: "",
        hospitalizationReason: "",
        dateBack: new Date()
    });
    const [openEdit, setOpenEdit] = useState(false);

    useEffect(() => {
        const data = props.data;

        setValues({
            hospitalName: data.data.hospitalName,
            hospitalSocialWorkerFullName: data.data.hospitalSocialWorkerFullName,
            hospitalSocialWorkerTelephone: data.data.hospitalSocialWorkerTelephone,
            hospitalizationReason: data.data.hospitalizationReason,
            dateBack: new Date(data.data.dateBack)
        });
    }, []);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleDateBackChange = (value: any) => {
        setValues({
             ...values,
             dateBack: value
         });
    };

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = () => {
        dispatch(trackerActions.updateTrackerOutingsHospitalization(
            {
                id: props.data.id,
                id_data: props.data.data.id,
                data: {
                    ...values,
                    dateBack: values.dateBack.toISOString()
                }
            }
        ));

        fetchData();

        return true;
    }

    const fetchData = () => {
        dispatch(trackerActions.getTrackerOutingsHospitalization(
            {
                location_id: locationSelectedId
            }
        ));
    };

    return (
        <div>
            <Button onClick={() => openEditDialog()} variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.edit.title")}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onSubmit={() => handleEdit()}
                onClose={closeEditDialog}
            >
                <div>
                    <Label htmlFor="hospitalName" className="text-right">Hospital Name</Label>

                    <Input
                        id="hospitalName"
                        name="hospitalName"
                        defaultValue="@peduarte"
                        className="col-span-3"
                        value={values.hospitalName}
                        onChange={handleInputChange}
                    />
                </div>

                <div>
                    <Label htmlFor="hospitalSocialWorkerFullName" className="text-right">Hospital Social worker/discharge planner full name</Label>

                    <Input
                        id="hospitalSocialWorkerFullName"
                        name="hospitalSocialWorkerFullName"
                        defaultValue="@peduarte"
                        className="col-span-3"
                        value={values.hospitalSocialWorkerFullName}
                        onChange={handleInputChange}
                    />
                </div>

                <div>
                    <Label htmlFor="hospitalSocialWorkerTelephone" className="text-right">Hospital Social worker/discharge planner Telephone</Label>

                    <Input
                        id="hospitalSocialWorkerTelephone"
                        name="hospitalSocialWorkerTelephone"
                        defaultValue="@peduarte"
                        className="col-span-3"
                        value={values.hospitalSocialWorkerTelephone}
                        onChange={handleInputChange}
                    />
                </div>

                <div>
                    <Label htmlFor="hospitalizationReason" className="text-right">Reason for Hospitalization</Label>

                    <Input
                        id="hospitalizationReason"
                        name="hospitalizationReason"
                        defaultValue="@peduarte"
                        className="col-span-3"
                        value={values.hospitalizationReason}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-4">
                    <Label htmlFor={"dateBack"} className="text-left">{"Date Back To The Facility"}</Label>

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                            variant={"outline"}
                            className={cn(
                                "w-[280px] justify-start text-left font-normal w-100",
                                !values.dateBack && "text-muted-foreground"
                            )}
                            >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {values.dateBack ? format(values.dateBack, "MM/dd/yyyy - hh:mm aa") : <span>Pick a date</span>}
                            </Button>
                        </PopoverTrigger>

                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                            value={values.dateBack}
                            timePicker={true}
                            onChange={handleDateBackChange}
                            />
                        </PopoverContent>
                    </Popover>
                </div>
            </CustomDialog>
        </div>
    );
};
