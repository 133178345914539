import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { cn } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/common/presentation/components/ui/button';
import { ArrowUpDown } from 'lucide-react';
import { TableActions } from '@/modules/staff/presentation/components/dashboard/tables/StaffTableActions';
import { parseISO } from 'date-fns';

export const getColumns = (isAdmin: boolean): ColumnDef<any>[] => [
  {
    header: 'Profile Picture',
    cell: ({ row }) => (
      <div className={cn('overflow-hidden rounded-full w-full flex justify-center')}>
        <Avatar className={cn('rounded-md transition-all hover:scale-105', `w-16 h-16 rounded-full`)}>
          <AvatarImage
            src={row.original.profile_url}
            alt={`${row.original.first_name} ${row.original.last_name}`}
            className="h-full w-full object-cover"
          />
          <AvatarFallback className={cn('bg-secondary text-sm rounded-md', 'bg-secondary text-sm')}>
            {row.original.first_name[0] + row.original.last_name[0]}
          </AvatarFallback>
        </Avatar>
      </div>
    )
  },
  {
    accessorKey: 'first_name',
    header: ({ column }) => (
      <Button
        variant="ghost"
        className="hover:bg-transparent/5 hover:text-primary-50 text-[16px]"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Name
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => `${row.original.first_name} ${row.original.middle_name || ''}`
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name'
  },
  {
    accessorKey: 'dob',
    header: 'Date of Birth',
    cell: ({ row }) => (row.original.dob && parseISO(row.original.dob).toLocaleDateString()) || 'Not Specified'
  },

  ...(isAdmin
    ? [
        {
          accessorKey: 'hire_date',
          header: 'Hire Date',
          cell: ({ row }: any) =>
            (row.original.hire_date && parseISO(row.original.hire_date).toLocaleDateString()) || 'Not Specified'
        },
        {
          id: 'actions',
          cell: ({ row }: any) => <TableActions id={row.original.id} />
        }
      ]
    : [])
];
