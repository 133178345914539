import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const PharmacyService = {
  getPharmacies: async () => {
    try {
      const response = await axios.get(`pharmacys/listpharmacies`);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error list pharmacies: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error fetching pharmacies: ' + error);
    }
  },

  getPharmacyById: async (pharmacyId: string) => {
    try {
      const response = await axios.get(`pharmacys/getpharmacy/${pharmacyId}`);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error not pharmacy: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error fetching pharmacy: ' + error);
    }
  },

  getPharmacy: async (locationId: string) => {
    try {
      const response = await axios.get(`pharmacys/listpharmacy?location_id=${locationId}`);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error list pharmacies: No data returned from server');
      }
    } catch (error) {
      throw new Error('Error fetching pharmacies: ' + error);
    }
  },

  createPharmacy: async (formData: any) => {
    try {
      const response = await axios.post(`pharmacys/createpharmacy`, formData);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating pharmacy: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating pharmacy: ' + error.message);
    }
  },

  createNewPharmacy: async (formData: any) => {
    try {
      const response = await axios.post(`pharmacys/createnewpharmacy`, formData);

      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error creating pharmacy: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error creating pharmacy: ' + error.message);
    }
  },
  getPharmacyByLocation: async (locationId: string, residentId?: string|null) => {
    try {
      let url = `pharmacys/${locationId}`;
      if (residentId) {
        url += `/${residentId}`;
      }
      const response = await axios.get(url);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error('Error getting pharmacies: No data returned from server');
      }
    } catch (error: any) {
      throw new Error('Error getting pharmacies: ' + error.message);
    }
  }
};

export default PharmacyService;
