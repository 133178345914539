
import { DeviceI } from '@/modules/security/domain/device.domain';
import { Badge } from '@/common/presentation/components/ui/badge';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

const LocationsBadge = ({ device }: { device: DeviceI }) => (
    <div className="flex flex-wrap -mx-1 -my-1">
      {device.locations.map((location) => (
        <Badge
          className="items-center align-middle mx-1 my-1 border text-primary border-primary text-center hover:!text-background bg-background cursor-pointer"
          key={location.id}
        >
          <Avatar className="mr-2 h-6 w-6">
            <AvatarImage src={location.image_path} className="h-full w-full object-cover" />
            <AvatarFallback className="font-normal text-black dark:text-white">
              <div className="text-xs">
                {location.name
                  .split(' ')
                  .map((chunk: string) => chunk[0])
                  .join('')}
              </div>
            </AvatarFallback>
          </Avatar>
          {location.name}
        </Badge>
      ))}
    </div>
  );


export default LocationsBadge;
