import { ColumnDef } from '@tanstack/react-table';
import { HistoryTableActions } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/HistoryTableActions';
import { format } from 'date-fns';
import { Button } from '@/common/presentation/components/ui/button';
import { useFormsStore } from '@/modules/residents/domain/stores/forms/formsStore';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';

export const HistoryTableColumns: ColumnDef<any>[] = [
  {
    header: 'Date',
    cell: ({ row }) => format(new Date(row.original.created_at), 'MM/dd/yyyy hh:mm a')
  },
  // {
  //   header: 'PDF',
  //   cell: ({ row }) => {
  //     const { generateHistoryPdf, status: formsStatus } = useFormsStore();
  //     const handleClick = async () => {
  //       try {
  //         const response = await generateHistoryPdf(row.original.id);

  //         const blob = new Blob([response], { type: 'application/pdf' });
  //         const url = URL.createObjectURL(blob);
  //         window.open(url); // Abre el PDF en una nueva pestaña

  //         setTimeout(() => {
  //           URL.revokeObjectURL(url);
  //         }, 100);
  //       } catch (error) {
  //         toast({
  //           description: 'Error generating PDF',
  //           variant: 'destructive'
  //         });
  //       }
  //     };

  //     return (
  //       <Button
  //         variant={'link'}
  //         onClick={handleClick}
  //         disabled={formsStatus === 'loading'}
  //         className={cn(formsStatus === 'loading' && 'opacity-50 cursor-wait')}
  //       >
  //         LIC 625 Appraisal Needs and Services Plan
  //       </Button>
  //     );
  //   }
  // },
  {
    header: 'Staff',
    cell: ({ row }) => {
      return (
        <div>
          {row.original.created_by?.first_name} {row.original.created_by?.last_name}
        </div>
      );
    }
  },
  {
    accessorKey: 'actions',
    header: () => {
      return <div className="w-12">Actions</div>;
    },
    cell: ({ row }) => <HistoryTableActions id={row.original.id} />
  }
];
