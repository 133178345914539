import { LoadingComponent } from '@/common/presentation/components/Loading/LoadingComponent';
import { Button } from '@/common/presentation/components/ui/button';
import { Dialog, DialogContent, DialogHeader } from '@/common/presentation/components/ui/dialog';
import { useCustomFieldsStore } from '@/modules/staff/domain/stores/updateStaff/customFields';
import { useAddCustomFieldDialog } from '@/modules/staff/infrastructure/hooks/updateStaff/addCustomField';
import { useManageCustomFieldsDialog } from '@/modules/staff/infrastructure/hooks/updateStaff/manageCustomFields';
import { useEffect, useMemo } from 'react';
import { CustomFieldItem } from '../customFieldItem';

export const ManageCustomFieldsDialog = () => {
  const { isOpen, onClose } = useManageCustomFieldsDialog();
  const { onOpen: openAddCustomFieldDialog } = useAddCustomFieldDialog();
  const { custom_fields, getCustomFields, status: customFieldsStatus } = useCustomFieldsStore();

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  const fieldsOrdered = useMemo(() => {
    if (!custom_fields) return [];

    return Object.entries(custom_fields)
      .map(([key, field]: any) => ({ key, ...field }))
      .sort((a, b) => a.order - b.order);
  }, [custom_fields]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader className="flex flex-row items-center justify-between gap-4 px-2">
            <h2 className="text-center text-lg font-semibold">Manage Custom Fields</h2>
            <Button className="text-xs" variant={'default'} size="sm" onClick={openAddCustomFieldDialog}>
              Add Custom Field
            </Button>
          </DialogHeader>

          {customFieldsStatus === 'loading' && (
            <LoadingComponent status={customFieldsStatus} label="Loading custom fields" />
          )}

          {customFieldsStatus === 'failed' && <p>Failed to load custom fields</p>}

          {customFieldsStatus === 'idle' && (
            <div className="grid grid-cols-1 gap-4">
              {fieldsOrdered.map((field) => (
                <CustomFieldItem key={field.key} field={field} />
              ))}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
