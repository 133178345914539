import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const TrackerConfiguration = {

  update: async (formData: any) => {
    try {
      const response = await axios.post(`trackerConfiguration/update`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error update enabled trackers: ${error.message}`);
    }
  },
  getEnabledTrackers: async (locationId: string) => {
    try {
      const { data } = await axios.get(`trackerConfiguration/getEnabledTrackers/${locationId}`);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get enabled trackers: ${error.message}`);
    }
  }

}

export default TrackerConfiguration;