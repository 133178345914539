import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';
import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { useSettings } from '@/modules/settings/infrastructure/hooks/use-settings';
import { RootState } from '@/store/store';
import { LogOut, Settings } from 'lucide-react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

export const UserItem = () => {
  const { logout } = useContext(AuthContext);
  const user = useSelector((state: RootState) => state.auth.user);
  const { onOpen } = useSettings();

  const handleLogout = () => {
    logout();
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div role="button" className="flex items-center text-sm md:px-2 mx-1 py-1 my-1 rounded-sm">
          <div className="gap-x-2 flex items-center max-w-[150px]">
            <Avatar className="h-8 w-8">
              <AvatarImage
                src={user?.profile_url ? user?.profile_url : undefined}
                className="h-full w-full object-cover"
                alt="User Image"
              />
              <AvatarFallback className="bg-secondary text-sm">
                {user?.first_name?.charAt(0)}
                {user?.last_name?.charAt(0)}
              </AvatarFallback>
            </Avatar>
            <span className="text-start font-medium line-clamp-1 text-white hidden md:block">
              {user?.first_name} {user?.last_name}
            </span>
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[280px] md:w-80" align="start" alignOffset={11}>
        <div className="flex flex-col space-y-4 p-2">
          <p className="text-xs font-medium leading-none text-muted-foreground">
            {user?.username} &middot; {user?.email}
          </p>
          <div className="flex items-center gap-x-2">
            <div className="rounded-md bg-background p-1">
              <Avatar className="border-2 border-background ring-0">
                <AvatarImage
                  src={user?.profile_url ? user?.profile_url : undefined}
                  alt="User Image"
                  className="h-full w-full object-cover"
                />
                <AvatarFallback className="bg-primary/20 text-sm">
                  {user?.first_name?.charAt(0)}
                  {user?.last_name?.charAt(0)}
                </AvatarFallback>
              </Avatar>
            </div>
            <div className="space-y-1 flex-1">
              <p className="text-sm line-clamp-1">
                {user?.first_name} {user?.middle_name} {user?.last_name}
              </p>
            </div>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};