import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  fetchResidentMedicationById,
  fetchTypes,
  getResidentMedicationTime,
  editResidentMedicationById,
  fetchForms
} from '../../slices/medicationsSlice';
import { RootState } from '@/store/store';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { ArrowLeft, Workflow } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CustomImages } from '../CustomImages';
import MedicationsOptional from './MedicationOptional';
import MedicationFormSelect from './MedicationFormSelect';
import ChartRX from './ChartRX';
import { useTranslation } from 'react-i18next';
import { websocket } from '@/utils/helpers/websocket.helper';
import columnsChart from './columnChartRX';
import { Badge } from '@/common/presentation/components/ui/badge';
import LastDispensing from './LastDispensing';
import { Switch } from '@/common/presentation/components/ui/switch';
import { useCustomToast } from '@/common/presentation/components/CustomToast/CustomToast';

const MedicationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { medicationId } = useParams();
  const [listSelectForm, setlistSelectForm] = useState<string[]>([]);
  const [residentId, setResidentId] = useState(null);
  const [currentListForm, setCurrentListForm] = useState([]);
  const [isDiscontinued, setIsDiscontinued] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast } = useCustomToast();

  const { types, residentMedicationTime, medicationsResident, forms, status } = useSelector(
    (state: RootState) => state.medications
  );

  const { control, handleSubmit, setValue, reset, watch, getValues } = useForm({
    defaultValues: useMemo(
      () => ({
        medication: '',
        form: [],
        strength: '',
        purpose: '',
        not_exceed: '',
        frequency: '',
        instruction: '',
        special: '',
        date_prescribed: '',
        current_quantity: 0,
        minimum_quantity: 0,
        units_per_dose: 0,
        type: '',
        location_type_id: '',
        responsible_administrations_id: '',
        routes_medication_id: '',
        resident_id: '',
        status: '',
        typeName: '',
        selectedMedication: {} as [],
        special_days: [],
        location_id: '',
        chart: [],
        physician_id: '',
        prescriber_npi: '',
        physician_typeName: '',
        physician_type_id: '',
        content_notes: '',
        pharmacy_id: '',
        time: [],
        images: []
      }),
      []
    )
  });

  const pharmacyId = watch('pharmacy_id');
  const chartData = watch('chart');
  const strength = watch('strength');

  const [images, setImages] = useState<File[]>([]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [typeName, setTypeName] = useState('');

  const handleImageChange = useCallback(
    (newFiles: File[]) => {
      setImages(newFiles);
      setValue('images', newFiles);
    },
    [setValue]
  );

  const handleSelectedTimesChange = useCallback(
    (selectedTimes: string[]) => {
      setSelectedTimes(selectedTimes);
      setValue('time', selectedTimes);
    },
    [setValue]
  );

  const handleSelect = useCallback(
    (option: any) => {
      setValue('medication_id', option.id);
      setValue('form', option.forms || []);
      setValue('strength', option.strengths || []);
    },
    [setValue]
  );

  const handleBackClick = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    if (medicationId) {
      dispatch(fetchResidentMedicationById(medicationId)).then((response) => {
        if (response.payload) {
          const fetchedResidentId = response.payload.resident_id;
          setResidentId(fetchedResidentId);

          dispatch(fetchTypes({ locationId: medicationsResident.location_id }));
          dispatch(fetchForms());
        }
      });
    }
  }, [medicationId]);

  useEffect(() => {
    if (residentId) {
      dispatch(getResidentMedicationTime(residentId));
    }
  }, [residentId, dispatch]);

  useEffect(() => {
    const handleSocketMessage = (data) => {
      if (data?.message?.chart) {
        const updatedChartData = data.message.chart.map((item) => ({
          ...item,
          status: item.status
        }));
        setValue('chart', updatedChartData);
      }
    };

    const socket = websocket.subscribe(`resident-medication-channel-${medicationId}`, handleSocketMessage);

    return () => {
      if (websocket.unsubscribe) {
        websocket.unsubscribe(socket);
      }
    };
  }, [medicationId, setValue]);

  useEffect(() => {
    if (medicationsResident) {
      const upperCaseForm = medicationsResident?.form ? medicationsResident.form.toUpperCase() : null;
      const listForm = forms.find((form) => form.name === upperCaseForm);

      let updatedListSelectForm;
      if (listForm) {
        updatedListSelectForm = forms;
        setCurrentListForm(listForm);
      } else {
        updatedListSelectForm = medicationsResident?.medication?.form
          ? medicationsResident?.medication?.form
              .toLowerCase()
              .split(',')
              .map((item) => item.trim())
          : [];
      }

      setlistSelectForm(updatedListSelectForm);

      const hasActiveStatus = medicationsResident.chart?.some((item) => item.status === true);
      setIsDiscontinued(!hasActiveStatus);

      reset({
        ...medicationsResident,
        medication: medicationsResident.medication?.name?.toUpperCase() || '',
        type: medicationsResident?.type_medication_id || '',
        typeName: medicationsResident?.location_type_medication?.type_medication?.name || '',
        location_type_id: medicationsResident.location_type_id || '',
        responsible_administrations_id: medicationsResident.responsible_administrations_id || '',
        routes_medication_id: medicationsResident.routes_medication_id || '',
        special_days: medicationsResident.special_days || [],
        location_id: medicationsResident.location_id || '',
        chart: medicationsResident.chart || [],
        physician_id: medicationsResident.physician?.id || '',
        prescriber_npi: medicationsResident.physician?.npi || '',
        physician_type_id: medicationsResident.physician?.physician_type_id || '',
        physician_typeName: medicationsResident.physician_type || '',
        content_notes: medicationsResident.notes?.content || '',
        pharmacy_id: medicationsResident.pharmacy?.id || '',
        time: medicationsResident.time || [],
        date_prescribed: medicationsResident.date_prescribed || '',
        purpose: medicationsResident.purpose || '',
        minimum_quantity: medicationsResident.minimum_quantity || 0,
        form: listForm ? medicationsResident.form : medicationsResident.form?.toLowerCase(),
        strength: medicationsResident?.strength,
        images: medicationsResident?.images
      });
    }
  }, [medicationsResident, reset]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const formData = new FormData();

      // Aquí se asume que `responsible_administrations_id` debería ser un array
      const responsibleAdministrations = Array.isArray(data.responsible_administrations_id)
        ? data.responsible_administrations_id
        : JSON.parse(data.responsible_administrations_id || '[]');

      const sanitizedData = {
        ...data,
        responsible_administrations_id: responsibleAdministrations // Asegúrate de que esto sea un array
      };

      if (images.length > 0) {
        images.forEach((file, index) => {
          formData.append(`images[${index}]`, file);
        });
      }

      delete sanitizedData.images;
      formData.append('data', JSON.stringify(sanitizedData));

      const response = await dispatch(
        editResidentMedicationById({
          id: medicationId,
          data: formData
        })
      );

      if (!response.error) {
        showToast(
          'success',
          t('medications.medicationDetail.success'),
          t('medications.medicationDetail.medicineUpdated')
        );
        await dispatch(fetchResidentMedicationById(medicationId));
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      console.error('Error updating data:', error);
      showToast('error', t('medications.medicationDetail.error'), t('medications.medicationDetail.medicineUpdated'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const getDefaultRow = () => {
    const defaultRow = {};
    columnsChart.forEach((column) => {
      switch (column.type) {
        case 'checkbox':
          defaultRow[column.value] = false;
          break;
        case 'calendar':
          defaultRow[column.value] = '';
          break;
        case 'number':
          defaultRow[column.value] = 0;
          break;
        default:
          defaultRow[column.value] = '';
      }
    });
    return defaultRow;
  };

  const handleAddRow = useCallback(() => {
    const newRow = getDefaultRow();
    const updatedChart = [newRow, ...chartData];
    setValue('chart', updatedChart);
  }, [chartData, setValue]);

  useEffect(() => {
    if (medicationsResident?.chart) {
      const hasActiveStatus = medicationsResident.chart.some((item) => item.status === true);
      setIsDiscontinued(!hasActiveStatus);
    }
  }, [medicationsResident?.chart]);

  const handleDiscontinueChange = useCallback(
    async (checked) => {
      setIsDiscontinued(checked);

      if (checked) {
        const currentDate = new Date().toISOString().split('T')[0];
        const updatedChart = (medicationsResident.chart || []).map((item) => ({
          ...item,
          status: false,
          cycle_end_date: item.cycle_end_date || currentDate
        }));

        const updatedData = {
          ...medicationsResident,
          chart: updatedChart
        };

        const formData = new FormData();
        formData.append('data', JSON.stringify(updatedData));

        try {
          const response = await dispatch(editResidentMedicationById({ id: medicationId, data: formData }));

          if (!response.error) {
            showToast(
              'success',
              t('medications.medicationDetail.success'),
              t('medications.medicationDetail.medicineUpdated')
            );
            await dispatch(fetchResidentMedicationById(medicationId));
          }
        } catch (error) {
          showToast('error', t('medications.medicationDetail.error'), t('Error al actualizar'));

          setIsDiscontinued(false);
        }
      }
    },
    [medicationsResident, medicationId, dispatch]
  );

  return (
    <div className="gap-4 my-4">
      <div>
        <div className="flex flex-col gap-4 my-4">
          <div className="flex items-center mb-4">
            <Button variant="ghost" onClick={handleBackClick}>
              <ArrowLeft className="mr-2 h-4 w-4" />
              {t('medications.back')}
            </Button>
            <div className="text-xl text-primary font-semibold ml-4">{t('medications.detailsTitle')}</div>
          </div>
          <CustomAccordion
            defaultOpen
            titleComponent={
              <div className="flex items-center">
                <span className="text-xl text-primary font-semibold mr-2">Medications</span>
                <Switch
                  checked={isDiscontinued}
                  onCheckedChange={handleDiscontinueChange}
                  disabled={isDiscontinued}
                  className="data-[state=checked]:bg-red-300 relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-200 ease-in-out"
                >
                  <span
                    className={`inline-block w-4 h-4 transform transition-transform duration-200 ease-in-out rounded-full bg-white ${
                      isDiscontinued ? 'translate-x-5' : 'translate-x-1'
                    }`}
                  />
                </Switch>
                <span className={`ml-2 font-semibold ${isDiscontinued ? 'text-red-400' : 'text-primary'}`}>
                  {isDiscontinued ? t('medications.discontinued') : t('medications.discontinuedActive')}
                </span>
              </div>
            }
          >
            <MedicationFormSelect
              control={control}
              handleSelect={handleSelect}
              types={types}
              selectedTimes={watch('time')}
              handleSelectedTimesChange={handleSelectedTimesChange}
              handleSelectedTypeChange={setTypeName}
              typeName={watch('typeName')}
              selectedListForm={listSelectForm || []}
              medicationTimes={residentMedicationTime}
              setValue={setValue}
              strength={strength}
            />
            <div className="flex justify-end p-6">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>
          <CustomAccordion
            title="Medication Information"
            description="Additional details about the prescribing physician and pharmacy."
          >
            {medicationsResident && (
              <MedicationsOptional
                residentId={medicationsResident.resident_id}
                locationId={medicationsResident.location_id}
                control={control}
                handleSubmit={handleSubmit}
                setValue={setValue}
                watch={watch}
              />
            )}
            <div className="flex justify-end p-6">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>
          <CustomAccordion title="RX Chart" description="Additional details about the medication.">
            <div className="max-h-96 overflow-y-auto">
              {' '}
              <ChartRX
                control={control}
                fields={chartData}
                onAddRow={handleAddRow}
                setValue={setValue}
                typeName={medicationsResident?.typeName}
              />
            </div>
            <div className="flex justify-end p-6">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>

          <CustomAccordion title="Images" description="Additional notes for the medication.">
            <div className="mb-4 max-h-96 overflow-y-auto">
              <CustomImages
                onChange={handleImageChange}
                control={control}
                name="images"
                initialImages={medicationsResident?.images || []}
              />
            </div>

            <div className="flex justify-end p-6">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>

          {medicationsResident?.is_from_pharmacy && medicationsResident?.is_from_pharmacy == 'true' && (
            <CustomAccordion
              titleComponent={
                <div className="flex flex-row justify-between gap-2 items-center">
                  <span className="text-xl text-primary font-semibold">
                    {t('medications.lastDispensingInformation')}
                  </span>
                  <Badge variant="outline" className="h-7 text-xs text-primary border-primary">
                    <Workflow size={15} />
                    {t('medications.integration')}
                  </Badge>
                </div>
              }
            >
              <LastDispensing
                status={status === 'loading' || medicationsResident.length === 0}
                dispensingHistorical={medicationsResident?.last_time_dispensing ?? []}
              />
            </CustomAccordion>
          )}
        </div>
      </div>
    </div>
  );
};

export default MedicationDetails;
