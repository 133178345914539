import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const EmarService = {
  getResidentMedicationsTimesByResident: async (location_id: string, resident_id: string, date?: string) => {
    try {
      const response = await axios.get(`emar/resident/${location_id}/${resident_id}/${date}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching times: ' + error);
    }
  },

  handleDispenseMedication: async (data: any) => {
    try {
      const locationId = data?.location_id;
      const residentId = data?.resident_id;
      const response = await axios.post(
        locationId && residentId ? `emar/dispense/routine/${locationId}/${residentId}` : `emar/dispense/routine`,
        data
      );
      return response.data;
    } catch (error) {
      throw new Error('Error dispensing medication: ' + error);
    }
  },

  handleWitnessMedication: async (data: any) => {
    try {
      const locationId = data?.location_id;
      const residentId = data?.resident_id;
      const response = await axios.put(
        locationId && residentId
          ? `emar/witness/${data.emar_medication_id}/${locationId}/${residentId}`
          : `emar/witness/${data.emar_medication_id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw new Error('Error witnessing medication: ' + error);
    }
  },

  handleExceptionMedication: async (data: any) => {
    try {
      const locationId = data?.location_id;
      const residentId = data?.resident_id;
      const response = await axios.post(
        locationId && residentId ? `emar/exception/${locationId}/${residentId}` : `emar/exception`,
        data
      );
      return response.data;
    } catch (error) {
      throw new Error('Error handling exception: ' + error);
    }
  },

  handleDispensePrnMedication: async (data: any) => {
    try {
      const locationId = data?.location_id;
      const residentId = data?.resident_id;
      const response = await axios.post(
        locationId && residentId ? `emar/dispense/prn/${locationId}/${residentId}` : `emar/dispense/prn`,
        data
      );
      return response.data;
    } catch (error) {
      throw new Error('Error dispensing PRN medication: ' + error);
    }
  }
};

export default EmarService;
