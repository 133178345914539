import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
  } from "@/common/presentation/components/ui/alert-dialog"
  import { useTranslation } from 'react-i18next';
  
  interface DeleteDialogProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    handleDelete?: () => void;
  }
  
  const DeleteDialog: React.FC<DeleteDialogProps> = ({ isOpen, onOpenChange, handleDelete }) => {
    const { t } = useTranslation();
  
    const onConfirmDelete = () => {
     if(handleDelete){
      handleDelete();
     }
      onOpenChange(false);
    };
  
    return (
      <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialogContent className="z-[99999999999999999999999999]">
        <AlertDialogHeader>
          <AlertDialogTitle>{t('alerts.youAreSure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('alerts.thisActionCannotBeUndone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
    
        <AlertDialogFooter>
          <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
          <AlertDialogAction
            className="bg-primary hover:bg-primary-400"
            onClick={onConfirmDelete}
          >
            {t('common.delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
    
    )
  }
  
  export default DeleteDialog
  