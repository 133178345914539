import { useState, useEffect } from 'react';

/*This hook return true or false if current window height is greater than pased value*/

export const useWindowHeight = (threshold: number): boolean=> {
  const [isHeightGreaterThanThreshold, setIsHeightGreaterThanThreshold] = useState(window.innerHeight > threshold);

  useEffect(() => {
    const handleResize = () => {
      setIsHeightGreaterThanThreshold(window.innerHeight > threshold);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [threshold]);

  return isHeightGreaterThanThreshold;
}
