import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import useRouteParams from '@/common/hooks/RouteParamsHook';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Input } from '@/common/presentation/components/ui/input';
import { Pencil, Trash, Ellipsis } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { RadioGroup } from '@/common/presentation/components/ui/radio-group';
import { useTranslation } from 'react-i18next';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { useDateHelpers } from '@/utils/helpers/dates.helper';

interface TypeI {
    id: string;
    tracker_name: string;
    type_name: string;
    image_url: string;
    description: string;
    status: 'active' | 'inactive';
}


interface TrackerI {
    id: string;
    location_id: string;
    created_at: string;
    data: TrackerDataI;
}

interface TrackerDataI {
    type_name?: string;
    image?: string;
    date: string;
    detail: string | null;
    location_id: string;
    resident: {
        id: string;
        first_name: string;
        last_name: string;
    };
    type: string;
    id: string;

    who: {
        id: string;
        first_name: string;
        last_name: string;
    };

}

interface TrackerUrineI extends TrackerI {
    data: TrackerDataI & {
        output: string;
        smell: string;
        other: string;
        type_description?: string;
    };
}


function TrackerInconsistence() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [typesBowel, setTypesBowel] = useState<TypeI[]>([]);
    const [typesUrine, setTypesUrine] = useState<TypeI[]>([]);
    const [typesBrief, setTypesBrief] = useState<TypeI[]>([]);
    const [dataReportBowel, setDatareportBowel] = useState<TrackerI[]>([]);
    const [dataReportUrine, setDatareportUrine] = useState<TrackerUrineI[]>([]);
    const [dataReportBrief, setDatareportBrief] = useState<TrackerI[]>([]);
    const dispatch = useDispatch();
    const { tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [trackerSlect, settrackerSlect] = useState('');
    const [selectedIncosistence, setSelectedInconsistence] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [inputValues, setInputValues] = useState({});
    const { t } = useTranslation();
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    const { locationSelectedId } = useLocation();

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedInconsistence('form');
        }

        if (!locationSelectedId || locationSelectedId.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    useEffect(() => {
        if (!params.r) {
            setParam('r', 'all-residents');
        }
    }, [params.r, setParam]);

    const [selectedTracker, setSelectedTracker] = useState<string>('bowel');

    const onSelectTracker = (e: string) => {
        setSelectedTracker(e);
    };

    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedInconsistence(e);
    };

    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };

    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);


    const residentSelected = useMemo(() => {
        return UserHelper.getResidentInformation(params.r, residents);
    }, [params.r, residents]);


    const newColumns = () => {
        const firstObject = {
            label: t('trackers.Type'),
            value: "type",
            type: "texto"
        };
        return [firstObject, ...residents.map((resident: { name: any; }) => ({
            label: `${resident.first_name} ${resident.last_name}`,
            value: resident.first_name,
            type: 'text'
        }))];
    };
    const columns = newColumns();

    type TrackerType = 'Bowel' | 'Urine' | 'Brief';
    const renderActions = (row: any, type: TrackerType) => (
        <div className="flex space-x-2">

            <span className="cursor-pointer" onClick={() => handleButtonOpenDialog(Fields[`Edit${type}`], 'Edit', row.id, row.data, '', type)}>
                <Pencil className="mr-2 h-4 w-4" />
            </span>

            <span className="cursor-pointer" onClick={() => handleButtonOpenDialog([], 'Delete', row.id, row.data, "Are you sure you want to delete this item?", type)}>
                <Trash className="mr-2 h-4 w-4" />
            </span>

        </div>
    );

    const columnsreportvirt = [
        { key: 'date', labelTranslationKey: t('trackers.Date/time'), value: 'date', type: 'date', sortable: true },
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name', sortable: true },
        { key: 'type_name', labelTranslationKey: t('trackers.Type'), value: 'type_name', sortable: true },
        { key: 'detail', labelTranslationKey: t('trackers.Detail'), value: 'detail', sortable: true },
        { key: 'who_name', labelTranslationKey: t('trackers.Staff'), value: 'who_name', sortable: true },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions',
            render: (row: any) => renderActions(row, 'Bowel'), width: '10%'
        },
    ];

    const columnsreportUrineVirt = [
        { key: 'date', labelTranslationKey: t('trackers.Date/time'), value: 'date', type: 'date', sortable: true },
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name', sortable: true },
        { key: 'type_name', labelTranslationKey: t('trackers.Type'), value: 'type_name', sortable: true },
        { key: 'output', labelTranslationKey: t('trackers.Output'), value: 'output', sortable: true },
        { key: 'smell', labelTranslationKey: t('trackers.Smell'), value: 'smell', sortable: true },
        { key: 'other', labelTranslationKey: t('trackers.Other'), value: 'other', sortable: true },
        { key: 'detail', labelTranslationKey: t('trackers.Detail'), value: 'detail', sortable: true },
        {
            key: 'actions', labelTranslationKey: t('trackers.Actions'), value: 'actions',
            render: (row: any) => renderActions(row, 'Urine'), width: '10%'
        }
    ];

    const columnsreportBrief = [
        { key: 'date', labelTranslationKey: t('trackers.Date/time'), value: 'date', type: 'date', sortable: true },
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name', sortable: true },
        { key: 'type_name', labelTranslationKey: t('trackers.Type'), value: 'type_name', sortable: true },
        { key: 'detail', labelTranslationKey: t('trackers.Detail'), value: 'detail', sortable: true },
        { key: 'who_name', labelTranslationKey: t('trackers.Staff'), value: 'who_name', sortable: true, },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions',
            render: (row: any) => renderActions(row, 'Brief'), width: '10%'
        },
    ];

    const [currentPage, setCurrentPage] = useState<number>(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState<number>(20); // Data per page
    const [hasMoreRows, setHasMoreRows] = useState<boolean>(false); // Indicates if there are more rows to load
    const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state



    const dataBowelReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoading(true);
        const json = { location_id: locationSelectedId, per_page: perPage, page, resident_id: params.r };
        const response = await TrackerService.getTrackerBowel(json);

        if (response) {
            try {
                const { types, trackerBowels }: { types: TypeI[], trackerBowels: { data: TrackerI[], current_page: number, last_page: number, total: number } } = response;
                setTypesBowel(types);

                const data = trackerBowels.data;

                if (resetData) {
                    setDatareportBowel(data);
                    setDataOriginalBowel(data);
                } else {

                    setDatareportBowel((prevData) => [...prevData, ...data]);
                    setDataOriginalBowel((prevData) => [...prevData, ...data]);

                }

                if (trackerBowels.current_page >= trackerBowels.last_page || trackerBowels.total < data.length) {
                    setHasMoreRows(false);
                } else {
                    setHasMoreRows(true);
                }

            } catch (error: any) {
                console.error("Error fetching data:", error);
            }
        }

        setIsLoading(false);
    };

    const loadMoreBowelData = useCallback(() => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        dataBowelReport(currentPage + 1, rowsPerPage);

    }, [hasMoreRows, currentPage, dataBowelReport]);


    const [currentPageUrine, setCurrentPageUrine] = useState<number>(1); // Current page
    const [rowsPerPageUrine, setRowsPerPageUrine] = useState<number>(20); // Data per page
    const [hasMoreRowsUrine, setHasMoreRowsUrine] = useState<boolean>(false); // Indicates if there are more rows to load
    const [isLoadingUrine, setIsLoadingUrine] = useState<boolean>(false); // Loading state

    const dataUrineReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoadingUrine(true);
        const json = { location_id: locationSelectedId, per_page: perPage, page, resident_id: params.r };
        const response = await TrackerService.getTrackerUrine(json);
        if (response) {
            try {
                const { types, trackerUrines }: { types: TypeI[], trackerUrines: { data: TrackerUrineI[], current_page: number, last_page: number, total: number } } = response;
                setTypesUrine(types);

                const data = trackerUrines.data;
        
                if (resetData) {
                    setDatareportUrine(data);
                    setDataOriginalUrine(data);
                } else {

                    setDatareportUrine((prevData) => [...prevData, ...data]);
                    setDataOriginalUrine((prevData) => [...prevData, ...data]);

                }

                if (trackerUrines.current_page >= trackerUrines.last_page || trackerUrines.total < data.length) {
                    setHasMoreRowsUrine(false);
                } else {
                    setHasMoreRowsUrine(true);
                }

            } catch (error: any) {
                console.error("Error fetching data:", error);
            }
        }

        setIsLoadingUrine(false);
    };

    const loadMoreUrineData = useCallback(() => {
        if (!hasMoreRowsUrine || isLoadingUrine) return;
        setCurrentPageUrine(currentPageUrine + 1);
        dataUrineReport(currentPageUrine + 1, rowsPerPageUrine);

    }, [hasMoreRowsUrine, currentPageUrine, dataUrineReport])


    const [currentPageBrief, setCurrentPageBrief] = useState<number>(1); // Current page
    const [rowsPerPageBrief, setRowsPerPageBrief] = useState<number>(20); // Data per page
    const [hasMoreRowsBrief, setHasMoreRowsBrief] = useState<boolean>(false); // Indicates if there are more rows to load
    const [isLoadingBrief, setIsLoadingBrief] = useState<boolean>(false); //


    const dataBriefReport = async (page: number, perPage: number, resetData: boolean = false) => {
        setIsLoadingBrief(true);
        const json = { location_id: locationSelectedId, per_page: perPage, page, resident_id: params.r };
        const response = await TrackerService.getTrackerBrief(json);
        if (response) {
            try {
                const { types, trackerBriefs }: { types: TypeI[], trackerBriefs: { data: TrackerI[], current_page: number, last_page: number, total: number } } = response;
                setTypesBrief(types);
                const data = trackerBriefs.data;

                if (resetData) {
                    setDatareportBrief(data);
                    setDataOriginalBrief(data);
                } else {

                    setDatareportBrief((prevData) => [...prevData, ...data]);
                    setDataOriginalBrief((prevData) => [...prevData, ...data]);

                }

                if (trackerBriefs.current_page >= trackerBriefs.last_page || trackerBriefs.total < data.length) {
                    setHasMoreRowsBrief(false);
                } else {
                    setHasMoreRowsBrief(true);
                }

            } catch (error: any) {
                console.error("Error fetching data:", error);
            }
        }

        setIsLoadingBrief(false);
    };

    const loadMoreBriefData = useCallback(() => {
        if (!hasMoreRowsBrief || isLoadingBrief) return;
        setCurrentPageBrief(currentPageBrief + 1);
        dataBriefReport(currentPageBrief + 1, rowsPerPageBrief);
    }, [hasMoreRowsBrief, currentPageBrief, dataBriefReport])

    const [isBowelInitialized, setIsBowelInitialized] = useState<boolean>(false);
    const [isUrineInitialized, setIsUrineInitialized] = useState<boolean>(false);
    const [isBriefInitialized, setIsBriefInitialized] = useState<boolean>(false);

    const [dataOriginalBowel, setDataOriginalBowel] = useState<any[]>([]);
    const [dataOriginalUrine, setDataOriginalUrine] = useState<any[]>([]);
    const [dataOriginalBrief, setDataOriginalBrief] = useState<any[]>([]);

    // useEffect to filter residents in bowel movement
    useEffect(() => {
        if (selectedTracker !== "bowel") {
            return;
        }

        // Initialize Bowel data
        if (!isBowelInitialized && dataReportBowel.length > 0) {
            setDataOriginalBowel(dataReportBowel);
            setIsBowelInitialized(true);
        }

        if (dataOriginalBowel.length > 0 || dataReportBowel.length > 0) {
            let updatedData = dataOriginalBowel.length > 0 ? dataOriginalBowel : dataReportBowel;

            if (params.r === "all-residents") {
                if (locationSelectedId !== "all-locations") {
                    updatedData = updatedData.filter((item: any) => item.location_id === locationSelectedId);
                }
            } else {
                updatedData = updatedData.filter((item: any) => {
                    if (locationSelectedId === "all-locations") {
                        return item.data.resident.id === params.r;
                    }
                    return item.data.resident.id === params.r && item.location_id === locationSelectedId;
                });
            }

            if (JSON.stringify(updatedData) !== JSON.stringify(dataReportBowel)) {
                setDatareportBowel(updatedData);
            }
        }
    }, [params.r, locationSelectedId, dataOriginalBowel, dataReportBowel, isBowelInitialized, selectedTracker]);

    // useEffect to filter residents in urine
    useEffect(() => {
        if (selectedTracker !== "urine") {
            return;
        }

        // Initialize urine data
        if (!isUrineInitialized && dataReportUrine.length > 0) {
            setDataOriginalUrine(dataReportUrine);
            setIsUrineInitialized(true);
        }

        if (dataOriginalUrine.length > 0 || dataReportUrine.length > 0) {
            let updatedData = dataOriginalUrine.length > 0 ? dataOriginalUrine : dataReportUrine;

            if (params.r === "all-residents") {
                if (locationSelectedId !== "all-locations") {
                    updatedData = updatedData.filter((item: any) => item.location_id === locationSelectedId);
                }
            } else {
                updatedData = updatedData.filter((item: any) => {
                    if (locationSelectedId === "all-locations") {
                        return item.data.resident.id === params.r;
                    }
                    return item.data.resident.id === params.r && item.location_id === locationSelectedId;
                });
            }

            if (JSON.stringify(updatedData) !== JSON.stringify(dataReportUrine)) {
                setDatareportUrine(updatedData);
            }
        }
    }, [params.r, locationSelectedId, dataOriginalUrine, dataReportUrine, isUrineInitialized, selectedTracker]);

    // // useEffect to filter residents in brief
    useEffect(() => {
        if (selectedTracker !== "brief") {
            return;
        }

        // Initialize brief data
        if (!isBriefInitialized && dataReportBrief.length > 0) {
            setDataOriginalBrief(dataReportBrief);
            setIsBriefInitialized(true);
        }

        if (dataOriginalBrief.length > 0 || dataReportBrief.length > 0) {
            let updatedData = dataOriginalBrief.length > 0 ? dataOriginalBrief : dataReportBrief;

            if (params.r === "all-residents") {
                if (locationSelectedId !== "all-locations") {
                    updatedData = updatedData.filter((item: any) => item.location_id === locationSelectedId);
                }
            } else {
                updatedData = updatedData.filter((item: any) => {
                    if (locationSelectedId === "all-locations") {
                        return item.data.resident.id === params.r;
                    }
                    return item.data.resident.id === params.r && item.location_id === locationSelectedId;
                });
            }

            if (JSON.stringify(updatedData) !== JSON.stringify(dataReportBrief)) {
                setDatareportBrief(updatedData);
            }
        }
    }, [params.r, locationSelectedId, dataOriginalBrief, dataReportBrief, isBriefInitialized, selectedTracker]);


    const handleDateChange = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value instanceof Date && !isNaN(value) ? value : null
        }));
    };

    const Fields = {
        'EditBowel': [
            { fieldName: 'date', label: t('trackers.Date/Time'), type: 'date' },
            { fieldName: 'type', label: t('trackers.Type'), type: 'select' },
            { fieldName: 'detail', label: t('trackers.Detail'), type: 'textarea' }
        ],
        'EditUrine': [
            { fieldName: 'date', label: t('trackers.Date/Time'), type: 'date' },
            { fieldName: 'type', label: t('trackers.Type'), type: 'selecturine' },
            { fieldName: 'output', label: t('trackers.Output'), type: 'selectoutput' },
            { fieldName: 'smell', label: t('trackers.Smell'), type: 'selectsmell' },
            { fieldName: 'other', label: t('trackers.Other'), type: 'selectother' },
            { fieldName: 'detail', label: t('trackers.Detail'), type: 'textarea' }

        ],
        'EditBrief': [
            { fieldName: 'date', label: t('trackers.Date/Time'), type: 'date' },
            { fieldName: 'type', label: t('trackers.Type'), type: 'selectbrief' },
            { fieldName: 'detail', label: t('trackers.Detail'), type: 'textarea' }
        ],

    };

    const [fields, setDialogField] = useState(false);
    const handleButtonOpenDialog = (fields: any[], title: string, idTracker: string, data: TrackerDataI, description: string, tracker: string) => {
        const combinedData = { ...data, idTracker };
        setShowEditDialog(true);
        setFormValue(combinedData);
        setDialogField(fields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
        settrackerSlect(tracker);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };


    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};
        if (trackerSlect === 'Bowel') {
            if (action === 'Edit') {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                json["data"] = {
                    "type": formData.type,
                    "detail": formData.detail,
                    "resident_id": formData.resident_id,
                    "date": formData.date
                };
                const response = await TrackerService.updateTrackerBowel(json);
                setShowEditDialog(false);
            } else {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                const response = await TrackerService.destroyTrackerBowel(json);
                setShowEditDialog(false);
            }
            setCurrentPage(1);
            dataBowelReport(1, rowsPerPage, true);
        } else if (trackerSlect === 'Urine') {
            if (action === 'Edit') {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                json["data"] = {
                    "type": formData.type,
                    "resident_id": formData.resident_id,
                    "date": formData.date,
                    "output": formData.output,
                    "smell": formData.smell,
                    "other": formData.other,
                    "detail": formData.detail
                }
                const response = await TrackerService.updateTrackerUrine(json);
                setShowEditDialog(false);
            } else {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                const response = await TrackerService.destroyTrackerUrine(json);
                setShowEditDialog(false);
            }
            setCurrentPageUrine(1);
            dataUrineReport(1, rowsPerPageUrine, true);
        } else if (trackerSlect === 'Brief') {
            if (action === 'Edit') {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                json["data"] = {
                    "type": formData.type,
                    "detail": formData.detail,
                    "resident_id": formData.resident_id,
                    "date": formData.date
                }
                const response = await TrackerService.updateTrackerBrief(json);
                setShowEditDialog(false);
            } else {
                json["id"] = formData.idTracker;
                json["id_data"] = formData.id;
                const response = await TrackerService.destroyTrackerBrief(json);
                setShowEditDialog(false);
            }
            setCurrentPageBrief(1);
            dataBriefReport(1, rowsPerPageBrief, true);
        }

    };

    useEffect(() => {
        if (selectedIncosistence !== 'form') return;

        dataBowelReport(1, rowsPerPage, true);
        dataUrineReport(1, rowsPerPage, true);
        dataBriefReport(1, rowsPerPage, true);
    }, [locationSelectedId, params.r]);

    useEffect(() => {
        if (selectedTracker !== 'bowel') return;

        // Limpiar completamente los datos y reiniciar la paginación
        setDatareportBowel([]);
        setDataOriginalBowel([]);
        setCurrentPage(1);
        setHasMoreRows(true);// Asumimos que habrá más filas por defecto

        // Hacer la nueva solicitud solo cuando los datos estén vacíos
        dataBowelReport(1, rowsPerPage, true);
    }, [locationSelectedId, params.r, selectedTracker]);

    useEffect(() => {
        if (selectedTracker !== 'urine') return;

        // Limpiar completamente los datos y reiniciar la paginación
        setDatareportUrine([]);
        setDataOriginalUrine([]);
        setCurrentPageUrine(1);
        setHasMoreRowsUrine(true);// Asumimos que habrá más filas por defecto

        // Hacer la nueva solicitud solo cuando los datos estén vacíos
        dataUrineReport(1, rowsPerPageUrine, true);
    }, [locationSelectedId, params.r, selectedTracker]);


    useEffect(() => {
        if (selectedTracker !== 'brief') return;

        // Limpiar completamente los datos y reiniciar la paginación
        setDatareportBrief([]);
        setDataOriginalBrief([]);
        setCurrentPageBrief(1);
        setHasMoreRowsBrief(true);// Asumimos que habrá más filas por defecto

        // Hacer la nueva solicitud solo cuando los datos estén vacíos
        dataBriefReport(1, rowsPerPageBrief, true);
    }, [locationSelectedId, params.r, selectedTracker]);



    const [filters, setFilters] = useState({
        resident_name: ' ',
        who_name: ''
    });

    useEffect(() => {
        const resident = residents.find((res) => res.id === params.r);
        if (resident) {
            setFilters({
                resident_name: `${resident.first_name} ${resident.last_name}`,
                who_name: ''
            });
        } else {
            setFilters({
                resident_name: '',
                who_name: ''
            });
        }
    }, [params.r]);

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);


    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {
        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'date':
                if (formData[column.fieldName] != undefined) {
                    const dateString = formData[column.fieldName];
                    const parsedDate = new Date(dateString);
                    const formattedDate = format(parsedDate, "dd/MM/yyyy hh:mm a");
                    return <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={`w-[319px] justify-start text-left font-normal`}>
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {formattedDate}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                id={column.fieldName}
                                name={column.fieldName}
                                selectedDate={parsedDate}
                                onChange={date => handleDateChange(column.fieldName, date)}
                                MonthAndYearPicker={true}
                                timePicker={true}
                            />
                        </PopoverContent>
                    </Popover>
                };
            case 'textarea':
                return <textarea
                    id={column.fieldName}
                    name={column.fieldName}
                    value={formData[column.fieldName] || ''}
                    onChange={handleChange}
                    style={{ outline: 'none' }}
                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                />;
            case 'select':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)} style={{ outline: 'none' }}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md " style={{ outline: 'none' }}>
                            <SelectValue placeholder={column.label}>
                                {typesBowel.find((option) => option.id === formData.type)?.type_name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesBowel.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.type_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selecturine':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesUrine.find((option) => option.id === formData.type)?.type_name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesUrine.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.type_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectoutput':
                return (
                    <Select value={formData.output} onValueChange={(value) => handleSelectChange('output', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesUrineOutput.find((option) => option.id === formData.output)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesUrineOutput.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectsmell':
                return (
                    <Select value={formData.smell} onValueChange={(value) => handleSelectChange('smell', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesUrineSmell.find((option) => option.id === formData.smell)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesUrineSmell.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectother':
                return (
                    <Select value={formData.other} onValueChange={(value) => handleSelectChange('other', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesUrineOther.find((option) => option.id === formData.other)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesUrineOther.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectbrief':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {typesBrief.find((option) => option.id === formData.type)?.type_name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {typesBrief.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.type_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            default:
                return null;
        }
    };

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction: direction });
    };

    const sortedDataBowel = useMemo(() => {

        if (Array.isArray(typesBowel) && !typesBowel.length) {
            return [];
        }

        const dataWithTypeName = dataReportBowel.map(row => ({
            ...row,
            data: {
                ...row.data,
                type_name: typesBowel.find(type => type.id === row.data.type)?.type_name || '',
                image: typesBowel.find(type => type.id === row.data.type)?.image_url || ''
            }

        }));

        return dataWithTypeName ?? []

    }, [dataReportBowel, sortConfig]);


    const sortedDataUrine = useMemo(() => {
        if (sortConfig.key) {
            return [...dataReportUrine].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });

        }

        if (Array.isArray(typesUrine) && !typesUrine.length) {
            return [];
        }

        const dataWithTypeUrine = dataReportUrine.map(row => ({
            ...row,
            data: {
                ...row.data,
                type_name: typesUrine.find(type => type.id === row.data.type)?.type_name || '',
                type_description: typesUrine.find(type => type.id === row.data.type)?.description || ''
            }

        }));

        return dataWithTypeUrine ?? []
    }, [dataReportUrine, sortConfig]);

    const sortedDataBrief = useMemo(() => {
        if (sortConfig.key) {
            return [...dataReportBrief].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
        }

        if (Array.isArray(typesBrief) && !typesBrief.length) {
            return [];
        }
        const dataWithTypeBrief = dataReportBrief.map(row => ({
            ...row,
            data: {
                ...row.data,
                type_name: typesBrief.find(type => type.id === row.data.type)?.type_name || ''
            }

        }));


        return dataWithTypeBrief || [];
    }, [dataReportBrief, sortConfig]);

    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedTypeUrine, setSelectedTypeUrine] = useState<string | null>(null);
    const [selectedTypeBrief, setSelectedTypeBrief] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [detailBowel, setDetailBowel] = useState('');
    const [detailUrine, setDetailUrine] = useState('');
    const [detailBrief, setDetailBrief] = useState('');
    const formattedDate = selectedDate ? selectedDate.toLocaleString() : '';
    const [userHasChanged, setUserHasChanged] = useState<boolean>(false);
    const [userHasChangedUrine, setUserHasChangedUrine] = useState<boolean>(false);

    useEffect(() => {
        if (Array.isArray(typesBowel) && !typesBowel.length) {
            return;
        }

        // This validation is done because when switching between residents, 
        // this useEffect is triggered again due to calls to dataReport, and 
        // when typesBowel is modified, this useEffect is re-executed. 
        // It was implemented to prevent the user from having to uncheck the input again 
        // after switching between residents if they had unchecked it previously.
        if (!userHasChanged) {
            const defaultType = typesBowel?.find(type => type.type_name === 'Type 4');
            if (defaultType) {
                setSelectedType(defaultType.id);
            }
        }
    }, [typesBowel, userHasChanged]);

    useEffect(() => {
        if (Array.isArray(typesUrine) && !typesUrine.length) {
            return;
        }

        if (!userHasChangedUrine) {
            const defaultUrineType = typesUrine?.find(type => type.type_name === 'Yellow');
            if (defaultUrineType) {
                setSelectedTypeUrine(defaultUrineType.id);
            }
        }
    }, [typesUrine, userHasChangedUrine]);

    const handleDateChangeBowel = (date) => {
        setSelectedDate(date);
    };
    const handleDateChangeBrief = (date) => {
        setSelectedDate(date);
    };

    const handleDateChangeUrine = (date) => {
        setSelectedDate(date);
    };

    const handleChangeBowel = (event) => {
        setDetailBowel(event.target.value);
    };

    const handleChangeBrief = (event) => {
        setDetailBrief(event.target.value);
    };

    const handleChangeUrine = (event) => {
        setDetailUrine(event.target.value);
    };

    const handleSubmitAll = async (event) => {
        event.preventDefault();
        if (!params.r || params.r === 'all-residents') {
            toast({
                description: t('trackers.trackerincontinence.selectResident'),

            });
            return;
        } else {
            setErrorMessage('');
        }

        const bowelData = {
            type: selectedType,
            resident_id: params.r,
            detail: detailBowel,
            date: selectedDate,
            location_id: locationSelectedId
        };

        const urineData = {
            type: selectedTypeUrine,
            resident_id: params.r,
            date: selectedDate,
            output: formDataOut.type,
            smell: formDataSmell.type,
            other: formDataOther.type,
            detail: detailUrine,
            location_id: locationSelectedId
        };

        const briefData = {
            type: selectedTypeBrief,
            resident_id: params.r,
            detail: detailBrief,
            date: selectedDate,
            location_id: locationSelectedId
        }

        try {

            if (selectedType) {
                await TrackerService.saveTrackerBowel([bowelData]);
                setDetailBowel(' ');
                setCurrentPage(1);
                dataBowelReport(1, rowsPerPage, true);
            }


            if (selectedTypeUrine) {
                await TrackerService.saveTrackerUrine([urineData]);
                setDetailUrine(' ');
                setCurrentPageUrine(1);
                dataUrineReport(1, rowsPerPageUrine, true);
            }


            if (selectedTypeBrief) {
                await TrackerService.saveTrackerBrief([briefData]);
                setDetailBrief(' ');
                setSelectedTypeBrief(null);
                setCurrentPageBrief(1);
                dataBriefReport(1, rowsPerPageBrief, true);
            }


            if (!selectedType && !selectedTypeUrine && !selectedTypeBrief) {
                toast({
                    description: 'You must select at least one tracker to save.',
                });
                return;
            }

            setUserHasChanged(false);
            setUserHasChangedUrine(false);

            toast({
                title: 'Success',
                description: 'Data saved successfully',
                className: 'bg-green-500 text-white'
            });

        } catch (error) {
            console.error('Error al guardar:', error);
            toast({
                description: 'There was an error saving the data',
                variant: 'destructive'
            });
        }
    };


    const { formatDate } = useDateHelpers();
    const [formDataOut, setFormDataOut] = useState({ type: 'Medium' });

    const typesUrineOutput = [
        { id: 'Light', name: t('trackers.Light') },
        { id: 'Medium', name: t('trackers.Medium') },
        { id: 'Heavy', name: t('trackers.Heavy') }
    ];


    const handleSelectChangeOut = (field, value) => {
        setFormDataOut((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };
    const [formDataSmell, setFormDataSmell] = useState({ type: 'Normal' });

    const typesUrineSmell = [
        { id: 'Normal', name: t('trackers.Normal') },
        { id: 'Strong', name: t('trackers.Strong') }
    ];


    const handleSelectChangeSmell = (field, value) => {
        setFormDataSmell((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };


    const [formDataOther, setFormDataOther] = useState({ type: 'None' });

    const typesUrineOther = [
        { id: 'None', name: t('trackers.None') },
        { id: 'Discharge', name: t('trackers.Discharge') },
        { id: 'Painful Urination', name: t('trackers.Painful Urination') },
        { id: 'Effort to urinate', name: t('trackers.Effort to urinate') }
    ];

    const handleSelectChangeOther = (field, value) => {
        setFormDataOther((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackerincontinence.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerincontinence.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedIncosistence} onValueChange={(e) => { onSelectVitals(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('medications.newMedication')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }


            {
                selectedIncosistence === 'form' && (
                    <div>
                        {
                            locationSelectedId && (
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <ResidentSelector
                                            onSelect={onResidentSelect}
                                            locationId={location}
                                            residentId={params.r}
                                            showAllResidents={false}
                                            showKeepInEye={true}
                                        />
                                    </div>
                                    <form onSubmit={handleSubmitAll}>
                                        <div >
                                            <Card className="border-t-4 border-t-primary/80">
                                                <CardHeader>
                                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Bowel Movement')}</CardTitle>
                                                </CardHeader>

                                                <CardContent>
                                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                                    <div className="flex flex-wrap lg:flex-nowrap">
                                                        <RadioGroup className="flex flex-wrap lg:flex-nowrap gap-4 w-full">
                                                            {typesBowel && typesBowel.map((type) => (
                                                                <label key={type.type_name} className="bowel-type flex flex-col items-center p-4 w-full sm:w-1/2 md:w-1/3 lg:w-auto">
                                                                    <img
                                                                        className="img-thumbnail thumbnail-administrator w-24 h-auto"
                                                                        src={`/img/trackers/bowel/${type.image_url}`}
                                                                        alt={type.image_url}
                                                                    />
                                                                    <br />
                                                                    <input
                                                                        type="radio"
                                                                        name="tracker_bowel_type"
                                                                        value={type.id}
                                                                        checked={selectedType === type.id}
                                                                        onClick={() => {
                                                                            if (selectedType === type.id) {
                                                                                setSelectedType(null);
                                                                                setUserHasChanged(true);
                                                                            }
                                                                        }}
                                                                        onChange={() => {
                                                                            setSelectedType(type.id);
                                                                            setUserHasChanged(true);
                                                                        }}

                                                                    />

                                                                    <b className="mt-2 font-bold text-muted-foreground dark:text-white">{type.type_name}</b>
                                                                    <div>
                                                                        <p className="mt-1 text-center">{type.description} </p>
                                                                    </div>

                                                                </label>
                                                            ))}
                                                        </RadioGroup>
                                                    </div>

                                                    <div className="flex flex-wrap">
                                                        <div className="flex flex-col w-full md:w-1/2 p-2">
                                                            <Label htmlFor="tracker_bowel_date" className="control-label mb-3">
                                                                {t('trackers.Date/Time')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Popover>
                                                                    <PopoverTrigger asChild>
                                                                        <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                                            {formattedDate}
                                                                        </Button>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent className="w-auto p-0">
                                                                        <CustomCalendar
                                                                            id="tracker_bowel"
                                                                            name="tracker_bowel"
                                                                            selectedDate={selectedDate}
                                                                            onChange={handleDateChangeBowel}
                                                                            MonthAndYearPicker={true}
                                                                            timePicker={true}
                                                                        />
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col w-full md:w-1/2 p-2">
                                                            <Label htmlFor="tracker_bowel_detail" className="control-label mb-3">
                                                                {t('trackers.Detail')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    className="w-full justify-start text-left font-normal"
                                                                    id="tracker_bowel_detail"
                                                                    name="tracker_bowel_detail"
                                                                    type="text"
                                                                    value={detailBowel}
                                                                    onChange={handleChangeBowel}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>

                                        <div>
                                            <Card className="border-t-4 border-t-primary/80">
                                                <CardHeader>
                                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Urine')}</CardTitle>
                                                </CardHeader>

                                                <CardContent>
                                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                                    <div className="flex flex-wrap lg:flex-nowrap">
                                                        <RadioGroup className="flex justify-between flex-wrap lg:flex-nowrap gap-7 w-full">
                                                            {typesUrine && typesUrine && typesUrine.map((type) => (
                                                                <label key={type.type_name} className="bowel-type flex flex-col items-center p-4 w-full sm:w-1/2 md:w-1/3 lg:w-auto">
                                                                    <img
                                                                        className="img-thumbnail thumbnail-administrator w-10 h-auto"
                                                                        src={`/img/trackers/urine/${type.image_url}`}
                                                                        alt={type.image_url}
                                                                    />
                                                                    <br />
                                                                    <input
                                                                        type="radio"
                                                                        name="tracker_urine_type"
                                                                        value={type.id}
                                                                        checked={selectedTypeUrine === type.id}
                                                                        onClick={() => {
                                                                            if (selectedTypeUrine === type.id) {
                                                                                setSelectedTypeUrine(null);
                                                                                setUserHasChangedUrine(true);
                                                                            }
                                                                        }}
                                                                        onChange={() => {
                                                                            setSelectedTypeUrine(type.id)
                                                                            setUserHasChangedUrine(true);
                                                                        }}
                                                                        className='mt-2'
                                                                    />
                                                                    <b className="mt-2 text-muted-foreground dark:text-white">{type.type_name}</b>
                                                                    <p className="mt-1 text-center">{type.description}</p>
                                                                </label>
                                                            ))}
                                                        </RadioGroup>
                                                    </div>

                                                    <div className="flex flex-wrap">
                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_date" className="control-label mb-3">
                                                                {t('trackers.Date/Time')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Popover>
                                                                    <PopoverTrigger asChild>
                                                                        <Button variant="outline" className="w-full lg:w-[200px] justify-start text-left font-normal">
                                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                                            {formattedDate}
                                                                        </Button>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent className="w-auto p-0">
                                                                        <CustomCalendar
                                                                            id="tracker_urine"
                                                                            name="tracker_urine"
                                                                            selectedDate={selectedDate}
                                                                            onChange={handleDateChangeUrine}
                                                                            MonthAndYearPicker={true}
                                                                            timePicker={true}
                                                                        />
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_output" className="control-label mb-3">
                                                                {t('trackers.Output')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Select value={formDataOut.type} onValueChange={(value) => handleSelectChangeOut('type', value)} >
                                                                    <SelectTrigger>
                                                                        <SelectValue placeholder="Select Urine Output">
                                                                            {typesUrineOutput.find((option) => option.id === formDataOut.type)?.name}
                                                                        </SelectValue>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {typesUrineOutput.map((option) => (
                                                                            <SelectItem key={option.id} value={option.id}>
                                                                                {option.name}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectContent>
                                                                </Select>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_smell" className="control-label mb-3">
                                                                {t('trackers.Smell')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Select value={formDataSmell.type} onValueChange={(value) => handleSelectChangeSmell('type', value)}>
                                                                    <SelectTrigger>
                                                                        <SelectValue placeholder="Select Urine Output">
                                                                            {typesUrineSmell.find((option) => option.id === formDataSmell.type)?.name}
                                                                        </SelectValue>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {typesUrineSmell.map((option) => (
                                                                            <SelectItem key={option.id} value={option.id}>
                                                                                {option.name}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectContent>
                                                                </Select>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_other" className="control-label mb-3">
                                                                {t('trackers.Other')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Select value={formDataOther.type} onValueChange={(value) => handleSelectChangeOther('type', value)}>
                                                                    <SelectTrigger>
                                                                        <SelectValue placeholder="Select Urine Other">
                                                                            {typesUrineOther.find((option) => option.id === formDataOther.type)?.name}
                                                                        </SelectValue>
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        {typesUrineOther.map((option) => (
                                                                            <SelectItem key={option.id} value={option.id}>
                                                                                {option.name}
                                                                            </SelectItem>
                                                                        ))}
                                                                    </SelectContent>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col w-full md:w-1/5 p-2">
                                                            <Label htmlFor="tracker_urine_detail" className="control-label mb-3">
                                                                {t('trackers.Detail')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    className="w-full lg:w-[233px] justify-start text-left font-normal"
                                                                    id="tracker_urine_detail"
                                                                    name="tracker_urine_detail"
                                                                    type="text"
                                                                    value={detailUrine}
                                                                    onChange={handleChangeUrine}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>

                                            </Card>
                                        </div>


                                        <div>
                                            <Card className="border-t-4 border-t-primary/80">
                                                <CardHeader>
                                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Brief')}</CardTitle>
                                                </CardHeader>
                                                <CardContent>
                                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                                    <div className="flex flex-wrap lg:flex-nowrap">
                                                        <RadioGroup className="flex justify-between flex-wrap lg:flex-nowrap gap-7 w-full">
                                                            {typesBrief && typesBrief.map((type) => (
                                                                <label key={type.type_name} className="bowel-type flex flex-col items-center p-4 w-full sm:w-1/2 md:w-1/3 lg:w-auto">
                                                                    <img
                                                                        className="img-thumbnail thumbnail-administrator w-25 h-auto"
                                                                        src={`/img/trackers/brief/${type.image_url}`}
                                                                        alt={type.image_url}
                                                                        style={{ height: '130px' }}
                                                                    />
                                                                    <br />
                                                                    <input
                                                                        type="radio"
                                                                        name="tracker_brief_type"
                                                                        value={type.id}
                                                                        checked={selectedTypeBrief === type.id}
                                                                        onClick={() => {
                                                                            if (selectedTypeBrief === type.id) {
                                                                                setSelectedTypeBrief(null);
                                                                            }
                                                                        }}
                                                                        onChange={() => setSelectedTypeBrief(type.id)}

                                                                        className='mt-2'
                                                                    />
                                                                    <b className="mt-2 text-muted-foreground dark:text-white">{type.type_name}</b>
                                                                    <p className="mt-1 text-center">{type.description}</p>
                                                                </label>
                                                            ))}
                                                        </RadioGroup>
                                                    </div>
                                                    <div className="flex flex-wrap">
                                                        <div className="flex flex-col w-full md:w-1/2 p-2">
                                                            <Label htmlFor="tracker_brief_date" className="control-label mb-3">
                                                                {t('trackers.Date/Time')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Popover>
                                                                    <PopoverTrigger asChild>
                                                                        <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                                            {formattedDate}
                                                                        </Button>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent className="w-auto p-0">
                                                                        <CustomCalendar
                                                                            id="tracker_brief"
                                                                            name="tracker_brief"
                                                                            selectedDate={selectedDate}
                                                                            onChange={handleDateChangeBrief}
                                                                            MonthAndYearPicker={true}
                                                                            timePicker={true}
                                                                        />
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col w-full md:w-1/2 p-2">
                                                            <Label htmlFor="tracker_brief_detail" className="control-label mb-3">
                                                                {t('trackers.Detail')}
                                                            </Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    className="w-full justify-start text-left font-normal"
                                                                    id="tracker_brief_detail"
                                                                    name="tracker_brief_detail"
                                                                    type="text"
                                                                    value={detailBrief}
                                                                    onChange={handleChangeBrief}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row justify-end mt-4">
                                                        <Button type="submit" className="bg-primary text-white px-4 py-2 rounded">
                                                            {t('trackers.Save')}
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </form>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                selectedIncosistence === 'report' && (
                    <div>

                        <ResidentSelector
                            showKeepInEye={true}
                            onSelect={onResidentSelect}
                            residentId={params.r}
                            locationId={location}
                            description={t('calendar.selectAResident')}
                        />

                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <Tabs defaultValue={selectedTracker} onValueChange={(e) => { onSelectTracker(e); }}
                                    className="w-auto">
                                    <TabsList>
                                        <TabsTrigger value="bowel" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t('trackers.Bowel Movement')}
                                        </TabsTrigger>
                                        <TabsTrigger value="urine" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t('trackers.Urine')}
                                        </TabsTrigger>
                                        <TabsTrigger value="brief" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                            {t('trackers.Brief')}
                                        </TabsTrigger>
                                    </TabsList>

                                </Tabs>
                                {selectedTracker === 'bowel' && (
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Tracker Bowel Report')}</CardTitle>
                                )}
                                {selectedTracker === 'urine' && (
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Tracker Urine Report')}</CardTitle>
                                )}
                                {selectedTracker === 'brief' && (
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.Tracker Brief Report')}</CardTitle>
                                )}

                            </CardHeader>
                            <CardContent>

                                <CustomDialog
                                    width="30rem"
                                    height="30rem"
                                    newDialog={true}
                                    isOpen={showEditDialog}
                                    onClose={handleCloseEditDialog}
                                    title={dialogTitle}
                                    onSubmit={handleSubmit}
                                    description={dialogDescription}
                                >

                                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                        <div className="grid gap-4">
                                            {fields && fields.map((field, index) => (
                                                <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                                    <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                                    {renderInputForColumn(field, index)}
                                                </div>
                                            )
                                            )}

                                        </div>
                                        <div className="flex flex-row justify-end mt-4">
                                            <Button type="submit" className="bg-primary">
                                                {action === "Delete" ? "Delete" : "Edit"}
                                            </Button>
                                        </div>

                                    </form>


                                </CustomDialog>

                                {selectedTracker === 'bowel' && (
                                    <div>
                                        <TableCustomVirtuoso
                                            data={sortedDataBowel}
                                            columns={columnsreportvirt}
                                            renderCellContent={(index, column, data) => {
                                                const row = data[index];
                                                switch (column.key) {
                                                    case 'date':

                                                        return (
                                                            <>
                                                                {formatDate(row.data.date, false)}
                                                                <br />
                                                                {format(new Date(row.data.date), "hh:mm a")}
                                                            </>
                                                        );
                                                    case 'resident_name':
                                                        return `${capitalizeWords(row.data.resident.first_name)} ${capitalizeWords(row.data.resident.last_name)}`;
                                                    case 'type_name':
                                                        return row.data.type_name !== undefined ? (
                                                            <>
                                                                {capitalizeWords(row.data.type_name)}

                                                            </>
                                                        ) : null;
                                                    case 'detail':
                                                        return row.data.detail ? capitalizeWords(row.data.detail) : '';
                                                    case 'who_name':
                                                        return `${capitalizeWords(row.data.who.first_name)} ${capitalizeWords(row.data.who.last_name)}`;
                                                    default:
                                                        return row[column.key];
                                                }
                                            }}
                                            additionalButtons={<></>}
                                            onSort={(columnKey: string, direction: string) => {
                                                setSortConfig({ column: columnKey, direction });

                                                const sortedData = [...sortedDataBowel].sort((a: any, b: any) => {
                                                    const getValue = (data: any, key: string) => {
                                                        if (key === 'resident_name') {
                                                            return data.resident ? `${data.resident.first_name} ${data.resident.last_name}` : '';
                                                        } else if (key === 'who_name') {
                                                            return data.who ? `${data.who.first_name} ${data.who.last_name}` : '';
                                                        }
                                                        return data[key] || '';
                                                    };

                                                    const aValue = getValue(a.data, columnKey);
                                                    const bValue = getValue(b.data, columnKey);

                                                    if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
                                                    if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
                                                    return 0;
                                                });

                                                setDatareportBowel(sortedData);
                                            }}
                                            loadMore={loadMoreBowelData}
                                            hasMore={hasMoreRows}
                                            key={params.r}
                                            isLoading={isLoading}
                                        />
                                    </div>

                                )}

                                {selectedTracker === 'urine' && (
                                    <div>
                                        <TableCustomVirtuoso
                                            data={sortedDataUrine}
                                            columns={columnsreportUrineVirt}
                                            renderCellContent={(index, column, data) => {
                                                const row = data[index];
                                                switch (column.key) {
                                                    case 'date':
                                                        return (
                                                            <>
                                                                {formatDate(row.data.date, false)}
                                                                <br />
                                                                {format(new Date(row.data.date), "hh:mm a")}
                                                            </>
                                                        );
                                                    case 'resident_name':
                                                        return `${capitalizeWords(row.data.resident.first_name)} ${capitalizeWords(row.data.resident.last_name)}`;
                                                    case 'type_name':
                                                        return (
                                                            <>
                                                                {capitalizeWords(row.data.type_name)}
                                                                {row.data.type_description && <> - {row.data.type_description}</>}
                                                            </>
                                                        );
                                                    case 'output':
                                                        return row.data.output ? capitalizeWords(row.data.output) : '';
                                                    case 'smell':
                                                        return row.data.smell ? capitalizeWords(row.data.smell) : '';
                                                    case 'other':
                                                        return row.data.other ? capitalizeWords(row.data.other) : '';
                                                    case 'detail':
                                                        return row.data.detail ? capitalizeWords(row.data.detail) : '';
                                                    case 'who_name':
                                                        return `${capitalizeWords(row.data.who.first_name)} ${capitalizeWords(row.data.who.last_name)}`;
                                                    default:
                                                        return capitalizeWords(String(row[column.value as keyof typeof row]));
                                                }
                                            }}
                                            additionalButtons={<></>}
                                            onSort={(columnKey: string, direction: string) => {
                                                setSortConfig({ column: columnKey, direction });

                                                const sortedData = [...sortedDataUrine].sort((a: any, b: any) => {
                                                    const getValue = (data: any, key: string) => {
                                                        if (key === 'resident_name') {
                                                            return data.resident ? `${data.resident.first_name} ${data.resident.last_name}` : '';
                                                        } else if (key === 'who_name') {
                                                            return data.who ? `${data.who.first_name} ${data.who.last_name}` : '';
                                                        }
                                                        return data[key] || '';
                                                    };

                                                    const aValue = getValue(a.data, columnKey);
                                                    const bValue = getValue(b.data, columnKey);

                                                    if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
                                                    if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
                                                    return 0;
                                                });

                                                setDatareportUrine(sortedData);
                                            }}
                                            loadMore={loadMoreUrineData}
                                            hasMore={hasMoreRowsUrine}
                                            key={params.r}
                                            isLoading={isLoadingUrine}
                                        />
                                    </div>

                                )}

                                {selectedTracker === 'brief' && (
                                    <div>
                                        <TableCustomVirtuoso
                                            data={sortedDataBrief}
                                            columns={columnsreportBrief}
                                            renderCellContent={(index, column, data) => {
                                                const row = data[index];
                                                switch (column.key) {
                                                    case 'date':

                                                        return (
                                                            <>
                                                                {formatDate(row.data.date, false)}
                                                                <br />
                                                                {format(new Date(row.data.date), "hh:mm a")}
                                                            </>
                                                        );
                                                    case 'resident_name':
                                                        return `${capitalizeWords(row.data.resident.first_name)} ${capitalizeWords(row.data.resident.last_name)}`;
                                                    case 'type_name':
                                                        return row.data.type_name !== undefined ? (
                                                            <>
                                                                {capitalizeWords(row.data.type_name)}
                                                            </>
                                                        ) : null;
                                                    case 'detail':
                                                        return row.data.detail ? capitalizeWords(row.data.detail) : '';
                                                    case 'who_name':
                                                        return `${capitalizeWords(row.data.who.first_name)} ${capitalizeWords(row.data.who.last_name)}`;
                                                    default:
                                                        return row[column.key];
                                                }
                                            }}
                                            additionalButtons={<></>}
                                            onSort={(columnKey: string, direction: string) => {
                                                setSortConfig({ column: columnKey, direction });

                                                const sortedData = [...sortedDataBrief].sort((a: any, b: any) => {
                                                    const getValue = (data: any, key: string) => {
                                                        if (key === 'resident_name') {
                                                            return data.resident ? `${data.resident.first_name} ${data.resident.last_name}` : '';
                                                        } else if (key === 'who_name') {
                                                            return data.who ? `${data.who.first_name} ${data.who.last_name}` : '';
                                                        }
                                                        return data[key] || '';
                                                    };

                                                    const aValue = getValue(a.data, columnKey);
                                                    const bValue = getValue(b.data, columnKey);

                                                    if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
                                                    if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
                                                    return 0;
                                                });

                                                setDatareportBrief(sortedData);
                                            }}

                                            loadMore={loadMoreBriefData}
                                            hasMore={hasMoreRowsBrief}
                                            key={params.r}
                                            isLoading={isLoadingBrief}
                                        />
                                    </div>
                                )}

                            </CardContent>
                        </Card>
                    </div>
                )
            }

        </div >
    )
}

export default TrackerInconsistence