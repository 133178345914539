import { Label } from '@/common/presentation/components/ui/label';
import { Button } from '@/common/presentation/components/ui/button';
import { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { Input } from '@/common/presentation/components/ui/input';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { ITrackerCleaningArea, ITrackerCleaningSummaryForm, ITrackerCleaningTask } from './TrackerCleaningMockup';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { createSummary, getAreas, getTasks } from '../../slices/TrackerCleaningSlice';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

export function TrackerCleaningForm() {
    const dispatch = useDispatch();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const { params } = useRouteParams();
    const { toast } = useToast();
    const { t } = useTranslation();
    const [areas, setAreas] = useState<ITrackerCleaningArea[]>([]);
    const [tasks, setTasks] = useState<ITrackerCleaningTask[]>([]);
    const { locationSelectedId } = useLocation();
    const [values, setValues] = useState<ITrackerCleaningSummaryForm>({
        date: new Date(),
        area: "",
        detail: "",
        task: ""
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setAreas(trackerCleaning.activeAreas);
        setTasks(trackerCleaning.activeTasks);
    }, [trackerCleaning]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleDateChange = (value: any) => {
       setValues({
            ...values,
            date: value
        });
    };

    const handleAreaChange = (value: any) => {
        setValues({
             ...values,
             area: value
         });
     };

     const handleTaskChange = (value: any) => {
        setValues({
             ...values,
             task: value
         });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(values.area && values.date && values.detail && values.task) {
            await dispatch(createSummary(
                {
                    location_id: locationSelectedId,
                    data: {
                        ...values,
                        date: values.date.toISOString()
                    }
                }
            ));
    
            cleanInputs();

            toast(
                {
                    description: t("cleaning.form.detailed.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.form.detailed.toast.required")
                }
            );
        }
    }

    const fetchData = async () => {
        await dispatch(getAreas(
            {
                location_id: locationSelectedId
            }
        ));

        await dispatch(getTasks(
            {
                location_id: locationSelectedId
            }
        ));
    }

    const cleanInputs = () => {
        setValues({
            date: new Date(),
            area: "",
            detail: "",
            task: ""
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="general-outings-form grid grid-cols-2 gap-4 mt-5">
                <div className="grid grid-cols-1 items-center gap-1">
                    <Label 
                        htmlFor={"task"} 
                        className="text-right justify-self-start"
                        style={{ color: "#6B7280" }}
                    >
                        { t("cleaning.form.detailed.form.task") }
                    </Label>

                    <Select 
                        onValueChange={handleTaskChange} 
                        value={values.task}
                        disabled={!tasks.length}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder={t("cleaning.form.detailed.form.taskPlaceholder")} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    tasks.length ? (
                                        tasks.map(value => <SelectItem value={value.id}>{value.name}</SelectItem>)
                                    ) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                        <Label 
                            htmlFor={"date"} 
                            className="text-left"
                            style={{ color: "#6B7280" }}
                        >
                            { t("cleaning.form.detailed.form.date") }
                        </Label>

                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                variant={"outline"}
                                className={cn(
                                    "w-[280px] justify-start text-left font-normal w-100",
                                    !values.date && "text-muted-foreground"
                                )}
                                >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {values.date ? format(values.date, "MM/dd/yyyy - hh:mm aa") : <span>mm/dd/yyyy - hh:mm aa</span>}
                                </Button>
                            </PopoverTrigger>

                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                value={values.date}
                                timePicker={true}
                                onChange={handleDateChange}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label 
                        htmlFor={"area"} 
                        className="text-right justify-self-start"
                        style={{ color: "#6B7280" }}
                    >
                        { t("cleaning.form.detailed.form.area") }
                    </Label>

                    <Select 
                        onValueChange={handleAreaChange} 
                        value={values.area}
                        disabled={!tasks.length}
                        
                    >
                        <SelectTrigger>
                            <SelectValue placeholder={t("cleaning.form.detailed.form.areaPlaceholder")} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    areas.length ? (
                                        areas.map(value => <SelectItem value={value.id}>{value.name}</SelectItem>)
                                    ) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label 
                        htmlFor={"detail"} 
                        className="text-right justify-self-start"
                        style={{ color: "#6B7280" }}
                    >
                        { t("cleaning.form.detailed.form.detail") }
                    </Label>

                    <Input
                        id={"detail"}
                        name={"detail"}
                        type="text"
                        value={values.detail}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="w-100 mt-4 flex flex-row justify-end">
                <Button type="submit" className="mr-2">
                    { t("cleaning.form.detailed.form.submit") }
                </Button>
            </div>
        </form>
    );
} 
