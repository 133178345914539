import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
 import { Label } from '@/common/presentation/components/ui/label';
 import { Input } from '@/common/presentation/components/ui/input';
import { useEffect, useState } from 'react';
import { Pencil } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { getAreas, updateArea } from '../../slices/TrackerCleaningSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { Checkbox } from '@/components/ui/checkbox';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

export function TrackerCleaningAreaEditDialog(props: { id: string, name: string, status: string }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { toast } = useToast();
    const { locationSelectedId } = useLocation();
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        name: "",
        status: false
    });

    useEffect(() => {
        setValues({
            name: props.name,
            status: props.status === "active" ? true : false
        });
    }, []);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleCheckboxChange = (event: any) => {
        setValues({
            ...values,
            status: event
        });
    }

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = () => {
        if(values.name) {
            dispatch(updateArea(
                {
                    id: props.id,
                    ...values,
                    status: values.status ? 'active' : 'inactive'
                }
            ));

            fetchData();
            setOpenEdit(false);

            toast(
                {
                    description: t("cleaning.create.area.edit.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.create.area.edit.toast.required")
                }
            );
        }

        fetchData();

        return true;
    }

    const fetchData = () => {
        dispatch(getAreas(
            {
                location_id: locationSelectedId
            }
        ));
    };

    return (
        <div>
            <Button onClick={() => openEditDialog()} variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.edit.title")}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
            >
                <div>
                    <Label htmlFor="name" className="text-right">
                        {t("cleaning.create.area.edit.form.name")}
                    </Label>

                    <Input
                        id="name"
                        name="name"
                        defaultValue="@peduarte"
                        className="col-span-3"
                        value={values.name}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="flex items-center space-x-2 mt-2">
                    <Checkbox id="terms" onCheckedChange={handleCheckboxChange} checked={values.status} />

                    <label
                        htmlFor="terms"
                        className="text-sm font-medium peer-disabled:opacity-70"
                    >
                        Active
                    </label>
                </div>

                <div className='flex justify-end'>
                    <Button type="submit" onClick={handleEdit}>
                        { t("trackers.trackercleaning.accept") }
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};
