import useRouteParams from "@/common/hooks/RouteParamsHook";
import { RootState } from "@/store/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/common/presentation/components/ui/table"
import { format } from "date-fns";
import { DeleteOutingsTrackerReportDialog } from "./DeleteOutingsTrackerReportDialog";
import { GeneralOutingsEditDialog } from "./GeneralOutingsEditDialog";
import { Search } from "lucide-react";
import { t } from "i18next";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";

/*
  [
      { label: 'Date/Time', value: 'date', type: 'date' },
      { label: 'Resident (link)', value: 'resident' },
      { label: 'Outing / Activity', value: 'activity' },
      { label: 'Duration', value: 'duration' },
      { label: 'Staff', value: 'staff' },
      { label: 'Notes', value: 'note' }
  ]
*/

export function GeneralOutingsTable() {
  const { params, setParams } = useRouteParams();
  const dispatch = useDispatch();
  const tracker = useSelector((state: RootState) => state.tracker);
  const { locationSelectedId } = useLocation();
  
  const fetchData = async () => {
    await dispatch(trackerActions.getTrackerOutingsGeneral(
        {
          "location_id": locationSelectedId
        }
      )
    );
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {
        tracker.generalReport.length ? (
          <Table>
            <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
              <TableRow className="bg-primary/80 hover:bg-primary/80">
                <TableHead className="text-white">Resident</TableHead>
                <TableHead className="text-white">Date/Time</TableHead>
                <TableHead className="text-white">Outing / Activity</TableHead>
                <TableHead className="text-white">Duration</TableHead>
                <TableHead className="text-white">Staff</TableHead>
                <TableHead className="text-white">Notes</TableHead>
                <TableHead className="text-white">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {
                tracker.generalReport.length && tracker.generalReport.map(
                  element =>
                    <TableRow>
                      <TableCell>{element.data.resident_id.first_name + " " + element.data.resident_id.last_name}</TableCell>
                      <TableCell>{format(new Date(element.data.date), "dd/MM/yyyy hh:mm a")}</TableCell>
                      <TableCell>{element.data.outingActivity}</TableCell>
                      <TableCell>{element.data.duration}</TableCell>
                      <TableCell>{element.data.staff}</TableCell>
                      <TableCell>{element.data.notes}</TableCell>
                      <TableCell className="flex">
                        <GeneralOutingsEditDialog data={element}/>
                        <DeleteOutingsTrackerReportDialog id={element.id} id_data={element.data.id}/>
                      </TableCell>
                    </TableRow>
                )
              }
            </TableBody>
          </Table>
        ) : (
          <div className="flex flex-col w-100 justify-center align-center">
            <div className="flex justify-center items-center">
                <Search className="text-center w-5 mb-2" />
            </div>

            <h1 className="text-center">
                {t("cleaning.report.summary.table.notFound")}
            </h1>
          </div>
        )
      }
    </div>
  );
}