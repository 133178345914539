import { useConfirm } from '@/common/hooks/useConfirm';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { Textarea } from '@/common/presentation/components/ui/textarea';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Loader2 } from 'lucide-react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z.object({
  termination_date: z.date({
    required_error: 'Termination date is required.'
  }),
  reason_termination: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

const rolesToValidate = ['Super Administrator', 'Executive'];

export const TerminationForm = ({ className }: Props) => {
  const [ConfirmDialog, confirm] = useConfirm(
    'Are you sure?',
    'You are about to change the termination date of the staff member. This action will innactivate the staff member. Do you want to continue?'
  );

  const dispatch = useDispatch<AppDispatch>();
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMemberForm);
  const { user } = useSelector((state: RootState) => state.auth);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      termination_date: undefined,
      reason_termination: ''
    },
    mode: 'onChange'
  });

  useEffect(() => {
    if (staffMember) {
      form.reset({
        termination_date: staffMember.termination_date ? new Date(staffMember.termination_date) : undefined,
        reason_termination: staffMember.reason_termination || ''
      });
    }
  }, [staffMember]);

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  const onSubmitForm = async (data: FormValues) => {
    if (staffFormStatus !== 'loading') {
      const ok = await confirm();

      if (ok) {
        const response = await dispatch(
          staffMemberFormActions.updateStaffMemberTerminationForm({
            staff_member_id: staffMember?.id,
            ...data
          })
        );

        if (response.payload) {
          toast({
            description: 'Staff information updated successfully'
          });

          form.reset(data);
        }

        if (response.meta.requestStatus === 'rejected') {
          toast({
            description: 'Failed to update staff information',
            variant: 'destructive'
          });
        }
      }
    }
  };

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Termination">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="flex flex-col gap-4 px-2 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="termination_date"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Termination date <span className="text-primary">*</span>
                          </FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={'outline'}
                                  className={cn(
                                    'w-full pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                  disabled={staffFormStatus === 'loading' || !isAdmin}
                                >
                                  {field.value ? (
                                    format(field.value, 'MM/dd/yyyy hh:mm a')
                                  ) : (
                                    <span>Select a date </span>
                                  )}
                                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <div className="rounded-md border">
                                <CustomCalendar
                                  onChange={(date) => {
                                    field.onChange(date);
                                  }}
                                  selectedDate={field.value}
                                  MonthAndYearPicker
                                  timePicker
                                />
                              </div>
                            </PopoverContent>
                          </Popover>

                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="reason_termination"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Reason of termination</FormLabel>
                          <FormControl>
                            <Textarea
                              className="min-h-[40px] h-[40px]"
                              {...field}
                              disabled={staffFormStatus === 'loading' || !isAdmin}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                {form.formState.isDirty && (
                  <div className="mt-6 flex justify-end space-x-2">
                    <Button type="submit" disabled={staffFormStatus === 'loading' || !form.formState.isValid}>
                      {staffFormStatus === 'loading' && <Loader2 className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </div>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}

      <ConfirmDialog />
    </>
  );
};
