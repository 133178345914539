import { Users } from 'lucide-react';
import { AlertCard } from '../alert-card';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import AlertsSkeleton from '../skeleton';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useTranslation } from 'react-i18next';

interface IResidentAlertsProps {
};

export const ResidentAlerts: React.FC<IResidentAlertsProps> = () => {
  const residentAlerts = useSelector((state: RootState) => state.dashboard.residentAlerts);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();

  return (
    <>
      <AlertCard title={t('dashboard.residentAlerts')} alerts={residentAlerts?.data ?? []} icon={Users} custom={true}>
        {residentAlerts.status === 'loading' ? (
          <AlertsSkeleton />
        ) : residentAlerts?.data?.length === 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          residentAlerts?.data?.map((alert, index) => (
            <div key={index}>
              <div className="py-1 px-2 rounded-sm hover:bg-primary/5 cursor-pointer overflow-hidden text-ellipsis ">
                <span className="text-primary font-semibold">{formatDate(alert.date, false)}</span> -
                <span className="text-primary font-semibold ml-1">
                  {UserHelper.getResidentName(alert.resident_id, residents)}
                </span>{' '}
                -
                <span className="ml-1">
                  {t(`dashboard.${alert.alert_key}`, { value: alert.value, evaluator: alert.evaluator })}
                </span>
              </div>
            </div>
          ))
        )}
      </AlertCard>
    </>
  );
};
