import { useEffect, useRef } from "react";
import { AppDispatch, RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Button } from "@/components/ui/button";
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import { toast } from "@/common/presentation/components/ui/use-toast.ts";
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';

import {
    getPermissionsUserMedication,
    allowUsersEditMedicationLoading,
    updateUserPermissionsMedication,
    getPermissionsMedications
} from '@/modules/settings/presentation/components/menus/medications/slices/MedicationConfigurationSlice';
import { getClientConfigurations } from "@/common/presentation/slices/configurationSlice";
import { useTranslation } from 'react-i18next';

interface IAllowUsers {
    name: string;
    userId: string;
    label?: string;
    status: boolean;
}

interface FormValues {
    [key: string]: string[];
}

export const AllowStaffPermissionsMedication = () => {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { userPermissions, permissionsMedications, isLoading } = useSelector((state: RootState) => state?.settings.medicationConfiguration);

    const form = useForm<FormValues>({
        defaultValues: {},
    });

    const onSubmit = async (data: FormValues) => {
        
        const response = await dispatch(updateUserPermissionsMedication(data));
        if (response?.payload?.status === 'ok') {
            toast({
                description: 'updated successfully',
                className: 'bg-green-500 text-white z-[99999]'
            });

            dispatch(getClientConfigurations());
        }
    };


    const selectedOptions = (value: any) => {
        if (!value) return [];

        return value.map((pId: any) => {
            const permissionSelected: any = permissionsMedications?.find((p: any) => p.id === pId);
            if (permissionSelected) {
                return {
                    value: permissionSelected.id,
                    label: t('settings.allowStaffPermissionsMedication.'+permissionSelected.permission_key)
                };
            }
        });
    }

    useEffect(() => {
        if (userPermissions.length > 0) {
            const defaultValues: FormValues = userPermissions.reduce((acc, userPermissions: any) => {
                acc[userPermissions.userId] = userPermissions.permissions;
                return acc;
            }, {} as FormValues);

            form.reset(defaultValues);
        }
    }, [userPermissions, form]);


    useEffect(() => {
        dispatch(allowUsersEditMedicationLoading());
        dispatch(getPermissionsMedications({}));
        dispatch(getPermissionsUserMedication());
    }, [dispatch]);



    return (
        <>
            {isLoading && (
                <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
                    <Spinner size={'icon'} />
                </div>
            )}

            {!isLoading && (
                <div className="flex flex-col h-[100%]">
                    <Form {...form}>
                        <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col h-[100%]">
                            <ScrollArea className="pr-4 h-full">
                                <div className="space-y-5 pb-2">
                                    {userPermissions && userPermissions.map((user: IAllowUsers) => (
                                        <FormField
                                            key={user.userId}
                                            control={form.control}
                                            name={user.userId}
                                            render={({ field }) => (
                                                <FormItem className="flex flex-col rounded-lg border p-4">
                                                    <FormLabel className="text-base">{user.label}</FormLabel>
                                                    <FormControl>
                                                        <MultiSelect
                                                            name={'name_multi_select_' + user.userId}
                                                            placeHolder={t('settings.allowStaffPermissionsMedication.assingPermission')}
                                                            options={permissionsMedications.map((permission: any) => ({
                                                                value: permission.id,
                                                                label: t(`settings.allowStaffPermissionsMedication.${permission.permission_key}`),
                                                            }))}
                                                            selectedOptions={selectedOptions(field.value)}
                                                            onChange={(selectedOptions) => {
                                                                const values = selectedOptions.map((option: any) => option.value);
                                                                form.setValue(user.userId, values);
                                                            }}
                                                            className="text-sm font-medium w-full"
                                                            customMessage={'not found '}
                                                        />
                                                    </FormControl>
                                                </FormItem>
                                            )}
                                        />
                                    ))}
                                </div>
                            </ScrollArea>
                            <div className="mt-4 flex justify-end">
                                <Button type="submit">{t('settings.save')}</Button>
                            </div>
                        </form>
                    </Form>
                </div>
            )}
        </>
    );
};

export default AllowStaffPermissionsMedication;
