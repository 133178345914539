import { Input } from "@/components/ui/input";
import QuickTrackerInputsProps from "../interfaces/QuickTrackerInputsPropsInterface";

const QuickTrackerInputText = ({
    item,
    resident,
    type,
    exist,
    existOnList,
    onselectvalue
}: QuickTrackerInputsProps) => {
    return (
        <Input 
            key={item} 
            type="text" 
            style={{ maxWidth: "15rem" }}
            className="focus-visible:ring-0 focus-visible:ring-offset-0"
            value={item && !existOnList ? item.action : null}
            onChange={
                (event) => onselectvalue(
                    event.currentTarget.value, 
                    resident, 
                    type, 
                    exist,
                    exist ? item.id : undefined,
                    exist ? item.id_data : undefined
                )
            } 
        />
    );
};

export default QuickTrackerInputText;
