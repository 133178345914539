import { useState } from "react";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "@/components/ui/table";
import { useTranslation } from "react-i18next";
import QuickTrackerResident from "./interfaces/QuickTrackerResidentInterface";
import QuickTrackerItem from "./interfaces/QuickTrackerItemInterface";
import QuickTrackerType from "./interfaces/QuickTrackerTypeInterface";
import QuickTrackerProps from "./interfaces/QuickTrackerPropsInterface";
import QuickTrackerService from "./services/QuickTrackerService";
import QuickTrackerTableHeader from "./components/QuickTrackerTableHeader";
import QuickTrackerInput from "./components/QuickTrackerInput";
import { Button } from "@/components/ui/button";

const QuickTracker = ({ types, residents, loading, submit, data, input }: QuickTrackerProps) => {
    const [newItems, setNewItems] = useState<QuickTrackerItem[]>([]);
    const { t } = useTranslation();

    const save = () => {
        submit([...newItems]);
        setNewItems([]);
    };

    const insertNewValue = (action: string, resident: QuickTrackerResident, type: QuickTrackerType, exist: boolean, id?: string, id_data?: string) => {
        setNewItems(
            QuickTrackerService.insertNewValue(newItems, action, resident, type, exist, id, id_data)
        );
    };

    return (
        <LoadingWrapper loading={loading}>
            <Table className="shadow-lg">
                <TableHeader style={{ position: "sticky", top: "0", zIndex: "50" }}>
                    <QuickTrackerTableHeader residents={residents} />
                </TableHeader>

                <TableBody className="bg-white dark:bg-background">
                    {
                        types.length ? (
                            types.map(
                                (type, index) => (
                                    <>
                                        <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800 border-b border-gray-300">
                                            <TableCell 
                                                className="bg-white" 
                                                style={{ 
                                                    position: "sticky", 
                                                    top: "auto", 
                                                    left: 0, 
                                                    zIndex: "10", 
                                                    padding: "0.25rem", 
                                                    borderRight: "#e5e7eb solid 1px" 
                                                }}
                                            >
                                                <span className="ml-2">{ type.name }</span>
                                            </TableCell>

                                            {
                                                residents.length ? (
                                                    residents.map(
                                                        (resident, index) => (
                                                            <TableCell 
                                                                key={index} 
                                                                style={{ padding: "0.25rem" }}
                                                            >
                                                                <div className="flex justify-center align-center">
                                                                    <QuickTrackerInput 
                                                                        currentItems={newItems}
                                                                        resident={resident} 
                                                                        type={type} 
                                                                        data={data} 
                                                                        onselectvalue={insertNewValue}
                                                                        input={input}
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                        )
                                                    )
                                                ) : null
                                            }
                                        </TableRow>
                                    </>
                                )
                            )
                        ) : null
                    }

                    <TableRow>
                        <TableCell style={{ padding: "1.5rem" }}></TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Button 
                onClick={save} 
                className="mb-1 mr-1"
                style={{ position: "absolute", bottom: 0, right: 0 }}
            >
                {t('trackers.Save Quick Tracker')}
            </Button>
        </LoadingWrapper>
    );
};

export default QuickTracker;
