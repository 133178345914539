export const FingerPrintHelper = {
  getFingerPrint: async (): Promise<{
    finger_print: string | null;
    browser: string;
    agent: string;
    operative_system: string;
    device_type: string;
    full_data: any[];
  } | null> => {
    try {
      const userAgent = navigator.userAgent;
      const browser = getBrowser(userAgent);
      const operativeSystem = getOperatingSystem(userAgent, navigator?.platform);
      const deviceType = getDeviceType(userAgent);

      const components: (string | number | boolean)[] = [
        userAgent,
        screen.colorDepth,
        new Date().getTimezoneOffset(),
        navigator.platform || '',
        navigator.vendor || ''
      ];

      const canvas = document.createElement('canvas');
      const gl = canvas.getContext('webgl');
      if (gl) {
        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        if (debugInfo) {
          components.push(gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL));
          components.push(gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL));
        }
      }

      const fingerprintString = components.join('###');
      const encoder = new TextEncoder();
      const data = encoder.encode(fingerprintString);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

      return {
        finger_print: hashHex,
        browser,
        agent: userAgent,
        operative_system: operativeSystem,
        device_type: deviceType,
        full_data: components
      };
    } catch (error) {
      console.error('Error generating fingerprint:', error);
      return null;
    }
  }
};

const getBrowser = (userAgent: string): string => {
  if (userAgent.includes('Edg/')) return 'Microsoft Edge';
  if (userAgent.includes('OPR/') || userAgent.includes('Opera')) return 'Opera';
  if (userAgent.includes('Brave')) return 'Brave';
  if (userAgent.includes('Vivaldi')) return 'Vivaldi';
  if (userAgent.includes('YaBrowser')) return 'Yandex Browser';
  if (userAgent.includes('UCBrowser')) return 'UC Browser';
  if (userAgent.includes('SamsungBrowser')) return 'Samsung Browser';
  if (userAgent.includes('Chrome')) {
    if (userAgent.includes('Chromium')) return 'Chromium';
    return 'Google Chrome';
  }

  if (userAgent.includes('Firefox')) {
    if (userAgent.includes('Seamonkey')) return 'Seamonkey';
    if (userAgent.includes('Waterfox')) return 'Waterfox';
    if (userAgent.includes('PaleMoon')) return 'Pale Moon';
    return 'Mozilla Firefox';
  }

  if (userAgent.includes('Safari')) {
    if (userAgent.includes('Android')) return 'Android Browser';
    if (userAgent.includes('Coast')) return 'Coast by Opera';
    return 'Safari';
  }

  if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
    const version = userAgent.match(/MSIE\s?(\d+\.\d+)/);
    if (version) return `Internet Explorer ${version[1]}`;
    return 'Internet Explorer';
  }

  if (userAgent.includes('DuckDuckGo')) return 'DuckDuckGo Browser';
  if (userAgent.includes('QQBrowser')) return 'QQ Browser';
  if (userAgent.includes('Maxthon')) return 'Maxthon';
  if (userAgent.includes('Dolphin')) return 'Dolphin';
  if (userAgent.includes('FBAV') || userAgent.includes('FB_IAB')) return 'Facebook In-App Browser';
  if (userAgent.includes('Instagram')) return 'Instagram In-App Browser';
  if (userAgent.includes('Line')) return 'Line In-App Browser';
  if (userAgent.includes('KAKAOTALK')) return 'KakaoTalk In-App Browser';
  if (userAgent.includes('WeChat') || userAgent.includes('MicroMessenger')) return 'WeChat Browser';

  if (userAgent.includes('Gecko/')) return 'Gecko-based Browser';
  if (userAgent.includes('WebKit/')) return 'WebKit-based Browser';
  if (userAgent.includes('Presto/')) return 'Presto-based Browser';

  return 'Unknown';
};

const getOperatingSystem = (userAgent: string, platform?: string): string => {
  if (userAgent.includes('Win')) return 'Windows';
  if (userAgent.includes('Mac')) return 'MacOS';
  if (userAgent.includes('Linux')) return 'Linux';
  if (userAgent.includes('Android')) return 'Android';
  if (userAgent.includes('like Mac')) return 'iOS';

  if (platform) {
    if (platform.includes('Win')) return 'Windows';
    if (platform.includes('Mac')) return 'MacOS';
    if (platform.includes('Linux')) return 'Linux';
    if (platform.includes('Android')) return 'Android';
    if (platform.includes('like Mac')) return 'iOS';
  }
  return 'Unknown';
};

const getDeviceType = (userAgent: string): string => {
  if (userAgent.includes('Mobi') || userAgent.includes('Android')) {
    return 'phone';
  }
  if (userAgent.includes('Tablet')) {
    return 'tablet';
  }
  return 'computer';
};
