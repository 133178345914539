import { z } from 'zod';

export const FormSchema = z.object({
  title: z.string().optional()
});

export interface IContactsResumeData {
  resident_id: string;
  location_id: string;
  page: number;
  per_page: number;
  title?: string;
}
