const NoteHelperService = {
  defineState: (state = {}) => {
    let defaultState = {
      isCreating: false,
      isUpdating: false,
      isFinishingDraft: false,
      showingDrafts: false,
      showingStaffNotes: false,
      isCreatingStaffNote: false,
      isUpdatingStaffNote: false,
      itemId: '',
      action: () => { },
    };
    return {
      ...defaultState,
      ...state
    };
  },
  resizePanel: (state: any) => {
    let value = [440, 0];
    if (
      state.isCreating ||
      state.isCreatingStaffNote ||
      state.isUpdating ||
      state.isUpdatingStaffNote ||
      state.isFinishingDraft
    ) {
      value = [0, 655];
    }
    return value;
  },
  decodeHTMLEntities(text: string) {
    let element = document.createElement('textarea');
    element.innerHTML = text;
    return element.value;
  }
};

export default NoteHelperService;
