import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import QuickTrackerInputsProps from "../interfaces/QuickTrackerInputsPropsInterface";

const QuickTrackerInputSelect = ({
    item,
    resident,
    type,
    exist,
    existOnList,
    onselectvalue
}: QuickTrackerInputsProps) => {
    return (
        <Select 
        key={item} 
        onValueChange={
            (value) => {
                onselectvalue(
                    value, 
                    resident, 
                    type, 
                    exist,
                    exist ? item.id : undefined,
                    exist ? item.id_data : undefined
                )
            }
        }>
            <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0" style={{ maxWidth: "15rem" }}>
                {
                    item && !existOnList ? (
                        <SelectValue placeholder={item.action}>
                            {item.action}
                        </SelectValue>
                    ) : (
                        <SelectValue></SelectValue>
                    )
                }
            </SelectTrigger>

            <SelectContent>
                <SelectItem value="Done">Done</SelectItem>
                <SelectItem value="Refused">Refused</SelectItem>
            </SelectContent>
        </Select>
    );
};

export default QuickTrackerInputSelect;
