import ResidentService from '@/modules/residents/infrastructure/services/ResidentsService';
import { create } from 'zustand';

type DashboardResidentsState = {
  inactiveResidents: any[];
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  getInactiveResidents: (lcoationId: string | undefined) => Promise<any>;
};

export const useDashboardResidentsStore = create<DashboardResidentsState>((set) => ({
  inactiveResidents: [],
  status: 'idle',
  error: null,

  getInactiveResidents: async (locationId) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentService.getInactiveResidents(locationId);
      set({ status: 'idle', inactiveResidents: response.data.inactive_residents });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
