import React, { useEffect } from 'react';
import { CSS } from '@dnd-kit/utilities';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

import {
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable';
import { TableVirtuoso } from 'react-virtuoso';
// import { cn } from '@/lib/utils';
// import { t } from 'i18next';
// import { Skeleton } from '../ui/skeleton';

interface SortableItemProps {
    id: number;
    props?: any;
    children?: React.ReactNode;
    index?: number;
    //Here you must set the number of columns that you do not want the drag cell or dragging to work in the most common case “the column of the buttons”.
    disableDragCells?: number[]; 
}

interface SortableTableProps {
    data: any[];
    isLoading?: boolean;
    itemContent: (index: number) => React.ReactNode;
    fixedHeaderContent: () => React.ReactNode;
    handleEndReached: () => void;
    handleDragEnd: (result: any) => void;
    totalCount: number;
    className: string;
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, props, children, disableDragCells = [] }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: String(id) });

    const style = {
        ...props?.style,
        transform: CSS.Transform.toString(transform),
        transition,
    };

    
    return (
        <>
            {React.Children.map(children, (child, index) => {
                const childElement = child as React.ReactElement;
                const isDisabledCell = disableDragCells.includes(index);
                
                if (isDisabledCell) {
                    // For cells where we want to disable dragging
                    return React.cloneElement(childElement, {
                        ref: setNodeRef,
                        style,
                        className: `${childElement.props.className || ''}`,
                        onClick: (e: React.MouseEvent) => {
                            e.stopPropagation();
                            // Allow original cell click to work
                            childElement.props.onClick?.(e);
                        },
                    });
                }

              // For the rest of the cells, keep the drag behavior.
                return React.cloneElement(childElement, {
                    ref: setNodeRef,
                    style,
                    ...attributes,
                    ...listeners,
                    className: `cursor-pointer ${childElement.props.className || ''}`,
                });
            })}
        </>
    );
};

export const SortableTable: React.FC<SortableTableProps> = ({
    data,
    isLoading,
    fixedHeaderContent,
    handleEndReached,
    handleDragEnd,
    totalCount,
    itemContent,
    className

}) => {

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 300, // 300ms before dragging is activated
                tolerance: 10,  // Finger can move up to 10px before dragging is activated
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={data.map(item => String(item.id))}
                strategy={verticalListSortingStrategy}
            >
                <TableVirtuoso
                    totalCount={totalCount}
                    data={data?.length == 0 ? [null] : data}
                    itemContent={(index) => itemContent(index)}
                    fixedHeaderContent={fixedHeaderContent}
                    className={className}
                    endReached={handleEndReached}
                    overscan={200}
                    style={{ borderRadius: '0.5rem' }}
                />
            </SortableContext>
        </DndContext>
    );
};

