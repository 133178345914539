import React from 'react';
import { Separator } from '@/common/presentation/components/ui/separator';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useMedicationStore } from '@/modules/emar/domain/stores/useMedicationStore';
import { usePrnDialog } from '@/modules/emar/infrastructure/hooks/UsePrnDialog';
import { PrnDialogForm } from '@/modules/emar/presentation/components/forms/PrnDialogForm';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';

export const NewPrnDialog = () => {
  const { isOpen, onClose, data, locationId } = usePrnDialog();

  const { residents } = useSelector((state: RootState) => state.residents.allResidents);

  const resident = residents.find((resident) => resident.id === data?.resident_id);
  const { dispensePrnMedication, status: medicationCardStatus } = useMedicationStore();

  const onSubmit = async (values: any) => {
    try {
      await dispensePrnMedication(
        values.time_given,
        values.follow_up,
        values.reason,
        values.level_of_pain,
        values.result,
        values.comments,
        resident?.id,
        locationId,
        data?.id,
        data?.dispense_id
      );

      onClose();
      toast({
        description: 'Medication dispensed successfully'
      });
    } catch (error: any) {
      toast({
        description: 'Failed to dispense medication',
        variant: 'destructive'
      });
    }
  };

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      title={`PRN Medication for ${resident?.first_name} ${resident?.last_name}`}
      description=""
      className="md:max-w-[625px] lg:max-w-[625px]"
      newDialog={true}
      overFlow={true}
    >
      <>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
          <div>
            <Label className="font-bold block">Medications: </Label>
            <p className="mt-1 text-gray-700">{data?.medication_name || ''}</p>
          </div>
          <div>
            <Label className="font-bold block">Strength Per Tab: </Label>
            <p className="mt-1 text-gray-700">{data?.strength || ''}</p>
          </div>
          <div>
            <Label className="font-bold block">Frequency (PRN) Hrs: </Label>
            <p className="mt-1 text-gray-700">{data?.frequency || ''}</p>
          </div>
          <div>
            <Label className="font-bold block">Instructions: </Label>
            <p className="mt-1 text-gray-700">{data?.instruction || ''}</p>
          </div>
          <div>
            <Label className="font-bold block">Special instructions: </Label>
            <p className="mt-1 text-gray-700">{data?.special_instructions || ''}</p>
          </div>
          <div>
            <Label className="font-bold block">Purpose of Medication: </Label>
            <p className="mt-1 text-gray-700">{data?.purpose || ''}</p>
          </div>
        </div>

        <Separator className="my-6" />

        <PrnDialogForm
          defaultValues={{
            time_given: data?.dispensed_at || new Date(),
            follow_up: data?.follow_up || '',
            reason: data?.reason || '',
            result: data?.result || '',
            comments: data?.comments || '',
            level_of_pain: data?.level_of_pain || 0
          }}
          onSubmit={onSubmit}
          disabled={medicationCardStatus === 'loading'}
        />
      </>
    </CustomDialog>
  );
};
