import { Button } from '@/common/presentation/components/ui/button';
import { Pencil, Trash } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { deleteArea, getAreas } from '../../slices/TrackerCleaningSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

export function TrackerCleaningAreaDeleteDialog(props: { id: string }) {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [openDelete, setOpenDelete] = useState(false);

    const openDeleteDialog = () => {
        setOpenDelete(true);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = () => {
        dispatch(deleteArea(
            {
                id: props.id
            }
        ));

        fetchData();
        setOpenDelete(false);

        return true;
    }
    
    const fetchData = () => {
        dispatch(getAreas(
            {
                location_id: locationSelectedId
            }
        ))
    }

    return (
        <div>
            <Button onClick={() => openDeleteDialog()} variant="outline" size="icon" className='mr-2'>
                <Trash className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.delete.title")} 
                description={t("cleaning.report.summary.delete.description")} 
                width="30rem"
                newDialog={true}
                isOpen={openDelete}
                onClose={closeDeleteDialog}
            >
                <div className='flex justify-end'>
                    <Button type="submit" onClick={handleDelete}>
                        { t("trackers.trackercleaning.accept") }
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};
