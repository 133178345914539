import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { DocumentResourceResponse, DocumentResourcesParams } from '../types/documents';

const ResidentFormService = {
  createCategory: async (data: any) => {
    const response = await axios.post(`/residents/documents/category/${data.category_id}`, { ...data });
    return response;
  },

  getCategories: async () => {
    const response = await axios.get(`/residents/documents/categories`);
    return response;
  },

  getResource: async (data: DocumentResourcesParams) => {
    const response = await axios.get<DocumentResourceResponse>(`/documents/resource`, { params: { ...data } });
    return response;
  }
};

export default ResidentFormService;
