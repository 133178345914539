import { cn } from '@/lib/utils';
import { MenuIcon } from 'lucide-react';
import { ReactNode, useMemo } from 'react';
import { LanguageMode } from '@/common/presentation/components/layout/navbar/components/LanguageMode';
import { ThemeToggle } from '@/common/presentation/components/layout/navbar/components/ThemeToggle';
import { UserItem } from '@/common/presentation/components/layout/navbar/components/UserItem';
import { Notifications } from '@/common/presentation/components/layout/navbar/components/Notifications';
import SettingsButton from './components/SettingsButton';
import LogOutButton from './components/LogOutButton';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useRoleHelper } from '@/auth/infrastructure/providers/RoleHelperProvider';

interface NavbarProps {
  className?: ReactNode;
  isCollapsed: boolean;
  onResetWidth: () => void;
}

const Navbar = ({ className, isCollapsed, onResetWidth }: NavbarProps) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const { hasAnyRole } = useRoleHelper();

  const canModifyClient =
    useMemo(() => {
      return !hasAnyRole(['Pharmacy']);
    }, [user]) ?? false;
    
  return (
    <nav className={cn('bg-primary w-full px-3 flex items-center gap-x-2', className)}>
      {isCollapsed && (
        <MenuIcon
          role="button"
          onClick={onResetWidth}
          className="h-6 w-6 text-secondary dark:text-secondary-foreground"
        />
      )}

      <div className="flex items-center justify-between w-full">
        <div className="flex-1" />
        <div className="flex items-center gap-x-2">
          <LanguageMode />
          <ThemeToggle />
          <Notifications />
          {canModifyClient && <SettingsButton />}
          <UserItem />
          <LogOutButton />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
