

import { Input } from '@/components/ui/input';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from "@/components/ui/button";


interface FiltersTableProps {
  onFilterChange: (value: string) => void;
  filterValue: string;
  onClear: () => void;
}

export const FiltersTable: React.FC<FiltersTableProps> = ({ 
  onFilterChange, 
  filterValue,
  onClear 
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full relative">
      <Input
        type="text"
        id='title'
        placeholder={t('settings.visitor.questions.searchTitle')}
        className="w-full h-10 rounded-lg border-gray-300 px-4 py-2 text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
        value={filterValue}
        onChange={(e) => onFilterChange(e.target.value)}
      />
      {filterValue && (
        <Button
          onClick={onClear}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 bg-transparent hover:bg-transparent border-none shadow-none text-gray-500"
          variant="outline"
        >
          <X className="w-4 h-4" />
        </Button>
      )}
    </div>
  );
};