import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/common/presentation/components/ui/tooltip';

const variants = {
  base: 'relative rounded-md flex justify-center items-center flex-col cursor-pointer border-2 border-dashed border-gray-300 bg-gray-100 dark:bg-gray-800 dark:border-gray-600 transition-colors duration-200 ease-in-out',
  active: 'border-blue-500',
  disabled: 'bg-gray-200 border-gray-300 cursor-default pointer-events-none bg-opacity-30 dark:bg-gray-700',
  accept: 'border-blue-500 bg-blue-500 bg-opacity-10',
  reject: 'border-red-700 bg-red-700 bg-opacity-10'
};

type InputProps = {
  width?: number;
  height?: number;
  className?: string;
  onChange?: (files: File[]) => void;
  disabled?: boolean;
  dropzoneOptions?: any;
  initialImages?: { image: string }[];
};
const CustomImages = React.forwardRef<HTMLInputElement, InputProps>(
  ({ dropzoneOptions, width, height, className, disabled, onChange, initialImages = [] }, ref) => {
    const [files, setFiles] = React.useState<File[]>([]);

    React.useEffect(() => {
      const fetchFiles = async () => {
        if (!Array.isArray(initialImages) || initialImages.length === 0) {
          return;
        }

        const fetchedFiles: File[] = [];

        for (const { image, name } of initialImages) {
          try {
            const response = await fetch(image);
            const blob = await response.blob();
            const file = new File([blob], name || 'file', { type: blob.type });
            fetchedFiles.push(file);
          } catch (error) {
            console.error('Error fetching file:', error);
          }
        }

        setFiles(fetchedFiles);
        if (onChange) {
          onChange(fetchedFiles);
        }
      };

      if (initialImages.length > 0) {
        fetchFiles();
      }
    }, [initialImages, onChange]);

    const onDrop = React.useCallback(
      (acceptedFiles: File[]) => {
        const newFiles = [...files, ...acceptedFiles];
        setFiles(newFiles);
        onChange && onChange(newFiles);
      },
      [files, onChange]
    );

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      accept: { 'image/*': [], 'application/pdf': [] },
      multiple: true,
      disabled,
      onDrop,
      ...dropzoneOptions
    });

    const dropZoneClassName = React.useMemo(
      () =>
        twMerge(
          variants.base,
          isFocused && variants.active,
          disabled && variants.disabled,
          isDragReject && variants.reject,
          isDragAccept && variants.accept,
          className
        ).trim(),
      [isFocused, isDragAccept, isDragReject, disabled, className]
    );

    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [openPreview, setOpenPreview] = React.useState(false);

    const handleFileClick = (file: File) => {
      setSelectedFile(file);
      setOpenPreview(true);
    };

    const handleClosePreview = () => {
      setOpenPreview(false);
    };

    const handleRemoveFile = (fileToRemove: File) => {
      setFiles((prevFiles) => {
        const newFiles = prevFiles.filter((file) => file !== fileToRemove);
        onChange && onChange(newFiles);
        return newFiles;
      });
    };

    const truncateFileName = (name: string, maxLength: number = 20) => {
      if (name.length <= maxLength) return name;
      return name.slice(0, maxLength - 3) + '...';
    };

    return (
      <TooltipProvider>
        <div className="flex flex-col items-center justify-center w-full max-w-3xl mx-auto">
          <h2 className="text-lg font-semibold mb-2">Upload Images.</h2>
          <div className="w-full border-2 border-dashed border-gray-300 rounded-md flex flex-col items-center justify-center mb-8">
            <div
              {...getRootProps({
                className: dropZoneClassName,
                style: {
                  width: '100%',
                  height: '100px'
                }
              })}
            >
              <input ref={ref} {...getInputProps()} />
              <div className="flex items-center justify-between w-full p-4">
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-400 mr-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5c0 .414.336.75.75.75h9a.75.75 0 00.75-.75m-10.5 0A2.25 2.25 0 015.25 21h13.5A2.25 2.25 0 0021 18.75V8.25A2.25 2.25 0 0018.75 6H5.25A2.25 2.25 0 003 8.25v10.5z"
                    />
                  </svg>
                  <div className="flex flex-col">
                    <p className="text-sm text-gray-400">Select a file or drag and drop here</p>
                    <p className="text-xs text-gray-400">File size limit is 10GB</p>
                  </div>
                </div>
                <Button>SELECT FILE</Button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            {files.map((file, index) => {
              const isInitialImage = initialImages.some((image) => image.name === file.name);
              const uploadedImage = initialImages.find((image) => image.name === file.name);
              const displayDate =
                isInitialImage && uploadedImage?.uploaded_at
                  ? new Date(uploadedImage?.uploaded_at).toLocaleDateString()
                  : file.lastModified
                  ? new Date(file.lastModified).toLocaleDateString()
                  : '';
              return (
                <Card key={index} className="w-full">
                  <CardContent className="p-4">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center">
                        {file.type.startsWith('image/') ? (
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="w-10 h-10 rounded-full object-cover mr-2"
                          />
                        ) : file.type === 'application/pdf' ? (
                          <img src="/svg/pdf_icon.svg" alt="PDF Icon" className="w-10 h-10 mr-2" />
                        ) : null}
                        <Tooltip>
                          <TooltipTrigger>
                            <span className="font-medium">{truncateFileName(file.name)}</span>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{file.name}</p>
                          </TooltipContent>
                        </Tooltip>
                      </div>
                      <span className="text-sm text-gray-500">{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-gray-500">{displayDate}</span>
                      <div>
                        <Button size="sm" className="mr-2" onClick={() => handleFileClick(file)}>
                          Preview
                        </Button>
                        <Button variant="destructive" size="sm" onClick={() => handleRemoveFile(file)}>
                          Remove
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              );
            })}
          </div>

          <CustomDialog isOpen={openPreview} onClose={handleClosePreview} title="" maxWidth="800px" maxHeight="600px">
            {selectedFile && selectedFile.type.startsWith('image/') ? (
              <img src={URL.createObjectURL(selectedFile)} alt="File Preview" className="w-full h-full object-cover" />
            ) : selectedFile && selectedFile.type === 'application/pdf' ? (
              <iframe
                src={URL.createObjectURL(selectedFile)}
                style={{ maxWidth: '100%', maxHeight: '100%', height: 'calc(100vh - 150px)' }} // Ajuste para limitar el tamaño del iframe
                width="100%"
                height="100%"
                title="PDF Preview"
              />
            ) : null}
          </CustomDialog>
        </div>
      </TooltipProvider>
    );
  }
);

CustomImages.displayName = 'CustomImages';

export { CustomImages };
