import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import useRouteParams from '@/common/hooks/RouteParamsHook';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { FileDown, Trash, Ellipsis, Save } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { Textarea } from "@/common/presentation/components/ui/textarea";

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { ImageCardTables } from '@/common/presentation/components/Selectors/ImageCardTables';
import { SingleImageDropzone } from '@/common/presentation/components/ImageDropZone/single-image-dropzone';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'signature_pad';
import { format, parse } from "date-fns";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogDescription
} from '@/common/presentation/components/ui/dialog';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';



function TrackerCashResource() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState([{ id: 1, name: 'Skin Check', status: 'active' }]);
    const [dataReport, setDatareport] = useState(null);
    const [dataReportquick, setDatareportQuick] = useState(null);
    const { locationSelectedId } = useLocation();
    const dispatch = useDispatch();
    const { tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedCash, setSelectedCash] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [select, setSelect] = useState([{ id: 1, name: 'Clear', status: 'active' }, { id: 2, name: 'Not Clear', status: 'active' }]);
    const [data, setData] = useState([]);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [errorMessage, setErrorMessage] = useState('');
    const canvasRef = useRef(null);
    const [points, setPoints] = useState([]);
    const [clicks, setClicks] = useState(0);
    const timeout = 300;
    const pointSize = 3;
    const [imageData, setImageData] = useState('');
    const [sexResident, setsexResident] = useState<string>(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [detail, setDetail] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [file, setFile] = useState<File>();
    const formattedDate = selectedDate ? selectedDate.toLocaleString() : '';
    const [selectedMoisture, setSelectedMoisture] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedTemperature, setSelectedTemperature] = useState('');
    const { t } = useTranslation();
    const onSelectHygiene = (e: string) => {
        setParam('e', e);
        setSelectedCash(e);
    };

    const onResidentSelect = (residentId: any) => {
        setParam('r', residentId);
        const selectedResident = residents.find(resident => resident.id === residentId);
        setsexResident(selectedResident.sex);
    };
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);


    const columnsreport = [
        { label: t('trackers.Date/time'), value: 'yearMonth', type: 'date' },
        { label: t('trackers.Resident'), value: 'resident_name' },
    ];


    const dataCashReport = async () => {
        const json = { location_id: locationSelectedId };
        const response = await TrackerService.getTrackerCashResource(json);

        if (response && Array.isArray(response) && response.length > 0) {
            let allData = [];
            response.forEach(item => {
                if (item && item.data) {
                    try {
                        const parsedData = JSON.parse(item.data);

                        // Verifica si parsedData es un arreglo
                        if (Array.isArray(parsedData)) {
                            allData = allData.concat(parsedData.map(dataItem => ({
                                ...dataItem,
                                created_at: item.created_at,
                                dataReportid: item.id
                            })));
                        } else {
                            console.error('El JSON parseado no es un arreglo:', parsedData);
                        }
                    } catch (error) {
                        console.error('Error al parsear JSON:', error);
                    }
                }
            });

            allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setDatareport(allData);
        }
    };




    const dataSkinQuickReport = async () => {
        const json = { location_id: locationSelectedId };
        const response = await TrackerService.getTrackerHygiene(json);
        if (response && Array.isArray(response) && response.length > 0) {
            let allData = [];
            response.forEach(item => {
                if (item && item.data) {
                    const parsedData = JSON.parse(item.data);
                    allData = allData.concat(parsedData.map(dataItem => ({
                        ...dataItem,
                        created_at: item.created_at,
                        dataReportid: item.id
                    })));
                }
            });
            allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setDatareportQuick(allData);
        }
    };

    useEffect(() => {
        if (!dataReport) {
            dataCashReport();
        }

        if (!dataReportquick) {
            dataSkinQuickReport();
        }
    }, [dataReport, dataReportquick]);

    const createData = () => {
        const columnField = types || [];

        if (!types) return [];
        const groupedData = {};

        const today = new Date().toISOString().split('T')[0];

        const isReportChecked = (residentId, value, typeName) => {
            if (!dataReportquick) {
                return false;
            } else {
                return Array.isArray(dataReportquick) && dataReportquick.some((report) => {
                    const reportDate = new Date(report.date).toISOString().split('T')[0];
                    return report.resident_id === residentId && report.value === value && reportDate === today && report.type_name === typeName;
                });
            }

        };

        const handleSelectChange = (typeId, residentId, value) => {
            buttonSumbit({
                typeId,
                residentId,
                status: value,
                save: 'quick'
            });
        };

        residents.forEach((resident) => {
            columnField.forEach((field) => {
                if (field.status === "inactive") {
                    return;
                }
                const type = field.name;
                const typeid = field.id;

                const currentValue = select
                    .filter(option => option.status === 'active')
                    .find(option => isReportChecked(resident.id, option.name, type))?.name || '';

                if (!groupedData[type]) {
                    groupedData[type] = { type: type };
                }

                groupedData[type][resident.first_name] = (
                    <div className="flex justify-center" key={`${type}-${resident.first_name}`}>
                        <div className="text-center flex-1">
                            <Select
                                value={currentValue}
                                onValueChange={(value) => handleSelectChange(typeid, resident.id, value)}
                            >
                                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                                    <SelectValue placeholder="">
                                        {currentValue ? currentValue.charAt(0).toUpperCase() + currentValue.slice(1) : ' '}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    {select
                                        .filter(option => option.status === 'active')
                                        .map(option => (
                                            <SelectItem key={option.id} value={option.name}>
                                                {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
                                            </SelectItem>
                                        ))
                                    }
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                );
            });
        });

        const newData = Object.values(groupedData);
        return newData;
    };


    useEffect(() => {
        setData(createData());
    }, [types, dataReport, dataReportquick]);

    const Fields = {
        'Edit': [
            { fieldName: t('trackers.Date/Time'), label: 'Date/Time', type: 'date' },
            { fieldName: t('trackers.Type'), label: 'Type', type: 'select' },
            { fieldName: t('trackers.Value'), label: 'Value', type: 'selectval' },
            { fieldName: t('trackers.Detail'), label: 'Detail', type: 'textarea' }
        ]
    };

    const [fields, setDialogField] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null); // Aquí se define selectedIndex

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[], index: string, description: string) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(fields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
        setSelectedIndex(index);
    };



    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};

        if (action === 'Delete') {
            json["id"] = formData.dataReportid;
            json["id_data"] = formData.id;
            const response = await TrackerService.destroyTrackerCashResource(json);
            const updatedRecords = records.filter((_, i) => i !== selectedIndex);
            setRecords(updatedRecords);
            handleCloseEditDialog(false);
        }
        dataCashReport();
    };

    useEffect(() => {
        dataCashReport();
    }, [locationSelectedId]);


    const [filters, setFilters] = useState({
        resident_name: '',
        type_name: ''
    });

    const buttonSumbit = async ({ typeId, residentId, status, save }: { typeId: string; residentId: string; status: string; save: string; }) => {
        const today = new Date().toISOString().split('T')[0];

        const findSkinCheckId = (columnField) => {
            const skinCheckField = columnField.find(field => field.name.toLowerCase() === 'skin check');
            return skinCheckField ? skinCheckField.id : null;
        };
        if (save == 'quick') {
            const json = { location_id: locationSelectedId };
            const response = await TrackerService.getTrackerHygieneType(json);
            if (response && response.data) {
                const jsonData = JSON.parse(response.data);
                const skinCheckId = findSkinCheckId(jsonData.column_field);
                if (Array.isArray(dataReportquick)) {
                    const foundReport = dataReportquick.find(report => {
                        const reportDate = new Date(report.date).toISOString().split('T')[0];
                        return report.type === skinCheckId && report.resident_id === residentId && today === reportDate;
                    });

                    if (foundReport) {
                        const json: { [key: string]: any } = {};
                        json["id"] = foundReport.dataReportid;
                        json["id_data"] = foundReport.id;
                        json["data"] = {
                            "type": skinCheckId,
                            "detail": foundReport.detail,
                            "value": status,
                            "resident_id": residentId,
                            "date": foundReport.date
                        };
                        const response = await TrackerService.updateTrackerHygiene(json);
                        dataSkinQuickReport();
                        setData(createData());
                    } else {
                        const json = [{
                            type: skinCheckId,
                            value: status,
                            resident_id: residentId,
                            detail: " ",
                            location_id: locationSelectedId
                        }];
                        const response = await TrackerService.saveTrackerHygiene(json);
                        dataSkinQuickReport();
                        setData(createData());
                    }
                } else {
                    const json = [{
                        type: skinCheckId,
                        value: status,
                        resident_id: residentId,
                        detail: " ",
                        location_id: locationSelectedId
                    }];
                    const response = await TrackerService.saveTrackerHygiene(json);
                    dataCashReport();
                    setData(createData());;
                }
            }
        } else {

        }
    };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };



    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction: direction });
    };

    const groupDataByResidentAndDate = (data) => {
        const grouped = data.reduce((result, item) => {
            const { resident_id, resident_name, date } = item;
            const [year, month] = date.split('T')[0].split('-');
            const yearMonth = `${year}-${month}`;

            if (!result[resident_id]) {
                result[resident_id] = { resident_name, data: {} };
            }

            if (!result[resident_id].data[yearMonth]) {
                result[resident_id].data[yearMonth] = [];
            }

            result[resident_id].data[yearMonth].push(item);

            return result;
        }, {});

        const formattedResult = [];
        for (const resident_id in grouped) {
            const { resident_name, data } = grouped[resident_id];
            for (const yearMonth in data) {
                formattedResult.push({
                    resident_id,
                    resident_name,
                    yearMonth,
                    records: data[yearMonth]
                });
            }
        }

        return formattedResult;
    };

    let groupedData: { resident_id: string; resident_name: any; yearMonth: string; records: any; }[] = [];

    if (dataReport) {
        groupedData = groupDataByResidentAndDate(dataReport);
    }

    const sortedData = useMemo(() => {
        if (sortConfig.key) {
            return [...groupedData].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
        }

        return groupedData;
    }, [groupedData, sortConfig]);

    const onChange = async (file?: File) => {
        if (file) {
            setFile(file.target.files[0]);
        }
    };


    const handleChangeDetail = (e) => {
        setDetail(e.target.value);
    };

    const handleDateChangeSkin = (date) => {
        setSelectedDate(date);
    };


    const handleResidentClick = (resident) => {
        setFilters({
            resident_name: `${resident}`,
            who_name: ''
        });
    };

    const canvasRefFR = useRef(null);
    const canvasRefRR = useRef(null);
    const inputRefFR = useRef(null);
    const inputRefRR = useRef(null);
    const signaturePadFR = useRef(null);
    const signaturePadRR = useRef(null);

    useEffect(() => {
        if (canvasRefFR.current && canvasRefRR.current) {
            const options = {
                penColor: 'black',
                backgroundColor: 'white',
            };

            if (canvasRefFR.current && canvasRefRR.current) {
                signaturePadFR.current = new SignaturePad(canvasRefFR.current, options);
                signaturePadRR.current = new SignaturePad(canvasRefRR.current, options);
            }
        }

        return () => {
            if (signaturePadFR.current) {
                signaturePadFR.current.off();
                signaturePadFR.current = null;
            }
            if (signaturePadRR.current) {
                signaturePadRR.current.off();
                signaturePadRR.current = null;
            }
        };
    }, []);

    const clearSignature = (signaturePad, input) => {
        signaturePad.clear();
        input.current.value = '';
    };

    const formatSignatureBase64 = (signaturePad, input) => {
        const img = signaturePad.toDataURL('image/png');
        if (img) {
            input.current.value = img;
        }
        return img;
    };

    const [amountReceived, setAmountReceived] = useState('');
    const [amountSpent, setAmountSpent] = useState('');
    const [balance, setBalance] = useState('');

    const handleChangeAmountReceived = (event) => {
        const receivedValue = Number(event.target.value);
        setAmountReceived(receivedValue);
    };


    const handleChangeAmountSpent = (event) => {
        setAmountSpent(event.target.value);
    };

    const handleChangeBalance = async () => {
        const json = {
            location_id: locationSelectedId,
            resident_id: params.r,
        };
        try {
            const response = await TrackerService.balanceResident(json);
            setBalance(response.balance);
        } catch (error) {
            console.error('Error fetching balance:', error);
        }
    };
    useEffect(() => {
        if (params.r) {
            handleChangeBalance();
        }
    }, [params.r]);

    const handleSubmitSkin = async (event) => {
        event.preventDefault();
        // manejar el envío del formulario
        const captureCurrentDateTime = () => {
            const currentDateTime = new Date();
            setSelectedDate(currentDateTime);
        };
        captureCurrentDateTime();
        const total = amountReceived - amountSpent;
        const balancetotal = total + Number(balance);

        if (!params.r) {
            alert('Por favor seleccione un residente');
            return;
        }

        const imginputRefFR = formatSignatureBase64(signaturePadFR.current, inputRefFR);
        const imginputRefRR = formatSignatureBase64(signaturePadRR.current, inputRefRR);
        const isoDate = new Date(selectedDate).toISOString();
        const json = new FormData();
        json.append('resident_id', params.r);
        json.append('date', isoDate);
        json.append('description', detail);
        json.append('amount_received', amountReceived);
        json.append('amount_spend', amountSpent);
        json.append('balance', balancetotal);
        json.append('signature_facility', imginputRefFR);
        json.append('signature_resident', imginputRefRR);
        json.append('image', file);
        json.append('location_id', locationSelectedId);
        try {
            const response = await TrackerService.saveTrackerCashResource(json);
            setDetail('');
            setAmountReceived('');
            setAmountSpent('');
            handleChangeBalance();
            setImageFile(null);
            clearSignature(signaturePadFR.current, inputRefFR)
            clearSignature(signaturePadRR.current, inputRefRR)
        } catch (error) {
            console.error('Error al guardar:', error);
            setErrorMessage('Error al guardar los datos'); // Manejo de errores
        }
    };


    const [originalRecords, setOriginalRecords] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [records, setRecords] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedRecord, setSelectedRecord] = useState({
        date: '',
        description: '',
        amount_received: 0,
        amount_spend: 0,
        balance: 0
    });

    useEffect(() => {
        if (records.length > 0) { // Asegúrate de que records no esté vacío
            const copiedRecords = records.map(record => ({ ...record }));
            setOriginalRecords(copiedRecords);
        }
    }, [records]); // Observa los cambios en records

    const handleButtonEdit = (row) => {
        const firstRecord = row.records[0];
        setRecords(row.records);
        setSelectedRecord(firstRecord);
        setCurrentIndex(0);
        setShowModal(true);
    }


    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRecords = records.map((record, i) => (
            i === index ? { ...record, [name]: value } : record
        ));
        setRecords(updatedRecords);
    };


    const filterData = (data) => {
        return data.map(item => ({
            id: item.id,
            id_data: item.dataReportid,
            id_redident: item.resident_id,
            data: {
                description: item.description,
                amount_received: item.amount_received,
                amount_spend: item.amount_spend
            }
        }));
    };

    const handleSave = async () => {
        const result = filterData(records);
        const response = await TrackerService.updateTrackerCashResource(result);
        setRecords(records);
        setShowModal(false);
        dataCashReport();
        handleChangeBalance();
    };



    return (
        <div>
            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackercash.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackercash.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedCash} onValueChange={(e) => { onSelectHygiene(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Form')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }

            {!locationSelectedId && <LocationSelector selections={false} />}

            {
                selectedCash === 'form' && (
                    <div>
                        {
                            locationSelectedId && (
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <ResidentSelector
                                            onSelect={onResidentSelect}
                                            locationId={location}
                                            residentId={params.r}
                                            showAllResidents={false}
                                        />
                                    </div>

                                    <div>
                                        <Card className="border-t-4 border-t-primary/80">
                                            <CardHeader>
                                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.New Tracker')}</CardTitle>
                                            </CardHeader>
                                            <form onSubmit={handleSubmitSkin} encType="multipart/form-data">
                                                <CardContent>
                                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                                    <div className="flex flex-wrap lg:flex-nowrap">
                                                        <div className="flex flex-col w-full p-2">

                                                            <div className="flex flex-row w-full p-2 space-x-2">
                                                                <div className="flex-[0.5] flex flex-col">
                                                                    <Label htmlFor="tracker_brief_date" className="control-label mb-3">
                                                                        {t('trackers.Date/Time')}
                                                                    </Label>
                                                                    <div className="input-group">
                                                                        <Popover>
                                                                            <PopoverTrigger asChild>
                                                                                <Button variant="outline" className="w-full justify-start text-left font-normal">
                                                                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                                                                    {formattedDate}
                                                                                </Button>
                                                                            </PopoverTrigger>
                                                                            <PopoverContent className="w-auto p-0">
                                                                                <CustomCalendar
                                                                                    id="tracker_brief"
                                                                                    name="tracker_brief"
                                                                                    selectedDate={selectedDate}
                                                                                    onChange={handleDateChangeSkin}
                                                                                    MonthAndYearPicker={true}
                                                                                    timePicker={true}
                                                                                    classMonthYearSelect="your-css-class"
                                                                                />
                                                                            </PopoverContent>
                                                                        </Popover>
                                                                    </div>
                                                                </div>

                                                                <div className="flex-1 flex flex-col">
                                                                    <Label htmlFor="tracker_detail" className="control-label mb-3">
                                                                        {t('trackers.Description')}
                                                                    </Label>
                                                                    <Input
                                                                        id="tracker_detail"
                                                                        name="tracker_detail"
                                                                        className="w-full"
                                                                        value={detail}
                                                                        onChange={handleChangeDetail}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-row w-full p-2 space-x-4">


                                                                <div className="flex flex-col w-full p-2">
                                                                    <Label htmlFor="tracker_condition" className="control-label mb-3">
                                                                        {t('trackers.Amount Received')}
                                                                    </Label>
                                                                    <Input
                                                                        className="w-full justify-start text-left font-normal"
                                                                        id="amount_received"
                                                                        name="amount_received"
                                                                        type="text"
                                                                        value={amountReceived}
                                                                        onChange={handleChangeAmountReceived}
                                                                    />
                                                                </div>


                                                                <div className="flex flex-col w-full p-2">
                                                                    <Label htmlFor="tracker_condition" className="control-label mb-3">
                                                                        {t('trackers.Amount Spend Or Withdrawn')}
                                                                    </Label>
                                                                    <Input
                                                                        className="w-full justify-start text-left font-normal"
                                                                        id="amount_spend"
                                                                        name="amount_spend"
                                                                        type="text"
                                                                        value={amountSpent}
                                                                        onChange={handleChangeAmountSpent}
                                                                    />
                                                                </div>

                                                                <div className="flex flex-col w-full p-2">
                                                                    <Label htmlFor="tracker_condition" className="control-label mb-3">
                                                                        {t('trackers.Balance')}
                                                                    </Label>
                                                                    <Input
                                                                        className="w-full justify-start text-left font-normal"
                                                                        id="balance"
                                                                        name="balance"
                                                                        type="text"
                                                                        readOnly
                                                                        value={balance}
                                                                        onChange={handleChangeBalance}
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="flex flex-col w-full p-2">
                                                                <Label htmlFor="tracker_image" className="control-label mb-3">
                                                                    {t('trackers.Upload Image')}
                                                                </Label>
                                                                <Input
                                                                    type="file"
                                                                    id="tracker_image"
                                                                    name="tracker_image"
                                                                    className="w-full"
                                                                    accept="image/*"
                                                                    onChange={onChange}
                                                                    multiple
                                                                />
                                                            </div>



                                                            <div className="flex flex-row w-full p-2 space-x-4 justify-center" >
                                                                
                                                                <div className="flex flex-col w-1/3 p-2 space-y-1">
                                                                    <Label htmlFor="tracker_condition" className="control-label mb-3">
                                                                        {t('trackers.Facility Representative')}
                                                                    </Label>
                                                                    <canvas
                                                                        id="canvas-facility-representative"
                                                                        className="signature-pad shadow-lg"
                                                                        width="300"
                                                                        height="150"
                                                                        ref={canvasRefFR}
                                                                    ></canvas>
                                                                    <input
                                                                        type="hidden"
                                                                        name="signature_facility_representative"
                                                                        ref={inputRefFR}
                                                                    />
                                                                    <Button
                                                                        type="button"
                                                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                                                        onClick={() => clearSignature(signaturePadFR.current, inputRefFR)}
                                                                    >
                                                                        {t('trackers.Clear signature')}
                                                                    </Button>
                                                                </div>

                                                                <div className="flex flex-col w-1/3 p-2 space-y-1">
                                                                    <Label htmlFor="tracker_condition" className="control-label mb-3">
                                                                        {t('trackers.Client/Resident Or Representative')}
                                                                    </Label>
                                                                    <canvas
                                                                        id="canvas-resident-representative"
                                                                        className="signature-pad shadow-lg"
                                                                        width="300"
                                                                        height="150"
                                                                        ref={canvasRefRR}
                                                                    ></canvas>
                                                                    <input
                                                                        type="hidden"
                                                                        name="signature_resident_representative"
                                                                        ref={inputRefRR}
                                                                    />
                                                                    <Button
                                                                        type="button"
                                                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                                                        onClick={() => clearSignature(signaturePadRR.current, inputRefRR)}
                                                                    >
                                                                        {t('trackers.Clear signature')}
                                                                    </Button>
                                                                </div>
                                                            </div>




                                                        </div>

                                                    </div>


                                                    <div className="flex justify-center mt-4">
                                                        <Button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                                                            {t('trackers.Save')}
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </form>

                                        </Card>
                                    </div>

                                    <br />
                                    <br />
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                selectedCash === 'report' && (
                    <div>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.trackercashreport.title')}</CardTitle>
                                <div className="flex w-full items-center justify-between">
                                    <div>
                                        {t('trackers.trackercashreport.description')}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent>
                                <div className="flex space-x-4">
                                    <Input
                                        placeholder="Filter by Name"
                                        value={filters.resident_name}
                                        onChange={(e) => setFilters({ ...filters, resident_name: e.target.value })}
                                        className="w-[200px]"
                                    />
                                    <Input
                                        placeholder="Filter by Type"
                                        value={filters.type_name}
                                        onChange={(e) => setFilters({ ...filters, type_name: e.target.value })}
                                        className="w-[200px]"
                                    />
                                </div>
                                <br />
                                <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                    <Table className="shadow-lg overflow-hidden">
                                        <TableHeader>
                                            <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                {columnsreport.map((column) => (

                                                    <TableHead
                                                        key={column.value}
                                                        className="text-white cursor-pointer items-center"
                                                        onClick={() => handleSort(column.value)}>
                                                        <div className="flex items-center ">
                                                            <span>{column.label}</span>
                                                            <span className="ml-1 flex-col justify-end">
                                                                <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'ascending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                    ▲
                                                                </span>
                                                                <span className={`arrow ${sortConfig.key === column.value && sortConfig.direction === 'descending' ? 'active' : 'inactive'}`} style={{ fontSize: '0.6rem' }}>
                                                                    ▼
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </TableHead>

                                                ))}
                                                <TableHead className="text-white">{t('trackers.Actions')}</TableHead>

                                            </TableRow>
                                        </TableHeader>
                                        <TableBody className="bg-white dark:bg-background">

                                            {sortedData && sortedData
                                                .filter(row =>
                                                    (!filters.resident_name || row.resident_name.toLowerCase().includes(filters.resident_name.toLowerCase())) &&
                                                    (!filters.type_name || row.type_name.toLowerCase().includes(filters.type_name.toLowerCase()))
                                                )
                                                .map((row, index) => (
                                                    <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                        {columnsreport.map((column, columnIndex) => (
                                                            <TableCell key={columnIndex} className="py-2 px-4">
                                                                {column.value === 'yearMonth' ? (
                                                                    <span>

                                                                        {format(parse(`${row[column.value]}-01`, 'yyyy-MM-dd', new Date()), "MMMM yyyy")}
                                                                        {column.type_name === 'date' ? ` (${row.value})` : null}
                                                                    </span>
                                                                ) : (
                                                                    column.value === 'type_name' ? (
                                                                        <span>
                                                                            {capitalizeWords(String(row[column.value as keyof typeof row]))}
                                                                            {` (${row.value})`}
                                                                        </span>
                                                                    ) : (
                                                                        capitalizeWords(String(row[column.value as keyof typeof row]))
                                                                    )
                                                                )}

                                                            </TableCell>
                                                        ))}
                                                        <TableCell className="py-2 px-4">
                                                            <DropdownMenu>
                                                                <DropdownMenuTrigger asChild>
                                                                    <Button variant="outline" size="icon">
                                                                        <Ellipsis className="h-4 w-4" />
                                                                    </Button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent className="w-56">
                                                                    <DropdownMenuSeparator />
                                                                    <DropdownMenuGroup>
                                                                        <DropdownMenuItem>
                                                                            <FileDown className="mr-2 h-4 w-4" />
                                                                            <span onClick={() => handleButtonEdit(row)}>{t('trackers.Edit')}</span>
                                                                        </DropdownMenuItem>
                                                                    </DropdownMenuGroup>
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                        <Dialog open={showModal} onOpenChange={setShowModal}>
                                            <DialogContent className="max-w-6xl">
                                                <DialogHeader>
                                                    <DialogTitle style={{ color: accentColor }}>{t('trackers.Edit Records')}</DialogTitle>
                                                    <DialogDescription>{t('trackers.Modify the fields and save the changes.')}</DialogDescription>
                                                </DialogHeader>
                                                <table className="min-w-full border border-gray-200">
                                                    <thead className="border-b border-gray-200">
                                                        <tr>
                                                            <th className="border-r border-gray-200">{t('trackers.Description')}</th>
                                                            <th className="border-r border-gray-200">{t('trackers.Amount Received')}</th>
                                                            <th className="border-r border-gray-200">{t('trackers.Amount Spend Or Withdrawn')}</th>
                                                            <th className="border-r border-gray-200">{t('trackers.Balance')}</th>
                                                            <th className="border-r border-gray-200">{t('trackers.Picture')}</th>
                                                            <th>{t('trackers.Actions')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {records.map((record, index) => (
                                                            <tr key={record.id} className="border-b border-gray-200">
                                                                <td className="border-r border-gray-200">
                                                                    <Input
                                                                        type="text"
                                                                        name="description"
                                                                        value={record.description}
                                                                        onChange={(e) => handleChange(e, index)}
                                                                    />
                                                                </td>
                                                                <td className="border-r border-gray-200">
                                                                    <Input
                                                                        type="number"
                                                                        name="amount_received"
                                                                        value={record.amount_received}
                                                                        onChange={(e) => handleChange(e, index)}
                                                                    />
                                                                </td>
                                                                <td className="border-r border-gray-200">
                                                                    <Input
                                                                        type="number"
                                                                        name="amount_spend"
                                                                        value={record.amount_spend || ''}
                                                                        onChange={(e) => handleChange(e, index)}
                                                                    />
                                                                </td>
                                                                <td className="border-r border-gray-200">
                                                                    <Input
                                                                        type="number"
                                                                        name="balance"
                                                                        value={record.amount_received - record.amount_spend}
                                                                        readOnly
                                                                    />
                                                                </td>

                                                                <td className="border-r border-gray-200">
                                                                    {record.image.trim() ? (
                                                                        <img src={record.image} height="150" width="120" alt="Record" />
                                                                    ) : (
                                                                        <img src="https://caringdata.com/corte/transparente.png" height="100" width="100" alt="Record" />)}
                                                                </td>
                                                                <td className="border-r border-gray-200">
                                                                    <div className="flex justify-center space-x-2">
                                                                        <Button onClick={() => handleButtonOpenDialog('', 'Delete', record, index, "Are you sure you want to delete this item?")} variant="destructive"><Trash className="h-4 w-4" /></Button>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <DialogFooter>
                                                    <Button onClick={handleSave}>{t('trackers.Save')}</Button>
                                                    <Button variant="secondary" onClick={() => setShowModal(false)}>{t('trackers.Close')}</Button>
                                                </DialogFooter>
                                            </DialogContent>
                                        </Dialog>
                                    </Table>
                                    <div>
                                        <div className="flex items-center justify-between px-2">
                                            <div className="flex-1 text-sm text-muted-foreground">
                                                {sortedData && sortedData.length} of {sortedData && sortedData.length} row(s) selected.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <CustomDialog
                            isOpen={showEditDialog}
                            onClose={handleCloseEditDialog}
                            title={dialogTitle}
                            onSubmit={() => handleSubmit(selectedIndex)}
                            description={dialogDescription}
                        >
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(selectedIndex); }}>
                                <br />
                                <div className="grid gap-4 mt-2">
                                    {fields && fields.map((field, index) => (
                                        <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                            <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                        </div>
                                    ))}
                                </div>
                            </form>

                        </CustomDialog>
                    </div>
                )
            }

        </div >
    )
}

export default TrackerCashResource