import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BedroomQrCategory from "../infrastructure/services/BedroomQrCategory";

interface IPagination {
  total: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
  hasMorePages: boolean
}

interface IBedroomQrCategory {
  id: string;
  name: string;
  status: string;
}

export interface IBedroomQrState {
  categories: {
    items: IBedroomQrCategory[];
    pagination: IPagination;
  };
  isLoading: boolean;
  error: string | null;
}

const initialState: IBedroomQrState = {
  categories: {
    items: [],
    pagination: {
      total: 0,
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      hasMorePages: false
    }
  },
  isLoading: false,
  error: null
};



export const getBedroomQrCategories = createAsyncThunk('bedroomQrCategory/getBedroomQrCategory',
 async (formData:any) => {
    const response = await BedroomQrCategory.getBedroomQrCategory(formData);
    return response;
  }
);

export const saveOrUpdateCategories = createAsyncThunk('quickNotes/saveOrUpdateCategory',
  async (formData: any ) => {
     const response = await BedroomQrCategory.saveOrUpdateCategory(formData);
     return response;
   }
 );

export const deleteBedroomQrCategory = createAsyncThunk('quickNotes/deleteBedroomQrCategory',
  async (id: string ) => {
     const response = await BedroomQrCategory.deleteBedroomQrCategory(id);
     return response;
   }
 );

const QuickNoteslice = createSlice({
  name: 'quickNotes',
  initialState,
  reducers: {
    bedroomQrCategoriesLoading: (state)=>{
     state.isLoading = true;
     state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.
    addCase(getBedroomQrCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.isLoading = false;
    })
    .addCase(saveOrUpdateCategories.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(deleteBedroomQrCategory.fulfilled, (state) => {
      state.isLoading = false;
    });
  }
});

export const { bedroomQrCategoriesLoading } = QuickNoteslice.actions;
export default QuickNoteslice.reducer;