import useRouteParams from "@/common/hooks/RouteParamsHook";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { Button } from "@/common/presentation/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/common/presentation/components/ui/card";
import { Input } from "@/common/presentation/components/ui/input";
import { Label } from "@/common/presentation/components/ui/label";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/common/presentation/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { RootState } from "@/store/store";
import { format } from "date-fns";
import { CalendarIcon, Pencil, Search, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ITrackerMenstrutation, ITrackerMenstrutationForm } from "./TrackerMenstruationMockup";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { cn } from "@/lib/utils";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { deleteMenstruation, getMenstruations, updateMenstruation } from "../../slices/TrackerMenstruationSlice";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";

export function TrackerMenstruationReport() {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const configurations = useSelector((state: RootState) => state.configurations);
    const trackerMenstruation = useSelector((state: RootState) => state.trackerMenstruation);
    const { params } = useRouteParams();
    const { t } = useTranslation();
    let [dataSource, setDataSource] = useState<ITrackerMenstrutation[]>([]);
    const [accentColor, setAccentColor] = useState("");
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState<ITrackerMenstrutation>();
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteData, setDeleteData] = useState("");
    const { locationSelectedId } = useLocation();
    
    const [values, setValues] = useState<ITrackerMenstrutationForm>({
        startDate: new Date(),
        endDate: new Date(),
        comments: ""
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setDataSource(trackerMenstruation.menstruations);
    }, [trackerMenstruation]);
    
    useEffect(() => {
        if(configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    /* EDIT DIALOG */
    const openEditDialog = (element: ITrackerMenstrutation) => {
        setOpenEdit(true);
        setEditData(element);

        setValues({
            startDate: new Date(element.data.startDate),
            endDate: new Date(element.data.endDate),
            comments: element.data.comments
        });
    }

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleStartDateChange = (value: any) => {
        setValues({
             ...values,
             startDate: value
         });
     };
 
     const handleEndDateChange = (value: any) => {
         setValues({
              ...values,
              endDate: value
          });
     };

    const handleEdit = (element: ITrackerMenstrutation) => {
        if(values.startDate && values.endDate && values.comments) {
            dispatch(updateMenstruation(
                {
                    id: element.id,
                    data: {
                        ...values,
                        startDate: values.startDate.toISOString(),
                        endDate: values.endDate.toISOString()
                    }
                }
            ));

            fetchData();
            
            toast(
                {
                    description: t("mentruation.report.edit.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("mentruation.report.edit.toast.required")
                }
            );
        }

        fetchData();

        return true;
    }

     /* DELETE DIALOG */
     const openDeleteDialog = (id: string) => {
        setOpenDelete(true);
        setDeleteData(id);
    }

    const closeDeleteDialog = () => {
        setOpenDelete(false);
    }

    const handleDelete = (id: string) => {
        dispatch(deleteMenstruation(
            {
                id
            }
        ));

        fetchData();

        return true;
    }

    const fetchData = () => {
        dispatch(getMenstruations(
            {
                location_id: locationSelectedId
            }
        ));
    }

    return (
        <div>
            {
                deleteData && <CustomDialog 
                    title={t("cleaning.report.summary.delete.title")} 
                    description={t("cleaning.report.summary.delete.description")} 
                    width="30rem"
                    newDialog={true}
                    isOpen={openDelete}
                    onSubmit={() => handleDelete(deleteData)}
                    onClose={closeDeleteDialog}
                ></CustomDialog>
            }

            {
                editData && <CustomDialog 
                    title={t("mentruation.report.edit.title")}
                    width="30rem"
                    newDialog={true}
                    isOpen={openEdit}
                    onSubmit={() => handleEdit(editData)}
                    onClose={closeEditDialog}
                >
                    <form className="grid gap-4 py-4">
                    <div className="general-outings-form grid grid-cols-1 gap-4 mt-5">
                        <div className="grid grid-cols-1 items-center gap-2">
                                <Label htmlFor={"startDate"} className="text-left">
                                    { t("mentruation.report.edit.form.startDate") }
                                </Label>
                                
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-[280px] justify-start text-left font-normal w-100",
                                            !values.startDate && "text-muted-foreground"
                                        )}
                                        >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {values.startDate ? format(values.startDate, "MM/dd/yyyy - hh:mm aa") : <span>{ t("mentruation.form.form.datePlaceholder") }</span>}
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            value={values.startDate}
                                            timePicker={true}
                                            onChange={handleStartDateChange}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>

                            <div className="grid grid-cols-1 items-center gap-2">
                                <Label htmlFor={"endDate"} className="text-left">
                                    { t("mentruation.report.edit.form.endDate") }
                                </Label>
                                
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-[280px] justify-start text-left font-normal w-100",
                                            !values.endDate && "text-muted-foreground"
                                        )}
                                        >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {values.endDate ? format(values.endDate, "MM/dd/yyyy - hh:mm aa") : <span>{ t("mentruation.form.form.datePlaceholder") }</span>}
                                        </Button>
                                    </PopoverTrigger>

                                    <PopoverContent className="w-auto p-0">
                                        <CustomCalendar
                                            value={values.endDate}
                                            timePicker={true}
                                            onChange={handleEndDateChange}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>

                            <div className="grid grid-cols-1 items-center gap-2">
                                <Label htmlFor={"comments"} className="text-right justify-self-start">
                                    { t("mentruation.report.edit.form.comments") }
                                </Label>

                                <Input
                                    id={"comments"}
                                    name={"comments"}
                                    type="text"
                                    value={values.comments}
                                    style={{ outline: 'none' }}
                                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                                    onChange={handleInputChange}
                                />
                            </div>
                    </div>
                    </form>
                </CustomDialog>
            }

            <Card className="mt-2 mb-2 border-t-4 border-t-primary/80">
                <CardHeader>
                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                        {t("mentruation.report.title")}
                    </CardTitle>
                </CardHeader>
                    
                <CardContent>
                    <div className="grid grid-cols-1 grid-row-2 w-100">
                        {
                            dataSource.length ? (
                                <Table>
                                    <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                                        <TableRow className="bg-primary/80 hover:bg-primary/80">
                                            <TableHead className="text-white">{t("mentruation.report.table.resident")}</TableHead>
                                            <TableHead className="text-white">Start Date</TableHead>
                                            <TableHead className="text-white">End Date</TableHead>
                                            <TableHead className="text-white w-5">Details</TableHead>
                                            <TableHead className="text-white w-5">{t("mentruation.report.table.actions")}</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    
                                    <TableBody>
                                        {
                                            dataSource.length && dataSource.map(
                                                element =>
                                                <TableRow key={element.id}>
                                                    <TableCell>{element.data.resident.first_name + ' ' + element.data.resident.last_name}</TableCell>
                                                    <TableCell>{format(new Date(element.data.startDate), "dd/MM/yyyy hh:mm a")}</TableCell>
                                                    <TableCell>{format(new Date(element.data.endDate), "dd/MM/yyyy hh:mm a")}</TableCell>
                                                    <TableCell>{element.data.comments}</TableCell>
                                                    <TableCell className="flex">
                                                        <Button onClick={() => openEditDialog(element)} variant="outline" size="icon" className='mr-2'>
                                                            <Pencil className="h-4 w-4" />
                                                        </Button>
                                                        
                                                        <Button onClick={() => openDeleteDialog(element.id as string)} variant="outline" size="icon" className='mr-2'>
                                                            <Trash className="h-4 w-4" />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            ) : (
                                <div className="flex flex-col w-100 justify-center align-center">
                                    <div className="flex justify-center items-center">
                                        <Search className="text-center w-5 mb-2" />
                                    </div>

                                    <h1 className="text-center">
                                        {t("mentruation.report.table.notFound")}
                                    </h1>
                                </div>
                            )
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
