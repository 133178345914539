import { Button } from '@/common/presentation/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { Loader } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
    setOptionModal: (optionSelected: string) => void;
    isOpen: boolean;
    message: string;
    optionSelected: string;
}

const ConfirmDialog = ({ setOptionModal, isOpen, message, optionSelected }: ConfirmDialogProps) => {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onOpenChange={() => setOptionModal((optionSelected == 'no' ? '' : 'no'))}>
            <DialogContent className="min-h-[15vh] gap-6 flex flex-col shadow-lg rounded-md border-t-4 border-primary max-w-[40vw] max-h-[40vh]">
                <DialogHeader className="mb-4">
                    <DialogTitle>{t('notes.confirmAction')}</DialogTitle>
                </DialogHeader>
                <DialogDescription>{message}</DialogDescription>
                <DialogFooter className="mt-6">
                    {optionSelected == 'yes' ? (
                        <Loader className="mr-2 h-4 w-4 animate-spin" />
                    ) : (
                        <>
                            <Button variant="outline" onClick={() => setOptionModal((optionSelected == 'no' ? '' : 'no'))}>
                                {t('notes.no')}
                            </Button>
                            <Button onClick={() => setOptionModal("yes")}>{t('notes.yes')}</Button>
                        </>
                    )}
                </DialogFooter>
            </DialogContent >
        </Dialog >
    );
};

export default ConfirmDialog;
