import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Button } from '@/common/presentation/components/ui/button';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useDashboardResidentsStore } from '@/modules/residents/domain/stores/dashboard/dasboardResidents';
import { useResidentStore } from '@/modules/residents/domain/stores/primary-information/residentStore';
import { AppDispatch } from '@/store/store';
import { useDispatch } from 'react-redux';
import * as residentsActions from '@/modules/residents/presentation/slices/residentsSlice';

interface Props {
  id: string;
}

export const TableActions = ({ id }: Props) => {
  const { status: residentStatusReq, updateResidentStatus } = useResidentStore();
  const { getInactiveResidents } = useDashboardResidentsStore();
  const { params } = useRouteParams();
  const dispatch = useDispatch<AppDispatch>();

  const location = params.l === 'all-locations' ? undefined : params.l;

  const handleActivate = async () => {
    try {
      await updateResidentStatus({ residentId: id, status: 'active' });
      getInactiveResidents(location);
      dispatch(residentsActions.getResidentsByLocation(location));

      toast({
        title: `Resident status has been updated to active`,
        variant: 'default'
      });
    } catch (error: any) {
      console.error(error.message);
      toast({
        title: 'Error updating resident status',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      <div className="flex gap-4">
        <Button
          variant="outline"
          className="hover:border-green-600 hover:text-green-600 text-green-500 border-green-500"
          onClick={handleActivate}
          disabled={residentStatusReq === 'loading'}
        >
          Activate Resident
        </Button>
      </div>
    </>
  );
};
