import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { useNewDocument } from '@/modules/residents/infrastructure/hooks/documents/useNewDocument';
import { RootState } from '@/store/store';
import { FileStack, LucideIcon, UserRoundCog } from 'lucide-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type Props = {
  className?: string;
};

type NavItemProps = {
  label: string;
  onClick: () => void;
  icon: LucideIcon;
};

const NavItem = ({ label, onClick, icon: Icon }: NavItemProps) => {
  return (
    <div className="flex items-center gap-2">
      <Button
        onClick={onClick}
        variant={'default'}
        size={'sm'}
        className="w-full flex items-center gap-4 justify-start"
      >
        <Icon className="w-6 h-6" />
        {label}
      </Button>
    </div>
  );
};

export const QuickLinks = ({ className }: Props) => {
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);
  const navigate = useNavigate();
  const { onOpen } = useNewDocument();

  const items = useMemo(() => {
    return [
      {
        label: 'Edit Staff',
        icon: UserRoundCog,
        onClick: () => navigate(`/staff/update?s=${staffMember?.id}`)
      },
      {
        label: 'Documents',
        icon: FileStack,
        onClick: () => onOpen()
      }
    ];
  }, [navigate, onOpen, staffMember?.id]);

  return (
    <>
      <div className={cn(className, 'flex flex-row justify-center lg:justify-start')}>
        <CustomAccordion
          title="Quick Links"
          openOption={false}
          className={cn('w-full max-h-[30vh] lg:w-[60%]')}
          headerPadding={false}
          defaultOpen
        >
          <ScrollArea className="max-h-[20vh] overflow-y-auto">
            {items.map((item, index) => (
              <div key={index} className="border-b border-gray-200 py-2">
                <NavItem label={item.label} icon={item.icon} onClick={item.onClick} />
              </div>
            ))}
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </CustomAccordion>
      </div>
    </>
  );
};
