import { cn } from '@/lib/utils';
import React from 'react';
import Select, { MultiValue, components } from 'react-select';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';

interface IOption {
  value: string;
  label: string;
  imageUrl?: string;
}

interface IProps {
  name: string;
  options: IOption[];
  onChange: (value: any) => void;
  selectedOptions?: IOption[];
  customMessage?: string | null;
  placeHolder?: string | null;
  className?: string;
  isDisabled?: boolean;
}

const MultiSelect: React.FC<IProps> = ({
  name,
  options,
  onChange,
  selectedOptions,
  customMessage = null,
  placeHolder = null,
  className,
  isDisabled = false
}) => {
  const defaultClasses = {
    control: () =>
      cn(
        '!bg-background !border !border-gray-300 dark:!border-gray-700 !rounded-md focus:!outline-none !shadow-none !min-h-10',
        'max-h-20 overflow-y-auto'
      ),
    menu: () => cn('!bg-background !border !border-gray !z-[999999]'),
    option: ({ isSelected, isFocused }: any) =>
      cn(
        'dark:!text-white dark:hover:!bg-primary/60 hover:!bg-primary/60',
        isSelected ? '!bg-primary text-white' : isFocused ? '!bg-primary/10' : ''
      ),
    singleValue: () => cn('text-dark dark:text-white'),
    multiValue: () => cn('!bg-primary !text-white !rounded flex-shrink-0'),
    multiValueLabel: () => cn('!text-white'),
    multiValueRemove: () => cn('hover:!bg-gray-400 hover:!text-white'),
    valueContainer: () => cn('!flex !flex-row !flex-wrap !gap-1 !overflow-x-auto !py-1 max-h-20'),
    input: () => cn('!text-sm'),
    container: () => cn('!min-h-10')
  };

  const customStyles = {
    valueContainer: (base: any) => ({
      ...base,
      maxHeight: '5rem',
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }),
    multiValue: (base: any) => ({
      ...base,
      flexShrink: 0
    })
  };

  const customNoOptionsMessage = () => {
    return customMessage ?? 'No options';
  };

  const onChangeDefault = (selected: MultiValue<IOption>) => {
    onChange(Array.from(selected));
  };

  const Option = (props: any) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div className="flex items-center">
          {data.imageUrl !== undefined && (
            <Avatar className="mr-2 h-6 w-6">
              <AvatarImage src={data.imageUrl} className="h-full w-full object-cover" />
              <AvatarFallback>
                {data.label
                  .split(' ')
                  .map((chunk: string) => chunk[0])
                  .join('')}
              </AvatarFallback>
            </Avatar>
          )}
          <span>{data.label}</span>
        </div>
      </components.Option>
    );
  };

  const MultiValueLabel = (props: any) => {
    const { data } = props;
    return (
      <components.MultiValueLabel {...props}>
        <div className="flex items-center">
          {data.imageUrl && (
            <Avatar className="mr-2 h-5 w-5">
              <AvatarImage src={data.imageUrl} className="h-full w-full object-cover" />
              <AvatarFallback>
                {data.label
                  .split(' ')
                  .map((chunk: string) => chunk[0])
                  .join('')}
              </AvatarFallback>
            </Avatar>
          )}
          <span>{data.label}</span>
        </div>
      </components.MultiValueLabel>
    );
  };

  return (
    <div className={cn('w-full', className)}>
      <Select
        isMulti
        classNames={defaultClasses}
        styles={customStyles}
        placeholder={placeHolder}
        isSearchable
        name={name}
        options={options}
        onChange={onChangeDefault}
        isClearable={true}
        closeMenuOnSelect={false}
        value={selectedOptions}
        noOptionsMessage={customNoOptionsMessage}
        components={{ Option, MultiValueLabel }}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default MultiSelect;