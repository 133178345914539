import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';

import { useUploadDocument } from '@/modules/residents/infrastructure/hooks/documents/useUploadDocument';
import {
  FormValues,
  UploadDocumentForm
} from '@/modules/residents/presentation/components/Documents/Forms/UploadDocumentForm';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { saveResidentDocument } from '@/modules/residents/presentation/slices/residentSlice';

export const UploadDocumentDialog = () => {
  const { isOpen, onClose, data: documentData } = useUploadDocument();

  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const { status } = useSelector((state: RootState) => state.residents.resident);

  const dispatch = useDispatch<AppDispatch>();

  const saveDocument = async (values: FormValues) => {
    const { files, ...rest } = values;

    const data = {
      ...rest,
      key: values.label.trim().replace(/'/g, '').replace(/\s+/g, '_').replace(/[-_]+/g, '_').toLowerCase(),
      category: documentData?.category,
      resident_id: resident?.id,
      parent_document_id: null,
      other_information: {
        digital_singature_on_file: false,
        hard_copy: false
      }
    };

    const stringifiedData = JSON.stringify(data);

    const payload = {
      files: files,
      data: stringifiedData
    };

    const response = await dispatch(saveResidentDocument(payload));

    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(residentActions.getResidentById(resident?.id));
      onClose();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[500px] max-h-[80vh]">
          <DialogHeader>
            <DialogTitle className="text-xl">
              Upload Document for <span className="font-bold">{resident?.first_name}</span>
            </DialogTitle>
          </DialogHeader>
          <UploadDocumentForm
            onSubmit={saveDocument}
            status={status}
            defaultValues={{
              label: '',
              comments: '',
              files: undefined
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
