import React, { useEffect, useState } from 'react';
import { Edit, Trash2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/common/presentation/components/ui/dialog.tsx';

// Components
import { AlertDeleteEventDoesNotRepeat } from './AlertDeleteEventDoesNotRepeat.tsx';
import { DialogDeleteRecurringEvent } from './DialogDeleteRecurringEvent.tsx';

// Interfaces
import { InfoEventCardProp, ITaskEventList } from '../../domain/calendar.domain';

// Hooks redux
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
// import { deleteRecurringEvent, getDataCalendar } from "../slices/CalendarSlice.ts";
import { useDialog } from '@/common/presentation/components/CustomDialog/useDialog.tsx';
import { deleteRecurringEvent, getDataCalendar, getSpecificTaskEvent } from '../slices/CalendarSlice.ts';
import { format } from 'date-fns';
import { TaskEventDialog } from './DialogTaskEvent/DialogTaskEvent.tsx';
import { Button } from '@/common/presentation/components/ui/button.tsx';
import { Skeleton } from '@/common/presentation/components/ui/skeleton.tsx';
import { el } from 'date-fns/locale';
import { Label } from '@/common/presentation/components/ui/label.tsx';
import { useTranslation } from 'react-i18next';
import { toast } from "@/common/presentation/components/ui/use-toast.ts";
import styles from "../pages/styles/index.module.css";

export const InfoEventCard: React.FC<InfoEventCardProp> =
 ({
  open,
  onClose, 
  eventTaskId, 
  startEndDate,
  fetchParamsData, 
  onSelectedDate,
  locationId,
  residentId,
  fromCalendar = true,
  allowEdit = true
}) => {

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const [value, setValue] = useState<string>("thisEvent");
  const { isOpen, openDialog, closeDialog } = useDialog();

  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch<AppDispatch>();
  let dataEvent: any = useSelector((state: RootState) => state.calendar.taskEventInfo);
  const taskEventsList = useSelector((state: RootState) => state.calendar.taskEventsList);
  const [taskEvent, setTaskEvent] = useState<any>({});
  const [allEvents, setAllEvents] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleDeleteClick = () => {
    if (!taskEvent.options) return;

    onClose();
    const options = taskEvent.options;

    if (options === "does_not_repeat") {
      setOpenAlertDialog(true);
    }
    if (['custom', 'daily'].includes(options)) {
      setOpenDeleteDialog(true); //Dialog delete recurring
    }

  }

  const handleDispatchDeleteData = async (): Promise<void> => {

    if (eventTaskId && value && (!fromCalendar || startEndDate)) {

      const params = { eventId: eventTaskId, startDate: startEndDate ? startEndDate.start : '', type: value };
      const { payload } = await dispatch(deleteRecurringEvent(params));

      if (payload.result === 'ok' && fetchParamsData) {
        toast({
          description: t(`calendar.eventDeletedCorrectly`),
          className: 'bg-green-500 text-white'
        });
        dispatch(getDataCalendar({formData: fetchParamsData}))
      }
    }
  }


  useEffect(() => {

    setLoading(true);

      if (eventTaskId && open === true) {
          const eventId = { eventId: eventTaskId }

          dispatch(getSpecificTaskEvent(eventId))
              .then(({ payload }: any) => {
                if (Object.keys(payload).includes('id')) {
                  setTaskEvent(payload);
                  setLoading(false)
                }
          });
      }
      
      //Get data from table
      if ((!fromCalendar && taskEventsList.length == 0) || !open) {
        return;
      }

      if (!fromCalendar) {
          const event = taskEventsList.find((event: ITaskEventList) => event.id === eventTaskId);
          setValue('allEvents');
          setAllEvents(true);
          setTaskEvent(event);
          setLoading(false);
      }  


  }, [open]);

  useEffect(()=>{},[])

  function getNamesResident(dataEvent: any): string{
    if(isLoading) return '';
    
      if( Object.keys(dataEvent).includes('resident') && dataEvent.resident.length > 0 ){
          return dataEvent.resident.length > 1
                  ? t('calendar.everyone')
                  : `${dataEvent.resident[0].first_name} ${dataEvent.resident[0].last_name}`;
      }
    return '';
  }

  function getNamesUsers(dataEvent: any): string{
    if(isLoading) return '';
    
    if( Object.keys(dataEvent).includes('task_event_has_users') && dataEvent?.task_event_has_users?.length > 0 ){
        return dataEvent.task_event_has_users.lenght > 1
                ? t('calendar.everyone')
                : `${dataEvent.task_event_has_users[0].users.first_name} ${dataEvent.task_event_has_users[0].users.last_name}`;
    }
    return '';
  }
  
  return (
    <>
      <AlertDeleteEventDoesNotRepeat
        open={openAlertDialog}
        openChange={setOpenAlertDialog}
        onDelete={handleDispatchDeleteData}
      />

      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className={`${styles.dialogContent} md:max-w-[425px]`}>
          <DialogHeader>
            {(isLoading || !allowEdit) ? (<></>) : (
              <div className="flex space-x-4 justify-end mr-10 mt-[-9px]">
                <button className="text-gray-500 hover:text-gray-700">
                  <Edit size={18} onClick={openDialog} />
                </button>
                <button className="text-gray-500 hover:text-gray-700" onClick={handleDeleteClick}>
                  <Trash2 size={18} />
                </button>
              </div>
            )}
            <DialogTitle className="pb-3 pt-4 md:pt-0">
              <Label className="text-xl font-bold">{t('calendar.eventDetails')}</Label>
            </DialogTitle>
            {
              isLoading ? (
                <div className="flex items-center space-x-4">
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-[200px]" />
                  <Skeleton className="h-4 w-[250px]" />
                  <Skeleton className="h-4 w-[200px]" />
                </div>
              </div>
              )
              : (
                <div className="flex flex-col gap-2 justify-start items-start text-start">
                  <div className="flex flex-col">
                    <Label className="text-base font-bold">{t('calendar.title')}</Label>
                    <Label className="text-base font-normal">{taskEvent.title}</Label>
                  </div>
                  {
                    fromCalendar && (
                      <div className="flex flex-col">
                      <Label className="text-base font-bold">{t('calendar.date')}</Label>
                      <Label className="text-base font-normal">
                          {startEndDate && startEndDate.start ? format(new Date(startEndDate.start), 'MM/dd/YYY') : ''}
                      </Label>
                    </div>
                    )
                  }
                  <div className="flex flex-col">
                    <Label className="text-base font-bold">
                      { taskEvent.related_to === 'residents' ? t('calendar.resident') : t('calendar.user') }
                    </Label>
                    <Label className="text-base font-normal">
                      { taskEvent.related_to === 'residents' ? getNamesResident(dataEvent) : getNamesUsers(dataEvent) }
                    </Label>
                  </div>
                  <div className="flex flex-col">
                    <Label className="text-base font-bold">
                      {t('calendar.createdBy')}
                    </Label>
                    <Label className="text-base font-normal">
                      {Object.keys(taskEvent).includes('user')? `${taskEvent.user.first_name} ${taskEvent.user.last_name}` : ''}
                    </Label>
                  </div>
                </div>
              )
            }

          </DialogHeader>
          <DialogFooter>
            <DialogTrigger asChild>
              <Button
                className="bg-primary text-white py-2 px-4 rounded"
                onClick={onClose} 
                >{t('calendar.close')}
              </Button>
            </DialogTrigger>
          </DialogFooter>
        </DialogContent>

        <DialogDeleteRecurringEvent
          open={openDeleteDialog}
          openChange={setOpenDeleteDialog}
          dispatchDeleteData={handleDispatchDeleteData}
          onChange={handleValue}
          allEvents={allEvents}
        />

        {!isLoading && (
          <>
            <TaskEventDialog
              open={isOpen}
              onClose={closeDialog}
              selectedDate={startEndDate? startEndDate.start : format(new Date(), "MM/dd/yyyy")} 
              startEndDateUpdate={startEndDate} //
              onSelectedDate={onSelectedDate}
              fetchParamsData={fetchParamsData}
              isUpdate={true}
              idUpdate={eventTaskId}
              locationId={locationId}
              residentId={residentId}
              onCloseInfoCard={onClose}
              fromCalendar={fromCalendar}
            />
          </>
        )}
      </Dialog>
    </>
  );
};