import { useLocationSelectorDialog } from '@/auth/infrastructure/hooks/useLocationSelectorDialog';
import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/common/presentation/components/ui/dialog';
import { useContext } from 'react';

export const LocationSelectorDialog = () => {
  const { isOpen, onClose } = useLocationSelectorDialog();
  const { setParam } = useRouteParams();
  const { setHasSelectedLocation } = useContext(AuthContext);

  const handleLocationChange = (locationId: string) => {
    localStorage.setItem('location_selected', locationId);
    setParam('l', locationId);
    setHasSelectedLocation(true);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen}>
        <DialogContent className="max-w-[50vw] w-auto max-h-[90vh] h-auto overflow-x-auto">
          <DialogHeader>
            <DialogTitle className="text-xl">Location Selector</DialogTitle>
            <DialogDescription>Select a location to proceed</DialogDescription>
          </DialogHeader>
          <LocationSelector allLocations={false} onSelect={handleLocationChange} firstSelection={true} />
        </DialogContent>
      </Dialog>
    </>
  );
};
