// columnsContacts.tsx
import renderActionsColumn from './renderActionsColumn';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

const useContactsColumns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleResidentClick = (residentId) => {
    const currentLocation = searchParams.get('l') || 'all-locations';

    // Actualizamos la URL con los parámetros `r` y `l`
    setSearchParams({ r: residentId, l: currentLocation });
  
    // Navega a la URL con los parámetros actualizados
    //navigate(`/contacts?r=${residentId}&l=${currentLocation}`);
  };

  const ContactsColumns = [
    {
      key: 'category',
      id: 'category',
      labelTranslationKey: 'contacts.category',
      value: 'category',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'linkto',
      id: 'linkto',
      labelTranslationKey: 'contacts.resident',
      value: 'linkto',
      visible: true,
      sortable: true,
      width: '20%',
      render: (rowData) => (
        <span
          className="cursor-pointer text-primary hover:underline"
          onClick={() => handleResidentClick(rowData.resident_id)} // Define onClick correctamente
        >
          {rowData.linkto}
        </span>
      )
    },
    {
      key: 'full_name',
      id: 'full_name',
      labelTranslationKey: 'contacts.contact',
      value: 'full_name',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'phone',
      id: 'phone',
      labelTranslationKey: 'contacts.phone',
      value: 'phone',
      visible: true,
      sortable: true,
      width: '20%'
    },
    {
      key: 'email',
      id: 'email',
      labelTranslationKey: 'contacts.email',
      value: 'email',
      visible: false,
      sortable: true,
      width: '20%'
    },
    {
      key: 'relationship',
      id: 'relationship',
      labelTranslationKey: 'contacts.relationship',
      value: 'relationship',
      visible: false,
      sortable: true,
      width: '20%'
    },
    {
      key: 'actions',
      labelTranslationKey: 'contacts.actions',
      render: renderActionsColumn,
      visible: true,
      static: true,
      width: '20%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(ContactsColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = ContactsColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useContactsColumns;
