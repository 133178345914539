import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
 } from '@/common/presentation/components/ui/dialog';
 import { Button } from '@/common/presentation/components/ui/button';
 import { Label } from '@/common/presentation/components/ui/label';
 import { Input } from '@/common/presentation/components/ui/input';
import { Pencil, Trash } from 'lucide-react';
import { useDispatch } from 'react-redux';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useEffect, useState } from 'react';
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import { t } from 'i18next';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

export function GeneralOutingsEditDialog(props: any) {
    const dispatch = useDispatch();
    const { params, setParam } = useRouteParams();
    const [openEdit, setOpenEdit] = useState(false);
    const { locationSelectedId } = useLocation();
    const [values, setValues] = useState({
        outingActivity: "",
        duration: "",
        staff: "",
        notes: ""
    });
    
    useEffect(() => {
        const data = props.data;

        setValues({
            outingActivity: data.data.outingActivity,
            duration: data.data.duration,
            staff: data.data.staff,
            notes: data.data.notes
        });
    }, []);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = () => {
        dispatch(trackerActions.updateTrackerOutingsGeneral(
            {
                id: props.data.id,
                id_data: props.data.data.id,
                data: values
            }
        ));

        fetchData();

        return true;
    }

    const fetchData = () => {
        dispatch(trackerActions.getTrackerOutingsGeneral(
            {
              "location_id": locationSelectedId
            }
          )
        );
      }

    return (
        <div>
            <Button onClick={() => openEditDialog()} variant="outline" size="icon" className='mr-1 ml-1'>
                <Pencil className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.edit.title")}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onSubmit={() => handleEdit()}
                onClose={closeEditDialog}
            >
                <div>
                    <Label htmlFor="outing" className="text-right">Outing/Activity</Label>
                    
                    <Input
                        id="outingActivity"
                        name="outingActivity"
                        defaultValue="Pedro Duarte"
                        type="text"
                        className="col-span-3"
                        value={values.outingActivity}
                        onChange={handleInputChange}
                    />
                </div>

                <div>
                    <Label htmlFor="duration" className="text-right">Duration</Label>

                    <Input
                        id="duration"
                        name="duration"
                        defaultValue="@peduarte"
                        className="col-span-3"
                        type="text"
                        value={values.duration}
                        onChange={handleInputChange}
                    />
                </div>

                <div>
                    <Label htmlFor="staff" className="text-right">Staff Accompanying and Title</Label>

                    <Input
                        id="staff"
                        name="staff"
                        defaultValue="@peduarte"
                        className="col-span-3"
                        type="text"
                        value={values.staff}
                        onChange={handleInputChange}
                    />
                </div>

                <div>
                    <Label htmlFor="notes" className="text-right">Notes</Label>

                    <Input
                        id="notes"
                        name="notes"
                        defaultValue="@peduarte"
                        className="col-span-3"
                        type="text"
                        value={values.notes}
                        onChange={handleInputChange}
                    />
                </div>
            </CustomDialog>
        </div>
    );
};
