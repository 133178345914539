import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MedicationConfiguration from "../infrastructure/services/MedicationConfiguration";

export interface IMedicationConfigurationsState {
  userPermissions: [],
  permissionsMedications: [],
  isLoading: boolean;
  error: string | null;
}

const initialState: IMedicationConfigurationsState = {
  permissionsMedications: [],
  userPermissions:[],
    isLoading: false,
    error: ''
};


export const getPermissionsUserMedication = createAsyncThunk('allowStaffEditMedication/getPermissionsUserMedication',
 async () => {
    const response = await MedicationConfiguration.getPermissionsUserMedication();
    return response;
  }
);

export const getPermissionsMedications = createAsyncThunk('allowStaffEditMedication/getPermissionsMedications',
  async (formData: any = []) => {
     const response = await MedicationConfiguration.getPermissionsMedications(formData);
     return response;
   }
 );
export const updateUserPermissionsMedication = createAsyncThunk('allowStaffEditMedication/updateUserPermissionsMedication',
  async (formData: any ) => {
     const response = await MedicationConfiguration.updateUserPermissionsMedication(formData);
     return response;
   }
 );

const MedicationConfigurationSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    allowUsersEditMedicationLoading: (state)=>{
     state.isLoading = true;
     state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.
    addCase(getPermissionsUserMedication.fulfilled, (state, action) => {
      state.userPermissions = action.payload;
      state.isLoading = false;
    })
    .addCase(getPermissionsMedications.fulfilled, (state, action) => {
      state.permissionsMedications = action.payload;
      state.isLoading = false;
    });
  }
});

export const { allowUsersEditMedicationLoading } = MedicationConfigurationSlice.actions;
export default MedicationConfigurationSlice.reducer;