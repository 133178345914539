import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerElopementService from '../../infrastructure/services/TrackerElopementService';


export const getTrackerElopement = createAsyncThunk("trackerElopement/getTrackerElopement", async (body: any) => {
    const response = await TrackerElopementService.getTrackerElopement(body);

    return response;
});

export const saveTrackerElopement = createAsyncThunk("trackerElopement/SaveTrackerElopement", async (body: any) => {
    const response = await TrackerElopementService.saveTrackerElopement(body);

    return response;
});

interface TrackerElopementState {
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    elopementReport: any[];
    saveElopementResponse?: { 
        message: string;
        data: any[];          
    };
}

const initialState: TrackerElopementState = {
    status: 'idle',
    error: null,
    elopementReport: []
};


const trackerElopement = createSlice({
    name: 'trackerElopement',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getTrackerElopement.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getTrackerElopement.fulfilled, (state, action) => {
            state.status = 'idle';
            state.elopementReport = action.payload;
        });

        builder.addCase(getTrackerElopement.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });
        builder.addCase(saveTrackerElopement.fulfilled, (state, action) => {
            state.status = 'idle';
            state.saveElopementResponse = action.payload;
        });

        builder.addCase(saveTrackerElopement.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });
    }
});

export default trackerElopement.reducer;
