import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { Settings } from 'lucide-react';
import { useSettings } from '@/modules/settings/infrastructure/hooks/use-settings';

const SettingsButton = () => {
  const { onOpen } = useSettings();
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            onClick={onOpen}
            className="h-8 w-8 text-white hover:bg-primary/50 hover:text-white"
          >
            <Settings className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-background text-black dark:text-white shadow-md">
          <p>Settings</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default SettingsButton;
