import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

export const ProfileImageService = {
  getProfileImage: async (staff_id: string) => {
    const response = await axios.get(`/staff/${staff_id}/profile-image`);
    return response;
  },

  setProfileImage: async (staff_id: string, data: any) => {
    const formData = new FormData();
    formData.append('file', data);

    const response = await axios.post(`/staff/${staff_id}/profile-image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response;
  },

  deleteProfileImage: async (staff_id: string) => {
    const response = await axios.delete(`/staff/${staff_id}/profile-image`);
    return response;
  }
};

export default ProfileImageService;
