import { Loader2 } from "lucide-react";

interface LoadingWrapperProps {
    loading: boolean;
    children: React.ReactNode;
}
  
const LoadingWrapper = ({ loading, children }: LoadingWrapperProps) => {
    return (
        <div className="flex flex-col" style={{ maxHeight: "40rem", position: "relative" }}>
            {
                loading && (
                    <div className="absolute inset-0 rounded dark:bg-white/20 bg-white bg-opacity-70 flex items-center justify-center z-10">
                        <Loader2 className="animate-spin text-primary" size={24} />
                    </div>
                )
            }

            { 
                children 
            }
        </div>
    );
};

export default LoadingWrapper;
