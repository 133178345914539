import { cn } from '@/lib/utils';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { DraftI } from '../../../domain/note.domain';
import { fetchGetDrafts } from '../../slices/NoteSlice';
import { format } from 'date-fns';
import { Loader2 } from 'lucide-react';
import { RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/registry/new-york/ui/scroll-area';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from '@/common/presentation/components/ui/infinite-scroll';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import React, { useEffect } from 'react';

interface DraftListProps {
  items: DraftI[] | null;
  setCrudState: (crudState: CrudState) => void;
  crudState: CrudState;
  loadingNotes: boolean;
}

export function DraftList({ items, setCrudState, crudState, loadingNotes }: DraftListProps) {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = React.useState(true);
  let [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.auth.user);
  const userName = user?.first_name + ' ' + user?.last_name;
  const metaDraft = useSelector((state: RootState) => state.notes.drafts);

  useEffect(() => {
    if (metaDraft.current_page >= metaDraft.last_page) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [metaDraft]);

  const fectchMoreDrafts = async () => {
    setLoading(true);
    await dispatch<any>(fetchGetDrafts());
    setLoading(false);
  };

  if (loadingNotes) {
    return <div className='w-full flex justify-center'><Loader2 className="my-4 h-8 w-8 animate-spin" /></div>
  }

  if (metaDraft.current_page == 1 && !items?.length) {
    return <div className='w-full flex justify-center'>{t("notes.notesNotFound")}</div>
  }

  if (!items) {
    return;
  }

  return (
    <ScrollArea className="h-[65vh]">
      <div className="flex flex-col gap-2 p-4 pt-0">
        {items?.map((item) => (
          <button
            key={item.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all group hover:bg-accent',
              crudState.itemId === item.id && 'bg-primary/10'
            )}
            onClick={() => {
              setCrudState(
                NoteHelperService.defineState({ isFinishingDraft: true, showingDrafts: true, itemId: item.id })
              );
            }}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">{userName}</div>
                  {!item && <span className="flex h-2 w-2 rounded-full bg-blue-600" />}
                </div>
                <div
                  className={cn(
                    'ml-auto text-xs group-hover:hidden',
                    crudState.itemId === item.id ? 'text-foreground' : 'text-muted-foreground'
                  )}
                >
                  {format(new Date(item.created_at), 'yyyy-MM-dd HH:mm:ss')}
                </div>
              </div>
            </div>
            <div className="line-clamp-2 text-xs text-muted-foreground">{item.content.substring(0, 300)}</div>
          </button>
        ))}

        <InfiniteScroll hasMore={hasMore} isLoading={loading} next={fectchMoreDrafts} threshold={0.5}>
          {hasMore && <Loader2 className="my-4 h-8 w-8 animate-spin" />}
        </InfiniteScroll>
      </div>
    </ScrollArea>
  );
}
