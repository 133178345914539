import { useEffect, useState } from "react";
import QuickTrackerService from "../services/QuickTrackerService";
import QuickTrackerInputProps from "../interfaces/QuickTrackerInputPropsInterface";
import QuickTrackerInputSelect from "./QuickTrackerInputSelect";
import QuickTrackerInputText from "./QuickTrackerInputText";
import QuickTrackerInputDate from "./QuickTrackerInputDate";

const QuickTrackerInput = ({ currentItems, resident, type, onselectvalue, data, input }: QuickTrackerInputProps) => {
    const [item, setItem] = useState<any>();
    const [exist, setExist] = useState<boolean>(false);
    const [existOnList, setExistOnList] = useState<boolean>(false);

    useEffect(() => {
        if(data && data.length) {
            const filteredItem = data.filter(
                (value) => value.resident.id === resident.id && value.type === type.id
            )[0];

            setItem(filteredItem);
            setExist(!!filteredItem);
        } else {
            setItem(null);
            setExist(false);
        }
    }, [data]);

    useEffect(() => {
        if(item) {
            setExistOnList(QuickTrackerService.existOnList(currentItems, resident, type));
        }
    }, [item, currentItems]);

    return (
        <>
            {
                input === "select" && <QuickTrackerInputSelect item={item} resident={resident} type={type} exist={exist} existOnList={existOnList} onselectvalue={onselectvalue} />
            }

            {
                input === "text" && <QuickTrackerInputText item={item} resident={resident} type={type} exist={exist} existOnList={existOnList} onselectvalue={onselectvalue} />
            }

            {
                input === "date" && <QuickTrackerInputDate item={item} resident={resident} type={type} exist={exist} existOnList={existOnList} onselectvalue={onselectvalue} />
            }
        </>
    );
};

export default QuickTrackerInput;
