import { Pill } from 'lucide-react';
import { AlertCard } from './alert-card';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import AlertsSkeleton from './skeleton';
import { UserHelper } from '@/utils/helpers/user.helper';
import { useDateHelpers } from '@/utils/helpers/dates.helper';

export const MedicationAlerts = () => {
  const alerts = useSelector((state: RootState) => state.dashboard.medicationAlerts);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents ?? []);
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();

  return (
    <>
      <AlertCard title={t('dashboard.medicationAlerts')} alerts={alerts.data ?? []} icon={Pill} custom={true}>
        {alerts.status === 'loading' ? (
          <AlertsSkeleton />
        ) : !alerts.data || alerts.data.length === 0 ? (
          <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          alerts?.data?.map((alert, index) => (
            <div key={index}>
              <div className="py-1 px-2 rounded-sm hover:bg-primary/5 cursor-pointer overflow-hidden text-ellipsis ">
                <span className="text-primary font-semibold ml-1">
                  {UserHelper.getResidentName(alert.resident_id, residents)}
                </span>{' '}
                -
                <span className="ml-1">
                  {t(`dashboard.${alert.message}`, {
                    medicationName: alert.medication_info.name.charAt(0).toUpperCase() + alert.medication_info.name.slice(1) ,
                    expirationDate: alert.expiration_date ? formatDate(alert.expiration_date ?? '', false) : '',
                    minimunQuantity: alert.medication_info.minimum_quantity,
                    medicationType: alert.medication_info.type
                  })}
                </span>
              </div>
            </div>
          ))
        )}
      </AlertCard>
    </>
  );
};
