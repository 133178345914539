import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { FetchParamsData, IDeleteRecurringEvent, ICalendarResumeData } from '../../domain/calendar.domain';
import { AxiosRequestConfig } from 'axios';

const CalendarService = {

  saveInformation: async (formData: any) => {

    try {
      const response = await axios.post(`calendar/saveInformation`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error creating tasks event type: ${error.message}`);
    }
  },

  getCategories: async () => {
    try {
      const { data } = await axios.get(`calendar/getCategories`);

      if (data.categories) {
        return data.categories;
      }

    } catch (error: any) {
      throw new Error(`Error get categories event type: ${error.message}`);
    }
  },
  generateReport: async (filters: ICalendarResumeData) => {
    let url = '/calendar/generate-report';
    try {
      const response = await axios.post(url, filters, {
        responseType: 'blob'
      });
      return response.data;
    
    } catch (error: any) {

      if (error.response) {
        const errorMessage = error.response.data.message || 'Error generating report';
        throw new Error(errorMessage);

      } else if (error.request) {

        throw new Error('No response received from the server');

      } else {
        
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getDataCalendar: async (params: FetchParamsData, signal?: AbortSignal) => {

    try {
      const config: AxiosRequestConfig = { signal };
      const { data } = await axios.post(`calendar/getDataCalendar`, params, config);
    
      if (data) {
        return data;
      }

    } catch (error: any) {
      throw new Error(`Error get data calendar: ${error.message}`)
    }

  },
  getUsersToNotify: async (params: object) => {
    try {
      const { data } = await axios.post(`calendar/getUsersToNotify`, params);
      
      if (data) {
        return data;
      }

    } catch (error: any) {
      throw new Error(`Error get data calendar: ${error.message}`)
    }

  },
  getSpecificTaskEvent: async (params: object) => {
    try {
      const { data } = await axios.post(`calendar/getSpecificTaskEvent`, params);

      if (data.response) {
        return data.response;
      }

    } catch (error: any) {
      throw new Error(`Error get data calendar: ${error.message}`)
    }

  },
  updateTaskEvent: async (params: any) =>{
    try {
      const data = await axios.post(`calendar/updateTaskEvent`, params);
      
      if(data){
        return data;
      }

    } catch (error: any) {
      throw new Error(`Error update event: ${error.message}`)
    }
  },
  deleteRecurringEvent: async (params: IDeleteRecurringEvent) =>{
    try {
      
      const { data } = await axios.post(`calendar/deleteRecurringEvent`, params);
        
       if(data){
        return data;
      }

    } catch (error) {
      throw new Error(`Error delete recurring event`);
    }
  },
  getResume: async (filter: ICalendarResumeData) => {
    let url = '/calendar/resume';
    try {
      const response = await axios.post(url, filter);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error getting resume';
        throw new Error(errorMessage);
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

}

export default CalendarService;