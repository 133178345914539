import { Navigate, Route, Routes } from 'react-router-dom';
import { MenuProtectedRoute } from "./MenuProtectedRoute";

import AdministrationPage from '@/modules/tracker/presentation/pages/AdministrationPage';
import TrackerPage from '@/modules/tracker/presentation/pages/TrackerPage';
import CustomColumn from '@/modules/tracker/presentation/pages/CustomColumn';
import TrackerHygiene from '@/modules/tracker/presentation/pages/TrackerHygiene';
import TrackerHygieneType from '@/modules/tracker/presentation/pages/TrackerHygieneType';
import TrackerVitals from '@/modules/tracker/presentation/pages/TrackerVitals';
import TrackerVitalsType from '@/modules/tracker/presentation/pages/TrackerVitalsType';
import TrackerHygieneNight from '@/modules/tracker/presentation/pages/TrackerHygieneNight';
import TrackerHygieneNightType from '@/modules/tracker/presentation/pages/TrackerHygieneNightType';
import TrackerDirect from '@/modules/tracker/presentation/pages/TrackerDirect';
import TrackerDirectType from '@/modules/tracker/presentation/pages/TrackerDirectType';
import TrackerInconsistence from '@/modules/tracker/presentation/pages/TrackerInconsistence';
import TrackerOutingsPage from '@/modules/tracker/presentation/pages/TrackerOutingsPage';
import TrackerSkinCheck from '@/modules/tracker/presentation/pages/TrackerSkinCheck';
import { TrackerCleaning } from '@/modules/tracker/presentation/pages/TrackerCleaning';
import TrackerElopement from '@/modules/tracker/presentation/pages/TrackerElopement';
import { SeizurePage } from '@/modules/tracker/presentation/pages/SeizurePage';
import TrackerFood from '@/modules/tracker/presentation/pages/TrackerFood';
import TrackerFoodType from '@/modules/tracker/presentation/pages/TrackerFoodType';
import TrackerCashResource from '@/modules/tracker/presentation/pages/TrackerCashResource';
import { TrackerMenstruationPage } from '@/modules/tracker/presentation/pages/TrackerMenstruationPage';
import TrackerFall from '@/modules/tracker/presentation/pages/TrackerFall';
import TrackerSleep from '@/modules/tracker/presentation/pages/TrackerSleep';


const TrackerRouter = () => {
  const defaultNavigateTo: string = '/tracker/hygiene';
  const parentRoute: string = '/tracker';

  return (
    <>
      <Routes>
        <Route path="/" element={<TrackerPage />} />
        <Route path="/administration/:locationId?" element={<AdministrationPage />} />
        <Route path="/tableCustom" element={<CustomColumn />} />

        <Route path="/hygieneType/:locationId?" element={<TrackerHygieneType dataHygieneReport={() => { }} />} />
        <Route path="/vitalsType/:locationId?" element={<TrackerVitalsType setpTypesVital={() => { }} />} />
        <Route path="/hygienenightType/:locationId?" element={<TrackerHygieneNightType />} />
        <Route path="/directType/:locationId?" element={<TrackerDirectType setpTypesDirect={() => { }} />} />
        <Route path="/foodtype/:locationId?" element={<TrackerFoodType dataFoodReport={() => { }} />} />
        <Route path="/:locationId?" element={<AdministrationPage />} />

        <Route path="/hygiene/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={'/tracker'}
              parentRoute={parentRoute}
              childrenRoute="/tracker/hygiene"
              element={<TrackerHygiene />}
            />}
        />

        <Route path="/vitals/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/vitals"
              element={<TrackerVitals />}
            />}
        />

        <Route path="/hygienenight/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/hygienenight"
              element={<TrackerHygieneNight />}
            />}
        />

        <Route path="/direct/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/direct"
              element={<TrackerDirect />}
            />}
        />

        <Route path="/elopement/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/elopement"
              element={<TrackerElopement />}
            />}
        />

        <Route path="/incontinence/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/incontinence"
              element={<TrackerInconsistence />}
            />}
        />

        <Route path="/skincheck/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/skincheck"
              element={<TrackerSkinCheck />}
            />}
        />

        <Route path="/sleep/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/sleep"
              element={<TrackerSleep />}
            />}
        />
        <Route path="/food/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/food"
              element={<TrackerFood />}
            />}
        />
        <Route path="/fall/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/fall"
              element={<TrackerFall />}
            />}
        />
        <Route path="/cashresource/:locationId?"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/cashresource"
              element={<TrackerCashResource />}
            />}
        />
        <Route path="/outingsTracker"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/outingsTracker"
              element={<TrackerOutingsPage />}
            />}
        />
        <Route path="/cleaning"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/cleaning"
              element={<TrackerCleaning />}
            />}
        />
        {/* <Route path="/elopement"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/elopement"
              element={<ElopementPage />}
            />}
        /> */}
        <Route path="/seizure"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/seizure"
              element={<SeizurePage />}
            />}
        />
        <Route path="/menstruation"
          element={
            <MenuProtectedRoute
              navigateTo={defaultNavigateTo}
              parentRoute={parentRoute}
              childrenRoute="/tracker/menstruation"
              element={<TrackerMenstruationPage />}
            />}
        />

      </Routes>
    </>
  );
};

export default TrackerRouter;