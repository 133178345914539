import React, { useState } from 'react';
import { Row } from "@tanstack/react-table";
import { Button } from '@/common/presentation/components/ui/button';
import { Pencil, Trash } from 'lucide-react';
import { t } from 'i18next';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEditClick: (id: number, name: string, status: string) => void;
  handleDeleteClick: (id: string) => void;
}

const RenderAreaActionsColumn: React.FC<RenderActionsColumnProps> = (rowData, handleButtonOpenDialog) => {
    const Fields = {
        'Edit': [
            { fieldName: 'name', label: t('trackers.customtype.Name'), type: 'text' },
        ]
    };

    return (
        <div className='flex'>
            <Button onClick={() => handleButtonOpenDialog(Fields['Edit'], 'Edit', rowData)} variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>

            <Button onClick={() => handleButtonOpenDialog('', 'Delete', rowData, t('trackers.trackerhygiene.deleteMessage'))} variant="outline" size="icon" className='mr-2'>
                <Trash className="h-4 w-4" />
            </Button>
        </div>
    );
};

export default RenderAreaActionsColumn;
