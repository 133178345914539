import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';
import Select, { StylesConfig } from 'react-select';
import { useEffect } from 'react';

export interface SelectOption {
    value: string;
    label: string;
}

export interface CustomSelectProps {
    options: SelectOption[];
    value: SelectOption | null;
    onChange: any;
    name?: string;
    isSearchable?: boolean;
    isClearable?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
    options,
    value,
    onChange,
    name,
    isSearchable = false,
    isClearable = false
}) => {
    
    const clientColor = useSelector((state: RootState) => 
        state.configurations?.configurations?.accent_color
    ) ?? '#3b8bc3';

    const customStyles: StylesConfig<SelectOption, false> = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? clientColor : '#e5e7eb',
            borderWidth: state.isFocused ? '2px' : '1px',
            borderRadius: '0.375rem',
            boxShadow: 'none',
            fontSize: '13px',
            minHeight: '36px',
            '&:hover': {
                borderColor: clientColor,
                borderWidth: '2px',
            },
            transition: 'all 0.2s ease',
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '160px',
            padding: '0',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0',
            padding: '0',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#9CA3AF',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#374151',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '4px',
            color: 'gray',
            '&:hover': {
                color: clientColor
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? clientColor : 'white',
            color: state.isSelected ? 'white' : '#374151',
            fontSize: '13px',
            padding: '8px 12px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: state.isSelected ? clientColor : `${clientColor}20`,
                color: state.isSelected ? 'white' : '#374151'
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
        }),
    };

    useEffect(() => {
        if (value && !options.some(option => option.value === value.value)) {
            onChange({ value: '', label: 'Select option' });
        }
    }, [options, value]);

    return (
        <Select<SelectOption>
            styles={customStyles}
            value={value}
            onChange={onChange}
            options={options}
            name={name}
            isSearchable={isSearchable}
            isClearable={isClearable}
            placeholder="Select option"
            className="w-full z-[9999999999]"
        />
    );
};

export default CustomSelect;