import Select from 'react-select';
import { cn } from '@/lib/utils';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { NoteI } from '@/modules/notes/domain/note.domain';
import { useTranslation } from 'react-i18next';
import { fetchPharmacyByLocationId } from '@/modules/pharmacy/presentation/slices/PharmacySlice';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

interface PharmacyI {
  id: string;
  name: string;
  user: {
    id: string;
  };
  residents: {
    id: string;
  }[];
}

interface SelectNoteToProps {
  handleSelectNoteTo: (e: any) => void;
  note?: NoteI | null;
  setIsPharmacySelected: (state: boolean) => void;
  isPharmacySelected: boolean;
  residentSelectedId: string|null;
}

interface Option {
  value: string;
  label: string;
}

export function SelectNoteTo({
  handleSelectNoteTo,
  note,
  setIsPharmacySelected,
  isPharmacySelected,
  residentSelectedId
}: SelectNoteToProps) {
  // Init values
  const { t } = useTranslation();

  // Tools
  const { params } = useRouteParams();
  const dispatch = useDispatch<AppDispatch>();
  const { locationSelectedId } = useLocation();

  // Redux
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const departments = useSelector((state: RootState) => state.departments.departments);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const user = useSelector((state: RootState) => state.auth.user);

  // Hooks
  const [noteToOptions, setNoteToOptions] = useState<Option[]>([]);
  const [defaultNoteToOptions, setDefaultNoteToOptions] = useState<Option[]>([]);

  const [pharmacies, setPharmacies] = useState<PharmacyI[]>([]);

  useEffect(() => {
    getPharmacies();
  }, [locationSelectedId]);

  const getPharmacies = async () => {
    try {
      const response = await dispatch(fetchPharmacyByLocationId({locationId: locationSelectedId}));
      const { list }: { list: PharmacyI[] } = response.payload;
      setPharmacies(list);
    } catch (error) {
      console.log(error);
    }
  };

  // Recipients options
  useEffect(() => {
    const options: Option[] = [];
    const defaultOptionValues = defaultNoteToOptions.map((opt) => opt.value);

    const hasLocation = defaultOptionValues.some((val) => val.includes('-location'));
    const hasStaffOrDepartment = defaultOptionValues.some(
      (val) => val.includes('-staff') || val.includes('-department')
    );

    // Build options
    if (hasLocation) {
      setLocations(options);
    } else if (hasStaffOrDepartment) {
      if (!isPharmacySelected) {
        setDepartments(options);
        setStaff(options);
      }
    } else {
      setLocations(options);

      // If no specific filters, include all options
      if (params.l === 'all-locations') {
        // All houses option
        let showAllHouses = false;
        user.roles.forEach((role: any) => {
          if (role.name == 'Executive' || role.name == 'Super Administrator') {
            showAllHouses = true;
          }
        });

        if (showAllHouses) {
          const item = {
            value: 'all-houses' + '-location',
            label: t('notes.allHouses')
          };
          options.unshift(item);
        }
      }

      user.roles.forEach((role: any) => {
        if (role.name != 'Pharmacy') {
          setDepartments(options);
        }
      });

      user.roles.forEach((role: any) => {
        if (role.name != 'Pharmacy') {
          setStaff(options);
        }
      });
    }

    user.roles.forEach((role: any) => {
      if (role.name != 'Pharmacy') {
        setPharmaciesNoteTo(options);
      }
    });

    if (JSON.stringify(noteToOptions) !== JSON.stringify(options)) {
      setNoteToOptions(options);
    }
  }, [params.l, locations, defaultNoteToOptions, noteToOptions, pharmacies, residentSelectedId, isPharmacySelected]);

  useEffect(() => {
    if (isPharmacySelected) {
      const pharmacyOptions = defaultNoteToOptions.filter((option: any) =>
        pharmacies.some((pharmacy) => `${pharmacy?.user?.id}-staff` === option.value)
      );
      setDefaultNoteToOptions(pharmacyOptions);
    }
  }, [isPharmacySelected]);

  const setPharmaciesNoteTo = (options: Option[]) => {
    pharmacies.forEach((element) => {
      const pharmacyAssignedToResident = residentSelectedId == null || residentSelectedId == 'all-residents' || isPharmacyAssignedToResident(element, residentSelectedId);
      if (element?.user?.id && pharmacyAssignedToResident) {
        let item = {
          value: `${element.user.id}-staff`,
          label: `${element.name} - Pharmacy`
        };
        options.push(item);
      }
    });
  };

  const isPharmacyAssignedToResident = (pharmacy: PharmacyI, residentId: string) => {
    return pharmacy.residents.some(resident => resident.id === residentId);
  }

  useEffect(() => {
    const defaultOptions: Option[] = [];
    // Build default options
    if (note) {
      noteToOptions.forEach((option: Option) => {
        let value = option.value.replace('-staff', '').replace('-location', '').replace('-department', '');

        if (note.visibility == 'public') {
          if (note.scope_location_ids.includes(value)) {
            defaultOptions.push({ value: value + '-location', label: option.label });
          } else if (note.scope_location_ids.length <= 0) {
            const allHousesExists = defaultOptions.some((option) => option.value === 'all-houses-location');

            if (!allHousesExists) {
              defaultOptions.push({ value: 'all-houses-location', label: t('notes.allHouses') });
            }
          }
        }

        if (note.visibility == 'private' || note.visibility == 'department') {
          if (note.private_user_ids.includes(value)) {
            defaultOptions.push({ value: value + '-staff', label: option.label });
          }

          if (note.department_ids.includes(value)) {
            defaultOptions.push({ value: value + '-department', label: option.label });
          }
        }
      });
    }

    if (defaultOptions.length > 0 && JSON.stringify(defaultNoteToOptions) !== JSON.stringify(defaultOptions)) {
      setDefaultNoteToOptions(defaultOptions);
    }
  }, [noteToOptions]);

  const setLocations = (options: Option[]) => {
    if (params.l !== 'all-locations') {
      options.push({
        value: params.l + '-location',
        label: locations.find((loc) => loc.id === params.l)?.name || ''
      });
    } else {
      locations.forEach((element) => {
        const item = {
          value: element.id + '-location',
          label: element.name
        };
        options.push(item);
      });
    }
  };

  const setDepartments = (options: Option[]) => {
    departments.forEach((element) => {
      let item = {
        value: element.id + '-department',
        label: element.department
      };
      options.push(item);
    });
  };

  const setStaff = (options: Option[]) => {
    staff.forEach((element) => {
      if (userId != element.id) {
        let item = {
          value: element.id + '-staff',
          label: element.first_name + ' ' + element.last_name
        };
        options.push(item);
      }
    });
  };

  const customClassNames = {
    control: () =>
      cn(
        '!bg-background !border !border-gray-300 dark:!border-gray-700 !rounded-md focus:!outline-none !shadow-none !min-h-10',
        'max-h-20 overflow-y-auto'
      ),
    menu: () => cn('!bg-background !border !border-gray !z-[999999]'),
    option: ({ isSelected, isFocused }: any) =>
      cn(
        'dark:!text-white dark:hover:!bg-primary/60 hover:!bg-primary/60',
        isSelected ? '!bg-primary text-white' : isFocused ? '!bg-primary/10' : ''
      ),
    singleValue: () => cn('text-dark dark:text-white'),
    multiValue: () => cn('!bg-primary !text-white !rounded flex-shrink-0'),
    multiValueLabel: () => cn('!text-white'),
    multiValueRemove: () => cn('hover:!bg-gray-400 hover:!text-white'),
    valueContainer: () => cn('!flex !flex-row !flex-wrap !gap-1 !overflow-x-auto !py-1 max-h-20'),
    input: () => cn('!text-sm'),
    container: () => cn('!min-h-10')
  };

  const onchange = (selectedOptions: any) => {
    const mutableOptions = [...selectedOptions] as Option[];
    setDefaultNoteToOptions(mutableOptions);
    checkIsPharmacySelected(selectedOptions);
    handleSelectNoteTo(selectedOptions);
  };

  const checkIsPharmacySelected = (selectedOptions: Option[]) => {
    const hasPharmacy = selectedOptions.some((item: Option) =>
      pharmacies.some((pharmacy) => `${pharmacy?.user?.id}-staff` === item.value)
    );
    setIsPharmacySelected(hasPharmacy);
  };

  return (
    <Select
      isMulti
      name="colors"
      className="basic-multi-select"
      options={noteToOptions}
      classNamePrefix="select"
      placeholder={t('notes.noteTo')}
      classNames={customClassNames}
      value={defaultNoteToOptions}
      onChange={(selectedOptions) => {
        onchange(selectedOptions);
      }}
    />
  );
}
