import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';
import { useContext } from 'react';

interface IUser {
  id: string;
  first_name: string;
  last_name: string;
}

interface IResident {
  id: string;
  first_name: string;
  last_name: string;
  image_url?: string;
}

export const UserHelper = {
  getStaffName: (userId: string, userList: IUser[]): string | null => {
    const user = userList.find((user: IUser) => user.id === userId) ?? null;
    if (user === null) return null;
    return `${user.first_name} ${user.last_name}`;
  },
  getResidentName: (residentId: string, residentList: IResident[]) => {
    const resident = residentList.find((user: IUser) => user.id === residentId) ?? null;
    if (resident === null) return null;
    return `${resident.first_name} ${resident.last_name}`;
  },
  getResidentInformation: (residentId: string, residentList: IResident[]) => {
    const resident = residentList.find((user: IUser) => user.id === residentId) ?? null;
    if (resident === null) return null;
    return resident;
  }
};

export const RoleHelper = {
  getRoles: (): any[] => {
    const { roles } = useContext(AuthContext);
    return roles || [];
  },
  hasRole: (roleToCheck: string, rolesParam?: any[]): boolean => {
    const roles = rolesParam || RoleHelper.getRoles();
    return roles.some((role) => role.name === roleToCheck);
  },
  hasAnyRole: (rolesToCheck: string[]): boolean => {
    return rolesToCheck.some((role) => RoleHelper.hasRole(role));
  },
  hasAllRoles: (rolesToCheck: string[]): boolean => {
    return rolesToCheck.every((role) => RoleHelper.hasRole(role));
  }
};
