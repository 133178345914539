import React from 'react';
import { Row } from "@tanstack/react-table";
import { Button } from '@/common/presentation/components/ui/button';
import { Pencil, Trash } from 'lucide-react';
import { t } from 'i18next';
import TrackerHygieneReportUpdateDialog from './TrackerHygieneReportUpdateDialog';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEditClick: (id: number, name: string, status: string) => void;
  handleDeleteClick: (id: string) => void;
}

const RenderHygineReportActionsColumns: React.FC<RenderActionsColumnProps> = (rowData, handleButtonOpenDialog) => {

    return (
        <div className='flex'>
            <TrackerHygieneReportUpdateDialog row={rowData}/>

            <Button onClick={() => handleButtonOpenDialog('', t('trackers.trackerhygiene.delete'), rowData, t('trackers.trackerhygiene.deleteMessage'))} variant="outline" size="icon" className='mr-2'>
                <Trash className="h-4 w-4" />
            </Button>
        </div>
    );
};

export default RenderHygineReportActionsColumns;
