import { Button } from "@/common/presentation/components/ui/button";
import { Switch } from "@/common/presentation/components/ui/switch"
import { AppDispatch, RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateVisitQuestion } from "../../slices/VisitQuestionSlice";
import * as configActions from '@/common/presentation/slices/configurationSlice';
import { setConfigurationsLoading } from '@/common/presentation/slices/configurationSlice';
import { CheckCircle } from "lucide-react";

export const VisitQuestion = () => {

    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);

    const { configurations, isLoading } = useSelector((state: RootState) => state.configurations);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const questionVistiStatus = (configurations?.question_visit_status ?? false) == 'active' ? true : false;
        setChecked(questionVistiStatus);
    }, [configurations])


    const handleUpdate = async () => {
        dispatch(setConfigurationsLoading())
        const { payload } = await dispatch(updateVisitQuestion({ status: checked }));

        if (payload?.status == 'ok') {
            dispatch(configActions.setConfigurations({
                ...configurations,
                'question_visit_status': checked == true ? 'active' : 'inactive'
            }));
        }

    }

    return (
        <>
            {!isLoading &&
                <>

                    <div className="pt-2">
                        <h6 className="font-semibold text-[#52525B]">{t('settings.visitQuestion.title')}</h6>

                        <div className="text-sm text-gray-600 mb-6">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        </div>

                        <div className="bg-neutralLight h-[100px] rounded-lg p-4 flex items-center">
                            <div className="flex items-center justify-between bg-white h-[48px] pt-2 pb-2 pl-6 pr-6 rounded-md w-full">
                                <div className="flex items-center gap-4">
                                    <CheckCircle className="text-blue-500 w-5 h-5" />
                                    <span className="font-medium">{t('settings.visitQuestion.title')}</span>
                                </div>
                                <Switch
                                    checked={checked}
                                    onCheckedChange={setChecked}
                                    className="text-primary"
                                />
                            </div>
                        </div>

                        <div className="mt-6 flex justify-end">
                            <Button
                                variant="outline"
                                size="sm"
                                className='bg-primary hover:bg-primary hover:text-white text-white text-center  px-8'
                                onClick={handleUpdate}
                            >
                                <span>{t('settings.save')}</span>
                            </Button>
                        </div>
                    </div>
                </>
            }


        </>


    );
}