import { t } from "i18next";
import RenderHygineTypeActionsColumn from "./RenderHygineTypeActionsColumn";

const useHygineTypeColumns = () => {
  const HygineTypeColumns = [
    {
        key: 'name',
        id: 'name',
        labelTranslationKey: 'trackerhygienesettings.typeName',
        value: 'name',
        visible: true,
        sortable: true,
        width: '90%'
    },
    {
        key: 'actions',
        labelTranslationKey: 'contacts.actions',
        render: RenderHygineTypeActionsColumn,
        visible: true,
        static: true,
        width: '10%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(HygineTypeColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = HygineTypeColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useHygineTypeColumns;
