// contactscategoriescreate.tsx
import { useEffect, useState, useCallback, useMemo } from 'react';
import { getContactsCategoriesPersonal, createContact, getListPhonesById, createPhones, deletePhone, fetchInitialData, fetchGeoLocation } from '../../slices/contactsSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '@/store/store';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/common/presentation/components/ui/accordion';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContactsPhonesCreate from './ContactsPhonesCreate';
import { Building, User, Users, Mail, Phone,  MapPin, Map, Flag, Archive, Globe, Plus } from 'lucide-react';
import Select from 'react-select';
import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Label } from '@/common/presentation/components/ui/label';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';
import { ICountry } from '../../../../wizard/domain/signup.domain';
import CountrySelector from '../../../../wizard/presentation/components/CountrySelector/CountrySelector';
import { CountryCode, E164Number } from 'libphonenumber-js/core';
import { Input } from '@/common/presentation/components/ui/input';

interface Phone {
    category: string;
    number: string;
    ext: string;
}

interface Contact {
    client_id: string;
    company: string;
    name: string;
    last_name: string;
    email: string;
    relationship: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    same_as: string;
    linkto: string;
    phones: Phone[];
}

interface ContactInfo {
    [key: string]: {
        category: string;
        contacts: Contact[];
    };
}

interface ContactsCategoriesCreateProps {
    type: number;
    contactInfo: ContactInfo;
    setContactInfo: (info: ContactInfo) => void;
}

export const ContactsCategoriesCreate = ({ type, contactInfo, setContactInfo }: ContactsCategoriesCreateProps) => {
    const { t } = useTranslation();
    const contacts = useSelector((state: RootState) => state.contacts.contacts);
    const categories = useSelector((state: RootState) => state.contacts.categories);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const residentId = searchParams.get('r');
    const locationId = searchParams.get('l');
    const [checkedCategories, setCheckedCategories] = useState<string[]>([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [currentCategory, setCurrentCategory] = useState<string>('');
    const { contactId } = useParams<{ contactId: string }>();
    //const { countries } = useSelector((state: any) => state.contacts.countries);
    const countries = useSelector((state: RootState) => state.contacts.countries);
    //console.log(residentId);

    useEffect(() => {
        getInitialData();
    }, []);

    useEffect(() => {
        console.log(countries);
    }, [countries]);

    useEffect(() => {
        dispatch(getContactsCategoriesPersonal());
    }, [dispatch]);

    useEffect(() => {
        //console.log("id:"+contactId);
        if (contactId) {
            dispatch(getListPhonesById(contactId));
        }
    }, [dispatch, contactId]);

    useEffect(() => {
        if (categories.length > 0) {
            categories.forEach(category => {
                if (!contactInfo[category.id]) {
                    setContactInfo(prevState => ({
                        ...prevState,
                        [category.id]: {
                            category: category.id,
                            contacts: [{
                                company: '',
                                name: '',
                                last_name: '',
                                relationship: '',
                                email: '',
                                address: '',
                                city: '',
                                state: '',
                                zipcode: '',
                                country: '',
                                same_as: '',
                                linkto: residentId,
                                phones: [],
                            }],
                        },
                    }));
                }
            });
        }
    }, [categories, contactInfo, residentId, setContactInfo]);

    const handleInputChange = (category: string, index: number, field: string, value: string) => {
        setContactInfo((prevState: ContactInfo) => {
            const updatedContacts = prevState[category]?.contacts || [];
            updatedContacts[index] = {
                ...updatedContacts[index],
                [field]: value,
                category: category,
                linkto: residentId,
            };

            return {
                ...prevState,
                [category]: {
                    category: category,
                    contacts: updatedContacts,
                },
            };
        });
    };

    const handleCheckboxChange = (category: string) => {
        setCheckedCategories((prevState) =>
            prevState.includes(category)
                ? prevState.filter((cat) => cat !== category)
                : [...prevState, category]
        );
    };

    const addContact = (category: string) => {
        setContactInfo((prevState: ContactInfo) => {
            const updatedContacts = prevState[category]?.contacts || [];
            updatedContacts.push({
                company: '',
                name: '',
                last_name: '',
                relationship: '',
                email: '',
                address: '',
                city: '',
                state: '',
                zipcode: '',
                country: '',  
                linkto: residentId,
                phones: [],
            });
    
            return {
                ...prevState,
                [category]: {
                    category: category,
                    contacts: updatedContacts,
                },
            };
        });
    };

    useEffect(() => {
        const responsiblePersonData = contactInfo['983d5704-a6cc-415a-97a7-7da5808e46a2']?.contacts[0];
        if (responsiblePersonData) {
            checkedCategories.forEach((category) => {
                setContactInfo((prevState) => {
                    const updatedContacts = prevState[category]?.contacts || [];
    
                    // Verificar si el contacto ya existe en la categoría usando una comparación más robusta
                    const contactExists = updatedContacts.some(
                        (contact) => contact.linkto === residentId && contact.category === category
                    );
    
                    if (!contactExists) {
                        updatedContacts.push({
                            ...responsiblePersonData,
                            category: category,
                            linkto: residentId,
                        });
                    }
    
                    return {
                        ...prevState,
                        [category]: {
                            ...prevState[category],
                            contacts: updatedContacts,
                        },
                    };
                });
            });
        }
    }, [checkedCategories, contactInfo, residentId]);
    

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        
        const promises = [];
    
        Object.keys(contactInfo).forEach(categoryId => {
            contactInfo[categoryId]?.contacts.forEach(contactData => {
                const isValidContact = contactData.name || contactData.email || contactData.company || contactData.address || contactData.phones.length > 0;
    
                if (isValidContact) {
                    const createContactPromise = dispatch(createContact(contactData))
                        .unwrap()
                        .then((newContact) => {
                            const phonePromises = contactData.phones.map(phone => 
                                dispatch(createPhones({ ...phone, contact_id: newContact.id }))
                                    .catch((error) => {
                                        //console.error('Failed to create phone:', error);
                                    })
                            );
                            return Promise.all(phonePromises);
                        })
                        .catch((error) => {
                            //console.error('Failed to create contact:', error);
                        });
    
                    promises.push(createContactPromise);
                }
            });
        });
    
        Promise.all(promises)
            .then(() => {
                if (!locationId) {
                    // Si locationId está vacío o es nulo, redirige solo a /contacts
                    navigate('/contacts');
                } else {
                    // De lo contrario, redirige con los parámetros de locationId y residentId
                    navigate(`/contacts/?l=${locationId}&r=${residentId}`);
                }
            })
            .catch((error) => {
                //console.error('Failed to complete all operations:', error);
            });
    };

    const handleContactSelect = async (selectedOption) => {
        setSelectedContact(selectedOption);
        if (selectedOption && currentCategory) {
            const contact = contacts.find((c) => c.id === selectedOption.value);
            if (contact) {
                dispatch(getListPhonesById(selectedOption.value)).then((action) => {
                    const phones = action.payload; // Obtener los teléfonos del action payload
                    setContactInfo((prevState) => ({
                        ...prevState,
                        [currentCategory]: {
                            ...prevState[currentCategory],
                            contacts: prevState[currentCategory].contacts.map(contactInfo =>
                                contactInfo.linkto === residentId ? {
                                    ...contactInfo,
                                    category: currentCategory,
                                    company: contact.company,
                                    name: contact.name,
                                    last_name: contact.last_name,
                                    email: contact.email,
                                    relationship: contact.relationship,
                                    address: contact.address,
                                    zipcode: contact.zipcode,
                                    country: contact.country,
                                    state: contact.state,
                                    city: contact.city,
                                    phones: phones || [], // Actualiza los teléfonos aquí
                                } : contactInfo
                            ),
                        }
                    }));
                });
            }
        }
    };    

    const handlePhoneInputChange = (category: string, contactIndex: number, phoneIndex: number, field: string, value: string) => {
        setContactInfo((prevState: ContactInfo) => {
            const updatedContacts = prevState[category]?.contacts || [];
            const updatedPhones = updatedContacts[contactIndex]?.phones || [];
            updatedPhones[phoneIndex] = {
                ...updatedPhones[phoneIndex],
                [field]: value,
            };
            updatedContacts[contactIndex] = {
                ...updatedContacts[contactIndex],
                phones: updatedPhones,
            };
    
            return {
                ...prevState,
                [category]: {
                    category: category,
                    contacts: updatedContacts,
                },
            };
        });
    };
    
    const addPhone = (category: string, contactIndex: number) => {
        setContactInfo((prevState: ContactInfo) => {
            const updatedContacts = prevState[category]?.contacts || [];
            const updatedPhones = updatedContacts[contactIndex]?.phones || [];
            updatedPhones.push({
                category: '',
                number: '',
                ext: '',
            });
            updatedContacts[contactIndex] = {
                ...updatedContacts[contactIndex],
                phones: updatedPhones,
            };
    
            return {
                ...prevState,
                [category]: {
                    category: category,
                    contacts: updatedContacts,
                },
            };
        });
    };

    const removePhone = (category: string, contactIndex: number, phoneIndex: number, phoneId: string) => {
        //console.log("id de phone " + phoneId);
        dispatch(deletePhone(phoneId));
        setContactInfo((prevState: ContactInfo) => {
            const updatedContacts = prevState[category]?.contacts || [];
            const updatedPhones = updatedContacts[contactIndex]?.phones || [];
            updatedPhones.splice(phoneIndex, 1);
            updatedContacts[contactIndex] = {
                ...updatedContacts[contactIndex],
                phones: updatedPhones,
            };
    
            return {
                ...prevState,
                [category]: {
                    ...prevState[category],
                    contacts: updatedContacts,
                },
            };
        });
    };    

    const filteredCategories = categories.filter(category => category.type === type);

    const contactOptions = contacts.map((contact: any) => ({
        value: contact.id,
        label: `${contact.name} ${contact.last_name} - ${contact.company}`,
    }));

    const getInitialData = useCallback(async () => {
        const country = await getClientLocation();
        const response = await dispatch<any>(fetchInitialData(country));
    
        if (response.error) {
          return;
        }
    
        const { client_country, countries }: { client_country: string; countries: ICountry[] } = response.payload;
        const countryInfo = countries.find((country: ICountry) => country.code === client_country);
    
    }, []);

    const getClientLocation = async () => {
        try {
          let country = 'us';
          const response = await dispatch<any>(fetchGeoLocation());
    
          if (!response.error) {
            const { payload } = response;
            country = payload.country.toLocaleLowerCase();
          }
    
          return country;
        } catch (error) {
          return 'us';
        }
    };

    const countriesCode: CountryCode[] = useMemo(() => {
        return countries.map((country: ICountry) => country.code.toUpperCase());
    }, [countries]);
    
    const handleCountryChange = (value: string) => {
        setContactInfo(prevState => {
            const updatedContactInfo = { ...prevState };
            Object.keys(updatedContactInfo).forEach(categoryId => {
                updatedContactInfo[categoryId].contacts.forEach(contactData => {
                    contactData.country = value.toUpperCase();
                });
            });
            return updatedContactInfo;
        });
    };
    
    return (
        <form onSubmit={handleSubmit} className="space-y-6">
        <Accordion type="single" collapsible className="w-full">
            {filteredCategories.map((category) => (
                <AccordionItem key={category.id} value={category.id} className="border-t-4 border-t-primary/80">
                    <AccordionTrigger className="flex flex-row items-center text-lg font-bold" onClick={() => setCurrentCategory(category.id)}>{category.name}</AccordionTrigger>
                    <AccordionContent>
                        {contactInfo[category.id]?.contacts.map((contact, contactIndex) => (
                            <>
                            <div className="mb-4">
                                <Label className="block text-sm font-bold mb-2" htmlFor="contact-search">
                                    {t('contacts.search_contact')}
                                </Label>
                                <Select
                                    id="contact-search"
                                    options={contactOptions}
                                    value={selectedContact}
                                    onChange={(selectedOption) => handleContactSelect(selectedOption)}
                                    placeholder={t('contacts.search_select_contact') + '...'}
                                />
                            </div>
                            {/* Si el nombre de la categoría es "Name of Hospital to be Taken in an Emergency", solo muestra ciertos campos */}
                            {category.name === "Name of Hospital to be Taken in an Emergency" ? (
                                <>
                                <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
                                    {/* Primera fila: Name */}
                                    {[{ label: t('contacts.name'), name: 'name', icon: <User className="absolute left-3 text-gray-400" /> }].map((field) => (
                                        <div key={field.name} className="relative mb-4">
                                            <Label className="block text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                {field.label}
                                            </Label>
                                            <div className="flex items-center">
                                                {field.icon}
                                                <input
                                                    type="text"
                                                    name={field.name}
                                                    id={`${field.name}-${category.id}-${contactIndex}`}
                                                    value={contact[field.name]}
                                                    onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                    className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                                    {/* Tercera fila: Address, City, State, Zip Code */}
                                    {[
                                        { label: t('contacts.address'), name: 'address', icon: <MapPin className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.city'), name: 'city', icon: <Map className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.state'), name: 'state', icon: <Flag className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.zip_code'), name: 'zipcode', icon: <Archive className="absolute left-3 text-gray-400" /> }
                                    ].map((field) => (
                                        <div key={field.name} className="relative mb-4">
                                            <Label className="block text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                {field.label}
                                            </Label>
                                            <div className="flex items-center">
                                                {field.icon}
                                                <input
                                                    type="text"
                                                    name={field.name}
                                                    id={`${field.name}-${category.id}-${contactIndex}`}
                                                    value={contact[field.name]}
                                                    onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                    className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                </>
                            ) : (
                                <>
                                {/* Primera fila: Company, Name, Last Name */}
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                    {[
                                        { label: t('contacts.company'), name: 'company', icon: <Building className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.name'), name: 'name', icon: <User className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.last_name'), name: 'last_name', icon: <User className="absolute left-3 text-gray-400" /> }
                                    ].map((field) => (
                                        <div key={field.name} className="relative mb-4">
                                            <Label className="block text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                {field.label}
                                            </Label>
                                            <div className="flex items-center">
                                                {field.icon}
                                                <input
                                                    type="text"
                                                    name={field.name}
                                                    id={`${field.name}-${category.id}-${contactIndex}`}
                                                    value={contact[field.name]}
                                                    onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                    className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                                {/* Segunda fila: Email, Relationship/Specialty, Country */}
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                    {[
                                        { label: t('contacts.email'), name: 'email', icon: <Mail className="absolute left-3 text-gray-400" /> },
                                        { label: category.type === 2 ? t('contacts.specialty') : t('contacts.relationship'), name: 'relationship', icon: <Users className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.country'), name: 'country', icon: <Globe className="absolute left-3 text-gray-400" /> }
                                    ].map((field) => (
                                        <div key={field.name} className="relative mb-4">
                                            <Label className="block text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                {field.label}
                                            </Label>
                                            <div className="">
                                                {field.name === 'country' ? (
                                                    <CountrySelector
                                                    defaultCountry={contact.country?.code?.toUpperCase() || ''}
                                                    country={contact.country?.code?.toUpperCase() || ''}
                                                    name="country"
                                                    onChange={(e) => handleCountryChange(e.value.toLowerCase())}
                                                    countries={countriesCode}
                                                    />
                                                ) : (
                                                    <>
                                                    <div className="flex items-center">
                                                        {field.icon}
                                                        <input
                                                            type="text"
                                                            name={field.name}
                                                            id={`${field.name}-${category.id}-${contactIndex}`}
                                                            value={contact[field.name]}
                                                            onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                            className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        />
                                                    </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Tercera fila: Address, City, State, Zip Code */}
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                                    {[
                                        { label: t('contacts.address'), name: 'address', icon: <MapPin className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.city'), name: 'city', icon: <Map className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.state'), name: 'state', icon: <Flag className="absolute left-3 text-gray-400" /> },
                                        { label: t('contacts.zip_code'), name: 'zipcode', icon: <Archive className="absolute left-3 text-gray-400" /> }
                                    ].map((field) => (
                                        <div key={field.name} className="relative mb-4">
                                            <Label className="block text-sm font-bold mb-2" htmlFor={`${field.name}-${category.id}-${contactIndex}`}>
                                                {field.label}
                                            </Label>
                                            <div className="flex items-center">
                                                {field.icon}
                                                <input
                                                    type="text"
                                                    name={field.name}
                                                    id={`${field.name}-${category.id}-${contactIndex}`}
                                                    value={contact[field.name]}
                                                    onChange={(e) => handleInputChange(category.id, contactIndex, field.name, e.target.value)}
                                                    className="pl-10 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                </>
                            )}
                            
                            {/* Apartado para los teléfonos */}
                            <div className="w-full px-2 mb-4">
                                <ContactsPhonesCreate
                                    category={category.id}
                                    contactIndex={contactIndex}
                                    phones={contact.phones}
                                    handlePhoneInputChange={handlePhoneInputChange}
                                    addPhone={addPhone}
                                    removePhone={removePhone}
                                />
                            </div>
                        </>
                        ))}
                        {category.id === '983d5704-a6cc-415a-97a7-7da5808e46a2' && (
                            <div className="mt-4">
                                <h3 className="text-lg font-semibold mb-2">{t('contacts.select_related')}</h3>
                                <div className="flex flex-wrap">
                                    {categories.map((cat) => (
                                        cat.id !== category.id && (
                                            <div key={cat.id} className="mr-4 mb-2 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${cat.id}`}
                                                    name={cat.name}
                                                    checked={checkedCategories.includes(cat.id)}
                                                    onChange={() => handleCheckboxChange(cat.id)}
                                                    className="mr-2"
                                                />
                                                <label htmlFor={`checkbox-${cat.id}`}>{cat.name}</label>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="w-full flex justify-end px-2 mb-4">
                            <Button
                                type="button"
                                onClick={() => addContact(category.id)}
                                className="flex items-center px-3 py-2 text-white rounded"
                            >
                                <Plus className="mr-2" />
                                {t('contacts.add_contact')}
                            </Button>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            ))}
        </Accordion>
        <div className="flex justify-end mt-6">
            <Button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                {t('contacts.create_contact')}
            </Button>
        </div>
        {/* Botón flotante */}
        <Button size="sm"
            type="submit" 
            className="fixed right-5 top-1/2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg focus:outline-none focus:shadow-outline"
        >
            {t('contacts.save')}
        </Button>
    </form>
    );
};

ContactsCategoriesCreate.propTypes = {
    type: PropTypes.number.isRequired,
    contactInfo: PropTypes.object.isRequired,
    setContactInfo: PropTypes.func.isRequired,
};