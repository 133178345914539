import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { LogOut } from 'lucide-react';
import { useContext } from 'react';
import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';

const LogOutButton = () => {
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            onClick={handleLogout}
            className="h-8 w-8 text-white hover:bg-primary/50 hover:text-white"
          >
            <LogOut className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-background text-black dark:text-white shadow-md">
          <p>Logout</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default LogOutButton;
