import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onSelectDate: (date: Date, formattedDate: string) => void;
  defaultDate?: Date;
}

export const DateSelector = ({ onSelectDate, defaultDate }: Props) => {
  const [date, setDate] = useState<Date | undefined>(defaultDate || new Date());
  const { t } = useTranslation();
  const displayFormat = t('dateFormats.dateFormat');

  const onDateChange = (selectedDate: Date) => {
    setDate(selectedDate);
    const internalFormat = format(selectedDate, 'yyyy-MM-dd');
    onSelectDate(selectedDate, internalFormat);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn('w-[280px] justify-start text-left font-normal', !date && 'text-muted-foreground')}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, displayFormat) : <span>{t('common.pickDate', 'Pick a date')}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col w-full gap-2 p-0">
        <CustomCalendar onChange={onDateChange} selectedDate={date} MonthAndYearPicker />
      </PopoverContent>
    </Popover>
  );
};
