import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { CrudState, FormData as FormDataModel, NoteI } from '@/modules/notes/domain/note.domain';
import { ImgageGrid } from '../common/imageGrid';
import { InputsMedicalAppoinments } from './elements/inputsMedicalAppoinments';
import { Loader, Save } from 'lucide-react';
import { QuillJS } from '../common/quilljs';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Select as ShadSelect, SelectContent, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { SelectNoteCategory } from '../common/SelectNoteCategory';
import { SelectNoteTo } from '../notes/elements/SelectNoteTo';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { UrgentNoteCheckbox } from './elements/urgentNoteCeheckbox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import NoteService from '../../../infrastructure/services/NoteService';
import useRouteParams from '@/common/hooks/RouteParamsHook';

interface CreateNoteProps {
  crudState: CrudState;
  setCrudState: (crudState: CrudState) => void;
  note: NoteI | null;
}

export function UpdateNote({ crudState, setCrudState, note }: CreateNoteProps) {
  // Init values
  const { t } = useTranslation();
  const defaultFormData: FormDataModel = {
    content: note?.content || '',
    html_content: note?.html_content || '',
    images: note?.images || null,
    user_id: note?.private_user_ids || [],
    location_id: note?.scope_location_ids || [],
    department_id: note?.department_ids || [],
    category_id: note?.category_id || '',
    resident_id: note?.resident_id || null,
    urgent: note?.urgent || false,
    specialty: note?.specialty_id || null,
    doctor: note?.doctor_id || null,
    medical_appoinment_date: note?.medical_appoinment_date || null,
  };

  // Tools
  const { params } = useRouteParams();

  const [isPharmacySelected, setIsPharmacySelected] = useState<boolean>(false);

  // Hooks
  const [formData, setFormData] = useState<FormDataModel>(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [isMedicalAppoinment, setIsMedicalAppoinment] = useState<boolean>(note?.doctor_id && note?.specialty_id ? true : false);

  // Behaviors
  const getContent = () => {
    if (note) {
      return note.html_content;
    }

    return '';
  };

  const onResidentSelect = (resident: any) => {
    setFormData({
      ...formData,
      ['resident_id']: resident
    });
  };

  const handleSelectNoteTo = (e: any) => {
    const recipients: string[] = [];

    for (const key in e) {
      if (e.hasOwnProperty(key)) {
        recipients.push(e[key].value);
      }
    }

    const staffItems = recipients.filter((item) => item.endsWith('-staff')).map((item) => item.replace('-staff', ''));

    const departmentsItems = recipients
      .filter((item) => item.endsWith('-department'))
      .map((item) => item.replace('-department', ''));

    const locationItems = recipients
      .filter((item) => item.endsWith('-location') && item !== 'all-houses-location')
      .map((item) => item.replace('-location', ''));

    setFormData({
      ...formData,
      ['user_id']: staffItems,
      ['location_id']: locationItems,
      ['department_id']: departmentsItems
    });
  };

  const handleSelectCategory = (value: any) => {
    setFormData({
      ...formData,
      ['category_id']: value
    });
  };

  const handleSelectQuicknote = (value: any) => { };

  const handleQuillJs = (values: any) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...values
    }));
  };

  const handleUrgentNoteCheckbox = (value: boolean) => {
    setFormData({
      ...formData,
      ['urgent']: value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    let data = {
      ...formData,
      id: crudState.itemId
    };


    let images = formData.images;
    formData.images = [];

    await NoteService.updateNote(data);

    if (images?.length) {
      let form = new FormData();
      form.append('id', crudState.itemId);
      form.append('type', 'note');
      images.forEach(img => form.append('images[]', img))
      await NoteService.uploadImges(form);
    }

    setIsLoading(false);

    setCrudState(NoteHelperService.defineState({}));
  };

  const closeForm = () => {
    setCrudState(NoteHelperService.defineState({}));
  }

  const removeImage = (value: { id: string, image: string }) => {
    let copyDeleImages = [...formData.deleteImages || []]
    let findImage = copyDeleImages.findIndex(image => image == value.id);
    if (findImage >= 0) {
      copyDeleImages.splice(findImage, 1);
      setFormData({
        ...formData,
        deleteImages: copyDeleImages
      });
    } else {
      setFormData({
        ...formData,
        deleteImages: (formData.deleteImages || [])?.concat([value.id])
      });
    }
  }

  return (
    <Card className="col-span-12 md:col-span-7 border-t-4 border-t-primary/80">
      <CardContent>
        <ResidentSelector className="mt-3" locationId={params?.l} onSelect={onResidentSelect} setUrlParams={false} showInCard={false} closeButton={closeForm} />
        <ScrollArea className='h-[calc(100vh-450px)]'>
          <form onSubmit={handleSubmit}>
            <div className='grid grid-cols-12 gap-4 py-4'>
              <div className='col-span-12 md:col-span-4'>
                <SelectNoteTo
                  note={note}
                  handleSelectNoteTo={handleSelectNoteTo}
                  setIsPharmacySelected={setIsPharmacySelected}
                  isPharmacySelected={isPharmacySelected}
                  residentSelectedId={formData.resident_id}
                />
              </div>
              <div className='col-span-12 md:col-span-4'>
                <SelectNoteCategory
                  decodeHTMLEntities={NoteHelperService.decodeHTMLEntities}
                  handleSelectCategory={handleSelectCategory}
                  value={formData.category_id}
                  isPharmacySelected={isPharmacySelected}
                />
              </div>
              <div className='col-span-12 md:col-span-4'>
                <ShadSelect name="quickNote" onValueChange={handleSelectQuicknote}>
                  <SelectTrigger name="quickNote">
                    <SelectValue placeholder={t('notes.quickNote')} />
                  </SelectTrigger>
                  <SelectContent></SelectContent>
                </ShadSelect>
              </div>

              {isMedicalAppoinment && <InputsMedicalAppoinments setFormData={setFormData} formData={formData} />}

            </div>
            <Separator className='bg-primary mb-4' />
            <div className="w-full">
              <QuillJS onChange={handleQuillJs} value={getContent()} />
            </div>
            {note?.images && note.images.length > 0 && <ImgageGrid images={note.images} removeImage={removeImage} imagesDelete={formData.deleteImages} />}
            <div className="pt-4 flex justify-between">
              <UrgentNoteCheckbox
                checked={note ? Boolean(note.urgent) : false}
                onCheck={handleUrgentNoteCheckbox}
              />
              <Button type="submit" disabled={isLoading} size="sm" className="ml-auto">
                {isLoading ? (
                  <Loader className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    {t('notes.update')}
                  </>
                )}
              </Button>
            </div>
          </form>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
