import { useState } from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';
import { useTranslation } from 'react-i18next';

interface FormErrorsI {
  name?: string;
  description?: string;
}

interface DeviceFormProps {
  deviceInfoForm: {
    name: string;
    description: string;
    status: string;
  };
  inputHandler: (name: string, value: string) => void;
  updating: boolean;
  selectedLocations: any[];
  selectedUsers: any[];
  handleLocationsChange: (selectedLocations: any) => void;
  handleUsersChange: (selectedUsers: any) => void;
  locations: any[];
  users: any[];
}

const DeviceForm = ({
  deviceInfoForm,
  inputHandler,
  updating,
  selectedLocations,
  selectedUsers,
  handleLocationsChange,
  handleUsersChange,
  locations,
  users
}: DeviceFormProps) => {
  const [errors, setErrors] = useState<FormErrorsI>({});
  const { t } = useTranslation();
  const [isAllUsersSelected, setIsAllUsersSelected] = useState(false);

  const validateField = (name: string, value: string) => {
    const newErrors: FormErrorsI = { ...errors };

    switch (name) {
      case 'name':
        if (value.length < 3) {
          newErrors.name = t('security.deviceNameLength');
        } else if (value.length > 50) {
          newErrors.name = t('security.deviceNameLength');
        } else {
          delete newErrors.name;
        }
        break;
      case 'description':
        if (value.length < 10) {
          newErrors.description = t('security.descriptionLength');
        } else if (value.length > 200) {
          newErrors.description = t('security.descriptionLength');
        } else {
          delete newErrors.description;
        }
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (name: string, value: string) => {
    validateField(name, value);
    inputHandler(name, value);
  };

  // Add "All Users" option to the users array
  const allUsersOption = {
    label: t('security.allUserSelectedLocations'),
    value: 'all-users',
    imageUrl: undefined
  };

  const enhancedUsers = [allUsersOption, ...users];

  const handleEnhancedUsersChange = (selectedOptions: any) => {
    const hasAllUsers = selectedOptions.some((option: any) => option.value === 'all-users');
    
    if (hasAllUsers && !isAllUsersSelected) {
      // If "All Users" is selected, only keep that option
      setIsAllUsersSelected(true);
      handleUsersChange([allUsersOption]);
    } else if (hasAllUsers && selectedOptions.length > 1) {
      // If "All Users" is already selected and user tries to select other options,
      // remove "All Users" and keep the new selection
      setIsAllUsersSelected(false);
      handleUsersChange(selectedOptions.filter((option: any) => option.value !== 'all-users'));
    } else if (!hasAllUsers) {
      // Normal selection without "All Users"
      setIsAllUsersSelected(false);
      handleUsersChange(selectedOptions);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Label htmlFor="device-name">{ t('security.deviceName') }</Label>
          <Input
            id="device-name"
            className={`input border ${
              errors.name ? 'border-red-500' : 'border-gray-300'
            } dark:border-gray-700 rounded-md w-full mt-1 p-2`}
            value={deviceInfoForm.name}
            placeholder={ t('security.enterDeviceName') }
            onChange={(e) => handleInputChange('name', e.target.value)}
            disabled={updating}
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name}</p>
          )}
        </div>

        <div>
          <Label htmlFor="device-description">{ t('security.deviceDescription') }</Label>
          <Input
            id="device-description"
            className={`input border ${
              errors.description ? 'border-red-500' : 'border-gray-300'
            } dark:border-gray-700 rounded-md w-full mt-1 p-2`}
            value={deviceInfoForm.description ?? ''}
            placeholder={ t('security.enterDeviceDescription') }
            onChange={(e) => handleInputChange('description', e.target.value)}
            disabled={updating}
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Label htmlFor="device-locations">{ t('security.selectLocations') }</Label>
          <MultiSelect
            name="locations"
            options={locations}
            selectedOptions={selectedLocations}
            onChange={handleLocationsChange}
            placeHolder={ t('security.selectLocations') }
            className="w-full mt-1"
            isDisabled={updating}
          />
        </div>
        <div>
          <Label htmlFor="device-users">{ t('security.selectUsers') }</Label>
          <MultiSelect
            name="users"
            options={enhancedUsers}
            isDisabled={selectedLocations.length === 0 || updating}
            selectedOptions={selectedUsers}
            onChange={handleEnhancedUsersChange}
            placeHolder={ t('security.selectUsers') }
            className="w-full mt-1"
          />
        </div>
      </div>
    </>
  );
};

export default DeviceForm;