//renderActionsColumns.tsx
import React from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import { Pencil, Eye, Trash } from 'lucide-react';
import { Row } from "@tanstack/react-table";

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEyeClick: (id: number) => void;
  handleEditClick: (id: number, linkto: string) => void;
  handleDeleteClick: (id: string) => void;
}

const RenderActionsColumn: React.FC<RenderActionsColumnProps> = ({ rowData, handleEyeClick, handleEditClick, handleDeleteClick }) => {
  return (
    <>
      <div className="flex gap-2 justify-end">
        <Button
        size="icon"
        variant="outline"
        className="gap-2"
        onClick={() => handleEyeClick(rowData)}
        >
        <Eye className="h-4 w-4" />
        </Button>
        <Button
            size="icon"
            variant="outline"
            className="gap-2"
            onClick={() => handleEditClick(rowData.id, rowData.linkto)}
        >
            <Pencil className="h-4 w-4" />
        </Button>
        <Button
            size="icon"
            variant="outline"
            className="gap-2"
            onClick={() => handleDeleteClick(rowData.id)}
        >
            <Trash className="h-4 w-4" />
        </Button>
      </div>
    </>
  );
};

export default RenderActionsColumn;
