import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CalendarService from '../../infrastructure/services/CalendarService';

import { 
  IDeleteRecurringEvent, 
  FetchParamsData, 
  ICalendarResumeData, 
  Calendar
} from '../../domain/calendar.domain';

 import FormValues  from "../components/DialogTaskEvent/DialogTaskEvent.tsx";


const initialState: Calendar = {
  tasksEvents: [{
    id: '',
    title: '',
    start:'',
    end: '',
    allDay: false,
    eventId: '',
    options: '',
    color: '',
    categoryName: '',
    residentName: '',
    detail: '',
    category: [{
      id: '',
      name: '',
      color: ''
    }],
    byUser: ''
  }],
  taskEventInfo: {
      id: '',
      title: '',
      detail: '',
      start_date_time: '',
      end_date_time: '',
      all_day: false,
      type: '',
      active: 0,
      options: '',
      date_range: [],
      resident_id: '',
      user_id: '',
      tasks_events_categories_id: '',
      created_at: '',
      updated_at: '',
      resident: {
          id: '',
          first_name: '',
          middle_name: '',
          last_name: ''
      },
      tasks_events_custom: null,
      category: {
          id: '',
          name: '',
          color: ''
      },
      user: {
          id: '',
          first_name: '',
          last_name: ''
      }
  },
  confirmations: {
    deleting: {}
  },
  taskEventsList: [],
  status: 'idle',
  error: null,
  categories: [{
    id: '',
    name: '',
    color: ''
  }],
  staff: [],
  usersToNotify: [],
  updated: false,
};


export const saveTaskEvents = createAsyncThunk('calendar/saveInformation',
 
 async (formData: any) => {
    const response = await CalendarService.saveInformation(formData);
    return response;
  }
);

export const getCategories = createAsyncThunk('calendar/getCategories',
  async () => {
    const response = await CalendarService.getCategories();
    return response;
  }
);

export const getDataCalendar = createAsyncThunk('calendar/getDataCalendar',
 async ({ formData, signal }: { formData: FetchParamsData; signal?: AbortSignal }) => {
    const response = await CalendarService.getDataCalendar(formData, signal);
    return response;
  }
);
export const getUsersToNotify = createAsyncThunk('calendar/getUsersToNotify',
  async (formData: any) => {
   
     const response = await CalendarService.getUsersToNotify(formData);
     return response;
   }
 );
export const updateTaskEvent = createAsyncThunk('calendar/updateTaskEvent',
  async (formData: any)=>{

    const response = await CalendarService.updateTaskEvent(formData);
    return response;
    
  }
)
export const deleteRecurringEvent = createAsyncThunk('calendar/deleteRecurringEvent',
  async (dataDeleteRecurringEvent: IDeleteRecurringEvent) =>{
    const response = await CalendarService.deleteRecurringEvent(dataDeleteRecurringEvent);
    
    return response;
  }
);

export const getSpecificTaskEvent = createAsyncThunk('calendar/getSpecificTaskEvent',
  async (params: object) =>{
    const response = await CalendarService.getSpecificTaskEvent(params);
    return response;
  }
);

export const generateReport = createAsyncThunk('calendar/generateReport', async (reportData: ICalendarResumeData) => {
    const response = await CalendarService.generateReport(reportData); 
    return response; 
});

export const getResume = createAsyncThunk('calendar/getResume', async (filterDara: ICalendarResumeData) => {
  const response = await CalendarService.getResume(filterDara);
  return response;
});

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setUpdated: (state, action) => {
      state.updated = action.payload;
    }
  },
  extraReducers: (builder) => {

    
    builder.
    addCase(getResume.fulfilled, (state, action) => {
      state.taskEventsList = action.payload.data.data;
    })
    /* Save tasksEvents */
    builder.
      addCase(saveTaskEvents.fulfilled, (state, action) => {
        state.status = "idle";
        state.tasksEvents = action.payload;
      })
      .addCase(saveTaskEvents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(deleteRecurringEvent.fulfilled, (state, action) => {
        state.updated = true;
        state.updated = false;
      });

    /*Categories*/
    builder.
      addCase(getCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.categories = action.payload;
      })
      .addCase(getCategories.pending, (state) => {
        state.status = "loading";
      });

    /*Data calendar*/
    builder.
      addCase(getDataCalendar.fulfilled, (state, action) =>{
        state.status = "idle";
        state.tasksEvents = action.payload;
      })
      .addCase(getDataCalendar.pending, (state) => {
        state.status = "loading";
      });

    //Data Specific event
    builder.
      addCase(getSpecificTaskEvent.fulfilled, (state, action) =>{
        state.status = "idle";
        state.taskEventInfo = action.payload;
      })
      .addCase(getSpecificTaskEvent.pending, (state) => {
        state.status = "loading";
      });

      /*Users to notify*/
        builder.
        addCase(getUsersToNotify.fulfilled, (state, action) => {
          state.status = "idle";
          state.usersToNotify = action.payload;
        })
        .addCase(getUsersToNotify.pending, (state) => {
          state.status = "loading";
        });
  }
});

export const { setUpdated } = calendarSlice.actions;
export default calendarSlice.reducer;