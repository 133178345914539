import { RootState } from '@/store/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ActiveStatus } from '../ActiveStatus';
import { RestricByDevice } from '../RestricByDevice';
import { RestricByLocation } from '../RestricByLocation';

export const StaffActions = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { staffMember } = useSelector((state: RootState) => state.staff.staffMember);

  const isPersonalInformation = useMemo(() => {
    return user?.id === staffMember?.id;
  }, [user, staffMember]);

  return (
    <>
      <div className="px-2 grid grid-cols-2 gap-2">
        {!isPersonalInformation && <ActiveStatus />}
        <RestricByDevice />
        <RestricByLocation />
      </div>
    </>
  );
};
