import useRouteParams from "@/common/hooks/RouteParamsHook";
import { useToast } from "@/common/presentation/components/ui/use-toast";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
import { RootState } from "@/store/store";
import { useDateHelpers } from "@/utils/helpers/dates.helper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getHygiene, saveHygiene } from "../../slices/TrackerHygieneSlice";
import { Label } from "@/common/presentation/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/common/presentation/components/ui/popover";
import { Button } from "@/common/presentation/components/ui/button";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "lucide-react";
import { CustomCalendar } from "@/common/presentation/components/CustomCalendar/CustomCalendar";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/common/presentation/components/ui/select";
import { Input } from "@/common/presentation/components/ui/input";
import { getTrackerFoodType, saveTrackerFood } from "../../slices/TrackerFoodSlice";
import { id } from "date-fns/locale";

const TrackerFoodDetailed = () => {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { toast } = useToast();
    const trackerFood = useSelector((state: RootState) => state.trackerFood);
    const { t } = useTranslation();
    const [types, setTypes] = useState([]);
    const [value, setValue] = useState<string[]>([]);
    const [foodValue, setFoodValue] = useState<string[]>([]);
    const { locationSelectedId } = useLocation();
    const { formatDate } = useDateHelpers();
    const [values, setValues] = useState(
        {
            date: new Date(),
            type: "",
            detail: "",
        }
    );

    useEffect(() => {
        dispatch(getTrackerFoodType(
            {
                location_id: locationSelectedId
            }
        ));
        
        setValue(
            [
                "Food",
                "Fluid"
            ]
        );

        setFoodValue(
            [
                "Refused",
                "0%",
                "10%",
                "20%",
                "30%",
                "40%",
                "50%",
                "60%",
                "70%",
                "80%",
                "90%",
                "100%",
            ]
        );
    }, []);
    
    useEffect(() => {
        if(trackerFood.foodTypes.column_field && trackerFood.foodTypes.column_field.length) {
            setTypes(
                trackerFood.foodTypes.column_field.filter(
                    (value) => value.status === "active"
                )
            );
        }
    }, [trackerFood]);
        
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    };

    const handleValueChange = (value: any) => {
        setValues({
            ...values,
            type_val: value
        });
    };

    const handleTypesChange = (value: any) => {        
        setValues({
             ...values,
             type: value
         });
    };

    const handleDateChange = (value: any) => {
        setValues({
             ...values,
             date: value
         });
     };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(params.r != "all-residents") {
            if(values.type) {
                dispatch(saveTrackerFood([
                    {
                        date: values.date.toISOString(),
                        type: values.type,
                        value: "",
                        resident_id: params.r,
                        detail: values.detail,
                        type_val: types.filter(
                            (value) => value.id === values.type
                        )[0]
                            .food ? "food" : "fluid",
                        location_id: locationSelectedId
                    }
                ]));

                fetchData();
                cleanInputs();
        
                toast(
                    {
                        description: t("cleaning.form.detailed.toast.success")
                    }
                );
            } else {
                toast(
                    {
                        description: "Type field required"
                    }
                );
            }
        } else {
            toast(
                {
                    description: "Resident required"
                }
            );
        }
    }

    const fetchData = () => { }

    const cleanInputs = () => {
        setValues(
            {
                date: new Date(),
                type: "",
                detail: "",
            }
        );
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-3 gap-4 mt-5">
                <div className="grid grid-cols-1 items-center gap-1">
                        <Label htmlFor={"date"} className="text-left">
                            { t("cleaning.form.detailed.form.date") }
                        </Label>

                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                variant={"outline"}
                                className={cn(
                                    "w-[280px] justify-start text-left font-normal w-100",
                                    !values.date && "text-muted-foreground"
                                )}
                                >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {values.date ? formatDate(values.date.toISOString()) : <span>mm/dd/yyyy - hh:mm aa</span>}
                                </Button>
                            </PopoverTrigger>

                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                value={values.date}
                                timePicker={true}
                                onChange={handleDateChange}
                                />
                            </PopoverContent>
                        </Popover>
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"area"} className="text-right justify-self-start">
                        Daily meal
                    </Label>

                    <Select onValueChange={handleTypesChange} value={values.type}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select a Food" />
                        </SelectTrigger>
                        
                        <SelectContent>
                            <SelectGroup>
                                {
                                    types.length ? (
                                        types.map(value => <SelectItem value={value.id}>{value.name}</SelectItem>)
                                    ) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"detail"} className="text-right justify-self-start">
                        { t("cleaning.form.detailed.form.detail") }
                    </Label>

                    <Input
                        id={"detail"}
                        name={"detail"}
                        type="text"
                        value={values.detail}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="w-100 mt-4 flex flex-row justify-end">
                <Button type="submit" className="mr-2">
                    { t("cleaning.form.detailed.form.submit") }
                </Button>
            </div>
        </form>
    );
};

export default TrackerFoodDetailed;
