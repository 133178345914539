import { Button } from '@/common/presentation/components/ui/button';
import { Calendar as CalendarIcon } from "lucide-react";
import { Label } from '@/common/presentation/components/ui/label';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/common/presentation/components/ui/input';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { saveTrackerSleep } from "../../slices/TrackerSleepSlice";
import { ITrackerSleepFormData } from '../../../infrastructure/interfaces/SleepInterface';

interface FormData {
    start_up: string;
    end_up: string;
    detail: string;
    type: string;
}

interface TrackerSleepNapDetailI {
    dataSleepNapReport: (page: number, rowsPerPage: number, resetData: boolean) => void;
    residentId: string;
    locationId: string;
    rowsPerPage: number;
}

export function TrackerSleepDetail({ residentId, locationId, dataSleepNapReport, rowsPerPage }: TrackerSleepNapDetailI) {

    const { t } = useTranslation();
    const [selectedStartUp, setSelectedStartUp] = useState(new Date());
    const [selectedEndUp, setSelectedEndUp] = useState(new Date());
    const formattedStartUp = selectedStartUp ? selectedStartUp.toLocaleString() : '';
    const formattedEndUp = selectedEndUp ? selectedEndUp.toLocaleString() : '';
    const [formData, setFormData] = useState<FormData>({
        start_up: selectedStartUp.toISOString(),
        end_up: selectedEndUp.toISOString(),
        detail: '',
        type: ''
    });
    const [isSubmitForm, setIsSubmitForm] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const handleStartUpChange = (date: Date) => {
        setSelectedStartUp(date);
        setFormData(prevState => ({
            ...prevState,
            start_up: date.toISOString()
        }));
    };

    const handleEndUpChange = (date: Date) => {
        setSelectedEndUp(date);
        setFormData(prevState => ({
            ...prevState,
            end_up: date.toISOString()
        }));
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();


        const json: ITrackerSleepFormData = {
            start_up: formData.start_up,
            end_up: formData.end_up,
            detail: formData.detail,
            resident_id: residentId,
            location_id: locationId,
            isNap: true
        };

        if (residentId === 'all-residents' || !residentId) {
            toast({
                description: t('trackers.trackerincontinence.selectResident'),
            });
            return
        }

        if (!formData.detail) {
            toast({
                description: t('trackers.requiredDetail'),
            });
            return
        }


        try {
            setIsSubmitForm(true);
            const response = await dispatch(saveTrackerSleep([json]));
            if (response.payload) {
                toast({
                    title: 'Success',
                    description: 'Data saved successfully',
                    className: 'bg-green-500 text-white'
                });
                setFormData({
                    start_up: selectedStartUp.toISOString(),
                    end_up: selectedEndUp.toISOString(),
                    detail: '',
                    type: ''
                });
                setIsSubmitForm(false);
                dataSleepNapReport(1, rowsPerPage, true);
            } else {
                toast({
                    description: t('trackers.trackerFall.error'),
                    variant: 'destructive'
                });
                setIsSubmitForm(false);
            }

        } catch (error) {
            toast({
                description: t('trackers.trackerFall.error'),
                variant: 'destructive'
            });
            setIsSubmitForm(false);
        }
    };



    return (
        <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
                <div className="flex flex-col w-full md:w-1/3 p-2">
                    <Label htmlFor="tracker_sleep_date" className="control-label mb-3">
                        {t('trackers.trackerSleep.startUp')}
                    </Label>
                    <div className="input-group">
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button variant="outline" className="w-full justify-start text-left font-normal">
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    {formattedStartUp}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                    onChange={handleStartUpChange}
                                    selectedDate={selectedStartUp}
                                    MonthAndYearPicker={true}
                                    timePicker={true}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>

                <div className="flex flex-col w-full md:w-1/3 p-2">
                    <Label htmlFor="tracker_bowel_date" className="control-label mb-3">
                        {t('trackers.trackerSleep.endUp')}
                    </Label>
                    <div className="input-group">
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button variant="outline" className="w-full justify-start text-left font-normal">
                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                    {formattedEndUp}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                                <CustomCalendar
                                    onChange={handleEndUpChange}
                                    selectedDate={selectedEndUp}
                                    MonthAndYearPicker={true}
                                    timePicker={true}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>

                <div className="flex flex-col w-full md:w-1/3 p-2">
                    <Label htmlFor="detail" className="control-label mb-3">
                        {t('trackers.Detail')}
                    </Label>
                    <div className="input-group">
                        <Input
                            className="w-full justify-start text-left font-normal focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                            id="detail"
                            name="detail"
                            type="text"
                            value={formData.detail}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="w-full flex justify-center mt-4">
                    <Button type="submit"
                        className="bg-primary text-white px-4 py-2 rounded"
                        disabled={isSubmitForm}>
                        {t('trackers.Save')}
                    </Button>
                </div>
            </div>
        </form>
    )
}