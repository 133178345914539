import { Button } from '@/common/presentation/components/ui/button';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { ScrollArea, ScrollBar } from '@/common/presentation/components/ui/scroll-area';
import { getDataCalendar } from '@/modules/calendar/presentation/slices/CalendarSlice';
import { AppDispatch } from '@/store/store';
import { CalendarDays } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import CalendarSkeleton from './calendar/skeleton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfoEventCard } from '@/modules/calendar/presentation/components/InfoEventCard';
import { useLocale } from '@/utils/helpers/localization.helper';

interface ICalendarCardProps {
  location: string;
}

interface ITaskEvent {
  eventId: string;
  title: string;
  start_date_time: string;
  start: string;
}

export const CalendarCard: React.FC<ICalendarCardProps> = ({ location = 'all-locations' }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dispatch = useDispatch<AppDispatch>();
  const [events, setEvents] = useState<ITaskEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState<string>('');
  const abortControllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLocale();

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    getEvents(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [location, selectedDate]);

  const getEvents = async (signal: AbortSignal) => {
    try {
      setIsLoading(true);
      const { payload } = await dispatch(
        getDataCalendar({
          formData: {
            start: new Date(),
            selected_date: selectedDate,
            locationId: location
          },
          signal: signal
        })
      );
      setEvents(payload);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSelectDate: any = (date: Date) => {
    if (date === null || date == undefined) return;
    setSelectedDate(date);
  };

  const goToCalendar = () => {
    navigate('/calendar');
    return;
  };

  const formatDate = (date: string) => {
    if (!date) return '';
    if (format(new Date(), 'MM/dd/yyyy') === format(new Date(selectedDate), 'MM/dd/yyyy')) {
      return format(new Date(date), t('dateFormats.dateTimeFormat'));
    }
    return format(new Date(date), 'hh:mm a');
  };

  const viewEvent = (eventId: string) => {
    setSelectedEventId(eventId);
    setIsOpenModal(true);
  };

  return (
    <>
      <Card className="border-t-4 border-t-primary/80">
        <CardHeader>
          <div className="flex flex-row items-center mb-2 text-lg">
            <CalendarDays className="shrink-0 h-6 w-6 mr-2 text-muted-foreground" />
            <CardTitle className="text-lg text-muted-foreground font-bold flex-1">{t('dashboard.calendar')}</CardTitle>
            <div className="flex gap-4">
              <Button variant="outline" className="h-8 hover:bg-primary/5 gap-2" onClick={goToCalendar}>
                {t('dashboard.viewAll')}
              </Button>
            </div>
          </div>
          <hr />
        </CardHeader>
        <CardContent>
          <ScrollArea>
            <div className="grid grid-cols-1 lg:grid-cols-6 p-4 gap-4">
              <div className="col-span-6 2lg:col-span-3">
                <div className="w-full sm:w-full lg:w-auto">
                  <Calendar
                    mode="single"
                    selected={selectedDate}
                    onSelect={handleSelectDate}
                    className="w-full rounded-md border min-w-[280px] shadow-md flex justify-center"
                    locale={locale}
                  />
                </div>
              </div>
              {isLoading ? (
                <CalendarSkeleton />
              ) : events?.length == 0 || !events ? (
                <>
                  <div className="col-span-6 2lg:col-span-3 flex justify-end">
                    <div className="overflow-auto w-full 2lg:w-11/12">
                      <div className="flex justify-center bg-primary/10 h-10 rounded-lg text-sm items-center">
                        {t('dashboard.noEvents')}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-span-6 2lg:col-span-3 flex justify-end">
                  <ScrollArea className="w-full flex-grow overflow-auto h-[150px] md:h-[300px]">
                    {events.map((event: ITaskEvent, index: number) => (
                      <div key={`${event.eventId}-${index}`} className="m-2 w-full">
                        <div
                          onClick={() => viewEvent(event.eventId)}
                          className="flex flex-col bg-primary/10 p-3 rounded-lg border-l-8 border-primary hover:bg-primary/20 cursor-pointer transition-all"
                        >
                          <span className="text-primary font-bold ml-1">{event.title}</span>
                          <span className="ml-1 font-semibold">{formatDate(event?.start)}</span>
                        </div>
                      </div>
                    ))}
                    <ScrollBar orientation="vertical" />
                  </ScrollArea>
                </div>
              )}
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </CardContent>
      </Card>
      <InfoEventCard
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        eventTaskId={selectedEventId}
        fromCalendar={false}
        allowEdit={false}
      />
    </>
  );
};
