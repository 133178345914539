import { create } from 'zustand';
import ResidentsService from '@/modules/residents/infrastructure/services/ResidentsService';

type ResidentStore = {
  resident: any;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  getResident: (residentId: string) => Promise<any>;
  updateResidentStatus: (data: any) => Promise<any>;
};

export const useResidentStore = create<ResidentStore>((set, get) => ({
  resident: null,
  status: 'idle',
  error: null,

  getResident: async (residentId: string) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentsService.getResidentById(residentId);
      set({ status: 'idle', resident: response.data });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  updateResidentStatus: async (data: { residentId: string; status: string }) => {
    set({ status: 'loading' });
    try {
      const response = await ResidentsService.updateResidentStatus(data.residentId, data.status);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
