import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/common/presentation/components/ui/accordion';
import { Form } from '@/common/presentation/components/ui/form';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormFieldComponent } from '../FormFieldComponent';
import { formatTabLabel, getNestedProperty } from '../FormUtils';
import { SubCategoryComponent } from '../SubCategoryComponent';
import { useEditDefaultForm } from '@/modules/residents/infrastructure/hooks/forms/useEditDefaultForm';

const tabsOrder = ['socialization', 'emotional', 'mental', 'physical_health', 'functioning_skills'];

interface Props {
  defaultValues: any;
  formResource: any;
  currentTabIndex: number;
  onTabChange: (index: number) => void;
  onTotalTabsChange: (total: number) => void;
}

export const AppraisalForm = forwardRef(
  ({ defaultValues, formResource = {}, currentTabIndex, onTabChange, onTotalTabsChange }: Props, ref) => {
    const { defaultFormValues } = useEditDefaultForm();

    const tabs = useMemo(() => {
      if (!formResource || Object.keys(formResource).length === 0) {
        return [];
      }

      const t = Object.keys(formResource).map((tab) => ({
        label: formatTabLabel(tab),
        value: tab
      }));

      return tabsOrder.map((tab) => t.find((t) => t.value === tab)).filter((t) => t) as {
        label: string;
        value: string;
      }[];
    }, [formResource]);

    const categories = useCallback(
      (tab: string) => {
        return Object.entries(formResource[tab] || []).map(([category]) => ({
          label: formatTabLabel(category),
          value: category
        }));
      },
      [formResource]
    );

    const subCategories = useCallback(
      (tab: string, category: string) => {
        return Object.entries(formResource[tab]?.[category] || []).map(([subCategory]) => ({
          label: formatTabLabel(subCategory),
          value: subCategory
        }));
      },
      [formResource]
    );

    const [selectedSubCategories, setSelectedSubCategories] = useState<Record<string, boolean>>({});

    const generateFormSchemaFromResource = (tab: string) => {
      return z.object(
        Object.fromEntries(
          Object.entries(formResource[tab] || {}).map(([category]) => [
            category,
            z.object(
              Object.fromEntries(
                Object.entries(formResource[tab][category] || {}).map(([subCategory]) => [
                  subCategory,
                  z.object({
                    needs: z.string().optional(),
                    objectives: z.string().optional(),
                    time_frame: z.string().optional(),
                    person_responsible_for_implementation: z.string().optional(),
                    method_of_evaluating_progress: z.string().optional()
                  })
                ])
              )
            )
          ])
        )
      );
    };

    const formSchema = useMemo(() => {
      return z.object(Object.fromEntries(tabs.map((tab) => [tab.value, generateFormSchemaFromResource(tab.value)])));
    }, [tabs]);

    type FormValues = z.infer<typeof formSchema>;

    const form = useForm({
      resolver: zodResolver(formSchema),
      defaultValues,
      mode: 'onChange',
      values: { ...defaultValues } as FormValues
    });

    const handleSubCategoryChange = (tab: string, category: string, subCategory: string) => {
      setSelectedSubCategories((prevState) => ({
        ...prevState,
        [`${tab}-${category}-${subCategory}`]: !prevState[`${tab}-${category}-${subCategory}`]
      }));
    };

    const provideResource = (tree: string) => getNestedProperty(formResource, tree);

    const addCompletitionToTextarea = (value: string, tree: string) => {
      const currentValue = form.getValues(tree) || '';
      const newValue = `${currentValue}\n -${value}`;
      form.setValue(tree, newValue);
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: async () => {
        return new Promise((resolve, reject) => {
          form.handleSubmit(
            (data) => {
              resolve(data);
            },
            (errors) => {
              console.error('Form submission failed:', errors);
              reject(errors);
            }
          )();
        });
      }
    }));

    const updateSpecificFields = useCallback(() => {
      Object.keys(formResource).forEach((tab) => {
        Object.keys(formResource[tab]).forEach((category) => {
          Object.keys(formResource[tab][category]).forEach((subCategory) => {
            const pathBase = `${tab}.${category}.${subCategory}`;
            form.setValue(`${pathBase}.time_frame`, defaultFormValues.time_frame || '');
            form.setValue(
              `${pathBase}.person_responsible_for_implementation`,
              defaultFormValues.person_responsible_for_implementation || ''
            );
            form.setValue(
              `${pathBase}.method_of_evaluating_progress`,
              defaultFormValues.method_of_evaluating_progress || ''
            );
          });
        });
      });
    }, [defaultFormValues, formResource, form]);

    useEffect(() => {
      if (
        defaultFormValues?.time_frame?.length > 0 ||
        defaultFormValues?.person_responsible_for_implementation?.length > 0 ||
        defaultFormValues?.method_of_evaluating_progress?.length > 0
      ) {
        updateSpecificFields();
      }
    }, [defaultFormValues, updateSpecificFields]);

    useEffect(() => {
      if (onTotalTabsChange) {
        onTotalTabsChange(tabs.length);
      }
    }, [tabs, onTotalTabsChange]);

    return (
      <Form {...form}>
        <form className="flex flex-col gap-4">
          <Tabs
            value={tabs[currentTabIndex]?.value}
            onValueChange={(value) => {
              const newIndex = tabs.findIndex((tab) => tab.value === value);
              onTabChange(newIndex);
            }}
            className="w-full"
          >
            <TabsList className="w-full h-full grid grid-cols-1 md:grid-cols-5 md:gap-2">
              {tabs.map((tab) => (
                <TabsTrigger
                  key={tab.value}
                  value={tab.value}
                  className="w-full data-[state=active]:bg-primary data-[state=active]:text-white whitespace-normal overflow-ellipsis overflow-hidden"
                >
                  {tab.label}
                </TabsTrigger>
              ))}
            </TabsList>

            {tabs.map((tab) => (
              <TabsContent key={tab.value} value={tab.value} className="h-full">
                {categories(tab.value).map((category) => (
                  <Accordion type="multiple" key={category.value}>
                    <AccordionItem value={category.value}>
                      <AccordionTrigger className="hover:no-underline">
                        <h1 className="text-xl font-bold">{category.label}</h1>
                      </AccordionTrigger>
                      <AccordionContent>
                        {subCategories(tab.value, category.value).length === 1 ? (
                          <div className="grid grid-cols-1 grid-rows-auto md:grid-cols-2 md:grid-rows-2 gap-4">
                            <FormFieldComponent
                              name={`${tab.value}.${category.value}.${
                                subCategories(tab.value, category.value)[0].value
                              }.needs`}
                              label="Needs"
                              control={form.control}
                              defaultValue={provideResource(
                                `${tab.value}.${category.value}.${
                                  subCategories(tab.value, category.value)[0].value
                                }.needs`
                              )}
                              addCompletitionToTextarea={(value: string) =>
                                addCompletitionToTextarea(
                                  value,
                                  `${tab.value}.${category.value}.${
                                    subCategories(tab.value, category.value)[0].value
                                  }.needs`
                                )
                              }
                            />
                            <FormFieldComponent
                              name={`${tab.value}.${category.value}.${
                                subCategories(tab.value, category.value)[0].value
                              }.objectives`}
                              label="Objectives"
                              control={form.control}
                              defaultValue={provideResource(
                                `${tab.value}.${category.value}.${
                                  subCategories(tab.value, category.value)[0].value
                                }.objectives`
                              )}
                              addCompletitionToTextarea={(value: string) =>
                                addCompletitionToTextarea(
                                  value,
                                  `${tab.value}.${category.value}.${
                                    subCategories(tab.value, category.value)[0].value
                                  }.objectives`
                                )
                              }
                            />
                            <div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-4">
                              <FormFieldComponent
                                name={`${tab.value}.${category.value}.${
                                  subCategories(tab.value, category.value)[0].value
                                }.time_frame`}
                                label="Time Frame"
                                control={form.control}
                                defaultValue={provideResource(
                                  `${tab.value}.${category.value}.${
                                    subCategories(tab.value, category.value)[0].value
                                  }.time_frame`
                                )}
                              />
                              <FormFieldComponent
                                name={`${tab.value}.${category.value}.${
                                  subCategories(tab.value, category.value)[0].value
                                }.person_responsible_for_implementation`}
                                label="Person Responsible For Implementation"
                                control={form.control}
                                defaultValue={provideResource(
                                  `${tab.value}.${category.value}.${
                                    subCategories(tab.value, category.value)[0].value
                                  }.person_responsible_for_implementation`
                                )}
                              />
                              <FormFieldComponent
                                name={`${tab.value}.${category.value}.${
                                  subCategories(tab.value, category.value)[0].value
                                }.method_of_evaluating_progress`}
                                label="Method Of Evaluating Progress"
                                control={form.control}
                                defaultValue={provideResource(
                                  `${tab.value}.${category.value}.${
                                    subCategories(tab.value, category.value)[0].value
                                  }.method_of_evaluating_progress`
                                )}
                              />
                            </div>
                          </div>
                        ) : (
                          subCategories(tab.value, category.value).map((subCategory) => (
                            <SubCategoryComponent
                              key={subCategory.value}
                              tab={tab.value}
                              category={category.value}
                              subCategory={subCategory}
                              isSelected={
                                selectedSubCategories[`${tab.value}-${category.value}-${subCategory.value}`] || false
                              }
                              onSelectionChange={() =>
                                handleSubCategoryChange(tab.value, category.value, subCategory.value)
                              }
                              control={form.control}
                              provideResource={provideResource}
                              addCompletitionToTextarea={addCompletitionToTextarea}
                            />
                          ))
                        )}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                ))}
              </TabsContent>
            ))}
          </Tabs>
        </form>
      </Form>
    );
  }
);
