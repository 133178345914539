import { CrudState } from '@/modules/notes/domain/note.domain';
import { FloatButton } from './floatButton';
import { removeNoteFilter, setNoteFilters } from '../../slices/NoteSlice';
import { RootState } from '@/store/store';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/registry/new-york/ui/tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import Title from '@/common/presentation/components/Title/Title';

interface NavigationProps {
  setCrudState: (crudState: CrudState) => void;
  crudState: CrudState;
}

export function Navigation({ setCrudState, crudState }: NavigationProps) {
  // Init values
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Hooks
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    if (crudState.isCreating || crudState.isUpdating) {
      setSelectedValue(t('notes.notes'));
    } else if (crudState.isCreatingStaffNote || crudState.isUpdatingStaffNote || crudState.showingStaffNotes) {
      setSelectedValue(t('notes.staffNotes'));
    } else {
      setSelectedValue(t('notes.notes'));
    }
  }, [crudState]);

  // Redux
  const auth = useSelector((state: RootState) => state.auth.user);

  // Behavior
  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
    dispatch(setNoteFilters(['nextPage', 1]));
    if (value === t('notes.notes')) {
      setCrudState(NoteHelperService.defineState({}));
      dispatch(removeNoteFilter('target_type'));
    } else if (value === t('notes.staffNotes')) {
      setCrudState(NoteHelperService.defineState({ showingStaffNotes: true }));
      dispatch(setNoteFilters(['target_type', 'staff']));
    }
  };

  return (
    <div className='grid grid-cols-12 gap-3 mb-3'>
      <div className='col-span-10 md:col-span-5'>
        <div className="flex justify-between">
          <Title className='text-primary'>{t('notes.notes')}</Title>
          <Tabs value={selectedValue} onValueChange={(e) => handleSelectChange(e)} defaultValue={t('notes.notes')}>
            <TabsList>
              <TabsTrigger
                value={t('notes.notes')}
                className="text-zinc-600 dark:text-zinc-200 data-[state=active]:bg-primary data-[state=active]:text-white"
              >
                {t('notes.residents')}
              </TabsTrigger>
              {auth?.roles[0] && ['Super Administrator', 'Executive', 'Client Manager'].includes(auth?.roles[0].name) && (
                <TabsTrigger
                  value={t('notes.staffNotes')}
                  className="text-zinc-600 dark:text-zinc-200 data-[state=active]:bg-primary data-[state=active]:text-white"
                >
                  {t('notes.staffNotes')}
                </TabsTrigger>
              )}
            </TabsList>
          </Tabs>
        </div>
      </div>
      <div className='col-span-2 md:col-span-7 flex justify-end'>
        {!crudState.isCreating &&
          !crudState.isUpdating &&
          !crudState.isFinishingDraft &&
          !crudState.isUpdatingStaffNote &&
          !crudState.isCreatingStaffNote && (
            <FloatButton selectedValue={selectedValue} setCrudState={setCrudState} />
          )
        }
      </div>
    </div>
  );
}
