const columnsChart = [
  {
    key: 'status',
    labelTranslationKey: 'medications.columnsChartMedications.status',
    value: 'status',
    type: 'checkbox',
    sortable: false,
    visible: true,
    static: true,
    width: '10%'
  },
  {
    key: 'rx',
    labelTranslationKey: 'medications.columnsChartMedications.rx',
    value: 'rx',
    type: 'text',
    visible: true,
    sortable: true,
    width: '15%'
  },
  {
    key: 'filleDate',
    labelTranslationKey: 'medications.columnsChartMedications.filleDate',
    value: 'filleDate',
    type: 'calendar',
    sortable: true,
    visible: true,
    width: '5%'
  },
  {
    key: 'qtyReceived',
    labelTranslationKey: 'medications.columnsChartMedications.qtyReceived',
    value: 'qtyReceived',
    type: 'number',
    visible: true,
    sortable: true,
    width: '5%'
  },
  {
    key: 'cycle_start_date',
    labelTranslationKey: 'medications.columnsChartMedications.cycle_start_date',
    value: 'cycle_start_date',
    type: 'calendar',
    visible: true,
    sortable: true,
    width: '10%'
  },
  {
    key: 'cycle_end_date',
    labelTranslationKey: 'medications.columnsChartMedications.cycle_end_date',
    value: 'cycle_end_date',
    type: 'calendar',
    visible: true,
    sortable: true,
    width: '10%'
  },
  {
    key: 'expiration_date',
    labelTranslationKey: 'medications.columnsChartMedications.expiration_date',
    value: 'expiration_date',
    type: 'calendar',
    sortable: true,
    visible: true,
    width: '10%'
  },
  {
    key: 'refills',
    labelTranslationKey: 'medications.columnsChartMedications.refills',
    value: 'refills',
    type: 'number',
    visible: true,
    sortable: true,
    width: '5%'
  }
];

export default columnsChart;
