import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Switch } from '@/common/presentation/components/ui/switch';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { CardsToShow } from '@/modules/staff/domain/models/profile/formCards';
import { useFormsCardsStore } from '@/modules/staff/domain/stores/profile/formCards';
import { useWindowHeight } from '@/common/hooks/useWindowHeight';
import { RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import * as Icons from 'lucide-react';
import { z } from 'zod';
import { cn } from '@/lib/utils';
interface Props {
  defaultValues: CardsToShow | null;
}

const formSchema = z.object({
  company: z.object({
    order: z.number(),
    roles: z.array(z.string()),
    status: z.enum(['active', 'inactive']),
    staff_can_view: z.boolean(),
    staff_can_edit: z.boolean()
  }),
  residence: z.object({
    order: z.number(),
    roles: z.array(z.string()),
    status: z.enum(['active', 'inactive']),
    staff_can_view: z.boolean(),
    staff_can_edit: z.boolean()
  }),
  training: z.object({
    order: z.number(),
    roles: z.array(z.string()),
    status: z.enum(['active', 'inactive']),
    staff_can_view: z.boolean(),
    staff_can_edit: z.boolean()
  }),
  other: z.object({
    order: z.number(),
    roles: z.array(z.string()),
    status: z.enum(['active', 'inactive']),
    staff_can_view: z.boolean(),
    staff_can_edit: z.boolean()
  }),
  custom_fields: z.object({
    order: z.number(),
    roles: z.array(z.string()),
    status: z.enum(['active', 'inactive']),
    staff_can_view: z.boolean(),
    staff_can_edit: z.boolean()
  }),
  termination: z.object({
    order: z.number(),
    roles: z.array(z.string()),
    status: z.enum(['active', 'inactive']),
    staff_can_view: z.boolean(),
    staff_can_edit: z.boolean()
  }),
  login_information: z.object({
    order: z.number(),
    roles: z.array(z.string()),
    status: z.enum(['active', 'inactive']),
    staff_can_view: z.boolean(),
    staff_can_edit: z.boolean()
  })
});

type IconMap = {
  [key: string]: JSX.Element;
};

const icons: IconMap = {
  company: <Icons.Building2 className='text-primary' />,
  custom_fields: <Icons.PenLine className='text-primary'/>,
  login_information: <Icons.LogIn className='text-primary'/>,
  other: <Icons.CircleEllipsis className='text-primary'/>,
  residence: <Icons.Home className='text-primary'/>,
  termination: <Icons.FilePen className='text-primary'/>,
  training: <Icons.GraduationCap className='text-primary'/>,
};

type FormValues = z.infer<typeof formSchema>;

export const FormCards = ({ defaultValues }: Props) => {
  const { status } = useSelector((state: RootState) => state.staff.configurations);
  const { setFormCardsToShow } = useFormsCardsStore();
  const isHeightGreaterThan620 = useWindowHeight(620);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues || {},
    mode: 'onChange'
  });

  const onSubmit = async (data: FormValues) => {
    try {
      await setFormCardsToShow(data);
      toast({
        title: 'Form cards updated',
        variant: 'default'
      });
    } catch (error) {
      toast({
        title: 'An error occurred',
        variant: 'destructive'
      });
    }
  };

  const onError = (errors: any) => {
    console.log('Errores de validación:', errors);
  };


  const sortedFieldNames = useMemo(() => {
    const entries = Object.entries(defaultValues || {});
    return entries.sort(([_, a], [__, b]) => (a.order || 0) - (b.order || 0)).map(([key]) => key);
  }, [defaultValues]);



  const formatFieldLabel = (fieldName: string) => {
    const labels: Record<string, string> = {
      company: 'Company',
      training: 'Training',
      other: 'Other',
      custom_fields: 'Custom Fields',
      termination: 'Termination',
      login_information: 'Login Information'
    };
    return labels[fieldName] || fieldName;
  };

  return (
    <>
      <div className={cn("flex flex-col gap-4 w-full", isHeightGreaterThan620 ? "h-auto" : "h-[330px]")}>
        <div>
          <h2 className="text-[14px] font-semibold text-grayBase ">Staff form cards</h2> 
          <p className='text-[12px] leading-[22px]'>Here you can configure the staff form cards that will be displayed in the staff form page.</p>
        </div>

        <Form {...form}>
          <form className="flex flex-col w-full items-start p-4 md:p-6 rounded-lg bg-neutralLight min-h-auto overflow-scroll sm:overflow-hidden">
            <div className="grid lg:grid-cols-2 w-full ">
              {sortedFieldNames.map((fieldName) => (
                <FormField
                  key={fieldName}
                  control={form.control}
                  name={`${fieldName as keyof FormValues}.status`}
                  render={({ field }) => {
                    return (
                      <FormItem 
                          className="flex justify-between items-center rounded-t-[6px] bg-white h-[48px] w-[100%] 
                          sm:w-[98%] xl:w-[495px] pr-2 pl-2 md:pr-12 md:pl-6 pt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-gray-300" 
                          >
                        
                        <FormLabel className="text-base flex gap-6 text-[14px] font-semibold	items-center">
                          <div>{icons[fieldName]}</div>
                          {formatFieldLabel(fieldName)}
                        </FormLabel>
                        <FormControl>
                          <Switch
                            className='p-0'
                            checked={field.value === 'active'}
                            onCheckedChange={(checked) => {
                              field.onChange(checked ? 'active' : 'inactive');
                              form.handleSubmit(onSubmit, onError)();
                            }}
                            disabled={status === 'loading'}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              ))}
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
