import { Label } from '@/common/presentation/components/ui/label';
import { Input } from '@/common/presentation/components/ui/input';
import { useEffect, useState } from 'react';
import { ITrackerCleaningArea, ITrackerCleaningSummary, ITrackerCleaningTask } from './TrackerCleaningMockup';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { useDispatch, useSelector } from 'react-redux';
import { getAreas, getSummaries, getTasks, updateSummary } from '../../slices/TrackerCleaningSlice';
import { RootState } from '@/store/store';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Pencil } from 'lucide-react';
import { Button } from '@/common/presentation/components/ui/button';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

export function TrackerCleaningSummaryEditDialog(props: { summary: ITrackerCleaningSummary }) {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const trackerCleaning = useSelector((state: RootState) => state.trackerCleaning);
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const { toast } = useToast();
    const [areas, setAreas] = useState<ITrackerCleaningArea[]>([]);
    const [tasks, setTasks] = useState<ITrackerCleaningTask[]>([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        task: "",
        detail: "",
        area: ""
    });

    useEffect(() => {
        loadAreaAndTask();

        setValues({
            task: props.summary.data.task.id,
            detail: props.summary.data.detail,
            area: props.summary.data.area.id,
        });
    }, []);

    useEffect(() => {
        setAreas(trackerCleaning.activeAreas);
        setTasks(trackerCleaning.activeTasks);
    }, [trackerCleaning]);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleAreaChange = (value: any) => {
        setValues({
             ...values,
             area: value
         });
     };

     const handleTaskChange = (value: any) => {
        setValues({
             ...values,
             task: value
         });
    };

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const handleEdit = () => {
        if(values.area && values.task) {
            dispatch(updateSummary(
                {
                    id: props.summary.id,
                    data: {
                        ...values
                    }
                }
            ));
    
            fetchData();
            setOpenEdit(false);

            toast(
                {
                    description: t("cleaning.report.summary.edit.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.report.summary.edit.toast.required")
                }
            );
        }

        fetchData();

        return true;
    }

    const fetchData = async () => {
        dispatch(getSummaries(
            {
                location_id: locationSelectedId
            }
        ));
    };

    const loadAreaAndTask = async () => {
        dispatch(getAreas(
            {
                location_id: locationSelectedId
            }
        ));

        dispatch(getTasks(
            {
                location_id: locationSelectedId
            }
        ));
    };

    return (
        <div>
            <Button onClick={() => openEditDialog()} variant="outline" size="icon" className='mr-2'>
                <Pencil className="h-4 w-4" />
            </Button>

            <CustomDialog 
                title={t("cleaning.report.summary.edit.title")}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
            >
                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"task"} className="text-right justify-self-start">
                        {t("cleaning.report.summary.edit.form.task")}
                    </Label>

                    <Select onValueChange={handleTaskChange} defaultValue={props.summary.data.task.id}>
                        <SelectTrigger>
                            <SelectValue placeholder={t("cleaning.report.summary.edit.form.taskPlaceholder")} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    tasks.length ? tasks.map(value => <SelectItem value={value.id}>{value.name}</SelectItem>) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
                
                <div>
                    <Label htmlFor="detail" className="text-right">
                        {t("cleaning.report.summary.edit.form.detail")}
                    </Label>

                    <Input
                        id="detail"
                        name="detail"
                        className="col-span-3"
                        value={values.detail}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="grid grid-cols-1 items-center gap-1">
                    <Label htmlFor={"area"} className="text-right justify-self-start">
                        {t("cleaning.report.summary.edit.form.area")}
                    </Label>

                    <Select onValueChange={handleAreaChange} defaultValue={props.summary.data.area.id}>
                        <SelectTrigger>
                            <SelectValue placeholder={t("cleaning.report.summary.edit.form.areaPlaceholder")} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    areas.length ? areas.map(value => <SelectItem value={value.id}>{value.name}</SelectItem>) : null
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className='flex justify-end mt-5'>
                    <Button type="submit" onClick={handleEdit}>
                        { t("trackers.trackercleaning.accept") }
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};
