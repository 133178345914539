import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PharmacyService from '../../infrastructure/services/PharmacyService';
import { IStatePharmacy } from '../../domain/pharmacy.domian';



const initialState: IStatePharmacy = {
  pharmacies: [],
  pharmacy: null,
  status: 'loading',
  error: null
};

export const fetchAllPharmacies = createAsyncThunk('pharmacy/listAllPharmacy', async () => {
  try {
    const response = await PharmacyService.getPharmacies();
    return response.data;
  } catch (error) {
    throw new Error('Error fetching pharmacies: ' + error);
  }
});

export const fetchPharmacies = createAsyncThunk('pharmacy/listpharmacy', async (locationId) => {
  try {
    const response = await PharmacyService.getPharmacy(locationId);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching pharmacies: ' + error);
  }
});

export const fetchPharmacyById = createAsyncThunk('pharmacy/getPharmacy',async (pharmacyId: string) => {
  try {
    const response = await PharmacyService.getPharmacyById(pharmacyId);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching pharmacies: ' + error);
  }
})

export const createNewPharmacyModule = createAsyncThunk('pharmacy/createNewPharmacyModule',async (formData:any) => {
  const response = await PharmacyService.createNewPharmacy(formData);
  return response.data;
})

export const createNewPharmacy = createAsyncThunk('pharmacy/createNewPharmacy', async (formData) => {
  const response = await PharmacyService.createPharmacy(formData);
  return response.data;
});

export const fetchPharmacyByLocationId = createAsyncThunk('pharmacy/getPharmacyByLocationId', async (params: {locationId: string, residentId?: string|null}) => {
  try {
    const response = await PharmacyService.getPharmacyByLocation(params.locationId, params?.residentId);
    return response;
  } catch (error) {
    throw new Error('Error fetching pharmacies: ' + error);
  }
});

const pharmacySlice = createSlice({
  name: 'pharmacy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPharmacies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPharmacies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pharmacies = action.payload;
      })
      .addCase(fetchPharmacies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Error fetching pharmacies';
      })
      .addCase(createNewPharmacy.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createNewPharmacy.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Error creating pharmacy';
      })
      .addCase(fetchAllPharmacies.fulfilled, (state,action) => {
        state.status = 'idle';
        state.pharmacies = action.payload;
      })
      .addCase(fetchAllPharmacies.pending, (state) => {
        state.status = 'loading';
        state.pharmacies = [];
      })
      .addCase(fetchPharmacyById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPharmacyById.fulfilled, (state, action) => {
        state.status = 'idle';
        state.pharmacy = action.payload;
      })
      .addCase(fetchPharmacyById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Error fetching pharmacy';
      })
      .addCase(createNewPharmacyModule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createNewPharmacyModule.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Error creating pharmacy';
      });
  }
});

export default pharmacySlice.reducer;

export const selectPharmacies = (state) => state.pharmacy.pharmacies;
export const selectPharmacyStatus = (state) => state.pharmacy.status;
export const selectPharmacyError = (state) => state.pharmacy.error;
