import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Card } from '@/common/presentation/components/ui/card';
import { useTranslation } from 'react-i18next';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Ellipsis, FileDown } from 'lucide-react';
import { Button } from '@/common/presentation/components/ui/button';


export function ElopementSummary({ dataReport, isLoading, loadMore, hasMore, resident }: any) {

    const { t } = useTranslation();

    const renderActions = () => (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon">
                    <Ellipsis className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuItem>
                        <FileDown className="mr-2 h-4 w-4" />
                        <span> {t('general.export')}</span>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );

    const columnsreport = [
        { key: 'resident_name', labelTranslationKey: t('trackers.Resident'), value: 'resident_name', sortable: true },
        {
            key: 'Actions', labelTranslationKey: t('trackers.Actions'), value: 'actions', width: '10%',
            render: renderActions
        },
    ];

    return (
        <Card className="border-t-4 border-t-primary/80 p-5 mt-8">
            <TableCustomVirtuoso
                data={dataReport}
                columns={columnsreport}
                renderCellContent={(index, column, data) => {
                    const row = data[index];

                    return row[column.key];
                }}
                loadMore={loadMore}
                hasMore={hasMore}
                key={resident}
                isLoading={isLoading}
                additionalButtons={<></>}

            />
        </Card>
    )
}