import { 
    Dialog,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/common/presentation/components/ui/dialog';
import { Button } from '@/common/presentation/components/ui/button';
import { Label } from '@/common/presentation/components/ui/label';
import { Input } from '@/common/presentation/components/ui/input';
import { CircleFadingPlus, Plus } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createTask, getTasks } from '../../slices/TrackerCleaningSlice';
import { useToast } from '@/common/presentation/components/ui/use-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';

export function TrackerCleaningTaskCreateDialog() {
    const dispatch = useDispatch();
    const { params } = useRouteParams();
    const { toast } = useToast();
    const { t } = useTranslation();
    const { locationSelectedId } = useLocation();
    const [openEdit, setOpenEdit] = useState(false);
    const [values, setValues] = useState({
        name: "",
    });

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if(values.name) {
            dispatch(createTask(
                {
                    ...values,
                    location_id: locationSelectedId
                }
            ));
    
            fetchData();
            cleanInputs();
            closeEditDialog();

            toast(
                {
                    description: t("cleaning.create.task.create.toast.success")
                }
            );
        } else {
            toast(
                {
                    description: t("cleaning.create.task.create.toast.required")
                }
            );
        }
    }

    const openEditDialog = () => {
        setOpenEdit(true);
    }

    const closeEditDialog = () => {
        setOpenEdit(false);
    }

    const fetchData = async () => {
        dispatch(getTasks(
            {
                location_id: locationSelectedId
            }
        ));
    }

    const cleanInputs = () => {
        setValues({
            name: ""
        });
    };

    return (
        <div>
            <Button variant="outline" size="sm" className='bg-primary/80 hover:bg-primary/80 hover:text-white text-white align-self-end' onClick={openEditDialog}>
                <CircleFadingPlus className="h-4 w-4 mr-1" />
                <span className='mr-1'>{t("cleaning.create.area.newButton")}</span>
            </Button>

            <CustomDialog 
                title={"Create"}
                width="30rem"
                newDialog={true}
                isOpen={openEdit}
                onClose={closeEditDialog}
            >
                <div>
                    <Label htmlFor="name" className="text-right">
                        {t("cleaning.create.task.create.form.name")}
                    </Label>
                    
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        className="col-span-3"
                        value={values.name}
                        onChange={handleInputChange}
                    />
                </div>

                <div className='flex justify-end mt-5' onClick={handleSubmit}>
                    <Button type="submit">
                        {t("cleaning.create.task.create.form.submit")}
                    </Button>
                </div>
            </CustomDialog>
        </div>
    );
};
