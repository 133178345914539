import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { cn } from '@/lib/utils';
import { format, parse, isValid, isAfter, isBefore } from 'date-fns';
import { Badge } from '@/common/presentation/components/ui/badge';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import columnsChart from './columnChartRX';
import DateInputWithCalendar from './DateInputWithCalendar';
import { Trash2 } from 'lucide-react';

const DATE_FORMAT = 'yyyy-MM-dd';

const getDefaultRow = (index: number) => {
  const defaultRow: ChartData = { index };
  columnsChart.forEach((column) => {
    switch (column.type) {
      case 'checkbox':
        defaultRow[column.value] = false;
        break;
      case 'calendar':
        defaultRow[column.value] = '';
        break;
      case 'number':
        defaultRow[column.value] = 0;
        break;
      default:
        defaultRow[column.value] = '';
    }
  });
  return defaultRow;
};

const ChartRX = ({ control, fields, onAddRow, setValue, typeName }) => {
  const { t } = useTranslation();
  const [localTableData, setLocalTableData] = useState([]);
  const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });

  const isDateInRange = (startDate, endDate) => {
    if (!startDate || !endDate) return false;
    const today = new Date();
    const start = parse(startDate, DATE_FORMAT, new Date());
    const end = parse(endDate, DATE_FORMAT, new Date());
    return isAfter(end, today) && isBefore(start, today);
  };

  useEffect(() => {
    if (fields && Array.isArray(fields) && fields.length > 0) {
      const activeRow = fields.find((item) => item.status);
      const inactiveRows = fields.filter((item) => !item.status);

      const processedData = [
        ...(activeRow ? [{ ...activeRow, status: true }] : []),
        ...inactiveRows.sort((a, b) => {
          const aDate = parse(a.cycle_start_date, DATE_FORMAT, new Date());
          const bDate = parse(b.cycle_start_date, DATE_FORMAT, new Date());
          return aDate - bDate;
        })
      ];
      setLocalTableData(processedData);
    } else {
      setLocalTableData(onAddRow ? [onAddRow] : []);
    }
  }, [fields, onAddRow]);

  const handleCellChange = useCallback(
    (index, columnKey, value) => {
      const updatedData = localTableData.map((row, i) => {
        if (i === index) {
          const newRow = { ...row, [columnKey]: value };
          if (columnKey === 'cycle_start_date' || columnKey === 'cycle_end_date') {
            const startDate = newRow.cycle_start_date;
            const endDate = newRow.cycle_end_date;
            newRow.status = isDateInRange(startDate, endDate);
          }
          return newRow;
        }
        return row;
      });
      setLocalTableData(updatedData);
    },
    [localTableData]
  );

  const toggleMedicationStatus = (index) => {
    const updatedData = localTableData.map((row, i) => {
      if (i === index) {
        const newStatus = !row.status;
        return {
          ...row,
          status: newStatus,
          cycle_end_date: newStatus ? null : format(new Date(), DATE_FORMAT)
        };
      }
      return row;
    });

    setLocalTableData(updatedData);
    setValue('chart', updatedData);
  };

  const handleDeleteRow = (index) => {
    const updatedData = localTableData.filter((_, i) => i !== index);
    setLocalTableData(updatedData);
    setValue('chart', updatedData);
  };

  const renderCellContent = (index, column, data) => {
    return (
      <td key={column.value} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
        <Controller
          name={`chart.${index}.${column.value}`}
          control={control}
          render={({ field }) => {
            const value = field.value;

            if (column.type === 'checkbox') {
              const isActive = localTableData[index]?.status;
              return (
                <>
                  <Badge
                    variant="outline"
                    className={cn(
                      'text-md font-bold flex items-center',
                      isActive ? 'border-primary text-primary' : 'border-gray-500 text-gray-500'
                    )}
                    onClick={() => {
                      toggleMedicationStatus(index);
                      field.onChange(!isActive);
                    }}
                  >
                    {isActive ? 'Active' : 'Inactive'}
                  </Badge>
                  <span
                    className="ml-2 cursor-pointer text-red-400 flex items-center"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <Trash2 className="w-5 h-5" />
                  </span>
                </>
              );
            } else if (column.type === 'calendar') {
              return (
                <DateInputWithCalendar
                  value={value}
                  onChange={(date) => {
                    field.onChange(date);
                    handleCellChange(index, column.value, date);
                  }}
                />
              );
            } else {
              return (
                <Input
                  type={column.type === 'number' ? 'number' : 'text'}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleCellChange(index, column.value, e.target.value);
                  }}
                  style={{ width: '100px' }}
                />
              );
            }
          }}
        />
      </td>
    );
  };

  const handleAddRow = useCallback(() => {
    const newIndex = localTableData.length;
    const newRow = getDefaultRow(newIndex);
    const updatedChart = [newRow, ...localTableData];
    setValue('chart', updatedChart);
  }, [localTableData, setValue]);

  return (
    <TableCustomVirtoso
      columns={columnsChart}
      data={localTableData}
      renderCellContent={renderCellContent}
      showSearchInput={false}
      additionalButtons={
        <Button className="bg-primary" onClick={handleAddRow} size="sm">
          Add Row
        </Button>
      }
    />
  );
};

export default ChartRX;
