import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

interface Props {
  title?: string;
  titleComponent?: React.ReactNode;
  path?: string;
  onlyBack?: boolean;
}

export const BackNavigationBar = ({ title, titleComponent, path = '/', onlyBack = false }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex items-center gap-x-2">
        <Button variant={'ghost'} size={'sm'} onClick={() => (onlyBack ? navigate(-1) : navigate(path))}>
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        {title}
        {titleComponent}
      </div>
    </>
  );
};
