import { AuthContext } from '@/auth/infrastructure/providers/AuthContextProvider';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Eye, EyeOff, Loader2 } from 'lucide-react';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

const FormSchema = z.object({
  username: z
    .string({
      required_error: 'A username is required.'
    })
    .min(3, 'Username must be at least 3 characters.'),
  password: z
    .string({
      required_error: 'A password is required.'
    })
    .min(3, 'Password must be at least 3 characters.')
});

type FormValues = z.infer<typeof FormSchema>;

const defaultValues: Partial<FormValues> = {
  username: '',
  password: ''
};

const LoginPage = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { login, isLoading, isAuthenticated } = useContext(AuthContext);

  const navigate = useNavigate();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (data: FormValues) => {
    login(data.username, data.password);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="relative md:h-auto lg:h-full flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-screen flex-col bg-muted p-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0">
            <img
              src="/img/fondoB2.png"
              alt="Image"
              width="1920"
              height="1080"
              className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
            />
          </div>
          <div className="relative z-20 flex items-center text-2xl font-medium">
            <img src="/img/logo_redondo.png" alt="Caring Data Logo" width={60} height={60} className="mr-2" />
            Caring Data
          </div>

          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-2xl">
                &ldquo;Propel your Assisted Living Business with a profesional communication and documentation tool to
                track resident and caregiver information.&rdquo;
              </p>
            </blockquote>
          </div>
        </div>
        <div className="h-screen flex items-center">
          <div className="mx-auto grid w-[350px] gap-6">
            <div className="grid gap-2 text-center">
              <img
                src="/img/logo_redondo.png"
                alt="Caring Data Logo"
                width={60}
                height={60}
                className="mr-auto ml-auto lg:hidden flex"
              />
              <h1 className="text-3xl font-semibold tracking-tight">Log in to Caring Data</h1>
              <p className="text-balance text-muted-foreground">Enter your info below to sign in to your account</p>
            </div>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Username</FormLabel>
                      <FormControl>
                        <Input {...field} type="text" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <div className="relative flex items-center">
                          <Input
                            {...field}
                            type={passwordVisible ? 'text' : 'password'}
                            autoCorrect="off"
                            autoComplete="off"
                            className="w-full rounded-lg bg-background"
                          />
                          <Eye
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(true)}
                          />
                          <EyeOff
                            className={cn(
                              'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                              !passwordVisible && 'hidden'
                            )}
                            onClick={() => setPasswordVisible(false)}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" disabled={isLoading}>
                  {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  Sign in
                </Button>
                <Button
                  onClick={() => navigate('/sign-up')}
                  className="bg-background text-primary border-2 border-primary hover:bg-primary hover:text-white"
                >
                  Sign Up
                </Button>
              </form>
            </Form>

            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{' '}
              <span className="underline underline-offset-4 hover:text-primary">Terms of Service</span> and{' '}
              <span className="underline underline-offset-4 hover:text-primary">Privacy Policy</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
