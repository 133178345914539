import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const ClientService = {

    getClients: async () => {
        try {
          const response = await axios.get(`clients/listclients`);
          if (response && response.data) {
            return response.data;
          } else {
            throw new Error('Error list clients: No data returned from server');
          }
        } catch (error) {
          throw new Error('Error fetching clients: ' + error);
        }
      },
    
}

export default ClientService;