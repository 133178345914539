import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { cn } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { TableActions } from './tableActions';
import { Button } from '@/common/presentation/components/ui/button';
import { ArrowUpDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { utcToZonedTime } from 'date-fns-tz';

export const columns: ColumnDef<any>[] = [
  {
    header: 'Profile Picture',
    cell: ({ row }) => (
      <div className={cn('overflow-hidden rounded-full w-full flex justify-center')}>
        <Avatar className={cn('rounded-md transition-all hover:scale-105', `w-16 h-16 rounded-full`)}>
          <AvatarImage
            src={row.original.image_url}
            alt={`${row.original.first_name} ${row.original.last_name}`}
            className="h-full w-full object-cover"
          />
          <AvatarFallback className={cn('bg-secondary text-sm rounded-md', 'bg-secondary text-sm')}>
            {row.original.first_name[0] + row.original.last_name[0]}
          </AvatarFallback>
        </Avatar>
      </div>
    )
  },
  {
    accessorKey: 'first_name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="hover:bg-transparent/5 hover:text-primary-50 text-[16px]"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => `${row.original.first_name} ${row.original.last_name}`
  },
  {
    accessorKey: 'preferred_name',
    header: 'Likes To Be Called'
  },
  {
    header: 'Date Of Birth',
    cell: ({ row }) => {
      const { t } = useTranslation();
      const currentDate = row.original.dob ? new Date(row.original.dob).toISOString() : null;
      // const
      if (!currentDate) return 'Not Specified';

      // const newDate = format(parseISO(currentDate), t('dateFormats.dateTimeFormat'));
      const newDate = format(new Date(currentDate), 'MM/dd/yyyy');

      return newDate;
    }
    // format(parseISO(row.original.dob || undefined), 'MM/dd/yyyy')
  },
  {
    header: 'Admission Date',
    cell: ({ row }) => {
      const date = row.original.other_information?.admission_date;

      if (!date) return null;

      return format(parseISO(date || undefined), 'MM/dd/yyyy - hh:mm aa');
    }
  },
  {
    accessorKey: 'location.name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className="hover:bg-transparent/5 hover:text-primary-50 text-[16px]"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Home
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    }
  },
  {
    id: 'actions',
    cell: ({ row }) => <TableActions id={row.original.id} />
  }
];
