import * as authActions from '@/auth/presentation/slices/authSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as configActions from '@/common/presentation/slices/configurationSlice';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import * as residentsActions from '@/modules/residents/presentation/slices/residentsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationSelectorDialog } from '../hooks/useLocationSelectorDialog';
import { setNavigateFunction } from '../middleware/navigation';
import { useSessionExpiration } from './SessionExpirationProvider';
import { encryptToLaravel } from '@/utils/helpers/encryption.helper';
import { RoleHelperProvider } from './RoleHelperProvider';

interface AuthContextValues {
  isAuthenticated: boolean;
  isLoading: boolean;
  roles: string[];
  login: (username: string, password: string) => void;
  logout: () => void;
  setHasSelectedLocation: (value: boolean) => void;
}

const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  isLoading: true,
  roles: [],
  login: () => {},
  logout: () => {},
  setHasSelectedLocation: () => {}
};

export const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues);

interface AuthContextProviderProps {
  children: JSX.Element;
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const { isLoading: authLoading, isAuthenticated, roles } = useSelector((state: RootState) => state.auth);
  const [isVerifying, setIsVerifying] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { isOpen, onOpen } = useLocationSelectorDialog();
  const [hasSelectedLocation, setHasSelectedLocation] = useState(false);
  const { setParam, params } = useRouteParams();
  const { SessionExpirationDialog } = useSessionExpiration();

  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  const isUserActive = (user: any) => user.status !== 'inactive';

  const handleAuthSuccess = (payload: any) => {
    if (payload.access_token) {
      localStorage.setItem('access_token', payload.access_token);
    }

    dispatch(configActions.setConfigurations(payload.configuration));
    dispatch(locationsActions.setLocations(payload.user.locations));
    dispatch(residentsActions.setResidents(payload.user.residents));
    dispatch(authActions.setRoles(payload.user.roles));
  };

  const handleAuthFailure = (error?: any) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('location_selected');
    dispatch(authActions.unSetAuth());
    dispatch(locationsActions.unSetLocations());
    dispatch(configActions.unSetConfigurations());

    const path = location.pathname;
    const signUpPathRegex = /^\/sign-up(\/.*)?$/;

    if (!signUpPathRegex.test(path)) {
      navigate('/login', { replace: true });
      if (error) {
        toast({
          title: 'Error de Autenticación',
          description: `${error.message}`,
          variant: 'destructive'
        });
      }
    }
  };

  const login = async (username: string, password: string) => {
    try {
      const loginEncrypted = encryptToLaravel({ username, password });

      const response: any = await dispatch(authActions.login(loginEncrypted));
      if (response.meta.requestStatus === 'fulfilled') {
        if (!isUserActive(response.payload.user)) {
          handleAuthFailure({ message: 'Tu cuenta está inactiva. Por favor, contacta al administrador.' });
          return;
        }

        handleAuthSuccess(response.payload);

        const userRoles = response.payload.user.roles.map((role: any) => role.name);

        if (userRoles.includes('Staff')) {
          onOpen();
        }
      } else {
        const message = response.payload?.message || 'Error al iniciar sesión';
        handleAuthFailure({ message });
      }
    } catch (error) {
      handleAuthFailure(error);
    }
  };

  const logout = async () => {
    handleAuthFailure();
    try {
      await dispatch(authActions.logout());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  useEffect(() => {
    const verifySession = async () => {
      setIsVerifying(true);
      const accessToken = localStorage.getItem('access_token');
      const path = location.pathname;
      const signUpPathRegex = /^\/sign-up(\/.*)?$/;

      if (!accessToken && !signUpPathRegex.test(path)) {
        dispatch(authActions.unSetAuth());
        dispatch(locationsActions.unSetLocations());
        dispatch(configActions.unSetConfigurations());
        sessionStorage.removeItem('location_selected');
        navigate('/login', { replace: true });
        setIsVerifying(false);
        return;
      }

      try {
        const response: any = await dispatch(authActions.verifySession());
        if (response.meta.requestStatus === 'fulfilled') {
          if (!isUserActive(response.payload.user)) {
            handleAuthFailure({ message: 'Tu cuenta está inactiva. Por favor, contacta al administrador.' });
            return;
          }

          handleAuthSuccess(response.payload);
        } else {
          handleAuthFailure(response.error);
        }
      } catch (error) {
        handleAuthFailure(error);
      } finally {
        setIsVerifying(false);
      }
    };

    verifySession();
  }, [location.pathname]);

  useEffect(() => {
    const userRoles = roles.map((role: any) => role.name);

    if (isAuthenticated && userRoles.includes('Staff')) {
      const locationSelected = localStorage.getItem('location_selected');

      if (!locationSelected || locationSelected === 'all-locations') {
        if (!isOpen && hasSelectedLocation) {
          handleAuthFailure({ message: 'No se encontró una ubicación válida. Por favor, vuelva a iniciar sesión.' });
        } else if (!isOpen) {
          onOpen();
        }
      } else {
        const lParam = params.l;
        if (!lParam) {
          setParam('l', locationSelected);
        }
      }
    }

    if (isAuthenticated && userRoles.includes('Pharmacy')) {
      const pharmacyAllowPathRegex = /^\/notes(\/.*)?$/;
      const path = location.pathname;
      if (!pharmacyAllowPathRegex.test(path)) {
        navigate('/notes', { replace: true });
      }
    }
  }, [isAuthenticated, roles, location.pathname, isOpen, hasSelectedLocation]);

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      isLoading: isVerifying || authLoading,
      roles,
      login,
      logout,
      setHasSelectedLocation
    }),
    [isAuthenticated, isVerifying, authLoading, roles, login, logout, setHasSelectedLocation]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      <RoleHelperProvider>
        <>
          {children}
          <SessionExpirationDialog />
        </>
      </RoleHelperProvider>
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
