import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { fetchDevice, registerDevice } from '../../slices/SecuritySlice';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import { Loader2 } from 'lucide-react';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  getDevices: () => void;
}

interface OptionI {
  label: string;
  value: string;
  imageUrl?: string;
}

interface FormErrorsI {
  name?: string;
}

const RegisterDevice: React.FC<IProps> = ({ isOpen, onClose, getDevices }) => {
  const { t } = useTranslation();
  const [deviceName, setDeviceName] = useState<string>('');
  const [selectedLocations, setSelectedLocations] = useState<OptionI[]>([]);
  const [registering, setRegistering] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrorsI>({});
  const locationsState = useSelector((state: RootState) => state.locations.allLocations) ?? [];
  const [locations, setLocations] = useState<OptionI[]>([]);
  const { deviceInfo } = useConfigurationsProvider();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (locationsState.status === 'idle') {
      setLocations(formatLocationOptions(locationsState.locations));
    }
  }, [locationsState]);

  const formatLocationOptions = (locations: any[]): OptionI[] => {
    return locations.map((location) => ({
      label: location.name,
      value: location.id,
      imageUrl: location.image_path
    }));
  };

  const validateDeviceName = (value: string) => {
    const nameError = value.length < 3 || value.length > 50 ? t('security.deviceNameLength') : undefined;
    setErrors((prevErrors) => ({ ...prevErrors, name: nameError }));
    return !nameError;
  };

  const handleDeviceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDeviceName(value);
    validateDeviceName(value);
  };

  const handleLocationsChange = (selectedLocations: any) => {
    setSelectedLocations(selectedLocations);
  };

  const registerDeviceHanlder = useCallback(async () => {
    try {
      if (!validateDeviceName(deviceName) || selectedLocations.length === 0) {
        toast({
          title: t('security.validationError'),
          description: selectedLocations.length === 0 ? t('security.selectAtLeastOneLocation') : errors.name,
          className: 'bg-red-500 text-white'
        });
        return;
      }

      if (!deviceInfo) return;
      setRegistering(true);

      await dispatch(
        registerDevice({
          deviceInfo: deviceInfo,
          locationsId: selectedLocations.map((location: OptionI) => location.value),
          name: deviceName
        })
      );

      await dispatch(
        fetchDevice({
          fingerPrint: deviceInfo?.finger_print ?? ''
        })
      );

      getDevices();
      toast({
        title: t('security.deviceRegisterCurrentLocation'),
        className: 'bg-green-500 text-white'
      });
      onClose();
    } catch (error) {
      console.log(error);
    }
    setRegistering(false);
    setSelectedLocations([]);
    setDeviceName('');
  }, [deviceInfo, selectedLocations, deviceName, errors, t, dispatch, getDevices, onClose]);

  return (
    <CustomDialog
      title={t('security.registerDevice')}
      isOpen={isOpen}
      onClose={onClose}
      description=""
      className="sm:max-w-[425px]"
      newDialog={true}
      overFlow={true}
    >
      <div className="space-y-6 px-2 mb-8">
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="deviceName" className="text-sm font-medium">
              {t('security.deviceName')}
            </Label>
            <Input
              id="deviceName"
              placeholder={t('security.enterDeviceName')}
              value={deviceName}
              onChange={handleDeviceNameChange}
              disabled={registering}
              className={`w-full ${errors.name ? 'border-red-500' : ''}`}
              required
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
          </div>

          <div className="space-y-2">
            <Label htmlFor="deviceDescription" className="text-sm font-medium">
              {t('security.selectLocations')}
            </Label>
            <MultiSelect
              name="locations"
              options={locations}
              selectedOptions={selectedLocations}
              onChange={handleLocationsChange}
              placeHolder={t('security.selectLocations')}
              className="w-full mt-1"
              isDisabled={registering}
            />
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <Button type="button" variant="outline" onClick={onClose} className="mt-3 sm:mt-0" disabled={registering}>
            {t('common.cancel')}
          </Button>
          <Button onClick={registerDeviceHanlder} className="bg-primary hover:bg-primary/90" disabled={registering}>
            {registering && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {t('security.registerDevice')}
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};

export default RegisterDevice;
