import { Form, FormControl, FormField, FormItem, FormMessage } from '@/common/presentation/components/ui/form';
import { UseFormReturn } from 'react-hook-form';
import { Button } from '@/common/presentation/components/ui/button';
import { RefreshCwIcon } from 'lucide-react';
import { Input } from '@/common/presentation/components/ui/input';
import { z } from 'zod';
import { FormSchema } from './ContactsSchema';
import { useTranslation } from 'react-i18next';

interface FilterFormProps {
  onFilter: (data: z.infer<typeof FormSchema>) => void;
  onCleanFilter: () => void;
  form: UseFormReturn<z.infer<typeof FormSchema>>;
}

const FilterForm: React.FC<FilterFormProps> = ({ onFilter, onCleanFilter, form }) => {
  const { t } = useTranslation();

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFilter)}>
        <div className="flex flex-wrap gap-3">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem className="flex-grow min-w-[200px]">
                <FormControl>
                  <Input
                    placeholder={t('contacts.name_contact')}
                    name={field.name}
                    onChange={(e) => field.onChange(e)}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className="w-full md:w-auto  dark:text-white" type="submit">
            {t('form.filter')}
          </Button>
          <Button className="w-full md:w-auto dark:text-white" type="button" onClick={onCleanFilter}>
            <RefreshCwIcon size={16} className="mr-2" />
            {t('form.cleanFilter')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default FilterForm;
