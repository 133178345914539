import { Dialog } from '@/common/presentation/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useRequestRestrictByDeviceDialog } from '@/modules/security/infraestructure/hooks/useRestrictByDeviceDialog';
import { Loader2, MonitorSmartphone } from 'lucide-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RestrictByDeviceRequestDialog = () => {
  const { isOpen, onClose, message, justClose, onSubmit, submitLoading } = useRequestRestrictByDeviceDialog();
  const { t } = useTranslation();

  useEffect(() => {
  }, [isOpen, message, submitLoading]);
  
  return (
    <>
      {isOpen && <div className="fixed inset-0 bg-black/50 bg-opacity-10 z-[999999999]" />}
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="z-[9999999999]">
          <DialogHeader>
            <DialogTitle>
              <div className="flex flex-row gap-2 items-end">
                <MonitorSmartphone className="text-primary" size={20} />
                { t('security.restrictByDevice') }
              </div>
              <hr className="border-primary w-full my-3" />
            </DialogTitle>
            <DialogDescription>{ message }</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            {justClose ? (
              <Button onClick={onClose}>{ t('security.close') }</Button>
            ) : (
              <>
                <Button variant="outline" onClick={onClose}>
                { t('security.cancel') }
                </Button>
                <Button onClick={onSubmit} disabled={submitLoading}>
                  {
                    submitLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )
                  }
                  { t('security.requestRegistration') }
                </Button>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RestrictByDeviceRequestDialog;
