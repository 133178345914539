import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';

interface MonthAndYearSelectorProps {
  date: Date;
  onChange: (date: Date) => void;
  baseSelectorYear?: number | undefined;
  maxSelectorYear?: number | undefined;
  className?: string;
}

export const MonthAndYearSelector = ({
  date,
  onChange,
  baseSelectorYear = 1900,
  maxSelectorYear,
  className = ''
}: MonthAndYearSelectorProps) => {
  const months = useMemo(() => {
    const monthKeys = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december'
    ];
    return monthKeys.map((key, index) => ({
      value: index.toString(),
      label: t(`calendar.customCalendar.${key}`)
    }));
  }, []);

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const startYear = maxSelectorYear ?? currentYear + 50;
    const endYear = baseSelectorYear;
    const yearsArray = [];
    for (let i = startYear; i >= endYear; i--) {
      yearsArray.push(i);
    }
    return yearsArray;
  }, [baseSelectorYear, maxSelectorYear]);

  const handleMonthChange = useCallback(
    (value: string) => {
      const newDate = new Date(date as Date);
      newDate.setMonth(parseInt(value));
      onChange(newDate);
    },
    [date, onChange]
  );

  const handleYearChange = useCallback(
    (value: string) => {
      const newDate = new Date(date as Date);
      newDate.setFullYear(parseInt(value));
      onChange(newDate);
    },
    [date, onChange]
  );

  return (
    <div className="w-full flex flex-row md:p-2 gap-2 ">
      <Select onValueChange={handleMonthChange} value={date.getMonth().toString()}>
        <SelectTrigger>
          <SelectValue placeholder={t('Select Month')} />
        </SelectTrigger>
        <SelectContent position="popper">
          {months.map((month) => (
            <SelectItem key={month.value} value={month.value}>
              {month.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select onValueChange={handleYearChange} value={date.getFullYear().toString()}>
        <SelectTrigger>
          <SelectValue placeholder={t('Select Year')} />
        </SelectTrigger>
        <SelectContent position="popper" className={`overflow-y-auto ${className}`}>
          {years.map((y) => (
            <SelectItem key={y} value={y.toString()}>
              {y}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
