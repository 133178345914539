import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { fetchGetStaffNotes } from '../../slices/NoteSlice';
import { Plus } from 'lucide-react';
import { RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';

interface FloatButtonProps {
  setCrudState: (crudState: CrudState) => void;
  selectedValue: string;
}

export function FloatButton({ setCrudState, selectedValue }: FloatButtonProps) {
  // Init values
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux
  const auth = useSelector((state: RootState) => state.auth.user);

  return (
    <>
      {selectedValue == t('notes.notes') && (
        <Button type="button" onClick={() => setCrudState(NoteHelperService.defineState({ isCreating: true }))}>
          <Plus /> 
          <span className='hidden md:flex'>{t('notes.newNote')}</span>
        </Button>
      )}
      {
        auth?.roles[0] && ['Super Administrator', 'Executive', 'Client Manager'].includes(auth?.roles[0].name)
        && selectedValue == t('notes.staffNotes')
        && (
          <Button type="button" onClick={() => {
            setCrudState(NoteHelperService.defineState({ showingStaffNotes: true, isCreatingStaffNote: true }));
            dispatch<any>(fetchGetStaffNotes());
          }}>
            <Plus /> 
            <span className='hidden md:flex'>{t('notes.newStaffNote')}</span>
          </Button>
        )
      }
    </>
  );
}
