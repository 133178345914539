import { useMemo, useState } from 'react';
import { TimerSelector } from './TimerSelector';
import { UserAccordion } from './UserAccordion';
import { EmarRoutineCard } from './EmarRoutineCard';
import { format } from 'date-fns';

interface Medication {
  id: string;
  resident_id: string;
  time?: string[];
  emar_routine_medications?: any[];
  emar_medication_exceptions?: any[];
}

interface Props {
  medications: Medication[];
  residentId: string;
  date: Date;
  emar: string;
}

const normalizeTime = (time: string) => {
  const [hours, minutes] = time.split(':');
  return `${parseInt(hours, 10)}:${minutes}`;
};

export const EmarRoutineSection = ({ medications, residentId, date, emar }: Props) => {
  const times = useMemo(() => {
    const allTimes = medications.flatMap((medication) => medication.time || []);
    const uniqueTimes = Array.from(new Set(allTimes.map(normalizeTime)));
    return uniqueTimes.filter((time) => time && !time.includes(','));
  }, [medications]);

  const [selectedTime, setSelectedTime] = useState<string>(times[0] || '');

  const completedTimes = useMemo(() => {
    const groupedByDateTime = medications.reduce((acc: Record<string, Medication[]>, medication) => {
      const medicationTimes = (medication.time || []).map(normalizeTime);
      medicationTimes.forEach((time) => {
        if (!acc[time]) acc[time] = [];
        acc[time].push(medication);
      });
      return acc;
    }, {});

    return times.filter((time) => {
      const medicationsAtTime = groupedByDateTime[time] || [];
      return medicationsAtTime.every((medication) => {
        const routineMedication = medication.emar_routine_medications?.find(
          (m: any) =>
            m.resident_medication_id === medication.id &&
            m.time_to_be_witnessed === time &&
            m.date_to_be_witnessed?.split('T')[0] === format(date, 'yyyy-MM-dd') &&
            m.witnessed === 1
        );
        const exception = medication.emar_medication_exceptions?.find(
          (m: any) =>
            m.resident_medication_id === medication.id &&
            m.time_to_be_dispensed === time &&
            m.date_to_be_dispensed?.split('T')[0] === format(date, 'yyyy-MM-dd')
        );
        return routineMedication || exception;
      });
    });
  }, [medications, times, date]);

  const filteredMedicationsByResident = useMemo(() => {
    const normalizedTime = normalizeTime(selectedTime);

    return medications.reduce((acc: Record<string, Medication[]>, medication) => {
      const medResidentId = medication.resident_id;
      if (!acc[medResidentId]) acc[medResidentId] = [];
      const medicationTimes = medication.time?.map(normalizeTime) || [];
      if (medicationTimes.includes(normalizedTime)) {
        acc[medResidentId].push(medication);
      }
      return acc;
    }, {});
  }, [medications, selectedTime]);

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-xl font-semibold">Routine Medications</h2>

      <TimerSelector data={medications} completedTimes={completedTimes} onSelect={setSelectedTime} />
      {residentId === 'all-residents' ? (
        <>
          {Object.keys(filteredMedicationsByResident)
            .filter((residentId) => filteredMedicationsByResident[residentId].length > 0)
            .map((residentId) => (
              <div key={residentId}>
                <UserAccordion
                  medications={filteredMedicationsByResident[residentId]}
                  selectedDate={date}
                  selectedTime={selectedTime}
                  emarType={emar}
                  residentId={residentId}
                />
              </div>
            ))}
        </>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {filteredMedicationsByResident[residentId]?.map((medication) => (
            <EmarRoutineCard
              data={medications.find((med) => med.id === medication.id)}
              key={medication.id}
              selectedDate={date ?? new Date()}
              selectedTime={selectedTime ?? ''}
            />
          ))}
        </div>
      )}
    </div>
  );
};
