import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { IDispensingHistorical } from '@/modules/medications/domain/Medications';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import React, { useCallback, useEffect, useState } from 'react';

interface IProps {
  dispensingHistorical: IDispensingHistorical[];
  status: boolean;
}

interface ITableData {
  sentDate: string;
  writtenDate: string;
  diagnosis: string;
  medicationDispensedNote: string;
  fillStatus: string;
  fillStatusCode: string;
  fillStatusNote: string;
  substitutions: string;
}

const columns = [
  {
    key: 'sentDate',
    labelTranslationKey: 'medications.sentDate',
    value: 'sentDate',
    visible: true,
    static: true,
    width: '10%'
  },
  {
    key: 'writtenDate',
    labelTranslationKey: 'medications.writtenDate',
    value: 'writtenDate',
    visible: true,
    width: '15%'
  },
  {
    key: 'diagnosis',
    labelTranslationKey: 'medications.diagnosis',
    value: 'diagnosis',
    visible: false,
    width: '5%'
  },
  {
    key: 'medicationDispensedNote',
    labelTranslationKey: 'medications.medicationDispensedNote',
    value: 'medicationDispensedNote',
    visible: true,
    width: '5%'
  },
  {
    key: 'fillStatus',
    labelTranslationKey: 'medications.fillStatus',
    value: 'fillStatus',
    visible: true,
    width: '10%'
  },
  {
    key: 'fillStatusCode',
    labelTranslationKey: 'medications.fillStatusCode',
    value: 'fillStatusCode',
    visible: true,
    width: '10%'
  },
  {
    key: 'fillStatusNote',
    labelTranslationKey: 'medications.fillStatusNote',
    value: 'fillStatusNote',
    width: '10%'
  },
  {
    key: 'substitutions',
    labelTranslationKey: 'medications.substitutions',
    value: 'substitutions',
    visible: true,
    width: '5%'
  }
];

const LastDispensing: React.FC<IProps> = ({ dispensingHistorical, status }) => {
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const { formatDate } = useDateHelpers();

  useEffect(() => {
    if (dispensingHistorical.length === 0) return;
    formatHistoricalData();
  }, [dispensingHistorical]);

  const renderCellContent = (index: number, column: any, data: any) => {
    return column.render ? column.render(data[index]) : data[index][column.key];
  };

  const formatHistoricalData = useCallback(() => {
    const formattedData: ITableData[] = dispensingHistorical.map((row) => {
      return {
        sentDate: row.sent_date ? formatDate(row.sent_date, false) : '--',
        writtenDate: row.written_date ? formatDate(row.written_date, false) : '--',
        diagnosis: row.diagnosis && row.diagnosis !== '[]' ? JSON.parse(row.diagnosis).join(', ') : '--',
        medicationDispensedNote: row.dispensed_note && row.dispensed_note.trim() !== '' ? row.dispensed_note : '--',
        fillStatus: row.fill_status && row.fill_status.trim() !== '' ? row.fill_status : '--',
        fillStatusCode: row.fill_status_code && row.fill_status_code.trim() !== '' ? row.fill_status_code : '--',
        fillStatusNote: row.fill_status_note && row.fill_status_note.trim() !== '' ? row.fill_status_note : '--',
        substitutions:
          row.substitutions && row.substitutions.trim() !== '' && row.substitutions !== 'null'
            ? row.substitutions
            : '--'
      };
    });
    setTableData(formattedData);
  }, [dispensingHistorical]);

  return (
    <TableCustomVirtuoso
      columns={columns}
      data={tableData}
      onSort={() => {}}
      additionalButtons={false}
      loadMore={() => {}}
      isLoading={status}
      hasMore={false}
      filters={false}
      showSearchInput={false}
      renderCellContent={renderCellContent}
    />
  );
};

export default LastDispensing;
