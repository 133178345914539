import { t } from "i18next";
import RenderActionsColumn from "./renderActionsColumn";

const useCleaningColumns = () => {
  const CleaningColumns = [
    {
      key: 'name',
      id: 'name',
      labelTranslationKey: 'cleaning.create.task.table.task',
      value: 'name',
      visible: true,
      sortable: true,
      width: '90%'
    },
    {
      key: 'actions',
      labelTranslationKey: 'contacts.actions',
      render: (rowData: any) => RenderActionsColumn(rowData),
      visible: true,
      static: true,
      width: '10%'
    }
  ];

  const translateColumnLabel = (key) =>
    t(CleaningColumns.find((column) => column.key === key)?.labelTranslationKey || '');

  const columnsWithTranslation = CleaningColumns.map((column) => ({
    ...column,
    translateColumnLabel
  }));

  return columnsWithTranslation;
}

export default useCleaningColumns;
