import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO, format, isValid } from 'date-fns';
import { disposeMedication, fetchGetAllMedicationsByDestroyed } from '../../../slices/medicationsSlice';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { RootState } from '@/store/store';
import { useTranslation } from 'react-i18next';
import columnsDestructionMedications from './columnsDestructionMedications';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import DateInputWithCalendar from '../../MedicationsDetails/DateInputWithCalendar';
import { useCustomToast } from '@/common/presentation/components/CustomToast/CustomToast';

interface EditedData {
  [key: string]: {
    medication_name: string;
    rx: string;
    cycle_start_date: string;
    filleDate: string;
    medication_id: string;
    index: number;
    [key: string]: any;
  };
}

interface QuantityInputs {
  [key: string]: string;
}

const FormDestruction: React.FC = () => {
  const dispatch = useDispatch();
  const { params } = useRouteParams();
  const { t, i18n } = useTranslation();
  const { showToast } = useCustomToast();

  const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });
  const [filters, setFilters] = useState({ filter: '' });
  const [pageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [editedData, setEditedData] = useState<EditedData>({});
  const [quantityInputs, setQuantityInputs] = useState<QuantityInputs>({});

  const { medicationsAllMedicationsByDestroyed } = useSelector((state: RootState) => state.medications);

  useEffect(() => {
    dispatch(
      fetchGetAllMedicationsByDestroyed({
        residentId: params.r || 'all-residents',
        filter: filters.filter,
        page: currentPage,
        limit: pageSize
      })
    );
  }, [dispatch, params.r, filters.filter, currentPage]);

  const getRowId = (row: any): string => {
    return `${row.medication_name}_${row.rx}_${row.cycle_start_date}_${row.filleDate}`;
  };

  const formatDate = (date: string | Date): string => {
    if (!date) return '';
    try {
      const parsedDate = typeof date === 'string' ? parseISO(date) : date;
      return isValid(parsedDate) ? format(parsedDate, t('dateFormats.dateFormat')) : '';
    } catch (error) {
      return '';
    }
  };

  const handleCellChange = (index: number, columnKey: string, value: any, row: any) => {
    const rowId = getRowId(row);

    if (columnKey === 'quantity') {
      const numericValue = Number(value);

      if (numericValue < 0) {
        showToast('error', t('error'), t('medications.DestructionMedications.noNegativeValues'));
        return;
      }

      if (numericValue > row.current_quantity) {
        showToast('error', t('error'), t('medications.DestructionMedications.quantityExceedsReceived'));
        return;
      }

      setQuantityInputs((prev) => ({
        ...prev,
        [rowId]: value
      }));

      setEditedData((prevData) => ({
        ...prevData,
        [rowId]: {
          ...prevData[rowId],
          medication_name: row.medication_name,
          rx: row.rx,
          cycle_start_date: row.cycle_start_date,
          filleDate: row.filleDate,
          index: row.index,
          medication_id: row.medication_id,
          quantity: value
        }
      }));

      return;
    }

    if (columnKey === 'quantityToDestroy' && value > row.current_quantity) {
      showToast('error', t('error'), t('medications.DestructionMedications.cannotDestroyMoreThanAvailable'));
      return;
    }

    setEditedData((prevData) => ({
      ...prevData,
      [rowId]: {
        ...prevData[rowId],
        medication_name: row.medication_name,
        rx: row.rx,
        cycle_start_date: row.cycle_start_date,
        filleDate: row.filleDate,
        [columnKey]: value,
        index: row.index,
        medication_id: row.medication_id
      }
    }));
  };

  const handleSort = (columnKey: string, direction: 'ascending' | 'descending') => {
    setSorting({ key: columnKey, direction });
  };

  const renderCellContent = (index: number, column: any, data: any[]) => {
    const row = data[index];
    const rowId = getRowId(row);
    const value = editedData[rowId]?.[column.key] ?? row[column.key];

    if (column.key === 'quantity') {
      return (
        <td key={column.key} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
          <div className="flex flex-col items-center">
            <Input
              type="number"
              onChange={(e) => handleCellChange(index, column.key, e.target.value, row)}
              value={quantityInputs[rowId] || ''}
              min={0}
              className="w-24"
            />
            <span className="text-xs text-gray-500 mt-1">
              {t('medications.DestructionMedications.total')}: {row.current_quantity ?? 0}
            </span>
          </div>
        </td>
      );
    }

    return (
      <td key={column.key} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
        {column.type === 'medication' ? (
          row[column.key]
        ) : column.type === 'date' || column.type === 'calendar' ? (
          <DateInputWithCalendar
            value={value || ''}
            onChange={(selectedDate) => handleCellChange(index, column.key, selectedDate, row)}
            defaultValue={value ? formatDate(value) : ''}
          />
        ) : column.editable ? (
          <Input
            type={column.type === 'number' ? 'number' : 'text'}
            onChange={(e) => handleCellChange(index, column.key, e.target.value, row)}
            value={value}
            min={0}
            className="w-24"
          />
        ) : (
          value || ''
        )}
      </td>
    );
  };

  const formatDateForMySQL = (date: string | Date | null): string | null => {
    if (!date) return null;
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return dateObj.toISOString().slice(0, 19).replace('T', ' ');
  };

  const handleSave = async () => {
    const editedEntries = Object.entries(editedData).map(([rowId, entry]) => ({
      ...entry,
      date_filled: formatDateForMySQL(entry.date_filled),
      disposal_date: formatDateForMySQL(entry.disposal_date),
      quantity: quantityInputs[rowId] || null
    }));

    if (editedEntries.length === 0) {
      showToast(
        'info',
        t('medications.DestructionMedications.warning'),
        t('medications.DestructionMedications.noChangesToSave')
      );
      return;
    }

    try {
      const response = await dispatch(disposeMedication(editedEntries));

      if (response.error) {
        showToast('error', t('error'), t('medications.DestructionMedications.errorDestroyingMedications'));
      } else {
        showToast('success', t('success'), t('medications.DestructionMedications.medicationsDestroyed'));
        setEditedData({});
        setQuantityInputs({});
        dispatch(
          fetchGetAllMedicationsByDestroyed({
            residentId: params.r || 'all-residents',
            filter: filters.filter,
            page: currentPage,
            limit: pageSize
          })
        );
      }
    } catch (error) {
      showToast('error', t('error'), t('medications.DestructionMedications.errorDestroyingMedications'));
    }
  };

  return (
    <div className="flex flex-col gap-4 my-4">
      <TableCustomVirtoso
        data={medicationsAllMedicationsByDestroyed}
        columns={columnsDestructionMedications}
        renderCellContent={renderCellContent}
        onSort={handleSort}
        additionalButtons={undefined}
        hasMore={false}
        isLoading={false}
      />
      <div className="flex justify-end mt-4">
        <Button onClick={handleSave} className="px-6 py-2" disabled={Object.keys(editedData).length === 0}>
          {t('medications.DestructionMedications.saveChanges')}
        </Button>
      </div>
    </div>
  );
};

export default FormDestruction;
