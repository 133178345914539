import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const TrackerHygieneService = {
    getDayTrackerHygiene: async (data: any) => {
        try {
            return await axios.post('trackerHygiene/getDayTrackerHygiene', data);
        } catch (error: any) {
            throw new Error('Error fetching Hygiene: ' + error.message);
        }
    }
}

export default TrackerHygieneService;
