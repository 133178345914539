import ProfileImageService from '@/modules/staff/infrastructure/services/ProfileImage';
import { create } from 'zustand';

type ProfileImage = {
  profileImageURL: string | null;
  status: 'idle' | 'loading' | 'failed';
  error: any | null;

  getProfileImage: (staff_id: string) => Promise<any>;
  setProfileImage: (staff_id: string, data: any) => Promise<any>;
  deleteProfileImage: (staff_id: string) => Promise<any>;
};

export const useProfileImageStore = create<ProfileImage>((set) => ({
  profileImageURL: null,
  status: 'idle',
  error: null,

  getProfileImage: async (staff_id) => {
    set({ status: 'loading' });
    try {
      const response = await ProfileImageService.getProfileImage(staff_id);
      set({ status: 'idle', profileImageURL: response.data });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error?.response?.data || error.message });
      throw new Error(error?.response?.data?.message || error.message);
    }
  },

  setProfileImage: async (staff_id, data) => {
    set({ status: 'loading' });
    try {
      const response = await ProfileImageService.setProfileImage(staff_id, data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error?.response?.data || error.message });
      throw new Error(error?.response?.data?.message || error.message);
    }
  },

  deleteProfileImage: async (staff_id) => {
    set({ status: 'loading' });
    try {
      const response = await ProfileImageService.deleteProfileImage(staff_id);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error?.response?.data || error.message });
      throw new Error(error?.response?.data?.message || error.message);
    }
  }
}));
