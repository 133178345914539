import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const StaffStatusService = {
  changeStaffStatus: async (staff_id: string, status: string) => {
    const response = await axios.post(`/staff/${staff_id}/status`, { status });
    return response;
  },

  getInactiveStaff: async (locationId: string | undefined) => {
    const response = await axios.get(`/staff/inactive${locationId ? `?location_id=${locationId}` : ''}`);
    return response;
  }
};

export default StaffStatusService;
