import * as FAIcons from 'react-icons/fa';

import { Card, CardContent } from '@/common/presentation/components/ui/card';
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from '@/store/store';


function TrackerPage() {
  const navigate = useNavigate();
  const { configurations, isLoading } = useSelector((state: RootState) => state.configurations);

  const subMenuTrackers = configurations?.menus.filter(
    (m: any) => m.path === '/tracker'
  )?.map((m: any) => m.submenus)[0] ?? [];


  /*
    Si no salen los iconos correr esta migración:
    UpdateMenuIconsSeeder
  */
  return (
    <>
      {isLoading &&
        <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
          <Spinner size={'lg'} />
        </div>
      }

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 gap-y-16 p-8 mt-24">
        {!isLoading && subMenuTrackers?.map((item: any, index: string) => {
          const IconTracker = FAIcons[item.icon as keyof typeof FAIcons];

          return (
            <Card
              key={index}
              className="relative flex flex-col items-center p-4 border-2 border-primary cursor-pointer"
              onClick={() => navigate(item.path)}
            >
              <div className="absolute -top-8 flex items-center justify-center w-16 h-16 mb-2 border-2 border-primary rounded-full bg-white">
                <div className="text-2xl">
                  {IconTracker && <IconTracker />}
                </div>
              </div>
              <CardContent className="mt-8 text-sm text-center font-semibold text-2xl">
                {item.name}
              </CardContent>
            </Card>
          );
        })}
      </div>
    </>
  );
}

export default TrackerPage