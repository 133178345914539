import { TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { useFormsCardsStore } from '@/modules/staff/domain/stores/profile/formCards';
import { UserRoundCog } from 'lucide-react';
import { useEffect } from 'react';
import { FormCards } from './FormCards';

export const StaffTab = () => {
  return (
    <TabsTrigger value="staff" className="w-full flex items-center justify-start gap-2">
      <UserRoundCog />
      Staff
    </TabsTrigger>
  );
};

export const StaffContent = () => {
  const { formCardsToShow, getFormCardsToShow } = useFormsCardsStore();

  useEffect(() => {
    getFormCardsToShow();
  }, [getFormCardsToShow]);

  return (
    <div className="w-full">
      <div className="">
        <FormCards defaultValues={formCardsToShow} />
      </div>
    </div>
  );
};
