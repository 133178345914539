import React from 'react';
import { Row } from "@tanstack/react-table";
import { TrackerCleaningTaskEditDialog } from './TrackerCleaningTaskEditDialog';
import { TrackerCleaningTaskDeleteDialog } from './TrackerCleaningTaskDeleteDialog';
import { TrackerCleaningSummaryEditDialog } from './TrackerCleaningSummaryEditDialog';
import { TrackerCleaningSummaryDeleteDialog } from './TrackerCleaningSummaryDeleteDialog';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEditClick: (id: number, name: string, status: string) => void;
  handleDeleteClick: (id: string) => void;
}

const RenderSummaryActionsColumn: React.FC<RenderActionsColumnProps> = (rowData) => {

  return (
    <div className='flex'>
      <TrackerCleaningSummaryEditDialog summary={rowData} />
      <TrackerCleaningSummaryDeleteDialog id={rowData.id} />
    </div>
  );
};

export default RenderSummaryActionsColumn;
