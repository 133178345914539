import { AppDispatch, RootState } from "@/store/store";
import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { cleanShareNote, getReportShareNote } from "../slices/ReportsSlice";
import { FilterX, Loader, Search } from "lucide-react";
import { format } from "date-fns";
import { getContactsNote } from "@/modules/notes/presentation/slices/NoteSlice";
import { getStaffByLocation } from "@/modules/staff/presentation/slices/staffSlice";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/common/presentation/components/ui/select';
import { useCallback, useEffect, useState } from "react";
import { useDateHelpers } from "@/utils/helpers/dates.helper";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TableCustomVirtuoso from "@/common/presentation/components/Table/TableCustomVirtoso";
import useRouteParams from "@/common/hooks/RouteParamsHook";

function ReportShareNote() {

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { formatDate } = useDateHelpers();
    const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
    const contacts = useSelector((state: RootState) => state.notes.contacts) || [];
    const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
    const sharedNotes = useSelector((state: RootState) => state.reports.sharenote.payload);
    const metaShareNote = useSelector((state: RootState) => state.reports.sharenote.meta);

    // Hooks
    const { params } = useRouteParams();
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [dataFilters, setDataFilters] = useState<[string, string][]>([]);
    const [residentSelected, setResidentSelected] = useState<string>();
    const [contactSelected, setContactSelected] = useState<string>();
    const [staffSelected, setStaffSelected] = useState<string>();

    useEffect(() => {
        dispatch<any>(getContactsNote());
        dispatch(getStaffByLocation(params.l === 'all-locations' ? undefined : params.l));
    }, []);

    useEffect(() => {
        if (metaShareNote && metaShareNote.page >= metaShareNote.lastPage) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [metaShareNote]);

    const loadMore = () => {
        if (!hasMore || isLoading) return;
        getData();
    }

    const getData = async (init: boolean = false) => {
        setIsLoading(true);
        let page = (init ? 1 : (currentPage + 1));
        setCurrentPage(page);
        await dispatch(getReportShareNote({ filters: dataFilters, nextPage: `${page}` }))
        setIsLoading(false);
    }

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const columnsreport = [
        { key: 'posted_info', labelTranslationKey: t('reports.sharedNote.posted_info'), value: 'posted_info' },
        { key: 'html_content', labelTranslationKey: t('reports.sharedNote.html_content'), value: 'html_content' },
        { key: 'resident_name', labelTranslationKey: t('reports.sharedNote.resident_name'), value: 'resident_name' },
        { key: 'email', labelTranslationKey: t('reports.sharedNote.email'), value: 'email' },
        { key: 'contact_name', labelTranslationKey: t('reports.sharedNote.contact_name'), value: 'contact_name' },
        { key: 'created_at', labelTranslationKey: t('reports.sharedNote.shared_date'), value: 'created_at' },
        { key: 'comment', labelTranslationKey: t('reports.sharedNote.comment'), value: 'comment' },
        { key: 'who_shared', labelTranslationKey: t('reports.sharedNote.who_shared'), value: 'who_shared' },
    ];

    const findOptionList = (value: string, list: any[], isContact: boolean = false) => {
        let option = list.find(s => s.id === value);
        if (option) {
            return isContact ? option.name : `${option.first_name} ${option.last_name}`;
        }
    }

    const clearFilter = () => {
        dispatch(cleanShareNote());
        setDataFilters([]);
        setCurrentPage(1);
        setHasMore(true);
        setResidentSelected(undefined);
        setContactSelected(undefined);
        setStaffSelected(undefined);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentPage(1);
        dispatch(cleanShareNote());
        let filters: [string, string][] = [];
        if (residentSelected && residentSelected != '') {
            filters.push(['resident', residentSelected])
        }
        if (contactSelected && contactSelected != '') {
            filters.push(['contact', contactSelected])
        }
        if (staffSelected && staffSelected != '') {
            filters.push(['staff', staffSelected])
        }
        setDataFilters(filters);
    }

    useEffect(() => {
        getData(true);
    }, [dataFilters]);

    const handleFilter = useCallback((searchText: string) => {
        console.log('sear', searchText);
        let filters: [string, string][] = [...dataFilters];
        let index = filters.findIndex(it => it[0] == 'search');
        if (index >= 0) {
            filters[index][1] = searchText;
        } else {
            filters.push(['search', searchText])
        }
        setDataFilters(filters);
    }, [dispatch]);

    return (
        <Card className="border-t-4 border-t-primary/80 mt-24">
            <CardHeader>
                <CardTitle className="flex flex-row items-center text-lg font-bold">
                    <span className="font-bold text-muted-foreground dark:text-white">{t('reports.sharedNote.title')} </span>
                </CardTitle>
            </CardHeader>
            <CardContent>

                {/* Residente, cantacto y staff */}
                <form onSubmit={handleSubmit} className="grid grid-cols-4 grid-flow-col gap-5 mb-4">
                    <div className="input-group">
                        <Select value={residentSelected} onValueChange={(value: string) => setResidentSelected(value)}>
                            <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                {residentSelected ? (
                                    <SelectValue>
                                        {findOptionList(residentSelected, residents)}
                                    </SelectValue>
                                ) : t('reports.sharedNote.searchResident')}
                            </SelectTrigger>
                            <SelectContent>
                                {residents.map((member) => (
                                    <SelectItem key={member.id} value={member.id}>
                                        {member.first_name} {member.last_name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="input-group">
                        <Select value={contactSelected} onValueChange={(value: string) => setContactSelected(value)}>
                            <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                {contactSelected ? (
                                    <SelectValue>
                                        {findOptionList(contactSelected, contacts, true)}
                                    </SelectValue>
                                ) : t('reports.sharedNote.searchContact')}
                                <SelectValue>
                                </SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                                {contacts.map((member) => (
                                    <SelectItem key={member.id} value={member.id}>{member.name} ({member.email})</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="input-group">
                        <Select value={staffSelected} onValueChange={(value: string) => setStaffSelected(value)}>
                            <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0">
                                {staffSelected ? (
                                    <SelectValue>
                                        {findOptionList(staffSelected, staff)}
                                    </SelectValue>
                                ) : t('reports.sharedNote.searchStaff')}
                            </SelectTrigger>
                            <SelectContent>
                                {staff.map((member) => (
                                    <SelectItem key={member.id} value={member.id}>
                                        {member.first_name} {member.last_name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div>
                        <Button type="submit" disabled={isLoading} className="ml-auto mr-3">
                            {isLoading ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : <Search className="h-4 w-4 mr-2" />}
                            {!isLoading && t('reports.sharedNote.search')}
                        </Button>

                        <Button type="button" variant="outline" className="hover:bg-primary/5 gap-2" onClick={clearFilter}>
                            {<FilterX className="h-4 w-4 mr-2" />} {t('reports.sharedNote.clear')}
                        </Button>
                    </div>
                </form>

                <TableCustomVirtuoso
                    data={sharedNotes}
                    columns={columnsreport}
                    renderCellContent={(index, column, data) => {
                        const row = data[index];
                        switch (column.key) {
                            case 'posted_info':
                                return (
                                    <>
                                        <span>{formatDate(row.note.created_at, false)}</span>
                                        <br />
                                        <span>{row.note.posted_by}</span>
                                    </>
                                );
                            case 'created_at':
                                return (
                                    <>
                                        <span>{formatDate(row[column.key], false)}</span>
                                        <br />
                                        <span>{format(new Date(row[column.key]), 'hh:mm a')}</span>
                                    </>
                                );
                            case 'resident_name':
                                return row.note && row.note[column.key] ? capitalizeWords(row.note[column.key]) : '';
                            case 'contact_name':
                                return row.contact && row.contact[column.key] ? capitalizeWords(row.contact[column.key]) : '';
                            case 'who_shared':
                                return row.shared_by && row.shared_by[column.key] ? capitalizeWords(row.shared_by[column.key]) : '';
                            case 'html_content':
                                return row.note && row.note[column.key] ? <span dangerouslySetInnerHTML={{ __html: row.note[column.key] }}></span> : '';
                            default:
                                return row[column.key];
                        }
                    }}
                    additionalButtons={<></>}
                    onSort={() => { }}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    key={params.r}
                    isLoading={false}
                    onFilterChange={handleFilter}
                />
            </CardContent>
        </Card>

    )
}

export default ReportShareNote;
