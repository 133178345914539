import useRouteParams from "@/common/hooks/RouteParamsHook";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as trackerActions from '@/modules/tracker/presentation/slices/TrackerSlice';
import { RootState } from "@/store/store";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "@/common/presentation/components/ui/table"
import { format } from "date-fns";
import { DeleteHospitalizationsOutingsReportDialog } from "./DeleteHospitalizationsOutingsReportDialog";
import { HospitalizationOutingsEditDialog } from "./HospitalizationOutingsEditDialog";
import { Search } from "lucide-react";
import { t } from "i18next";
import { useLocation } from "@/modules/locations/infrastructure/providers/LocationContextProvider";
  
export function HospitalizationOutingsTable() {
    const { params, setParams } = useRouteParams();
    const tracker = useSelector((state: RootState) => state.tracker);
    const dispatch = useDispatch();
    const { locationSelectedId } = useLocation();

    const fetchData = async () => {
        await dispatch(trackerActions.getTrackerOutingsHospitalization(
            {
                location_id: locationSelectedId
            }
        ));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
      <div>
          {
            tracker.hospitalizationReport.length ? (
              <Table>
                <TableHeader className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md">
                  <TableRow className="bg-primary/80 hover:bg-primary/80">
                    <TableHead className="text-white">Resident</TableHead>
                    <TableHead className="text-white">Date/Time</TableHead>
                    <TableHead className="text-white">Hospital Name</TableHead>
                    <TableHead className="text-white">Reason</TableHead>
                    <TableHead className="text-white">Date Back</TableHead>
                    <TableHead className="text-white">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {
                    tracker.hospitalizationReport.length && tracker.hospitalizationReport.map(
                      element =>
                        <TableRow>
                          <TableCell>{element.data.resident_id.first_name + " " + element.data.resident_id.last_name}</TableCell>
                          <TableCell>{format(new Date(element.data.date), "dd/MM/yyyy hh:mm a")}</TableCell>
                          <TableCell>{element.data.hospitalName}</TableCell>
                          <TableCell>{element.data.hospitalizationReason}</TableCell>
                          <TableCell>{format(new Date(element.data.dateBack), "dd/MM/yyyy hh:mm a")}</TableCell>
                          <TableCell className="flex">
                            <HospitalizationOutingsEditDialog data={element}/>
                            <DeleteHospitalizationsOutingsReportDialog id={element.id} id_data={element.data.id}/>
                          </TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            ) : (
            <div className="flex flex-col w-100 justify-center align-center">
              <div className="flex justify-center items-center">
                  <Search className="text-center w-5 mb-2" />
              </div>

              <h1 className="text-center">
                  {t("cleaning.report.summary.table.notFound")}
              </h1>
            </div>
            )
          }
      </div>
    );
}