import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

interface LocationContextState {
  locationSelectedId: string;
  setLocationSelectedId: (locationId: string) => void;
}

interface ContextProps {
  children: ReactNode;
  storageKey?: string;
  defaultLocationId?: string;
}

export const LocationContext = createContext<LocationContextState>({
  locationSelectedId: 'all-locations',
  setLocationSelectedId: () => null
});

export const LocationProvider: React.FC<ContextProps> = ({
  children,
  storageKey = 'location_selected',
  defaultLocationId = 'all-locations'
}) => {
  const [locationSelectedId, setLocationSelectedId] = useState<string>(
    () => (sessionStorage.getItem(storageKey) as string) || defaultLocationId
  );

  useEffect(() => {
    sessionStorage.setItem('location_selected', locationSelectedId ?? defaultLocationId);
  }, [locationSelectedId]);

  const contextValue = useMemo<LocationContextState>(
    () => ({
      locationSelectedId: locationSelectedId ?? defaultLocationId,
      setLocationSelectedId
    }),
    [locationSelectedId]
  );

  return <LocationContext.Provider value={contextValue}>{children}</LocationContext.Provider>;
};

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (context === undefined) throw new Error('useLocation must be used within a LocationProvider');
  return context;
};
