import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerSleepService from '../../infrastructure/services/TrackerSleepService';
import {
    IUpdateSleepData,
    IUpdateNapSleep,
    ITrackerSleepFormData
} from '../../infrastructure/interfaces/SleepInterface';

interface ISleepReport {
    id: string;
    detail: string;
    isNap: boolean;
    location_id: string;
    resident_id: string;
    type?: string;  
    value?: string; 
    end_up?: string; 
    start_up?: string; 
}

export const getTrackerSleep = createAsyncThunk("trackerSleep/getTrackerSleep",
    async (params: { location_id: string; resident_id: string; per_page: number; page: number }) => {
        const response = await TrackerSleepService.getTrackerSleep(
            params.location_id,
            params.resident_id,
            params.per_page,
            params.page);
        return response;
    });

export const getDayTrackerSleep = createAsyncThunk("trackerSleep/getDayTrackerSleep",
    async (params: { location_id: string; resident_id: string }) => {
        const response = await TrackerSleepService.getDayTrackerSleep(
            params.location_id,
            params.resident_id);
        return response;
    });

export const getTrackerNapSleep = createAsyncThunk("trackerSleep/getTrackerNapSleep",
    async (params: { location_id: string; resident_id: string; per_page: number; page: number }) => {
        const response = await TrackerSleepService.getTrackerNapSleep(
            params.location_id,
            params.resident_id,
            params.per_page,
            params.page
        );
        return response;
    });


export const saveTrackerSleep = createAsyncThunk("trackerSleep/saveTrackerSleep", async (body: ITrackerSleepFormData) => {
    const response = await TrackerSleepService.saveTrackerSleep(body);
    return response;
});

export const updateTrackerNapSleep = createAsyncThunk("trackerSleep/updateTrackerNapSleep", async (body: IUpdateNapSleep) => {
    const response = await TrackerSleepService.updateTrackerNapSleep(body);
    return response;
});

export const updateTrackerSleep = createAsyncThunk("trackerSleep/updateTrackerSleep", async (body: IUpdateSleepData) => {
    const response = await TrackerSleepService.updateTrackerSleep(body);
    return response;
});

export const deleteTrackerNapSleep = createAsyncThunk("trackerSleep/deleteTrackerNapSleep", async (id: string) => {
    const response = await TrackerSleepService.deleteTrackerNapSleep(id);
    return response;
});


interface TrackerSleepState {
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    sleepReport: ISleepReport[];
    saveSleepResponse?: {
        message: string;
        data: ISleepReport[];
    };
}

const initialState: TrackerSleepState = {
    status: 'idle',
    error: null,
    sleepReport: []
};


const trackerSleep = createSlice({
    name: 'trackerSleep',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getTrackerSleep.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getTrackerSleep.fulfilled, (state, action) => {
            state.status = 'idle';
            state.sleepReport = action.payload;
        });

        builder.addCase(getTrackerSleep.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(getDayTrackerSleep.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getDayTrackerSleep.fulfilled, (state, action) => {
            state.status = 'idle';
            state.sleepReport = action.payload;
        });

        builder.addCase(getDayTrackerSleep.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(getTrackerNapSleep.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(getTrackerNapSleep.fulfilled, (state, action) => {
            state.status = 'idle';
            state.sleepReport = action.payload;
        });

        builder.addCase(getTrackerNapSleep.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(saveTrackerSleep.fulfilled, (state, action) => {
            state.status = 'idle';
            state.saveSleepResponse = action.payload;
        });

        builder.addCase(saveTrackerSleep.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(updateTrackerNapSleep.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(updateTrackerNapSleep.fulfilled, (state, action) => {
            state.status = 'idle';
            state.saveSleepResponse = action.payload;
        });

        builder.addCase(updateTrackerNapSleep.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(updateTrackerSleep.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(updateTrackerSleep.fulfilled, (state, action) => {
            state.status = 'idle';
            state.saveSleepResponse = action.payload;
        });

        builder.addCase(updateTrackerSleep.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

        builder.addCase(deleteTrackerNapSleep.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });

        builder.addCase(deleteTrackerNapSleep.fulfilled, (state, action) => {
            state.status = 'idle';
            state.sleepReport = state.sleepReport.filter(
                (item) => item.id !== action.meta.arg
            );
        });

        builder.addCase(deleteTrackerNapSleep.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || null;
        });

    }
});

export default trackerSleep.reducer;