import StaffStatusService from '@/modules/staff/infrastructure/services/StaffStatus';
import { create } from 'zustand';

type StaffStatus = {
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  changeStaffStatus: ({ staff_id, status }: { staff_id: string; status: string }) => Promise<any>;
};

export const useStaffStatusStore = create<StaffStatus>((set) => ({
  status: 'idle',
  error: null,

  changeStaffStatus: async ({ staff_id, status }) => {
    set({ status: 'loading' });
    try {
      const response = await StaffStatusService.changeStaffStatus(staff_id, status);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.errors || error.message;
      set({ status: 'failed', error: errorMessage });
      throw new Error(JSON.stringify(errorMessage));
    }
  }
}));
