import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import React from 'react';

interface UserI {
  id: string;
  first_name: string;
  last_name: string;
  profile_url: string;
}
interface IProps {
  users: UserI[];
  limit: number;
}

const UsersPreview: React.FC<IProps> = ({ users, limit }) => {
  const displayUsers = users.slice(0, limit);
  const remaining = Math.max(0, users.length - limit);
  return (
    <div className="flex -space-x-5 md:justify-end">
      {displayUsers.length > 0 ? (
        <>
          {displayUsers.map((user: UserI) => (
            <Avatar key={user.id} className="border-2 border-background ring-0">
              <AvatarImage src={user.profile_url ?? undefined} className="h-full w-full object-cover" />
              <AvatarFallback>
                {`${user.first_name} ${user.last_name}`
                  .split(' ')
                  .map((chunk) => chunk[0])
                  .join('')}
              </AvatarFallback>
            </Avatar>
          ))}
          {remaining > 0 && (
            <Avatar className="border-2 border-background bg-muted">
              <AvatarFallback className="bg-muted text-muted-foreground">+{remaining}</AvatarFallback>
            </Avatar>
          )}
        </>
      ) : (
        <Avatar className="border-2 border-background bg-muted">
          <AvatarFallback className="bg-muted text-muted-foreground">0</AvatarFallback>
        </Avatar>
      )}
    </div>
  );
};

export default UsersPreview;
