import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { Navigate } from 'react-router-dom';
import { toast } from "@/common/presentation/components/ui/use-toast.ts";
import { useTranslation } from 'react-i18next';

interface IMenuProtectedRouteProps {
    parentRoute: string;
    childrenRoute?: string;
    navigateTo: string;
    element: JSX.Element;
}

export const MenuProtectedRoute: React.FC<IMenuProtectedRouteProps> = ({
    parentRoute,
    childrenRoute,
    navigateTo,
    element
  }) => {
    const { configurations, isLoading } = useSelector((state: RootState) => state.configurations);
    const { t } = useTranslation();
    if (!isLoading && configurations?.menus) {
        
      const menu = configurations.menus.some((menu: any) => {
        if (menu?.submenus && menu?.path == parentRoute) {
            if(childrenRoute){
                return menu.submenus.some((subMenu: any) => subMenu.path === childrenRoute);
            }
            return true;
        }
      });
  
      if (menu) {
        return element;
      }
      
      toast({
        description: t('menuProtectedRoute.toast'),
        className: 'bg-red-400 text-white z-[99999]',
        duration: 10000 
      });

      return <Navigate to={navigateTo} replace />;

    }
  
  
  }
  