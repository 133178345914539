import StaffService from '@/modules/staff/infrastructure/services/StaffService';
import { create } from 'zustand';
import { CardsToShow } from '@/modules/staff/domain/models/profile/formCards';

type FormsCardsState = {
  formCardsToShow: CardsToShow | null;
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  getFormCardsToShow: () => Promise<any>;
  setFormCardsToShow: (formCards: CardsToShow) => Promise<void>;
};

export const useFormsCardsStore = create<FormsCardsState>((set) => ({
  formCardsToShow: null,
  status: 'idle',
  error: null,

  getFormCardsToShow: async () => {
    set({ status: 'loading' });
    try {
      const response = await StaffService.getFormCardsToShow();
      set({ status: 'idle', formCardsToShow: response.data.data });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  setFormCardsToShow: async (formCards: CardsToShow) => {
    set({ status: 'loading' });
    try {
      const response = await StaffService.setFormCardsToShow(formCards);
      set({ status: 'idle', formCardsToShow: response.data.data });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
