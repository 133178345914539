import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ConfigurationServices from '../../infrastructure/services/ConfigurationServices';

export interface ConfigurationState {
  configurations: any | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: ConfigurationState = {
  configurations: null,
  isLoading: true,
  error: null
};

export const updateAccentColor = createAsyncThunk('configurations/updateAccentColor', async (data: any) => {
  try {
    const response = await ConfigurationServices.updateAccentColor({ ...data });
    return response.data;
  } catch (error: any) {
    const message = error.response.data.message;
    throw new Error(message !== undefined ? message : 'Error updating accent color: ' + error);
  }
});

const configurationSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setConfigurations: (state, action) => {
      state.configurations = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setConfigurationsLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setConfigurationsError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.configurations = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateAccentColor.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateAccentColor.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.configurations = action.payload.configurations;
    });
    builder.addCase(updateAccentColor.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || null;
    });
  }
});

export const { setConfigurations, setConfigurationsLoading, setConfigurationsError } = configurationSlice.actions;
export default configurationSlice.reducer;
