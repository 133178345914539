import { useNavigate, useParams } from 'react-router-dom';
import { EmarNavigation } from '@/modules/emar/presentation/components/Navigation';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { EmarMedicationsCard } from '@/modules/emar/presentation/components/EmarMedicationsCard';
import { useEffect } from 'react';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';

export const EmarDetailPage = () => {
  const { locationId } = useParams();
  const { params, setParam } = useRouteParams();
  const navigate = useNavigate();

  const onLocationSelect = (selectedLocationId: string) => {
    const selectedLocation = selectedLocationId !== 'all-locations' ? selectedLocationId : 'all-locations';
    const emar = params.e === 'routine' ? 'routine' : 'prn';
    const url = `/emar/${selectedLocation}?e=${emar}&r=${params.r || 'all-residents'}`;
    navigate(url);
  };

  useEffect(() => {
    if (!params?.r) {
      setParam('r', 'all-residents');
    }
  }, [params?.r]);

  return (
    <div className="pt-4 flex flex-col gap-4">
      <LocationSelector selections={false} quickView={true} setUrlParams={false} onSelect={onLocationSelect} />

      <EmarNavigation location={locationId || 'all-locations'} emar={params?.e || 'routine'} />
      <ResidentSelector
        locationId={locationId || 'all-locations'}
        residentId={params?.r || 'all-residents'}
        setLocationParams={false}
      />
      <EmarMedicationsCard />
    </div>
  );
};
