import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/common/presentation/components/ui/form';
import { cn } from '@/lib/utils';
import { QuillJS } from '@/modules/notes/presentation/components/common/quilljs';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { toast } from '@/common/presentation/components/ui/use-toast';

interface Props {
  className?: string;
}

const formSchema = z.object({
  other_form_notes: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

const rolesToValidate = ['Super Administrator', 'Executive'];

export const OtherForm = ({ className }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMember);
  const { user } = useSelector((state: RootState) => state.auth);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { other_form_notes: '' },
    mode: 'onChange'
  });

  useEffect(() => {
    if (staffMember) {
      form.reset({ other_form_notes: staffMember?.other_data?.other_form_notes || '' });
    }
  }, [staffMember, form]);

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  const onSubmitForm = async (data: FormValues) => {
    const payload = {
      staff_id: staffMember?.id,
      data
    };

    const response = await dispatch(staffMemberFormActions.updateOtherForm(payload));

    if (response.payload) {
      toast({
        description: 'Notes member staff updated successfully'
      });

      form.reset(data);
    }

    if (response.meta.requestStatus === 'rejected') {
      toast({
        description: 'Error updating notes member staff'
      });
    }
  };

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Other">
            <div className="col-span-4">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmitForm)}>
                  <div className="flex flex-col gap-4 px-2 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm">
                    <FormField
                      control={form.control}
                      name="other_form_notes"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormControl>
                              <QuillJS
                                onChange={(value) => {
                                  field.onChange(value?.html_content || '');
                                }}
                                value={field.value}
                                disabled={staffFormStatus === 'loading' || !isAdmin}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  {form.formState.isDirty && (
                    <div className="mt-6 flex justify-end space-x-2">
                      <Button type="submit" disabled={staffFormStatus === 'loading'}>
                        {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                        Update
                      </Button>
                    </div>
                  )}
                </form>
              </Form>
            </div>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
