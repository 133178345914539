import { AppDispatch, RootState } from '@/store/store';
import { Card, CardContent } from '../../../../../common/presentation/components/ui/card';
import { cn } from '@/lib/utils';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { ImageCard } from '../../../../../common/presentation/components/Selectors/ImageCard';
import { Input } from '../../../../../common/presentation/components/ui/input';
import { ScrollArea, ScrollBar } from '../../../../../common/presentation/components/ui/scroll-area';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { toast } from '../../../../../common/presentation/components/ui/use-toast';
import { useDispatch, useSelector } from 'react-redux';
import { X } from 'lucide-react';
import NoteHelperService from '@/modules/notes/infrastructure/services/NoteHelperService';
import React, { useEffect, useState } from 'react';
import Title from '@/common/presentation/components/Title/Title';

interface StaffSelectorProps {
  onSelect?: (id: string) => void;
  className?: React.ReactNode;
  locationId?: string;
  staffId?: string[];
  setCrudState: (crudState: CrudState) => void;
}

export const StaffSelector = ({
  onSelect,
  className,
  locationId = 'all-locations',
  staffId = [],
  setCrudState
}: StaffSelectorProps) => {

  const { staff, status } = useSelector((state: RootState) => state.staff.allStaff);
  const [selectedUser, setSelectedUser] = useState(staffId || []);
  const [valueSearch, setValueSearch] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  let listStaff = staff.filter(staff => {
    let valueLowerCase = valueSearch.toLowerCase();
    return `${staff.first_name} ${staff.last_name}`.toLowerCase().includes(valueLowerCase)
  });

  const getStaff = async () => {
    const response: any = await dispatch(
      getStaffByLocation(locationId === 'all-locations' ? undefined : locationId)
    );
    if (response.error) {
      toast({
        description: response.error.message,
        variant: 'destructive'
      });
    }
  };

  const onSelectedUser = (id: string) => {
    const staff = [...selectedUser];
    const index = staff.indexOf(id);
    if (index !== -1) {
      staff.splice(index, 1);
    } else {
      staff.push(id);
    }

    setSelectedUser(staff);
    onSelect?.(id);
  };

  useEffect(() => {
    getStaff();
  }, [dispatch]);

  const closeForm = () => {
    setCrudState(NoteHelperService.defineState({ showingStaffNotes: true }));
  }

  return (
    <div className={cn(className)}>
      <div className="flex flex-row items-center text-lg font-bold justify-between mb-3">
        <Title className='text-primary text-xl'>Staff</Title>

        <div className='flex justify-end items-center'>
          {staff.length > 0 && (
            <Input onChange={(e) => setValueSearch(e.target.value)} placeholder="Filter Staff" className="w-full md:w-[350px] mt-4 md:mt-0 ml-auto" />
          )}
          <X className="h-5 w-5 flex-1 gap-4 cursor-pointer ml-3" onClick={closeForm} />
        </div>
      </div>

      <Card>
        <CardContent className='p-5 pb-0 bg-zinc-50 rounded-lg'>
          {status === 'loading' ? (
            <div className="flex items-center justify-start">
              <div className="flex flex-col items-center gap-2">
                <Skeleton className="h-20 w-20 rounded-full bg-primary/20" />
                <Skeleton className="h-4 w-16 mt-3 rounded-full bg-primary/20" />
                <Skeleton className="h-4 w-16 rounded-full bg-primary/20" />
              </div>
            </div>
          ) : (
            <ScrollArea>
              <div className="flex gap-4 mb-4">
                {listStaff.length === 0 && (
                  <div className="w-full flex flex-col gap-2 items-center justify-center">
                    <p>No staff found</p>
                  </div>
                )}

                {listStaff.length > 0 && (
                  <div className="flex gap-2">
                    {listStaff.map((staff, index) => (
                      <ImageCard
                        key={index}
                        item={{
                          id: staff.id,
                          firstName: staff.first_name,
                          lastName: staff.last_name,
                          image: staff.profile_url
                        }}
                        onSelect={() => onSelectedUser(staff.id)}
                        aspectRatio={'rounded'}
                        isSelected={selectedUser?.includes(staff.id)}
                      />
                    ))}
                  </div>
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
