import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/common/presentation/components/ui/input";
import { noteCategoriesLoading, saveOrUpdateNoteCategory } from '@/modules/settings/presentation/components/menus/notes/slices/NoteCategorySlice';
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { Switch } from "@/common/presentation/components/ui/switch";
import { Alert, AlertDescription } from "@/common/presentation/components/ui/alert";
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import { CheckCircle2 } from "lucide-react";
import CustomSelect from "@/common/presentation/components/CustomSelect/CustomSelect";

interface IDialogCreateOrUpdate {
    onChangeOpen: (open: boolean) => void;
    open: boolean;
    isUpdate?: boolean;
    onChangeIsUpdate?: (state: boolean) => void;
    dataUpdate?: {
        id?: string;
        category: string;
        type: string;
        status: string;
        availability: string;
    };
    onChangeGetData?: (currentPage: number) => void;
}

const FormSchema = z.object({
    category: z.string().min(1, { message: "Category name is required" }),
    type: z.string().min(1, { message: "Type is required" }),
    status: z.boolean()
});

type FormValues = z.infer<typeof FormSchema>;

export const DialogCreateOrUpdate: React.FC<IDialogCreateOrUpdate> = ({
    onChangeOpen,
    open,
    isUpdate = false,
    onChangeIsUpdate,
    dataUpdate,
    onChangeGetData
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [showSuccess, setShowSuccess] = useState(false);
    const { isLoading } = useSelector((state: RootState) => state.settings.noteCategory);

    const [selectedOption, setSelectedOption] = useState<any>({
        value: "residents",
        label: t('settings.select')
    });

    const form = useForm<FormValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            category: "",
            type: "resident",
            status: true
        }
    });

    const typeOptions = [
        { value: "resident", label: t('common.resident') },
        { value: "staff", label: t('common.staff') }
    ];

    const onSubmit = async (data: FormValues) => {
        try {
            const payload = {
                ...data,
                idUpdate: isUpdate ? dataUpdate?.id : undefined
            };

            dispatch(noteCategoriesLoading());
            const response = await dispatch(saveOrUpdateNoteCategory(payload));

            if (response.payload.result === 'ok') {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                    onChangeOpen(false);
                    onChangeIsUpdate?.(false);
                }, 3000);
                if (onChangeGetData) {
                    onChangeGetData(1);
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        if (open) {
            if (isUpdate && dataUpdate) {
                form.reset({
                    category: dataUpdate.category,
                    type: dataUpdate.type,
                    status: dataUpdate.status === 'active' ? true : false
                });

                const categoryOption = typeOptions.find((opt:any) => opt.value === dataUpdate.type);
                setSelectedOption(categoryOption);

            } else {
                form.reset({
                    category: "",
                    type: "resident",
                    status: true
                });
            }
        } else {
            onChangeIsUpdate?.(false);
        }
    }, [open, isUpdate, dataUpdate]);

    return (
        <CustomDialog
            height="auto"
            title={isUpdate ? t('common.update') : t('common.create')}
            width="30rem"
            newDialog={true}
            isOpen={open}
            onClose={() => onChangeOpen(false)}
            className="z-[999999] max-w-[92%] sm:max-w-[450px]"
            overFlow={true}
        >
            <div className="h-[350px]">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="h-[100%] space-y-4 flex flex-col justify-between">
                        {!isLoading && (

                                <div>
                                    {(dataUpdate?.availability == 'single_client' || !isUpdate) && //Client id null equal default category
                                        <>
                                            <FormField
                                                name="category"
                                                control={form.control}
                                                render={({ field }) => (
                                                    <FormItem className="pb-4">
                                                        <FormLabel>{t('common.category')}</FormLabel>
                                                        <FormControl>
                                                            <Input {...field} placeholder={t('settings.notes.category')} />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <div className="flex flex-col-reverse md:flex-row justify-between items-start">
                                                <FormField
                                                    control={form.control}
                                                    name="type"
                                                    render={({ field }) => (
                                                        <FormItem className="pb-4 w-full md:w-[50%]">
                                                            <FormLabel>{t('settings.notes.relatedTo')}</FormLabel>
                                                            <CustomSelect
                                                                value={selectedOption}
                                                                onChange={(val: any) => {
                                                                    if (val) {
                                                                        setSelectedOption(val);
                                                                        field.onChange(val.value);
                                                                    }
                                                                }}
                                                                options={typeOptions}
                                                                isSearchable={true}
                                                            />
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                        </>}

                                    <FormField
                                        control={form.control}
                                        name="status"
                                        render={({ field }) => (
                                            <FormItem className="pb-4">
                                                <div className="space-y-0.5">
                                                    <FormLabel>{t('settings.status')}</FormLabel>
                                                </div>
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={field.onChange}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                        )}

                        {isLoading && (
                            <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0]'>
                                <Spinner size={'lg'} />
                            </div>
                        )}

                        <div className="absolute bottom-0 left-0 right-0 p-4">
                            {showSuccess ? (
                                <Alert className="bg-green-50 border-green-200 mb-4">
                                    <CheckCircle2 className="h-4 w-4 text-green-600" />
                                    <AlertDescription className="text-green-600">
                                        {isUpdate ? t('settings.updatedSuccessfully') : t('settings.createdSuccessfully')}
                                    </AlertDescription>
                                </Alert>
                            ) : (
                                <div className="flex justify-end">
                                    <Button type="submit">
                                        {t('settings.save')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </form>
                </Form>
            </div>
        </CustomDialog>
    );
};