import { EditPage } from "@/modules/pharmacy/presentation/pages/EditPage";
import { PharmacyDashboardPage } from "@/modules/pharmacy/presentation/pages/PharmacyDashboardPage";
import { Route, Routes } from "react-router-dom";


const PharmaciesRouter = () => {
    return (
      <Routes>
        <Route path="/" element={<PharmacyDashboardPage/>} />
        <Route path="/update" element={<EditPage />} />
        <Route path="/update/:pharmacyId" element={<EditPage />} />
        {/* <Route path="/profile" element={<DashboardProfile />} /> */}
      </Routes>
    );
  };
  
  export default PharmaciesRouter;