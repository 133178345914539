import { useTranslation } from 'react-i18next';

const useOverviewRoutineColumns = () => {
  const { t } = useTranslation();

  const overviewRoutineColumns = [
    {
      key: 'dispensed_at',
      id: 'dispensed_at',
      labelTranslationKey: 'emar-routine.column-manage.dispensed_at',
      value: 'dispensed_at',
      visible: true,
      sortable: true,
      width: '20%',
    },
    {
      key: 'medication_name',
      id: 'medication_name',
      labelTranslationKey: 'emar-routine.column-manage.medication_name',
      value: 'medication_name',
      visible: true,
      sortable: true,
      width: '20%',
    },
    {
      key: 'resident',
      id: 'resident',
      labelTranslationKey: 'emar-routine.column-manage.resident',
      value: 'resident',
      visible: true,
      sortable: true,
      width: '20%',
    },
    {
      key: 'status',
      id: 'status',
      labelTranslationKey: 'emar-routine.column-manage.status',
      value: 'status',
      visible: true,
      sortable: true,
      width: '20%',
    },
    // {
    //     key: 'staff',
    //     id: 'staff',
    //     labelTranslationKey: 'emar-routine.column-manage.staff',
    //     value: 'staff',
    //     visible: true,
    //     sortable: true,
    //     width: '20%',
    //   },
    // {
    //   key: 'actions',
    //   labelTranslationKey: 'overview.actions',
    //   render: ({ row }) => <OverviewTableActions data={row?.original} />,
    //   visible: true,
    //   width: '20%',
    // },
  ];

  const columnsWithTranslation = overviewRoutineColumns.map((column) => ({
    ...column,
    label: t(column.labelTranslationKey), // Traducción aplicada a cada columna
  }));

  return columnsWithTranslation;
};

export default useOverviewRoutineColumns;
