import MenuService from '@/common/infrastructure/services/MenuService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export interface IMenuState {
  menuAvailable: boolean;
  isLoading: boolean;
  error: string | null;
}

const initialState: IMenuState = {
  menuAvailable: true,
  isLoading: true,
  error: null
};

export const getLocationMenusConfiguration = createAsyncThunk('configurations/getLocationMenus',
  async (locationId: string) => {
    try {
      const response = await MenuService.getLocationMenus(locationId);
      return response;
    } catch (error: any) {
      throw new Error(`Error getting location menus: ${error}`);
    }
  });

const MenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuAvailability(state, action) {
      state.menuAvailable = action.payload;
    }
  }
});

export const { setMenuAvailability } = MenuSlice.actions;
export default MenuSlice.reducer;