import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import TrackerHygieneType from './TrackerHygieneType';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, Trash, Ellipsis, Settings, Search, Loader2 } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { ImageCardTables } from '@/common/presentation/components/Selectors/ImageCardTables';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import useHygineTypeColumns from '../components/hygine/HygineTypeColumns';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import useHygineReportColumns from '../components/hygine/HygineReportColumns';
import RenderHygineReportActionsColumns from '../components/hygine/RenderHygineReportActionsColumns';
import { getDayHygiene, getHygiene } from '../slices/TrackerHygieneSlice';
import TrackerHygieneDetailed from '../components/hygine/TrackerHygieneDetailed';
import { current } from '@reduxjs/toolkit';
import LoadingWrapper from '@/common/presentation/components/LoadingWrapper/LoadingWrapper';
import QuickTracker from '@/common/presentation/components/QuickTracker/QuickTracker';
import QuickTrackerItem from '@/common/presentation/components/QuickTracker/interfaces/QuickTrackerItemInterface';

interface TypeI {
    id: string;
    name: string;
    status: 'active' | 'inactive';
}

interface TypesI {
    column_field: TypeI[];
    column_type: string[];
}

interface TrackerI {
    id: string;
    location_id: string;
    created_at: string;
    data: TrackerDataI;
}

interface TrackerDataI {
    type_name?: string;
    detail: string | null;
    location_id: string;
    type: string;
    value: string;
    id: string;
    resident: {
        id: string;
        first_name: string;
        last_name: string;
    };
    who: {
        id: string;
        first_name: string;
        last_name: string;
    };
    date: string;
}

function TrackerHygiene() {
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState<TypeI[]>([]);
    const [dataReport, setDatareport] = useState<TrackerI[]>([]);
    const [dataQuickTracker, setDataQuickTracker] = useState<any[]>([]);
    const dispatch = useDispatch();
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedHygiene, setSelectedHygiene] = useState<string>(params.e || 'form');
    const { residents } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [data, setData] = useState([]);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [selectedTab, setSelectedTab] = useState<string>("quick");
    const trackerHygiene = useSelector((state: RootState) => state.trackerHygiene);
    const { locationSelectedId } = useLocation();
    const { t } = useTranslation();
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    const [fields, setDialogField] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [hasMoreRows, setHasMoreRows] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [resetData, setResetData] = useState(false);
    const [loading, setLoading] = useState(false);
    const onSelectHygiene = (e: string) => {
        setParam('e', e);
        setSelectedHygiene(e);
    };
    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };
    const [hygineReportColumns] = useState(
        useHygineReportColumns().map((value) => {
            if (value.key === "actions") {
                return {
                    ...value,
                    render: (rowData: any) => RenderHygineReportActionsColumns(rowData, handleButtonOpenDialog.bind(this))
                };
            }

            return value;
        })
    );

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        fetchDayTrackerHygiene();
    }, []);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedHygiene('form');
        }

        if (!locationSelectedId || locationSelectedId.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    useEffect(() => {
        setDatareport([]);
        setCurrentPage(0);
        setHasMoreRows(true);
    }, [locationSelectedId]);

    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };

            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    useEffect(() => {
        if (trackerHygiene.hygienes) {
            const { types, trackerHygienes }: { types: any, trackerHygienes: any[] } = trackerHygiene.hygienes;

            if (types && types.column_field && types.column_field.length) {
                setTypes(
                    types.column_field.filter((value) => value.status === "active")
                );

                if (trackerHygienes && trackerHygienes.data.length) {
                    if (resetData) {
                        setDatareport(
                            trackerHygienes.data.map(row => ({
                                ...row,
                                data: {
                                    ...row.data,
                                    type_name: types.column_field.find(type => type.id === row.data.type)?.name || 'Unknown'
                                }
                        })));
                    } else {
                        const newData = trackerHygienes.data.map(row => ({
                            ...row,
                            data: {
                                ...row.data,
                                type_name: types.column_field.find(type => type.id === row.data.type)?.name || 'Unknown'
                            }
                        }));

                        setDatareport(prevData => [...prevData, ...newData]);
                    }
    
                    if (trackerHygienes.current_page >= trackerHygienes.last_page || trackerHygienes.total < data.length) {
                        setHasMoreRows(false);
                    } else {
                        setHasMoreRows(true);
                    }
                } else {
                    setDatareport([]);
                }
            } else {
                setTypes([]);
            }
        }
    }, [trackerHygiene.hygienes]);

    useEffect(() => {
        if (trackerHygiene.dayHygienes.data) {
            const { types, trackerHygienes }: { types: any, trackerHygienes: any[] } = trackerHygiene.dayHygienes.data;

            if (types && types.column_field && types.column_field.length) {
                setTypes(
                    types.column_field.filter((value) => value.status === "active") 
                    || []
                );

                if (trackerHygienes && trackerHygienes.length) {
                    setDataQuickTracker(
                        trackerHygienes.map(
                            (value) => (
                                {
                                    id: value.id,
                                    id_data: value.data.id,
                                    action: value.data.value,
                                    resident: value.data.resident,
                                    type: value.data.type
                                }
                            )
                        )
                    );
                } else {
                    setDataQuickTracker([]);
                }
            } else {
                setTypes([]);
            }
        }

        setLoading(false);
    }, [trackerHygiene.dayHygienes]);

    useEffect(() => {
        if (!dataReport) {
            dataHygieneReport(1, true);
        }
    }, [dataReport]);

    const renderInputForColumn = (column, index) => {
        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'date':

                if (formData[column.fieldName] != undefined) {
                    const dateString = formData[column.fieldName];
                    const parsedDate = new Date(dateString);
                    const formattedDate = format(parsedDate, "dd/MM/yyyy hh:mm a");
                    return <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={`w-[280px] justify-start text-left font-normal`}>
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {formattedDate}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                id={column.fieldName}
                                name={column.fieldName}
                                selectedDate={parsedDate}
                                onChange={date => handleDateChange(column.fieldName, date)}
                                MonthAndYearPicker={true}
                                timePicker={true}
                            />
                        </PopoverContent>
                    </Popover>
                }
            case 'textarea':
                return <textarea
                    id={column.fieldName}
                    name={column.fieldName}
                    value={formData[column.fieldName] || ''}
                    onChange={handleChange}
                    style={{ outline: 'none' }}
                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                />;
            case 'select':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {types.column_field.find((option) => option.id === formData.type)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {types.column_field.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectval':
                return (
                    <Select value={formData.value} onValueChange={(value) => handleSelectChange('value', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {types.column_field.find((option) => option.id === formData.value)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem key="done" value="done">
                                Done
                            </SelectItem>

                            <SelectItem key="refused" value="refused">
                                Refused
                            </SelectItem>
                        </SelectContent>
                    </Select>
                );
            default:
                return null;
        }
    };

    const dataHygieneReport = async (page: number, resetData: boolean = false) => {
        setResetData(resetData);

        dispatch(getHygiene(
            {
                location_id: locationSelectedId,
                per_page: rowsPerPage,
                page
            }
        ));
    };

    const fetchDayTrackerHygiene = () => {
        dispatch(
            getDayHygiene(
                {
                    location_id: locationSelectedId,
                    day: format(new Date(), "d")
                }
            )
        );
    }

    const resetPaginate = (): void => {
        setCurrentPage(1);
    }

    const handleButtonOpenDialog = (editFields: any[], title: string, row: any[], description: string) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(editFields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleDateChange = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value instanceof Date && !isNaN(value) ? value : null
        }));
    };

    const onSelectedTab = (event: string) => {
        setSelectedTab(event);
    }

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};

        if (!(action === 'Edit')) {
            json["id"] = formData.id;
            json["id_data"] = formData.data.id;

            await TrackerService.destroyTrackerHygiene(json);
        }

        setShowEditDialog(false);

        fetchDayTrackerHygiene();
        dataHygieneReport(1, true);
    };

    const loadMore = () => {
        if (!hasMoreRows || isLoading) return;
        setCurrentPage(currentPage + 1);
        const nextPage = currentPage + 1;
        dataHygieneReport(nextPage);
    };

    const submitQuickTracker = async (newItems: QuickTrackerItem[]) => {
        const saveQueries = newItems
        .filter((value) => !value.exist)
        .map(
            (value) => (
                {
                    type: value.type.id,
                    value: value.action,
                    resident_id: value.resident.id,
                    detail: "",
                    location_id: locationSelectedId
                }
            )
        );

        const updateQueries = newItems
        .filter((value) => value.exist)
        .map(
            (value) => (
                {
                    id: value.id,
                    id_data: value.id_data,
                    data: {
                        type: value.type.id,
                        value: value.action,
                        resident_id: value.resident.id,
                        detail: "",
                        location_id: locationSelectedId
                    }
                }
            )
        );

        setLoading(true);

        if(saveQueries.length) await TrackerService.saveTrackerHygiene(saveQueries);
        if(updateQueries.length) await TrackerService.updateTrackerHygiene(updateQueries);

        setLoading(false);
        
        fetchDayTrackerHygiene();
        dataHygieneReport(1, true);
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />

            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackerhygiene.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerhygiene.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedHygiene} onValueChange={(e) => { onSelectHygiene(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t("trackers.trackerhygiene.new")}
                                </TabsTrigger>

                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>

                                <TabsTrigger value="type" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <Settings className='w-5 h-5' />
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }

            {
                selectedHygiene === 'form' && (
                    <div>
                        {
                            selectedTab === "detailed" && (
                                <div className='mb-3'>
                                    <ResidentSelector
                                        onSelect={onResidentSelect}
                                        locationId={location}
                                        residentId={params.r}
                                        showAllResidents={false}
                                    />
                                </div>
                            )
                        }

                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <div className='flex justify-between'>
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                        {
                                            selectedTab === "quick" ? (
                                                <span>
                                                    {t("trackers.trackerhygiene.quickTracker")}
                                                </span>
                                            ) : (
                                                <span>
                                                    {t("trackers.trackerhygiene.detailedTracker")}
                                                </span>
                                            )
                                        }
                                    </CardTitle>

                                    <Tabs defaultValue={selectedTab} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto">
                                        <TabsList>
                                            <TabsTrigger value="quick" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                {t("trackers.trackerhygiene.quick")}
                                            </TabsTrigger>

                                            <TabsTrigger value="detailed" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                {t("trackers.trackerhygiene.detailed")}
                                            </TabsTrigger>
                                        </TabsList>
                                    </Tabs>
                                </div>
                            </CardHeader>

                            {
                                selectedTab === "quick" ? (
                                    <CardContent style={{ padding: 0 }}>
                                        <>
                                            {
                                                locationSelectedId && selectedTab === "quick" && (
                                                        <QuickTracker 
                                                            types={types} 
                                                            residents={residents} 
                                                            loading={loading} 
                                                            submit={submitQuickTracker} 
                                                            data={dataQuickTracker}
                                                            input={"select"}
                                                        />
                                                )
                                            }
                                        </>
                                    </CardContent>
                                ) : (
                                    <CardContent>
                                        <>
                                            {
                                                locationSelectedId && selectedTab === "detailed" && (
                                                    <TrackerHygieneDetailed dataHygieneReport={dataHygieneReport} resetFunc={resetPaginate} />
                                                )
                                            }
                                        </>
                                    </CardContent>
                                )
                            }
                        </Card>
                    </div>
                )
            }

            {
                selectedHygiene === 'report' && (
                    <div>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.trackerhygiene.report')}</CardTitle>
                            </CardHeader>

                            <CardContent>
                                <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                    <CustomDialog
                                        width="30rem"
                                        newDialog={true}
                                        isOpen={showEditDialog}
                                        onClose={handleCloseEditDialog}
                                        title={dialogTitle}
                                        onSubmit={handleSubmit}
                                        description={dialogDescription}
                                    >
                                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                            <div className="grid gap-4">
                                                {fields && fields.map((field, index) => (
                                                    <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                                        <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                                        {renderInputForColumn(field, index)}
                                                    </div>
                                                ))}
                                            </div>

                                            <div className='flex justify-end'>
                                                <Button type='submit'>Save</Button>
                                            </div>
                                        </form>
                                    </CustomDialog>

                                    <TableCustomVirtuoso
                                        data={dataReport}
                                        columns={hygineReportColumns}
                                        renderCellContent={(index, column, data) => data[index][column.key]}
                                        additionalButtons={<></>}
                                        showSearchInput={false}
                                        loadMore={loadMore}
                                        hasMore={hasMoreRows}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )
            }

            {
                selectedHygiene === 'type' && (
                    <div>
                        {
                            <div>
                                <TrackerHygieneType dataHygieneReport={dataHygieneReport} />
                            </div>
                        }
                    </div>
                )
            }

        </div>
    )
}

export default TrackerHygiene;
