import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../modules/dashboard/presentation/pages/DashboardPage';
import MedicationAlertsPage from '@/modules/dashboard/presentation/pages/MedicationAlertsPage';

const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/medication-alerts" element={<MedicationAlertsPage/>} />
    </Routes>
  );
};

export default DashboardRouter;
