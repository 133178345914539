import { Button } from "@/common/presentation/components/ui/button";
import { Switch } from "@/common/presentation/components/ui/switch"
import { AppDispatch, RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateEmarXs } from "../../slices/EmarXsSlice";
import * as configActions from '@/common/presentation/slices/configurationSlice';
import { setConfigurationsLoading } from '@/common/presentation/slices/configurationSlice';

export const EMarXs = () => {

    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);

    const { configurations, isLoading } = useSelector((state: RootState) => state.configurations);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const emarX = (configurations?.emar_x ?? false) == 'active' ? true : false;
        setChecked(emarX);
    }, [configurations])


    const handleUpdate = async () => {
        dispatch(setConfigurationsLoading())
        const { payload } = await dispatch(updateEmarXs({ status: checked }));

        if (payload?.status == 'ok') {
            dispatch(configActions.setConfigurations({
                ...configurations,
                'emar_x': checked == true ? 'active' : 'inactive'
            }));
        }

    }

    return (
        <>
            {!isLoading &&
                <>
                    <div className="h-[55vh] flex justify-center items-center flex-col">
                        <h6>{t('settings.emarX.title')}</h6>
                        <Switch
                            className="w-10 mt-4"
                            checked={checked}
                            onCheckedChange={setChecked}
                        />
                    </div>
                    <div className="flex justify-center">
                        <Button
                            variant="outline"
                            size="sm"
                            className='bg-primary hover:bg-primary hover:text-white text-white text-center'
                            onClick={handleUpdate}
                        >
                            <span>{t('settings.save')}</span>
                        </Button>
                    </div>
                </>
            }


        </>


    );
}