import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const NoteCategory = {
  getNoteCategories: async (formData: any) => {
    try {
      const { data } = await axios.post(`noteCategories/getNoteCategories`, formData);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error getting note categories: ${error.message}`);
    }
  },

  saveOrUpdateNoteCategory: async (formData: any) => {
    try {
      const response = await axios.post(`noteCategories/saveOrUpdateCategories`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error saving/updating note category: ${error.message}`);
    }
  },

  deleteNoteCategory: async (id: string) => {
    try {
      const { data } = await axios.delete(`noteCategories/deleteNoteCategory/${id}`);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error deleting note category: ${error.message}`);
    }
  }
};

export default NoteCategory;