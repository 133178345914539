import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { fetchAllClients } from '@/modules/client/presentation/slices/ClientSlice';
import { ClientI, LocationI } from '@/modules/client/domain/client.domain';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/common/presentation/components/ui/form';
import { FieldArrayWithId } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Trash } from 'lucide-react';
import MultiSelect from '@/common/presentation/components/MultiSelect2/MultiSelect';
import { FormValues } from './forms/NewPharmacyForm';

interface RepeaterProps {
  fields: FieldArrayWithId<FormValues, 'repeaterItems', 'id'>[];
  append: any;
  remove: (index: number) => void;
  form: any;
  disabled?: boolean;
}

interface OptionI {
  label: string;
  value: string;
  imageUrl?: string;
}

interface SelectedClientI {
  id: string;
  name: string;
}

interface FieldState {
  selectedClient: SelectedClientI | null;
  locations: OptionI[];
  selectedLocation: OptionI[];
}

const Repeater = ({ fields, append, remove, form, disabled }: RepeaterProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const clientState = useSelector((state: RootState) => state.client);
  const [clients, setClients] = useState<any[]>([]);
  const [fieldStates, setFieldStates] = useState<Record<number, FieldState>>({});

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    formatClientData(clientState.clients);
  }, [clientState]);

  const getClients = async () => {
    try {
      await dispatch(fetchAllClients());
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const formatClientData = (ClientList: ClientI[]) => {
    const formatedData = ClientList.map((client) => ({
      id: client.id,
      name: client.name,
      locations: client.locations
    }));
    setClients(formatedData);
  };

  const formatLocationOptions = (locations: any[]): OptionI[] => {
    return locations.map((location) => ({
      label: location.name,
      value: location.id,
      imageUrl: location.image_path
    }));
  };

  const formatLocationData = (LocationList: LocationI[]) => {
    return formatLocationOptions(
      LocationList.map((location) => ({
        id: location.id,
        name: location.name
      }))
    );
  };

  const handleClientChange = (clientId: string, index: number) => {
    const client = clients.find((c) => c.id === clientId);
    if (client) {
      const locations = formatLocationData(client.locations);
      setFieldStates(prev => ({
        ...prev,
        [index]: {
          selectedClient: client,
          locations,
          selectedLocation: []
        }
      }));
    }
  };

  const handleLocationChange = (selectedLocation: OptionI[], index: number) => {
    setFieldStates(prev => ({
      ...prev,
      [index]: {
        ...prev[index],
        selectedLocation
      }
    }));
  };

  return (
    <>
      <div className="p-2">
        <FormLabel>Locations</FormLabel>
        {fields.map((field, index) => {
          const fieldState = fieldStates[index] || {
            selectedClient: null,
            locations: [],
            selectedLocation: []
          };

          return (
            <div key={field.id} className="grid grid-cols-1 lg:grid-cols-3 gap-4 py-2 justify-center items-center align-top">
              <FormField
                control={form.control}
                name={`repeaterItems.${index}.client`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl className="w-full">
                      <Select
                        value={fieldState.selectedClient?.id || ''}
                        disabled={clientState.status === 'loading'}
                        onValueChange={(value) => {
                          field.onChange(value);
                          handleClientChange(value, index);
                        }}
                      >
                        <SelectTrigger className="col-span-3 px-2 py-1 rounded-md focus:outline-none">
                          <SelectValue placeholder="Select a client">
                            {fieldState.selectedClient ? fieldState.selectedClient.name : 'Select a client'}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          {clients.map((client) => (
                            <SelectItem key={client.id} value={client.id}>
                              {client.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`repeaterItems.${index}.location`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <MultiSelect
                        name="locations"
                        options={fieldState.locations}
                        isDisabled={clientState.status === 'loading'}
                        selectedOptions={fieldState.selectedLocation}
                        onChange={(value) => {
                          field.onChange(value || []);
                          handleLocationChange(value, index);
                        }}
                        placeHolder={'Select location'}
                        className="w-full mt-1"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                onClick={() => remove(index)}
                disabled={disabled}
                className="bg-background rounded w-12 px-0 shadow hover:bg-background"
              >
                <Trash className="text-red-500" size={17} />
              </Button>
            </div>
          );
        })}
        <div className="flex justify-end">
          <Button
            onClick={(e) => {
              e.preventDefault();
              append();
            }}
            disabled={disabled}
            className="p-2 bg-primary text-white rounded"
          >
            Add new client
          </Button>
        </div>
      </div>
    </>
  );
};

export default Repeater;