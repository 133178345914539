import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';

export const RestricByLocation = () => {
  return (
    <>
      <div className="flex items-center gap-4">
        <Switch checked={false} />
        <Label className="text-xs">Restric By Location</Label>
      </div>
    </>
  );
};
