import { NotebookTabs } from 'lucide-react';
import { AlertCard } from './alert-card';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import AlertsSkeleton from './skeleton';
import { useTranslation } from 'react-i18next';

interface Props {};

export const AdministrationAlerts: React.FC<Props> = () => {
  const alerts = useSelector((state: RootState) => state.dashboard.administrationAlerts);
  const { t } = useTranslation();
  const administration_alerts = [
    {
      id: 1,
      date: '2021-09-01',
      name: 'John Doe',
      alert: 'Administration Error Administration Error Administration Error',
      status: 'Active'
    },
    {
      id: 2,
      date: '2021-09-01',
      name: 'Jane Doe',
      alert: 'Administration Error',
      status: 'Active'
    },
    {
      id: 3,
      date: '2021-09-01',
      name: 'James Doe',
      alert: 'Administration Error',
      status: 'Active'
    },
    {
      id: 4,
      date: '2021-09-01',
      name: 'Jill Doe',
      alert: 'Administration Error',
      status: 'Active'
    },
    {
      id: 5,
      date: '2021-09-01',
      name: 'Jane Doe',
      alert: 'Administration Error',
      status: 'Active'
    },
    {
      id: 6,
      date: '2021-09-01',
      name: 'James Doe',
      alert: 'Administration Error',
      status: 'Active'
    },
    {
      id: 7,
      date: '2021-09-01',
      name: 'Jill Doe',
      alert: 'Administration Error',
      status: 'Active'
    },
    {
      id: 8,
      date: '2021-09-01',
      name: 'Jane Doe',
      alert: 'Administration Error',
      status: 'Active'
    },
    {
      id: 9,
      date: '2021-09-01',
      name: 'James Doe',
      alert: 'Administration Error',
      status: 'Active'
    },
    {
      id: 10,
      date: '2021-09-01',
      name: 'Jill Doe',
      alert: 'Administration Error',
      status: 'Active'
    }
  ];

  return (
    <>
      <AlertCard title={t('dashboard.administrationAlerts')} alerts={alerts?.data ?? []} icon={NotebookTabs} custom={true}>
        {
          alerts.status === 'loading' ? (
            <AlertsSkeleton />
          ) : alerts?.data?.length === 0 ? (
            <div className="p-2">
            <div className="rounded-lg border p-4">
              <div className="grid">
                <div className="col-span-10 flex flex-col justify-center text-center w-full">
                  <span className="w-full flex flex-row gap-2 items-center text-center justify-center text-sm">
                    {t('dashboard.noAlerts')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          ) : (
            <>
              --
            </>
          )
        }
      </AlertCard>
    </>
  );
};
