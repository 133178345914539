import { Dialog, DialogContent, DialogHeader } from '@/common/presentation/components/ui/dialog';
import { useAddCustomFieldDialog } from '@/modules/staff/infrastructure/hooks/updateStaff/addCustomField';
import { CustomFieldForm } from '../forms/CustomFieldForm';
import { useCustomFieldsStore } from '@/modules/staff/domain/stores/updateStaff/customFields';
import { useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/common/presentation/components/ui/alert';

export const AddCustomFieldDialog = () => {
  const { isOpen, onClose } = useAddCustomFieldDialog();
  const { setStatus, setCustomFields, getCustomFields } = useCustomFieldsStore();
  const [error, setError] = useState<string[] | null>(null);

  const onSubmit = async (values: any) => {
    const payload = {
      key: values.label.trim().replace(/'/g, '').replace(/\s+/g, '_').replace(/[-_]+/g, '_').toLowerCase(),
      ...values
    };

    try {
      await setCustomFields({ data: payload });
      await getCustomFields();
      onClose();
      setError(null);
    } catch (err: any) {
      if (err.response?.data?.errors) {
        const errorMessages = Object.values(err.response.data.errors);
        setError(errorMessages as string[]);
      } else {
        const errorMessages = [...Object.values(JSON.parse(err.message))];
        setError(errorMessages as string[]);
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        onClose();
        setError(null);
      }}
    >
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <h2 className="text-center text-lg font-semibold">Add Custom Field</h2>
        </DialogHeader>

        {error && (
          <Alert className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <AlertTitle>Error adding custom field</AlertTitle>
            <AlertDescription>
              {error.map((errorMessage, index) => (
                <div key={index}>{errorMessage}</div>
              ))}
            </AlertDescription>
          </Alert>
        )}

        <CustomFieldForm
          defaultValues={{
            label: '',
            type: '',
            required: false,
            default_value: '',
            placeholder: ''
          }}
          onSubmit={onSubmit}
          status={setStatus}
        />
      </DialogContent>
    </Dialog>
  );
};
