import { Form, FormControl, FormField, FormItem, FormLabel } from '@/common/presentation/components/ui/form';
import { Switch } from '@/common/presentation/components/ui/switch';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as residentConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import * as Icons from 'lucide-react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  values: Partial<FormValues>;
}

const formSchema = z.object({
  // level_of_care: z.boolean().optional(),
  // xxxx: z.boolean().optional(),
  religious_preferences: z.boolean().optional(),
  administrator_can_see: z.boolean().optional(),
  custom_fields: z.boolean().optional(),
  notes: z.boolean().optional(),
  discharge: z.boolean().optional()
});

type FormValues = z.infer<typeof formSchema>;

const defaultValues =  {
  level_of_care: false,
  xxxx: false,
  religious_preferences: false,
  administrator_can_see: false,
  custom_fields: false,
  notes: false,
  discharge: false
}

export const FormCards = ({ values }: Props) => {
  const { status } = useSelector((state: RootState) => state.residents.configurations);
  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (data: FormValues) => {
    const response = await dispatch(
      residentConfigurationsActions.postResidentsConfigurations({
        resident_form_cards: data
      })
    );

    if (response.payload !== undefined) {
      toast({
        title: 'Form cards updated',
        variant: 'default'
      });
    }
  };

  
  useEffect(() => {
    form.reset(values);
  }, [values]);


  return (
    <>
      <div className="flex flex-col gap-4">
        <div>
          <h2 className="text-[14px] font-semibold text-grayBase">Resident form cards</h2>
          <p className='text-[12px] leading-[22px]'>Here you can configure the residents form cards that will be displayed in the residents form page.</p>
        </div>

        <Form {...form}>
          <form
            onChange={form.handleSubmit(onSubmit)}
            className="flex flex-col w-full items-start p-4 md:p-6 rounded-lg bg-neutralLight min-h-auto overflow-scroll sm:overflow-hidden"
          >
            <div className="grid lg:grid-cols-2 w-full ">
              {/* <FormField
                control={form.control}
                name="level_of_care"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Level Of Care</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="xxxx"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">XXXX</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              /> */}
              <FormField
                control={form.control}
                name="religious_preferences"
                render={({ field }) => (
                  <FormItem className="flex justify-between items-center rounded-t-[6px] bg-white h-[48px] w-[100%] 
                          sm:w-[98%] xl:w-[495px] pr-2 pl-2 md:pr-12 md:pl-6 pt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-gray-300">
                    <FormLabel className="text-base flex gap-6 text-[14px] font-semibold	items-center">
                      <div> <Icons.Eye className='text-primary' /> </div>
                      Relogious Preferences
                    </FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="administrator_can_see"
                render={({ field }) => (
                  <FormItem className="flex justify-between items-center rounded-t-[6px] bg-white h-[48px] w-[100%] 
                          sm:w-[98%] xl:w-[495px] pr-2 pl-2 md:pr-12 md:pl-6 pt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-gray-300">
                    <FormLabel className="text-base flex gap-6 text-[14px] font-semibold	items-center">
                      <div><Icons.PenLine className='text-primary' /></div>
                      Administrator Can See
                    </FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="custom_fields"
                render={({ field }) => (
                  <FormItem className="flex justify-between items-center rounded-t-[6px] bg-white h-[48px] w-[100%] 
                          sm:w-[98%] xl:w-[495px] pr-2 pl-2 md:pr-12 md:pl-6 pt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-gray-300">
                    <FormLabel className="text-base flex gap-6 text-[14px] font-semibold	items-center">
                      <div><Icons.FilePen className='text-primary' /></div>
                      Custom Fields
                      </FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem className="flex justify-between items-center rounded-t-[6px] bg-white h-[48px] w-[100%] 
                          sm:w-[98%] xl:w-[495px] pr-2 pl-2 md:pr-12 md:pl-6 pt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-gray-300">
                    <FormLabel className="text-base flex gap-6 text-[14px] font-semibold	items-center">
                      <div><Icons.StickyNote className='text-primary' /></div>
                      Notes
                    </FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="discharge"
                render={({ field }) => (
                  <FormItem className="flex justify-between items-center rounded-t-[6px] bg-white h-[48px] w-[100%] 
                          sm:w-[98%] xl:w-[495px] pr-2 pl-2 md:pr-12 md:pl-6 pt-2 pb-2 border border-t-0 border-l-0 border-r-0 border-gray-300">
                    <FormLabel className="text-base flex gap-6 text-[14px] font-semibold	items-center">
                    <div><Icons.Church className='text-primary' /></div>
                      Discharge
                      </FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
