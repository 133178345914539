import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import { format } from "date-fns";
// import { da, es } from "date-fns/locale";

//Global components
import { useDialog } from '@/common/presentation/components/CustomDialog/useDialog';
import { MonthAndYearSelector } from '@/common/presentation/components/CustomCalendar/MonthAndYearSelector';

//Components from calendar
import { TaskEventDialog } from '../components/DialogTaskEvent/DialogTaskEvent';
import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import useRouteParams from '@/common/hooks/RouteParamsHook.ts';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import React, { useEffect, useRef, useState } from 'react';

import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { UsersSelector } from '@/common/presentation/components/Selectors/UsersSelector';

// import { ArrowLeft } from 'lucide-react';
// import { Button } from '@/common/presentation/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import momentPlugin from '@fullcalendar/moment';

import { CategoriesColorFilter } from '../components/CategoriesColorFilter.tsx';

// import Color from '../../../../common/presentation/components/primaryColor/color';

//Interfaces
import { FetchParamsData, ICategory, ITaskEvent, relatedToTypes, TypeRelatedTo } from "../../domain/calendar.domain.ts";

//Hooks redux
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

//Redux
import { getDataCalendar, getCategories } from "../slices/CalendarSlice.ts";

//Styles
import "../pages/styles/index.css";

//Components
import { InfoEventCard } from '../components/InfoEventCard.tsx';
import CalendarList from '../components/Resume/CalendarList.tsx';

import { useTranslation } from 'react-i18next';

import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import tagaLocale from '../translations/tagalo.ts';

import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider.tsx';
import { lowerCase } from 'lodash';

// import { websocket } from '@/utils/helpers/websocket.helper.ts';



const CalendarPage = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const { isOpen, openDialog, closeDialog } = useDialog();
  const [isOpenInfoEventCard, setOpenInfoEventCard] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const { params, setParam } = useRouteParams();
  const [location, setLocation] = useState<string | null>(null);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [residentSelected, setResidentSelected] = useState(params.r);

  const navigate = useNavigate();
  const [selectedCalendar, setSelectedCalendar] = useState<string>(params.e || 'calendar');

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [yearSeleted, setYearSelected] = useState(new Date(selectedDate).getFullYear());
  const [initialDataFetched, setInitialDataFetched] = useState(false);

  const [eventTaskId, setEventTaskId] = useState<string>('');

  const [categoryColor, setCategoryColor] = useState('');

  const { locationSelectedId } = useLocation();

  const [fetchParamsData, setFetchParamsData] = useState<FetchParamsData>({ locationId: locationSelectedId, start: '', end: '', category: 'all-colors', type: {} });

  const [isOpenPopoverCategories, setOpenPopoverCategories] = useState<boolean>(false);
  const calendarRef = useRef<any>(null);

  interface IDatesCell { start: string; end: string }
  const [datesCell, setDatesCell] = useState<IDatesCell>({ start: '', end: '' });
  const [selectedRelatedTo, setSelectedRelatedTo] = useState<TypeRelatedTo>(relatedToTypes.residents);

  //Redux
  const { locations } = useSelector((state: RootState) => state.locations.allLocations);
  // Hook personalizado que usa el tipo rootState para el estado
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

  const categories: ICategory[] = useTypedSelector((state) => state?.calendar?.categories);
  let tasksEvents: ITaskEvent[] = useTypedSelector((state) => state?.calendar?.tasksEvents);


  const { t } = useTranslation();
  const locale = localStorage.getItem('i18nextLng');

  const handleEventContent = (eventInfo: any) => {
    const currentDate = eventInfo.event.start ? new Date(eventInfo.event.start) : new Date();
    const formattedTime = format(currentDate, 'h:mma');
  
    const title = eventInfo.event.title.charAt(0).toUpperCase() + eventInfo.event.title.slice(1);
  
    // if a event all day
    if (eventInfo.event.allDay) {
      return (
        <div className="w-full h-full pl-[5px]" style={{
          backgroundColor: eventInfo.event.backgroundColor,
          color: eventInfo.event.textColor,
        }}>
          <span className="text-[12.3px]">
          {title}{' '}
          {eventInfo.event.extendedProps.categoryName === 'Birthday' && 
            ` ${t('calendar.birthday')}`}
        </span>
        </div>
      );
    }
  
    // for normal events with the color point
    return (
      <div className="flex items-center gap-[3.2px] p-[2px_5px] bg-transparent">
        <span className="w-[8px] h-[8px] rounded-full" style={{
          backgroundColor: eventInfo.event.backgroundColor,
        }}/>
        <span className="font-semibold lowercase text-[12.3px]">
          {formattedTime}
        </span>{' '}
        <span className="text-[12.3px] font-semibold">  
          {title}{' '}
          {eventInfo.event.extendedProps.categoryName === 'Birthday' && 
            ` ${t('calendar.birthday')}`}
        </span>
      </div>
    );
  };
  

  const buttonsText: Record<string, string> = {
    today: t('calendar.today'),
    dayGridMonth: t('calendar.month'),
    timeGridWeek: t('calendar.week'),
    dayGridDay: t('calendar.day'),
    list: t('calendar.list'),
    timeGridDay: t('calendar.dayTime')
  };

  const headerToolbar = isMobile
    ? {
      left: 'title',
      center: '',
      right: 'prev,next'
    }
    : {
      left: 'prev,title,next',
      right: 'timeGridDay,dayGridDay,timeGridWeek,dayGridMonth'
    };

  const views = {
    dayGridMonth: { buttonText: 'Month' },
    timeGridWeek: { buttonText: 'Week' },
    timeGridDay: { buttonText: 'Time' },
    dayGridDay: { buttonText: 'Day' }
  };



  useEffect(() => {
    setResidentSelected(params.r);
  }, [params.r]);

  const onSelectCalendar = (e: string) => {
    setParam('e', e);
    setSelectedCalendar(e);
  };

  const onResidentSelect = (resident: any) => {
    setParam('r', resident);
    setResidentSelected(resident);
  };

  const onSelectUser = (user: any) => {
    setParam('user', user);
  };


  const handleDateClick = (info: any) => {
    setSelectedDate(info.date);
    openDialog()
  };

  const handleInfoEventCard = (info: any) => {

    setDatesCell({
      start: info.event.start,
      end: info.event.end
    });

    const eventTaskId: string = info.el.fcSeg.eventRange.def.extendedProps.eventId;

    setEventTaskId(eventTaskId)
    setOpenInfoEventCard(true);

  }

  const handleFetchParamsClick = (info: any, locationId: string) => {

    const yearSelected = new Date(info.view.currentStart).getFullYear();
    setYearSelected(yearSelected); // Actualizamos el estado del año actual

    const params: FetchParamsData = {
      locationId,
      start: info.view.currentStart,
      end: info.end,
      type: info.view.type,
      category: categoryColor
    };


    setFetchParamsData(params); //Actualizamos el estado de los parametros requeridos

    // Obtenemos la data del calendar cuando cargue el componente por primera vez
    if (initialDataFetched === false) {

      dispatchGetDataCalendar(params);
      // Actualizamos el estado indicando que ya se cargo la data por primera vez
      setInitialDataFetched(true);
    }

  }


  const dispatchGetDataCalendar = (fetchParamsData: FetchParamsData) => {

    const locationId = locationSelectedId;

    if (fetchParamsData.start && fetchParamsData.end && locationId) {
      const params: FetchParamsData = {
        locationId,
        start: fetchParamsData.start,
        end: fetchParamsData.end,
        type: fetchParamsData.type,
        category: categoryColor
      };

      setFetchParamsData(params) //Actualizamos el estado de los parametros para el fetch
      dispatch(getDataCalendar({formData: params}));
    }

  }

  const capitalizeTitle = (dateInfo: any) => {
    const date = new Date(dateInfo?.end?.marker ?? '');
    const month = date.toLocaleString(locale === 'es' ? 'es' : 'en', { month: 'long' });
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
    return `${capitalizedMonth} ${date.getFullYear()}`;
  }

  const getLocaleTranslate = () => {
    const language = localStorage.getItem('i18nextLng') as keyof typeof localeFullCalendar;

    const localeFullCalendar = {
      en: enLocale,
      es: esLocale,
      tl: tagaLocale
    };

    return localeFullCalendar[language] ?? enLocale;
  }

  // Unicamente abre el card si se dio click en el evento y tiene información del mismo
  useEffect(() => {

    if (isOpenInfoEventCard === false && datesCell.start) {
      setOpenInfoEventCard(true);
    }
  }, [datesCell]);


  useEffect(() => {
    if (locationSelectedId && locations.length === 0) {
      const fetchData = async () => {
        const response = await dispatch(locationsActions.getLocations());
        if (response.payload) {
          const selectedLocations =
            locationSelectedId === 'all-locations'
              ? response.payload
              : response.payload.filter((l: any) => l.location?.id === locationSelectedId);
          setSelectedLocations(selectedLocations.map((l: any) => l.location));
        }
      };
      fetchData();
    }

    if (!locationSelectedId && locations.length === 1) {
      setSelectedLocations(locations);
      setLocation(locations[0].id);
      setParam('l', locations[0].id);
    }

    setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
    setLocation(locationSelectedId);

    if (locationSelectedId && !params.e) {
      setParam('e', 'calendar');
    }

    if (!params.r && locationSelectedId) {
      setParam('r', 'all-residents');
    }
  }, [params, locationSelectedId]);


  useEffect(() => {
    const type: any = fetchParamsData.type;

    if (calendarRef.current && type == "dayGridMonth") {//toca arreglar esto

      const calendarApi: any = calendarRef.current.getApi();
      const selectedDateOnCalendar = calendarApi.getDate();

      setSelectedDate(selectedDateOnCalendar);
      setYearSelected(new Date(selectedDateOnCalendar).getFullYear());
    }

  }, [fetchParamsData]);


  useEffect(() => {
    // Get categories
    dispatch(getCategories());
  }, []);


  // Fetched data sea cuando cambie los estados de el año seleccionado, location o la categoria
  useEffect(() => {
    // Si ya se realizo la petición al rederizar el compoenente por primera vez
    if (initialDataFetched === true) {
      dispatchGetDataCalendar(fetchParamsData)
    }

  }, [yearSeleted, locationSelectedId, categoryColor]);


  useEffect(() => {
    // Cuando cambie el estado del filtro de category actualizamos los parametros fetch
    const newFetchParamsData: FetchParamsData = fetchParamsData;
    newFetchParamsData.category = categoryColor;
    newFetchParamsData.locationId = locationSelectedId;

    if (newFetchParamsData.start == '' && newFetchParamsData.end == '') {
      newFetchParamsData.start = new Date();
      newFetchParamsData.end = new Date();
    }
    setFetchParamsData(newFetchParamsData);

  }, [locationSelectedId]);


  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);

    // Ejecutar la función una vez al inicio
    handleResize();

    // Agregar el evento listener
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    setSelectedDate(new Date())
  }, [isMobile])


  return (
    <>

      <div><LocationSelector quickView={true} /></div>

      {locationSelectedId && (
        <CardsResidentAndUserSelector
          onSelect={setSelectedRelatedTo}
          value={selectedRelatedTo}
          onUserSelect={onSelectUser}
          userId={params.user ?? 'all-users'}
          onResidentSelect={onResidentSelect}
          residentSelect={params.r}
          locationId={locationSelectedId}
          title={t('calendar.calendar')}
        />
      )}

      <Tabs
        defaultValue={selectedCalendar}
        onValueChange={(e) => {
          onSelectCalendar(e);
        }}
        className="w-auto mb-3 text-end"
      >
        <TabsList>
          <TabsTrigger value="calendar" className="data-[state=active]:bg-primary data-[state=active]:text-white">
            {t('calendar.calendar')}
          </TabsTrigger>
          <TabsTrigger
            value="entry_list"
            className="data-[state=active]:bg-primary data-[state=active]:text-white"
          >
            {t('calendar.resume')}
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {(selectedCalendar === 'calendar' || !params.e) && (
        <CustomCard
          description=""
          title=""
          children={
            <>


              <div className='flex flex-col md:flex-row items-center'>

                <div className='w-full md:w-1/2 mb-5'>
                  <MonthAndYearSelector
                    key={`MY-selector-${selectedDate.toString()}`}
                    date={selectedDate}
                    onChange={setSelectedDate}
                    className="h-[270px]"
                  />
                </div>

                <CategoriesColorFilter
                  categories={categories}
                  value={categoryColor}
                  onChange={setCategoryColor}
                  open={isOpenPopoverCategories}
                  openChange={setOpenPopoverCategories}
                />
              </div>

              <div className='container-fullcalendar'>
                <FullCalendar
                  ref={calendarRef}
                  key={`full-calendar-${selectedDate.toString()}`}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin]}
                  headerToolbar={headerToolbar}
                  views={views}
                  initialView={isMobile ? 'timeGridDay' : 'dayGridMonth'}
                  buttonText={buttonsText}
                  events={tasksEvents}
                  initialDate={selectedDate}
                  locale={getLocaleTranslate()}
                  eventContent={handleEventContent }
                  titleFormat={capitalizeTitle}
                  displayEventEnd={true}// Mostrar la hora de finalización del evento
                  dateClick={handleDateClick}// Mostrar el dialog para crear un tasks event
                  datesSet={(info: any) => handleFetchParamsClick(info, locationSelectedId)}// Aquí se maneja el cambio de los parametros requeridos para el fetch data
                  eventClick={handleInfoEventCard} // Muestra el dialog de la info del evento
                  displayEventTime={false}
                  dayCellClassNames={(arg) => {
                    if (arg.isToday) {
                      return 'fc-primary-color-cell';
                    }
                    return [];
                  }}

                />
              </div>

            </>}
        ></CustomCard>

      )}

      <TaskEventDialog
        open={isOpen}
        onClose={closeDialog}
        residentId={residentSelected}
        userId={params.user}
        locationId={locationSelectedId}
        selectedDate={selectedDate}
        onSelectedDate={setSelectedDate}
        fetchParamsData={fetchParamsData}
        isUpdate={false}
        relatedTo={selectedRelatedTo}
      />

      <InfoEventCard
        open={isOpenInfoEventCard}
        onClose={() => setOpenInfoEventCard(false)}
        onSelectedDate={setSelectedDate}
        eventTaskId={eventTaskId}
        startEndDate={datesCell}
        fetchParamsData={fetchParamsData}
        locationId={locationSelectedId}
        residentId={residentSelected}
        fromCalendar={true}
      />

      {selectedCalendar == 'entry_list' && selectedLocations.length > 0 && (
        <CalendarList
          residentSelectedId={residentSelected}
          userSelectedId={params.user}
          selectedRelatedTo={selectedRelatedTo}
          locationSelectedId={location ?? 'all-locations'}
        />
      )}
    </>

  )


};


interface IResidentAndUserSelector {
  onSelect: (val: any) => void;
  value: string;
  onUserSelect: (val: string) => void;
  userId: string;
  onResidentSelect: (val: string) => void;
  residentSelect: string;
  locationId: string;
  title: string;
}

const CardsResidentAndUserSelector = ({
  onSelect,
  value,
  title,
  onUserSelect,
  userId,
  onResidentSelect,
  residentSelect,
  locationId
}: IResidentAndUserSelector) => {

  const { t } = useTranslation();

  return (
    <>
      <div className='flex justify-between'>
        <div className="font-semibold text-2xl text-primary">
          {title}
        </div>
        <Tabs
          defaultValue={value}
          onValueChange={(val) => {
            onSelect(val);
          }}
          className="w-auto"
        >
          <TabsList>
            <TabsTrigger value="residents" className="data-[state=active]:bg-primary data-[state=active]:text-white">
              {t('calendar.residents')}
            </TabsTrigger>
            <TabsTrigger
              value="users"
              className="data-[state=active]:bg-primary data-[state=active]:text-white"
            >
              {t('calendar.staff')}
            </TabsTrigger>

          </TabsList>
        </Tabs>
      </div>
      {value === 'users' && (
        <div className="mt-4 mb-4">
          <UsersSelector
            onSelect={onUserSelect}
            userId={userId}
            locationId={locationId}
            roles={''}
          />
        </div>
      )}

      {value === 'residents' && (
        <div className="mt-4 mb-4">
          <ResidentSelector
            showKeepInEye={true}
            onSelect={onResidentSelect}
            residentId={residentSelect}
            locationId={locationId}
            description={t('calendar.selectAResident')}
          />
        </div>
      )}
    </>
  );
}
export default CalendarPage;
