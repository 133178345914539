import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateClient } from '../../domain/client.domain';
import ClientService from '../../infrastructure/ClientService';

const initialState: IStateClient = {
  clients: [],
  status: 'loading',
  error: null
};

export const fetchAllClients = createAsyncThunk('client/listClients', async () => {
  try {
    const response = await ClientService.getClients();
    return response.data;
  } catch (error) {
    throw new Error('Error fetching clients: ' + error);
  }
});

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClients.fulfilled, (state, action) => {
        state.status = 'idle';
        state.clients = action.payload;
      })
      .addCase(fetchAllClients.pending, (state) => {
        state.status = 'loading';
        state.clients = [];
      });
  }
});

export default clientSlice.reducer;