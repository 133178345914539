import CustomFieldsService from '@/modules/staff/infrastructure/services/CustomFields';
import { create } from 'zustand';

type StaffCustomFields = {
  staff_custom_fields: any;
  staffCustomFieldsStatus: 'idle' | 'loading' | 'failed';
  status: 'idle' | 'loading' | 'failed';
  error: string | null;

  getStaffCustomFields: (staff_id: string) => Promise<any>;
  saveStaffCustomFields: ({ staff_id, data }: { staff_id: string; data: any }) => Promise<any>;
};

export const useStaffCustomFieldsStore = create<StaffCustomFields>((set) => ({
  staff_custom_fields: null,
  staffCustomFieldsStatus: 'idle',
  status: 'idle',
  error: null,

  getStaffCustomFields: async (staff_id) => {
    set({ staffCustomFieldsStatus: 'loading' });
    try {
      const response = await CustomFieldsService.getStaffCustomFields(staff_id);
      set({ staffCustomFieldsStatus: 'idle', staff_custom_fields: response.data.staff_custom_fields });
      return response.data;
    } catch (error: any) {
      set({ staffCustomFieldsStatus: 'failed', error: error.message });
      throw new Error(error.message);
    }
  },

  saveStaffCustomFields: async ({ staff_id, data }) => {
    set({ status: 'loading' });
    try {
      const response = await CustomFieldsService.saveStaffCustomFields(staff_id, data);
      set({ status: 'idle' });
      return response.data;
    } catch (error: any) {
      set({ status: 'failed', error: error.message });
      throw new Error(error.message);
    }
  }
}));
