import axiosLib from 'axios';
import CryptoJS from 'crypto-js';

const baseURL = import.meta.env.VITE_REACT_ENVIROMENT === 'local' ? '/api' : import.meta.env.VITE_REACT_APP_API_URL;

const axios = axiosLib.create({
  baseURL,
  timeout: 40000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

axios.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('access_token');
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const nestApi = axiosLib.create({
  baseURL: import.meta.env.VITE_NEST_APP_API_URL,
  timeout: 40000,
  headers: {
    'Content-Type': 'application/json'
  }
});

nestApi.interceptors.request.use((config: any) => {
  const tokenObject = {
    api_key: import.meta.env.VITE_WEB_NEST_CARING_DATA_KEY,
    dateTime: new Date().getTime()
  };

  config.headers = {
    Authorization: `Bearer ${CryptoJS.AES.encrypt(JSON.stringify(tokenObject), import.meta.env.VITE_WEB_NEST_CARING_DATA_KEY).toString()}`
  };

  return config;
});

export { axios, nestApi };