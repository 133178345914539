import { useTranslation } from 'react-i18next';
import { OverviewTableActions } from './OverviewTableActions';

const useOverviewExceptionColumns = () => {
  const { t } = useTranslation();

  const overviewExceptionColumns = [
    {
      key: 'exception_date',
      id: 'exception_date',
      labelTranslationKey: 'emar-exception.column-exception.exception_date',
      value: 'exception_date',
      visible: true,
      sortable: true,
      width: '20%',
    },
    {
      key: 'medication_name',
      id: 'medication_name',
      labelTranslationKey: 'emar-exception.column-exception.medication_name',
      value: 'medication_name',
      visible: true,
      sortable: true,
      width: '20%',
    },
    {
      key: 'resident',
      id: 'resident',
      labelTranslationKey: 'emar-exception.column-exception.resident',
      value: 'resident',
      visible: true,
      sortable: true,
      width: '20%',
    },
    // {
    //   key: 'staff',
    //   id: 'staff',
    //   labelTranslationKey: 'emar-exception.column-exception.staff',
    //   value: 'staff',
    //   visible: true,
    //   sortable: true,
    //   width: '20%',
    // },
    // {
    //   key: 'actions',
    //   labelTranslationKey: 'overview.actions',
    //   render: ({ row }) => <OverviewTableActions data={row?.original} />,
    //   visible: true,
    //   width: '20%',
    // },
  ];

  const columnsWithTranslation = overviewExceptionColumns.map((column) => ({
    ...column,
    label: t(column.labelTranslationKey),
  }));

  return columnsWithTranslation;
};

export default useOverviewExceptionColumns;
