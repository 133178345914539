import React, { useState, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Loader2 } from 'lucide-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface VitalSettingProps {
  icon: React.ElementType;
  label: string;
  fields: {
    first_label: string;
    second_label: string;
    third_label?: string;
    value1: string;
    value2: string;
    key: string;
  }[];
  indexAlert: number;
  onInputChange: (settingIndex: number, fieldIndex: number, inputName: 'value1' | 'value2', value: string) => void;
  loading: boolean;
  bothRequired?: boolean;
  setFieldsErrors: (error: any) => void;
}

const VitalSetting: React.FC<VitalSettingProps> = ({
  icon: Icon,
  label,
  fields,
  indexAlert,
  onInputChange,
  loading,
  bothRequired = false,
  setFieldsErrors
}) => {
  const [invalidFields, setInvalidFields] = useState<any>({});
  const { t } = useTranslation();

  useEffect(() => {
    validateFields();
  }, [fields, bothRequired]);

  const validateFields = () => {
    if (bothRequired) {
      const newInvalidFields = fields.reduce((acc, field) => {
        const isValue1Empty = !field.value1;
        const isValue2Empty = !field.value2;
        const isInvalid = (isValue1Empty && !isValue2Empty) || (!isValue1Empty && isValue2Empty);
        acc[`${field.key}_value1`] = isInvalid;
        acc[`${field.key}_value2`] = isInvalid;
        return acc;
      }, {} as Record<string, boolean>);
      setInvalidFields(newInvalidFields);
      setFieldsErrors(newInvalidFields);
    }
  };

  const handleInputChange = (fieldIndex: number, inputName: 'value1' | 'value2', value: string) => {
    onInputChange(indexAlert, fieldIndex, inputName, value);

    if (bothRequired) {
      const field = fields[fieldIndex];
      const otherInputName = inputName === 'value1' ? 'value2' : 'value1';
      const otherValue = fields[fieldIndex][otherInputName];
      const isInvalid = (value && !otherValue) || (!value && otherValue);
      
      const newInvalidFields = {
        ...invalidFields,
        [`${field.key}_value1`]: isInvalid,
        [`${field.key}_value2`]: isInvalid
      };
      setInvalidFields(newInvalidFields);
      setFieldsErrors(newInvalidFields);
    }
  };

  return (
    <div className="flex flex-col rounded-lg border p-4 relative">
      {loading && (
        <div className="absolute inset-0 rounded dark:bg-white/20 bg-white bg-opacity-70 flex items-center justify-center z-10">
          <Loader2 className="animate-spin text-primary" size={24} />
        </div>
      )}
      <div className="flex flex-row gap-2 align-top justify-start items-center">
        <Icon className="text-primary" size={20} />
        <Label className="text-lg font-normal">{t(label)}</Label>
      </div>
      <Separator className="mt-3 mb-4 border border-primary rounded-lg" />
      <div className="space-y-4 text-base">
        {fields.map((rowField, indexRowFields) => (
          <div key={indexRowFields} className="grid grid-cols-1 sm:grid-cols-6 items-center gap-2">
            <label className="sm:col-span-2">{t(rowField.first_label)}</label>
            <Input
              onChange={(e) => handleInputChange(indexRowFields, 'value1', e.target.value)}
              disabled={loading}
              value={rowField.value1}
              type="number"
              placeholder="0"
              className={classNames('border rounded-md px-2 py-1 w-full sm:w-16 sm:col-span-1', {
                'border-red-500': !loading && bothRequired && invalidFields[`${rowField.key}_value1`]
              })}
            />
            <span className="sm:col-span-1">{t(rowField.second_label)}</span>
            <Input
              type="number"
              disabled={loading}
              value={rowField.value2}
              onChange={(e) => handleInputChange(indexRowFields, 'value2', e.target.value)}
              placeholder="0"
              className={classNames('border rounded-md px-2 py-1 w-full sm:w-16 sm:col-span-1', {
                'border-red-500': !loading && bothRequired && invalidFields[`${rowField.key}_value2`]
              })}
            />
            {rowField.third_label && <span className="sm:col-span-1 pl-1">{t(rowField.third_label)}</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VitalSetting;