import './quill.css';
import '@blocknote/core/fonts/inter.css';
import '@blocknote/mantine/style.css';
import 'react-quill/dist/quill.snow.css';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditorToolbar, { modules, formats } from './editorToolbar';
import ReactQuill from 'react-quill';



interface QuillJsValues {
  content: string;
  html_content: string;
  images: File[];
}

interface NotesEditorProps {
  value?: string;
  onChange: (values: QuillJsValues) => void;
  disabled?: boolean;
  submit?: boolean;
  translator?: boolean;
  crudState?: CrudState;
}

export const QuillJS = ({
  value: propValue = '',
  onChange,
  disabled,
  submit = false,
  translator = false,
  crudState
}: NotesEditorProps) => {

  // Initial
  const { t } = useTranslation();

  const quillRef = useRef<ReactQuill>(null);
  const [value, setValue] = useState(propValue || '');
  const [images, setImages] = useState<File[]>([]);

  useEffect(() => {
    if (crudState && crudState.isFinishingDraft || (value !== propValue)) {
      setValue(propValue);
      handleChange(propValue);
    }
  }, [propValue]);

  useEffect(() => {
    if (quillRef.current) {
      const quillEditor = quillRef.current.getEditor();
      handleChange(quillEditor.root.innerHTML);
    }
  }, [images])

  const handleChange = (content: string) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();

      let htmlContent = content;
      let tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;
      let imgTags = tempDiv.querySelectorAll('img');
      imgTags.forEach((img) => img.remove());
      let imagesSave: any = [];
      if (imgTags.length) {
        imagesSave = images;
      }
      let cleanedHtmlContent = tempDiv.innerHTML;

      setValue(content);
      onChange({
        content: editor.getText(),
        html_content: cleanedHtmlContent,
        images: imagesSave
      });
    }
  };

  // Función para manejar la inserción de la imagen
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (input.files && quillRef.current) {
        const file = input.files[0];
        const quillEditor = quillRef.current.getEditor();
        const range = quillEditor.getSelection(true);
        const base64Image = await fileToBase64(file);
        quillEditor.insertEmbed(range.index, 'image', base64Image);
        quillEditor.setSelection(range.index + 1, images.length);
        setImages((prevImages) => [...prevImages, file]);
      }
    };
  };

  const fileToBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  modules.toolbar.handlers.image = imageHandler;

  return (
    <div className="w-full overflow-y-auto bg-white">
      <EditorToolbar submit={submit} translator={translator} titleSubmit={submit ? t('notes.reply') : ''} />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={t('notes.writeSomething')}
        formats={formats}
        modules={modules}
        readOnly={disabled}
      />
    </div>
  );
};
