import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { LicencingForms } from '@/modules/residents/presentation/components/Forms/LicencingForms';
import { FacilityForms } from '@/modules/residents/presentation/components/Forms/FacilityForms';
import { RiskLevel } from '@/modules/residents/presentation/components/Forms/RiskLevelForms';
import { FormsNavigation } from '../../components/Forms/ApprasialNeedsAndServicePlan/Navigation';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

const tabs = [
  { label: 'Licencing Forms', value: 'licencing_forms' },
  { label: 'Facility Forms', value: 'facility_forms' },
  { label: 'Risk Level Forms', value: 'risk_level_forms' }
];

export const ResidentFormsPage = () => {
  const { resident } = useSelector((state: RootState) => state.residents.resident || {});

  return (
    <>
      <div className="mt-16 flex flex-col gap-4">
        <FormsNavigation path={`/residents/profile?r=${resident?.id}`} />

        <Tabs defaultValue={tabs[0].value} className="w-full">
          <TabsList className="w-full h-full grid grid-cols-3 gap-2">
            {tabs.map((tab) => (
              <TabsTrigger
                key={tab.value}
                value={tab.value}
                className="data-[state=active]:bg-primary data-[state=active]:text-white whitespace-normal overflow-ellipsis overflow-hidden"
              >
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>
          <TabsContent value="licencing_forms">
            <LicencingForms />
          </TabsContent>
          <TabsContent value="facility_forms">
            <FacilityForms />
          </TabsContent>
          <TabsContent value="risk_level">
            <RiskLevel />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};
