import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { CustomDialog } from "@/common/presentation/components/CustomDialog/CustomDialog";
import { t } from "i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Input } from "@/common/presentation/components/ui/input";
import { saveOrUpdateCategories, bedroomQrCategoriesLoading, getBedroomQrCategories } from '@/modules/settings/presentation/components/menus/BedroomQr/slices/BedroomQrCategorySlice';
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { FormMessage } from "@/common/presentation/components/ui/form";
import { Switch } from "@/common/presentation/components/ui/switch";
import { Alert, AlertDescription } from "@/common/presentation/components/ui/alert";
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';

import { CheckCircle2 } from "lucide-react";

interface IDialogCreateOrUpdate {
    onChangeOpen: (open: boolean) => void;
    open: boolean;
    isUpdate?: boolean;
    onChangeIsUpdate?: (state: boolean) => void
    dataUpdate?: {
        id?: string;
        name: string;
        status: string;
        availability: string;
    };
    onChangeGetData?: (currentPage: number) => void
}

const FormSchema = z.object({
    name: z.string({
        required_error: "Name is required",
    }).min(1, {
        message: "Name is required"
    }),
    status: z.boolean()
});

type FormValues = z.infer<typeof FormSchema>;

export const DialogCreateOrUpdate: React.FC<IDialogCreateOrUpdate> = ({
    onChangeOpen,
    open,
    isUpdate = false,
    onChangeIsUpdate,
    dataUpdate,
    onChangeGetData
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [showSuccess, setShowSuccess] = useState(false);
    const { isLoading } = useSelector((state: RootState) => state.settings.bedroomQrCategory);

    
    const form = useForm<FormValues>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            name: "",
            status: true
        }
    });

    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        
        try {
            const payload = {
                ...data,
                idUpdate: isUpdate ? dataUpdate?.id : undefined
            };

            dispatch(bedroomQrCategoriesLoading());
            const response = await dispatch(saveOrUpdateCategories(payload));

            if (response.payload.result === 'ok') {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                    onChangeOpen(false);
                    onChangeIsUpdate?.(false);
                }, 3000);
                if (onChangeGetData) {
                    onChangeGetData(1);
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    useEffect(() => {
        if (open) {
            if (isUpdate && dataUpdate) {// when is update
                form.reset({
                    name: dataUpdate.name,
                    status: dataUpdate.status === 'active'
                });
            }
            if (!isUpdate) {
                form.reset({
                    name: "",
                    status: true
                });
            }

        } else {
            onChangeIsUpdate?.(false);
        }

    }, [open, isUpdate, dataUpdate]);


    useEffect(() => {
        dispatch(getBedroomQrCategories(1));
    }, [dispatch])



    return <>

        <CustomDialog
            height="auto"
            title={isUpdate ? t('common.update') : t('common.create')}
            width="30rem"
            newDialog={true}
            isOpen={open}
            onClose={() => onChangeOpen(false)}
            className="z-[999999] max-w-[92%] sm:max-w-[450px] "
            overFlow={false}
        >
            <div className="min-h-[270px]">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="h-[100%] space-y-4 flex flex-col justify-between h-[100%]">
                        {!isLoading && (
                                <div>

                                {(dataUpdate?.availability == 'single_client' || !isUpdate ) && 
                                    <FormField
                                        name="name"
                                        control={form.control}
                                        render={({ field }) => (
                                            <FormItem className="pb-4">
                                                <FormLabel>{t('Name')}</FormLabel>
                                                <FormControl>
                                                    <Input {...field} placeholder="Enter name" />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />}

                                    <FormField
                                        control={form.control}
                                        name="status"
                                        render={({ field }) => (
                                            <FormItem className="pb-4">
                                                <div className="space-y-0.5">
                                                    <FormLabel>{t('settings.status')}</FormLabel>
                                                </div>
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={field.onChange}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                        )}

                        {isLoading && (
                            <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
                                <Spinner size={'lg'} />
                            </div>
                        )}
                        <div className="absolute bottom-0 left-0 right-0 p-4">
                            {showSuccess ? (
                                <Alert className="bg-green-50 border-green-200 mb-4">
                                    <CheckCircle2 className="h-4 w-4 text-green-600" />
                                    <AlertDescription className="text-green-600">
                                    {isUpdate ? t('settings.updatedSuccessfully') : t('settings.createdSuccessfully')}
                                    </AlertDescription>
                                </Alert>
                            ) : (
                                <div className="flex justify-end">
                                    <Button type="submit">
                                        {t('settings.save')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </form>
                </Form>
            </div>
        </CustomDialog>



    </>
};