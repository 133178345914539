import { Badge } from '@/common/presentation/components/ui/badge';
import { Button } from '@/common/presentation/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';

import * as residentsConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import { useNewCustomField } from '@/modules/settings/infrastructure/hooks/use-new-custom-field';
import { useUpdateCustomField } from '@/modules/settings/infrastructure/hooks/use-update-custom-field';
import { AppDispatch, RootState } from '@/store/store';
import { Pencil, Plus, Trash } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';

export const CustomFields = () => {
  const { configurations } = useSelector((state: RootState) => state.residents.configurations);

  const dispatch = useDispatch<AppDispatch>();

  const { onOpen: onOpenNewCustomField } = useNewCustomField();
  const { onOpen: onOpenEditCustomField } = useUpdateCustomField();

  const handleDelete = async (id: string) => {
    const newConfigurations = [...configurations?.custom_form_fields?.filter((field: any) => field?.name !== id)];

    dispatch(
      residentsConfigurationsActions.postResidentsConfigurations({
        custom_form_fields: newConfigurations
      })
    );
  };

  return (
    <>
      <div className="flex justify-between items-center bg-neutralLight pt-2 pb-2 pl-2 pr-2 md:pl-6 md:pr-6 w-full rounded-md">
        <div className="pr-1">
          <h1 className="text-[14px] font-semibold text-grayBase">Custom Fields</h1>
          <p className='text-[12px] leading-[22px]'>Here you can manage the custom fields for the resident form.</p>
        </div>
        <Button onClick={onOpenNewCustomField} variant={'outline'}>
          <Plus className="size-4 mr-2" />
          Add field
        </Button>
      </div>


      <div className="flex flex-col gap-2 h-auto pt-3">
        {configurations?.custom_form_fields?.map((field: any, index: number) => {
          return (
            <Card key={index} className="flex hover:cursor-pointer">
              <CardHeader className="flex-1 py-2">
                <CardTitle className="text-[14px] font-semibold text-grayBase">
                  {field?.label}
                  {field?.required && (
                    <Badge className="ml-2 border border-foreground bg-transparent hover:bg-transparent text-foreground">
                      Required
                    </Badge>
                  )}
                </CardTitle>
                <CardDescription className='text-[12px] leading-[22px'>Type: {field?.type}</CardDescription>
              </CardHeader>
              <CardContent className="flex items-center gap-2 p-0 mx-4">
                <Button
                  onClick={() => onOpenEditCustomField(field?.name)}
                  size={'icon'}
                  variant={'outline'}
                  className="cursor-default"
                >
                  <Pencil className="size-4" />
                </Button>
                <Button
                  onClick={() => handleDelete(field?.name)}
                  size={'icon'}
                  variant={'outline'}
                  className="cursor-default"
                >
                  <Trash className="size-4" />
                </Button>
              </CardContent>
            </Card>
          );
        })}
      </div>

    </>
  );
};
