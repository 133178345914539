import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import VisitQuestions from "../infrastructure/services/VisitQuestion";

export interface IVisitQuestioState {
  questionVisit: [],
  isLoading: boolean;
  error: string | null;
}

const initialState: IVisitQuestioState = {
  questionVisit:[],
    isLoading: false,
    error: ''
};

export const updateVisitQuestion = createAsyncThunk('clientConfiguration/updateQuestionVisitStatus',
  async (formData: any ) => {
     const response = await VisitQuestions.updateVisitQuestion(formData);
     return response;
   }
 );

const QuestionVisitSlice = createSlice({
  name: 'questionVisit',
  initialState,
  reducers: {
    questionVisitLoading: (state)=>{
     state.isLoading = true;
     state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.
    addCase(updateVisitQuestion.fulfilled, (state, action) => {
      state.questionVisit = action.payload;
      state.isLoading = false;
    });
  }
});

export const { questionVisitLoading } = QuestionVisitSlice.actions;
export default QuestionVisitSlice.reducer;