import useRouteParams from '@/common/hooks/RouteParamsHook';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { useDashboardResidentsStore } from '@/modules/residents/domain/stores/dashboard/dasboardResidents';
import { useInnactiveResidentsDialog } from '@/modules/residents/infrastructure/hooks/dashboard/useInactiveResidentsDialog';
import { useEffect, useMemo } from 'react';
import { columns } from '../tables/InnactiveResidentsColumns';

export const InactiveResidentsDialog = () => {
  const { isOpen, onClose } = useInnactiveResidentsDialog();
  const { getInactiveResidents, inactiveResidents } = useDashboardResidentsStore();
  const { params } = useRouteParams();

  const residents = useMemo(() => {
    return inactiveResidents.map((resident) => {
      return {
        ...resident.resident,
        location: resident.location
      };
    });
  }, [inactiveResidents]);

  useEffect(() => {
    const location = params.l === 'all-locations' ? undefined : params.l;
    getInactiveResidents(location);
  }, []);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-[80vw]  max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl">In active Residents</DialogTitle>
          </DialogHeader>

          <SingleTable data={residents} columns={columns} disable={false} filterKey={'first_name'} />
        </DialogContent>
      </Dialog>
    </>
  );
};
