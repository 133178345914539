import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const VisitQuestions = {

  updateVisitQuestion: async (formData: any) => {
    try {
      const response = await axios.post(`configurations/updateQuestionVisitStatus`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error update visit questions: ${error.message}`);
    }
  },

}

export default VisitQuestions;