import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerService from '../../infrastructure/services/TrackerService';

interface ITrackerHygieneState {
    foodTypes: any[];
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: ITrackerHygieneState = {
    foodTypes: [],
    status: 'idle',
    error: null
};

export const getTrackerFoodType = createAsyncThunk("trackerFoodType/getTrackerFoodType", async (body: any) => {
  const response = await TrackerService.getTrackerFoodType(body);

  return response;
});

export const saveTrackerFood = createAsyncThunk("trackerFoodType/getTrackerFoodType", async (body: any) => {
    const response = await TrackerService.saveTrackerFood(body);
  
    return response;
  });
  

const trackerFood = createSlice({
  name: 'trackerFood',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getTrackerFoodType.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getTrackerFoodType.fulfilled, (state, action) => {
      state.status = 'idle';
      state.foodTypes = JSON.parse(action.payload.data);
      console.log("action.payload", action.payload);
    });

    builder.addCase(getTrackerFoodType.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});

export default trackerFood.reducer;
