import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';

import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Eye, EyeOff, Loader } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z.object({
  first_name: z
    .string({
      required_error: 'First name is required.'
    })
    .min(3, {
      message: 'First name must be at least 3 characters.'
    })
    .max(30, {
      message: 'First name must not be longer than 30 characters.'
    }),
  middle_name: z.string().optional(),
  last_name: z
    .string({
      required_error: 'Last name is required.'
    })
    .min(3, {
      message: 'Last name must be at least 3 characters.'
    })
    .max(30, {
      message: 'Last name must not be longer than 30 characters.'
    }),
  aka: z.string().optional(),
  dob: z.date({
    required_error: 'Date of birth is required.'
  }),
  sex: z.string().optional(),
  telephone: z.string().optional(),
  email: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip_code: z.string().optional(),
  place_of_birth: z.string().optional(),
  social_security: z.string().optional(),
  driver_license: z.string().optional()
});

type FormValues = z.infer<typeof formSchema>;

const formatSocialNumber = (value: string | undefined) => {
  if (value) {
    const valueStr = value.replace(/\D/g, '');

    let formatNumber = '';

    if (valueStr.length > 5) {
      formatNumber = `${valueStr.slice(0, 3)}-${valueStr.slice(3, 5)}-${valueStr.slice(5, 9)}`;
    } else if (valueStr.length > 3) {
      formatNumber = `${valueStr.slice(0, 3)}-${valueStr.slice(3, 5)}`;
    } else {
      formatNumber = valueStr;
    }

    return formatNumber;
  }

  return '';
};

const rolesToValidate = ['Super Administrator', 'Executive'];

export const PersonalInformationForm = ({ className }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMemberForm);
  const { user } = useSelector((state: RootState) => state.auth);
  const [socialSecurityVisible, setSocialSecurityVisible] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      aka: '',
      dob: undefined,
      social_security: '',
      sex: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      place_of_birth: '',
      telephone: '+1',
      email: '',
      driver_license: ''
    },
    mode: 'onChange'
  });

  useEffect(() => {
    if (staffMember) {
      form.reset({
        first_name: staffMember.first_name || '',
        middle_name: staffMember.middle_name || '',
        last_name: staffMember.last_name || '',
        aka: staffMember.aka || '',
        dob: staffMember.dob ? new Date(staffMember.dob) : undefined,
        social_security: formatSocialNumber(`${staffMember.social_security}`) || '',
        sex: staffMember.sex || '',
        address: staffMember.address || '',
        city: staffMember.city || '',
        state: staffMember.state || '',
        zip_code: staffMember.zip_code || '',
        place_of_birth: staffMember.place_of_birth || '',
        telephone: staffMember.telephone || '+1',
        email: staffMember.email || '',
        driver_license: staffMember.driver_license || ''
      });
    }
  }, [staffMember, form]);

  const isAdmin = useMemo(() => {
    return user?.roles.some((role: any) => rolesToValidate.includes(role.name));
  }, [user]);

  const isPersonalInformation = useMemo(() => {
    return user?.id === staffMember?.id;
  }, [user, staffMember]);

  const dob = form.watch('dob');

  const age = useMemo(() => {
    if (dob) {
      const diff = Date.now() - dob.getTime();
      const ageDate = new Date(diff);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    return 0;
  }, [dob]);

  const onSubmitForm = async (data: FormValues) => {
    const response = await dispatch(
      staffMemberFormActions.updateStaffPersonalInformationForm({
        staff_member_id: staffMember?.id,
        ...data,
        social_security: data?.social_security?.split('-').join('')
      })
    );
    if (response.payload) {
      toast({
        description: 'Staff information updated successfully'
      });

      form.reset(data);
    } else {
      toast({
        description: 'Error updating staff information',
        variant: 'destructive'
      });
    }
  };

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load resident</div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Personal Information" openOption={false} defaultOpen>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="flex flex-col gap-4 px-2 bg-gray-50 dark:bg-[#1d1d1d] p-3 rounded-lg shadow-sm">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <FormField
                      control={form.control}
                      name="first_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            First Name <span className="text-primary">*</span>
                          </FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="middle_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Middle Name</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="last_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Last Name <span className="text-primary">*</span>
                          </FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="aka"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>AKA's</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <div className="grid grid-cols-12 gap-2 items-end">
                      <div className="col-span-9">
                        <FormField
                          control={form.control}
                          name="dob"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>
                                Date of Birth <span className="text-primary">*</span>
                              </FormLabel>
                              <Popover>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant={'outline'}
                                      className={cn(
                                        'w-full pl-3 text-left font-normal',
                                        !field.value && 'text-muted-foreground'
                                      )}
                                      disabled={staffFormStatus === 'loading'}
                                    >
                                      {field.value ? format(field.value, 'MM/dd/yyyy') : <span>Select a date</span>}
                                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent className="w-auto p-0" align="start">
                                  <div className="rounded-md border">
                                    <CustomCalendar
                                      onChange={(date) => {
                                        field.onChange(date);
                                      }}
                                      selectedDate={field.value}
                                      MonthAndYearPicker
                                      maxSelectorYear={new Date().getFullYear()}
                                    />
                                  </div>
                                </PopoverContent>
                              </Popover>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="col-span-3 flex flex-col gap-3">
                        <Label>Age</Label>
                        <Button variant={'outline'} className="w-full" disabled>
                          {age}
                        </Button>
                      </div>
                    </div>

                    <FormField
                      control={form.control}
                      name="sex"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Sex</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                            defaultValue={field.value}
                            disabled={staffFormStatus === 'loading'}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select a sex" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="male">Male</SelectItem>
                              <SelectItem value="female">Female</SelectItem>
                              <SelectItem value="other">Other</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="place_of_birth"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Place of birth</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="telephone"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Telephone</FormLabel>
                          <FormControl>
                            <PhoneInput
                              {...field}
                              value={field.value as any}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              disabled={staffFormStatus === 'loading'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} type="email" disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="address"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Address</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="city"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>City</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="state"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>State</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="zip_code"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Zip Code</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="social_security"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Social Security (Optional)</FormLabel>
                          <FormControl>
                            <div className="relative flex items-center">
                              <Input
                                {...field}
                                type={socialSecurityVisible ? 'text' : 'password'}
                                value={field.value || ''}
                                onChange={(e) => {
                                  field.onChange(formatSocialNumber(e.target.value));
                                }}
                                disabled={staffFormStatus === 'loading' || !isAdmin}
                                placeholder=""
                                autoCorrect="off"
                                autoComplete="off"
                                className="w-full rounded-lg bg-background"
                              />
                              {(isAdmin || isPersonalInformation) && (
                                <>
                                  {socialSecurityVisible ? (
                                    <EyeOff
                                      className="absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground cursor-pointer"
                                      onClick={() => setSocialSecurityVisible(false)}
                                    />
                                  ) : (
                                    <Eye
                                      className="absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground cursor-pointer"
                                      onClick={() => setSocialSecurityVisible(true)}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="driver_license"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>CA Driver's License or ID Number</FormLabel>
                          <FormControl>
                            <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                {form.formState.isDirty && (
                  <div className="mt-6 flex justify-end space-x-2">
                    <Button type="submit" disabled={staffFormStatus === 'loading'}>
                      {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                      Update
                    </Button>
                  </div>
                )}
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
